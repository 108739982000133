export const getFeedIngredients = async (index: string) => {
  const axiosInstance = require('utils/utils-axios-instance').default;
  const data = await axiosInstance.get(`index/${index}/api/document`);
  return data.data;
};

export const uploadFeedIngredients = async (
  index: string | number,
  body: { filename: string }
) => {
  const axiosInstance = require('utils/utils-axios-instance').default;
  const data = await axiosInstance.post(`index/${index}/api/document`, body);
  return data.data;
};

export const deleteFeedIngredients = async (
  index: string | number,
  document_id: string | number
) => {
  const axiosInstance = require('utils/utils-axios-instance').default;
  const data = await axiosInstance.delete(
    `index/${index}/api/document/delete/${document_id}`
  );
  return data.data;
};

export const previewFeedIngredientItems = async (
  index: string | number,
  document_id: string | number,
  page: number,
  offset: number
) => {
  const axiosInstance = require('utils/utils-axios-instance').default;
  const data = await axiosInstance.get(
    `index/${index}/api/document/row/${document_id}?page=${page}&offset=${offset}`
  );
  return data.data;
};

export const getDocumentImportPresignedUrl = async (
  index: string | number,
  body: { filename: string; type: string }
) => {
  const axiosInstance = require('utils/utils-axios-instance').default;
  const data = await axiosInstance.post(
    `index/${index}/api/presigned_url_document_import`,
    body
  );
  return data.data;
};

export const getDocumentIngredient = async (
  index: string | number,
  document_id: string | number
) => {
  const axiosInstance = require('utils/utils-axios-instance').default;
  const data = await axiosInstance.get(
    `index/${index}/api/document/${document_id}`
  );
  return data.data;
};

export const saveDocumentIngredient = async (
  index: string | number,
  document_id: string | number,
  body: any
) => {
  const axiosInstance = require('utils/utils-axios-instance').default;
  const data = await axiosInstance.put(
    `index/${index}/api/document/${document_id}`,
    body
  );
  return data.data;
};
