import {
  ColOrderType,
  IntervalType,
  PublishOptionType,
  RecipeType,
} from 'types/types';

export const getOpportunities = async (index: string | number) => {
  const axiosInstance = require('utils/utils-axios-instance').default;
  const data = await axiosInstance.get(`index/${index}/api/opportunity/`);
  return data.data;
};

export const getOpportunityItem = async (
  index: string | number,
  opporId: string | number
) => {
  const axiosInstance = require('utils/utils-axios-instance').default;
  const data = await axiosInstance.get(
    `index/${index}/api/opportunity/${opporId}`
  );
  return data.data;
};

export const postOpportunityItem = async (
  index: string | number,
  opportunityId: string | number,
  backQuery: any,
  page?: number,
  offset?: number,
  order_column?: string,
  order_dir?: string
) => {
  let _page = page || 0;
  let _offset = offset || 10;
  const axiosInstance = require('utils/utils-axios-instance').default;
  const data = await axiosInstance.post(
    `index/${index}/api/opportunity/${opportunityId}?page=${_page}&offset=${_offset}&needRecipeGroup=${true}`,
    { backQuery, order_column, order_dir }
  );
  return data.data;
};

export const updateOpportunityItem = async (
  index: string | number,
  opportunityId: string | number,
  body: any
) => {
  const axiosInstance = require('utils/utils-axios-instance').default;
  const data = await axiosInstance.patch(
    `index/${index}/api/opportunity/${opportunityId}`,
    body
  );
  return data.data;
};

export const deleteOpportunityItem = async (
  index: string | number,
  opportunityId: string | number
) => {
  const axiosInstance = require('utils/utils-axios-instance').default;
  const data = await axiosInstance.delete(
    `index/${index}/api/opportunity/${opportunityId}`
  );
  return data.data;
};

export const getLinkingModuleList = async (index: string | number) => {
  const axiosInstance = require('utils/utils-axios-instance').default;
  const data = await axiosInstance.get(
    `index/${index}/api/internal-linking-config/`
  );
  return data.data;
};

export const postLinkingModule = async (index: string | number, body: any) => {
  const axiosInstance = require('utils/utils-axios-instance').default;
  const data = await axiosInstance.post(
    `index/${index}/api/internal-linking-config/`,
    body
  );
  return data.data;
};

export const getLinkingModule = async (
  index: string | number,
  id: string | number
) => {
  const axiosInstance = require('utils/utils-axios-instance').default;
  const data = await axiosInstance.get(
    `index/${index}/api/internal-linking-config/${id}?&needRecipeGroup=${true}`
  );
  return data.data;
};

export const updateLinkingModule = async (
  index: string | number,
  id: string | number,
  body: any
) => {
  const axiosInstance = require('utils/utils-axios-instance').default;
  const data = await axiosInstance.patch(
    `index/${index}/api/internal-linking-config/${id}/`,
    body
  );
  return data.data;
};

export const deleteLinkingModule = async (
  index: string | number,
  id: string | number
) => {
  const axiosInstance = require('utils/utils-axios-instance').default;
  const data = await axiosInstance.delete(
    `index/${index}/api/internal-linking-config/${id}/`
  );
  return data.data;
};

export const postOpportunity = async (
  action: PublishOptionType,
  refresh_interval: IntervalType,
  index: string,
  name: string,
  description: string,
  show_version?: string,
  recipe?: any,
  config_ids?: number[],
  col_sorting?: ColOrderType,
  col_invisible?: string[],
  col_display_order?: Record<string, number>,
  col_width?: Record<string, number>
) => {
  const body: Partial<
    RecipeType & {
      description: string;
      config_ids: number[];
      col_sorting?: ColOrderType;
      col_invisible?: string[];
      col_display_order?: Record<string, number>;
      col_width?: Record<string, number>;
      refresh_interval: IntervalType;
    }
  > = {
    name: '',
    action: 'noindex',
    enabled: false,
    default: false,
  };
  body.action = action;
  body.refresh_interval = refresh_interval;
  if (recipe) body['recipe'] = recipe;
  if (description) body['description'] = description;
  if (col_sorting) body['col_sorting'] = col_sorting;
  if (col_invisible) body['col_invisible'] = col_invisible;
  if (col_display_order) body['col_display_order'] = col_display_order;
  if (col_width) body['col_width'] = col_width;
  body.name = name;
  if (action === 'use in linking' && config_ids?.length === 0)
    throw Error('No linking module specified.');
  if (config_ids) body.config_ids = config_ids;
  const axiosInstance = require('utils/utils-axios-instance').default;
  let data;
  if (show_version) {
    data = await axiosInstance.post(
      `index/${index}/api/opportunity?show_version=${show_version}`,
      body
    );
  } else {
    data = await axiosInstance.post(`index/${index}/api/opportunity`, body);
  }
  return data.data;
};

export const previewOpportunity = async (
  index: string,
  id: number | string,
  body?: any
) => {
  const axiosInstance = require('utils/utils-axios-instance').default;
  const data = await axiosInstance.post(
    `index/${index}/api/opportunity/${id}/preview`,
    body
  );
  return data.data;
};

export const previewConfirmOpportunity = async (
  index: string,
  id: number | string
) => {
  const axiosInstance = require('utils/utils-axios-instance').default;
  const data = await axiosInstance.post(
    `index/${index}/api/opportunity/${id}/preview/confirm`
  );
  return data.data;
};

export const publishOpportunity = async (
  index: string,
  id: number | string,
  body: any
) => {
  const axiosInstance = require('utils/utils-axios-instance').default;
  const data = await axiosInstance.post(
    `index/${index}/api/opportunity/${id}/publish`,
    body
  );
  return data.data;
};

export const postCreateSegment = async (index: string, body: any) => {
  const axiosInstance = require('utils/utils-axios-instance').default;
  const data = await axiosInstance.post(
    `index/${index}/api/opportunity/create-segment`,
    body
  );
  return data.data;
};

export const cloneOpportunity = async (
  index: string,
  id: number | string,
  body: any
) => {
  const axiosInstance = require('utils/utils-axios-instance').default;
  const data = await axiosInstance.post(
    `index/${index}/api/opportunity/${id}/clone`,
    body
  );
  return data.data;
};

export const refreshListing = async (index: string, id: number | string) => {
  const axiosInstance = require('utils/utils-axios-instance').default;
  const data = await axiosInstance.post(
    `index/${index}/api/opportunity/${id}/refresh_listing`
  );
  return data.data;
};

export const linkingVerificationResult = async (
  index: string,
  id: number | string,
  body: any
) => {
  const axiosInstance = require('utils/utils-axios-instance').default;
  const data = await axiosInstance.post(
    `index/${index}/api/opportunity/${id}/linking-verification-result`,
    body
  );
  return data.data;
};

export const linkingVerificationResultSummary = async (
  index: string,
  id: number | string
) => {
  const axiosInstance = require('utils/utils-axios-instance').default;
  const data = await axiosInstance.post(
    `index/${index}/api/opportunity/${id}/linking-verification-result-summary`
  );
  return data.data;
};

export const verify = async (index: string, id: number | string) => {
  const axiosInstance = require('utils/utils-axios-instance').default;
  const data = await axiosInstance.post(
    `index/${index}/api/opportunity/${id}/verify`
  );
  return data.data;
};

export const postNewPageOpportunityAction = async (
  index: string,
  opporId: string | number,
  body: any
) => {
  const axiosInstance = require('utils/utils-axios-instance').default;
  const data = await axiosInstance.post(
    `index/${index}/api/opportunity/${opporId}/apply-new-page-opportunity-action/`,
    body
  );
  return data.data;
};

export const patchNewPageOpportunityKeywordProducts = async (
  index: string,
  opporId: string | number,
  body: any
) => {
  const axiosInstance = require('utils/utils-axios-instance').default;
  const data = await axiosInstance.patch(
    `index/${index}/api/opportunity/${opporId}/update-keyword-and-product-info/`,
    body
  );
  return data.data;
};

export const createPageCandidates = async (
  index: string,
  opporId: string | number,
  body: any
) => {
  const axiosInstance = require('utils/utils-axios-instance').default;
  const data = await axiosInstance.post(
    `index/${index}/api/opportunity/${opporId}/create-page-candidates/`,
    body
  );
  return data.data;
};

export const postGetNewPageOpportunityKeywordProducts = async (
  index: string,
  opporId: string | number,
  body: any
) => {
  const axiosInstance = require('utils/utils-axios-instance').default;
  const data = await axiosInstance.post(
    `index/${index}/api/opportunity/${opporId}/get-keyword-and-product-info/`,
    body
  );
  return data.data;
};

export const bulkFlagIrrelevant = async (
  index: string,
  opporId: string | number,
  body: any
) => {
  const axiosInstance = require('utils/utils-axios-instance').default;
  const data = await axiosInstance.post(
    `index/${index}/api/opportunity/${opporId}/bulk-flag-irrelevant/`,
    body
  );
  return data.data;
};
