import { postLogin } from 'apis/login';
import jwt_decode from 'jwt-decode';
import {
  createContext,
  FunctionComponent,
  ReactNode,
  useState,
  useContext,
} from 'react';
import { UserType } from 'types/types';
import { useSnackbar } from './snackbar-context';

type AuthProps = {
  user: UserType | null;
  login: (email: string, password: string) => void;
  logout: () => void;
};

const initialAuth: AuthProps = {
  user: null,
  login: Function,
  logout: Function,
};

const AuthContext = createContext<AuthProps>(initialAuth);
export default AuthContext;

type Props = {
  children: ReactNode;
};

export const AuthProvider: FunctionComponent<Props> = ({ children }) => {
  const username = localStorage.getItem('username');
  let isStaff = false;
  const token = localStorage.getItem('accessToken');
  if (token) {
    const initUser: any = jwt_decode(token);
    isStaff = initUser?.is_staff;
  }
  const [user, setUser] = useState<UserType | null>(
    username ? { username, isStaff } : null
  );
  const { setSnackbar } = useSnackbar();

  const login = async (username: string, password: string) => {
    try {
      setSnackbar({ severity: 'info', message: 'Login ...' });
      const data = await postLogin(username, password);
      const user: any = jwt_decode(data.access);
      setUser({ username, isStaff: user.is_staff });
      localStorage.setItem('username', username);
      localStorage.setItem('accessToken', data.access);
      localStorage.setItem('refreshToken', data.refresh);
      setSnackbar({
        severity: 'success',
        message: 'Login success!',
        duration: 1000,
      });
    } catch (err) {
      setSnackbar({ severity: 'error', message: 'Login failed.' });
    }
  };

  const logout = () => {
    setUser(null);
    localStorage.removeItem('username');
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('isStaff');
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        login,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const ctx = useContext(AuthContext);
  return ctx;
};
