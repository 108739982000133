import {
  Box,
  Button,
  ListSubheader,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from '@mui/material';
import {
  postWorkbenchKeywordAction,
  postWorkbenchPagesAction,
  postWorkbenchTopicsAction,
} from 'apis/workbench';
import { useUser } from 'contexts/user-context';
import { FC, useEffect, useState } from 'react';
import { OptionType, WBSourceType } from 'types/types';
import ActionApplyDialog from './dialog/ActionApplyDialog';
import { useSnackbar } from 'contexts/snackbar-context';
import { useQuery } from '@tanstack/react-query';
import { postGetPagedLabels } from 'apis/index_labels';
import { generateBackQueryFromTopicIds, isEmpty } from 'utils';
import { GroupOptionsType } from 'hooks/useWBActions';
import { postNewPageOpportunityAction } from 'apis/opportunities';
import { useAtom } from 'jotai';
import {
  WBColPreviewAtom,
  selectedFunctionIngredientIdAtom,
  selectedNewPageOpportunityTopicIdsAtom,
} from 'store/jotai';

type Props = {
  fieldOptions: OptionType[];
  options2?: OptionType[];
  backQuery: any;
  source: WBSourceType;
  onSelectOption?: (value: string) => void;
  isSupportRowSelector?: boolean;
  opporId?: number;
  groupOptions: GroupOptionsType;
};

const ActionSelector: FC<Props> = ({
  fieldOptions,
  options2,
  backQuery,
  source,
  isSupportRowSelector,
  onSelectOption,
  opporId,
  groupOptions,
}) => {
  const { setSnackbar } = useSnackbar();
  const { curIndex } = useUser();
  const [action, setAction] = useState('');
  const [field, setField] = useState('');
  const [option2, setOption2] = useState('');
  const [labelGroupId, setLabelGroupId] = useState('');

  const [, setSelectedFunctionIngredientId] = useAtom(
    selectedFunctionIngredientIdAtom
  );
  const [colPreview] = useAtom(WBColPreviewAtom);

  useEffect(() => {
    if (!field && fieldOptions.length === 1) {
      setField(fieldOptions[0].value);
      if (colPreview === 'GPT') {
        setSelectedFunctionIngredientId(+fieldOptions[0].value);
      } else {
        setSelectedFunctionIngredientId(undefined);
      }
    }
  }, [field, fieldOptions, colPreview, setSelectedFunctionIngredientId]);

  const handleSelectAction = (e: SelectChangeEvent<string>) => {
    const action = e.target.value;
    setAction(action);
    if (onSelectOption) {
      onSelectOption(action);
    }
  };

  const handleSelectField = (e: React.ChangeEvent<HTMLInputElement>) => {
    const field = e.target.value;
    setField(field);
    if (colPreview === 'GPT') {
      setSelectedFunctionIngredientId(+field);
    } else {
      setSelectedFunctionIngredientId(undefined);
    }
  };

  const handleSelectLabelGroupId = (e: React.ChangeEvent<HTMLInputElement>) => {
    const labelGroup = e.target.value;
    setLabelGroupId(labelGroup);
  };

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const handleDialogOpen = () => {
    setIsDialogOpen(true);
  };
  const handleDialogClose = () => {
    setIsDialogOpen(false);
  };

  const { data: dataLabelGroups } = useQuery(
    [curIndex, 'LabelGroups'],
    () => postGetPagedLabels(curIndex!.value, {}),
    { enabled: Boolean(curIndex), staleTime: 1000 * 60 }
  );
  let labelGroupOptions: OptionType[] = [];
  if (!isEmpty(dataLabelGroups?.items)) {
    labelGroupOptions = dataLabelGroups.items.map((item: any) => ({
      label: item.name,
      value: item.id,
    }));
  }

  const [newPageOpporTopicIds] = useAtom(
    selectedNewPageOpportunityTopicIdsAtom
  );

  const handleApply = async (body: any) => {
    setIsDialogOpen(false);
    const { selector } = body;
    try {
      if (source === 'keyword') {
        await postWorkbenchKeywordAction(curIndex!.value, {
          action,
          field,
          option: option2,
          back_query: backQuery,
          label_group_id: labelGroupId,
          opporId,
          selector,
        });
      } else if (source === 'page') {
        await postWorkbenchPagesAction(curIndex!.value, {
          action,
          field,
          option: option2,
          back_query: backQuery,
          label_group_id: labelGroupId,
          opporId,
          selector,
        });
      } else if (source === 'topic') {
        await postWorkbenchTopicsAction(curIndex!.value, {
          action,
          field,
          back_query: backQuery,
          label_group_id: labelGroupId,
          selector,
        });
      } else if (source === 'site') {
        await postWorkbenchPagesAction(curIndex!.value, {
          action,
          field,
          back_query: backQuery,
          label_group_id: labelGroupId,
          selector,
        });
      } else if (source === 'new_page_opportunity') {
        if (newPageOpporTopicIds.length > 0) {
          backQuery = generateBackQueryFromTopicIds(newPageOpporTopicIds);
        }
        await postNewPageOpportunityAction(curIndex!.value, opporId!, {
          action,
          field,
          back_query: backQuery,
          label_group_id: labelGroupId,
          selector,
        });
      }
      setSnackbar({ severity: 'success', message: 'Applied.' });
    } catch (err: any) {
      if (err?.response?.status === 400) {
        const msg = err.response.data?.errMsg;
        if (msg) {
          setSnackbar({
            severity: 'error',
            message: msg,
            duration: 9999999999,
          });
        }
      }
    }
  };
  const showLabelGroup = action === 'label_classifier' && Boolean(field);

  return (
    <>
      {isDialogOpen && (
        <ActionApplyDialog
          open={isDialogOpen}
          opporId={opporId}
          isSupportRowSelector={isSupportRowSelector}
          actionConfirmParam={{
            source,
            action,
            field,
            backQuery,
            labelGroupId,
          }}
          onClose={handleDialogClose}
          onSave={handleApply}
        />
      )}
      <Box
        sx={{
          p: 2,
          display: 'flex',
          gap: 2,
          borderBottom: '1px solid #ddd',
          alignItems: 'center',
        }}
      >
        <Typography variant="h4">Step / Action</Typography>
        <Select
          sx={{ minWidth: 300 }}
          defaultValue=""
          size="small"
          value={action}
          onChange={handleSelectAction}
        >
          <ListSubheader sx={{ color: '#999' }}>Step</ListSubheader>
          {groupOptions.step.map((item) => (
            <MenuItem key={item.value} value={item.value}>
              {item.label}
            </MenuItem>
          ))}
          <ListSubheader sx={{ color: '#999' }}>Action</ListSubheader>
          {groupOptions.action.map((item) => (
            <MenuItem key={item.value} value={item.value}>
              {item.label}
            </MenuItem>
          ))}
          <ListSubheader sx={{ color: '#999' }}>
            Create opportunity
          </ListSubheader>
          {groupOptions.create_opportunity.map((item) => (
            <MenuItem key={item.value} value={item.value}>
              {item.label}
            </MenuItem>
          ))}
        </Select>

        {fieldOptions.length > 0 && (
          <TextField
            sx={{ minWidth: 300 }}
            select
            defaultValue=""
            size="small"
            label="Select Field"
            value={field}
            onChange={handleSelectField}
          >
            {fieldOptions.map((item) => (
              <MenuItem key={item.value} value={item.value}>
                {item.label}
              </MenuItem>
            ))}
          </TextField>
        )}
        {options2 && options2.length > 0 && (
          <TextField
            sx={{ minWidth: 300 }}
            select
            defaultValue=""
            size="small"
            label="Select Option"
            value={option2}
            onChange={(e) => setOption2(e.target.value)}
          >
            {options2.map((item) => (
              <MenuItem key={item.value} value={item.value}>
                {item.label}
              </MenuItem>
            ))}
          </TextField>
        )}
        {showLabelGroup && (
          <TextField
            sx={{ minWidth: 300 }}
            select
            defaultValue=""
            size="small"
            label="Select Label Group"
            value={labelGroupId}
            onChange={handleSelectLabelGroupId}
          >
            {labelGroupOptions.map((item) => (
              <MenuItem key={item.value} value={item.value}>
                {item.label}
              </MenuItem>
            ))}
          </TextField>
        )}
        {field && action && (
          <Button variant="contained" onClick={handleDialogOpen}>
            Apply
          </Button>
        )}
      </Box>
    </>
  );
};

export default ActionSelector;
