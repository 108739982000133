import { Box, Paper, Typography } from '@mui/material';
import { FC } from 'react';
import GSCConnection from './GSCConnection';
import BotifyConnection from './BotifyConnection';
import OnCrawlConnection from './OnCrawlConnection';
import JetOctopusConnection from './JetOctopusConnection';
import XmlRpcConnection from './XmlRpcConnection';

const SettingsConnections: FC = () => {
  return (
    <Box sx={{ p: 2 }}>
      <Box>
        <Typography variant="h6" sx={{ fontWeight: 700 }}>
          Seamless Integrations.
        </Typography>
      </Box>
      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2, mt: 2 }}>
        <Paper sx={{ p: 2, minWidth: 420 }}>
          <GSCConnection />
        </Paper>
        <Paper sx={{ p: 2, minWidth: 420 }}>
          <BotifyConnection />
        </Paper>
        <Paper sx={{ p: 2, minWidth: 420 }}>
          <OnCrawlConnection />
        </Paper>
        <Paper sx={{ p: 2, minWidth: 420 }}>
          <JetOctopusConnection />
        </Paper>
        <Paper sx={{ p: 2, minWidth: 420 }}>
          <XmlRpcConnection />
        </Paper>
      </Box>
    </Box>
  );
};

export default SettingsConnections;
