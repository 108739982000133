import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { FunctionComponent } from 'react';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

type Props = {
  labelX: string;
  labels: (string | number)[];
  bars: number[];
  title?: string;
  height?: string;
};

const HorizontalBarChart: FunctionComponent<Props> = ({
  labelX,
  labels,
  bars,
  title,
  height,
}) => {
  const _height = height || '100px';
  let _title = { display: false, text: title };
  if (title) _title.display = true;

  const options = {
    indexAxis: 'y' as const,
    responsive: true,
    plugins: {
      legend: {
        position: 'top' as const,
      },
      title: _title,
    },
  };

  const data = {
    labels,
    datasets: [
      {
        label: labelX,
        data: bars,
        backgroundColor: 'rgba(53, 162, 235, 0.5)',
      },
    ],
  };

  return <Bar height={_height} options={options} data={data} />;
};

export default HorizontalBarChart;
