import { Typography } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import { FC } from 'react';

type _CSCType = {
  knowledge_graph: {
    website: null | string;
    phone: null | string;
    street: null | string;
    description: null | string;
    title: null | string;
    classification: null | string;
  };
  auto_corrected_to: null | string;
  ad_count: number;
  shopping: boolean;
};
export type CanonicalSerpCtxType = _CSCType | [_CSCType];

const AdCountCell: FC<{
  params: GridRenderCellParams<any>;
}> = ({ params }) => {
  let ctx = params.row.canonical_serp_context as CanonicalSerpCtxType;
  if (!ctx) return <></>;
  if (Array.isArray(ctx)) ctx = ctx[0];
  const adCount = ctx.ad_count;
  return <Typography variant="body2">{adCount}</Typography>;
};

export const renderAdCount = (params: GridRenderCellParams<any>) => {
  return <AdCountCell params={params} />;
};

const ShoppingCell: FC<{
  params: GridRenderCellParams<any>;
}> = ({ params }) => {
  let ctx = params.row.canonical_serp_context as CanonicalSerpCtxType;
  if (!ctx) return <></>;
  if (Array.isArray(ctx)) ctx = ctx[0];
  const shopping = ctx.shopping;
  if (!shopping) {
    return <></>;
  }
  return <Typography variant="body2">{shopping ? 'Yes' : 'No'}</Typography>;
};

export const renderShopping = (params: GridRenderCellParams<any>) => {
  return <ShoppingCell params={params} />;
};

const TitleCell: FC<{
  params: GridRenderCellParams<any>;
}> = ({ params }) => {
  let ctx = params.row.canonical_serp_context as CanonicalSerpCtxType;
  if (!ctx) return <></>;
  if (Array.isArray(ctx)) ctx = ctx[0];
  if (!ctx.knowledge_graph) return <></>;
  const title = ctx.knowledge_graph.title;
  return <Typography variant="body2">{title}</Typography>;
};

export const renderTitle = (params: GridRenderCellParams<any>) => {
  return <TitleCell params={params} />;
};
