import { Box } from '@mui/material';

import { FC } from 'react';
import { useParams } from 'react-router-dom';
import SiteTopicTopGrammarTable from './SiteTopicTopGrammarTable';

const SiteTopicTopGrammarMain: FC = () => {
  const params = useParams();
  const reportId = params.reportId!;

  return (
    <Box sx={{ ml: 3, mr: 3, mt: 4 }}>
      <SiteTopicTopGrammarTable report_id={reportId} />
    </Box>
  );
};

export default SiteTopicTopGrammarMain;
