import { FC, useEffect, useRef, useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { WBSourceType } from 'types/types';
import { useQuery } from '@tanstack/react-query';
import { CSVLink } from 'react-csv';
import { useUser } from 'contexts/user-context';
import { postWidgetItem } from 'apis/widgets';
import LoadingSpinner from 'components/shared/ui/LoadingSpinner';
import DoughnutChart from 'components/chart/DoughnutChart';
import AddEditWidgetDlg from './AddEditWidgetDlg';
import HorizontalBarChart2 from 'components/chart/HorizontalBarChart2';
import DelWidgetDlg from './DelWidgetDlg';
import { useNavigate } from 'react-router-dom';
import { toLocaleString } from 'utils';

export type WidgetItemAggType =
  | 'sum'
  | 'avg'
  | 'min'
  | 'max'
  | 'value_count'
  | 'cardinality';

export type WidgetItemFieldType = 'continuous' | 'less_than_1' | 'categorical';

export type WidgetItemGraphType = 'histogram' | 'pie_chart' | 'value';

export type WidgetItemType = {
  id: number;
  name: string;
  aggregation: WidgetItemAggType;
  field: string;
  by_field?: string;
  cutoff?: number;
  field_type: WidgetItemFieldType;
  graph: WidgetItemGraphType;
  source: WBSourceType;
  template: string | number;
  opportunity?: number;
  version?: string;
};
type Props = {
  item: WidgetItemType;
  isSimpleView?: boolean;
  isSingleView?: boolean;
  isExport?: boolean;
  setIsExport?: React.Dispatch<React.SetStateAction<boolean>>;
  back_query?: any;
  opporId?: number;
};
export const WidgetItem: FC<Props> = ({
  item,
  isExport,
  setIsExport,
  isSingleView,
  isSimpleView,
  back_query,
  opporId,
}) => {
  const graph = item.graph;
  const { curIndex } = useUser();
  const { data, isLoading } = useQuery(
    [curIndex, 'Widget', item.id, back_query, opporId],
    () => postWidgetItem(curIndex!.value, item.id, { back_query, opporId }),
    { staleTime: 60 * 1000 }
  );
  let dataPoints: number[] = [];
  let dataPoints2: number[] = [];
  let labels: string[] = [];
  let dataVal: string | number = '';
  if (data?.aggregations) {
    const aggs = data.aggregations;
    const key = Object.keys(aggs)[0];
    const dataAgg = aggs[key];
    if (
      (!item.by_field && graph === 'histogram') ||
      (!item.by_field && graph === 'pie_chart')
    ) {
      if (Array.isArray(dataAgg)) {
        labels = dataAgg.map((item: any) => item.key);
        dataPoints = dataAgg.map((item: any) => item.doc_count);
      }
    } else if (graph === 'value') {
      dataVal = Object.values(dataAgg)[0] as string;
    } else if (item.by_field) {
      if (Array.isArray(aggs)) {
        labels = aggs.map((item: any) => item.key);
        dataPoints = aggs.map((item: any) => item.doc_count);
        dataPoints2 = aggs.map((_item: any) => _item[item.field]);
      }
    }
  }
  let height = '400px';
  if (labels.length > 10) height = `${labels.length * 40}px`;
  const [editWidgetDialogOpen, setEditWidgetDialogOpen] = useState(false);
  const [deleteWidgetDialogOpen, setDeleteWidgetDialogOpen] = useState(false);

  // Export CSV
  let headers = ['category', 'count'];
  let row: any[] = [];

  if (labels && labels.length > 0) {
    let points = dataPoints;
    if (item.by_field) points = dataPoints2;
    for (let i = 0; i < labels.length; i++) {
      row.push([labels[i], points[i]]);
    }
  }

  if (graph === 'value') {
    headers = [item.name];
    row = [[dataVal || '']];
  }
  const csvData = [headers, ...row];

  const csvBtnRef = useRef<any>();

  useEffect(() => {
    if (isExport && setIsExport && csvBtnRef.current) {
      csvBtnRef.current.link.click();
      setIsExport(false);
    }
  }, [isExport, setIsExport]);

  const title = item.id + ' - ' + item.name;

  const navigator = useNavigate();
  const onClickView = () => {
    navigator(`?id=${item.id}`);
  };

  return (
    <Box
      sx={{
        mx: isSingleView ? 'auto' : undefined,
        width: isSimpleView ? 300 : 480,
        mt: isSimpleView ? 1 : 4,
        p: isSimpleView ? 1 : 2,
        border: '1px solid #ccc',
      }}
    >
      {editWidgetDialogOpen && (
        <AddEditWidgetDlg
          open={editWidgetDialogOpen}
          openFrom={
            isSimpleView ? { button: 'Edit', source: item.source } : undefined
          }
          onClose={() => setEditWidgetDialogOpen(false)}
          widget={item}
        />
      )}
      {deleteWidgetDialogOpen && (
        <DelWidgetDlg
          open={deleteWidgetDialogOpen}
          onClose={() => setDeleteWidgetDialogOpen(false)}
          id={item.id}
          name={title}
        />
      )}
      {isLoading && <LoadingSpinner />}
      {!isLoading && (
        <Box
          sx={{
            display: 'flex',
            gap: 2,
            mb: isSimpleView ? 1 : 2,
            alignItems: 'center',
          }}
        >
          {!isSingleView && !isSimpleView && (
            <Button variant="outlined" color="success" onClick={onClickView}>
              View
            </Button>
          )}
          <Button
            variant={isSimpleView ? 'text' : 'outlined'}
            size={isSimpleView ? 'small' : 'medium'}
            onClick={() => setEditWidgetDialogOpen(true)}
          >
            Edit
          </Button>
          <Button
            variant={isSimpleView ? 'text' : 'outlined'}
            size={isSimpleView ? 'small' : 'medium'}
            color="error"
            onClick={() => setDeleteWidgetDialogOpen(true)}
          >
            Delete
          </Button>
          <CSVLink ref={csvBtnRef} data={csvData} filename={item.name}>
            Export
          </CSVLink>
        </Box>
      )}
      {!isLoading && graph === 'histogram' && (
        <HorizontalBarChart2
          title={title}
          bars={dataPoints}
          bars2={dataPoints2}
          labels={labels}
          labelX={'doc_count'}
          labelX2={item.field}
          labelY={item.aggregation}
          height={height}
        />
      )}
      {!isLoading && graph === 'pie_chart' && (
        <DoughnutChart _data={dataPoints} labels={labels} title={title} />
      )}
      {!isLoading && graph === 'value' && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            gap: 1,
          }}
        >
          <Typography sx={{ fontSize: 12, fontWeight: 700 }}>
            {title}
          </Typography>
          <Typography
            sx={{ fontSize: isSimpleView ? 24 : 32, fontWeight: 700 }}
          >
            {toLocaleString(dataVal)}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default WidgetItem;
