export const postCloneJob = async (
  index: string | number,
  jobId: string | number
) => {
  const axiosInstance = require('utils/utils-axios-instance').default;
  const data = await axiosInstance.post(
    `index/${index}/api/all_jobs/${jobId}/clone`
  );
  return data.data;
};

export const postCancelJob = async (
  index: string | number,
  jobId: string | number
) => {
  const axiosInstance = require('utils/utils-axios-instance').default;
  const data = await axiosInstance.post(
    `index/${index}/api/all_jobs/${jobId}/cancel`
  );
  return data.data;
};

export const postStopJob = async (
  index: string | number,
  jobId: string | number
) => {
  const axiosInstance = require('utils/utils-axios-instance').default;
  const data = await axiosInstance.post(
    `index/${index}/api/all_jobs/${jobId}/stop`
  );
  return data.data;
};

export const postFlushLogJob = async (
  index: string | number,
  jobId: string | number
) => {
  const axiosInstance = require('utils/utils-axios-instance').default;
  const data = await axiosInstance.post(
    `index/${index}/api/all_jobs/${jobId}/flushlog`
  );
  return data.data;
};

export const postDeleteJob = async (
  index: string | number,
  jobId: string | number
) => {
  const axiosInstance = require('utils/utils-axios-instance').default;
  const data = await axiosInstance.post(
    `index/${index}/api/all_jobs/${jobId}/delete`
  );
  return data.data;
};

export const postMarkJobDone = async (
  index: string | number,
  jobId: string | number
) => {
  const axiosInstance = require('utils/utils-axios-instance').default;
  const data = await axiosInstance.post(
    `index/${index}/api/all_jobs/${jobId}/markdone`
  );
  return data.data;
};
