import { Box, Button, Paper } from '@mui/material';
import { DataGridPro, GridColDef, GridToolbar } from '@mui/x-data-grid-pro';
import { useQuery } from '@tanstack/react-query';
import { FC } from 'react';
import { useUser } from 'contexts/user-context';
import { useNavigate } from 'react-router-dom';
import { getSiteTopicReports } from 'apis/site_topic';

const ViewBtn: FC<{ row: any }> = ({ row }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`${row.id}/top-label-groups`);
  };
  return <Button onClick={handleClick}>View</Button>;
};

const columns: GridColDef[] = [
  {
    field: 'view',
    headerName: '',
    headerAlign: 'center',
    align: 'center',
    headerClassName: 'App-Mui-DataGrid-Header',
    renderCell: (params) => <ViewBtn row={params.row} />,
  },
  {
    field: 'id',
    headerName: 'Id',
    headerAlign: 'center',
    align: 'center',
    headerClassName: 'App-Mui-DataGrid-Header',
  },
  {
    field: 'name',
    headerName: 'Name',
    headerAlign: 'center',
    align: 'center',
    headerClassName: 'App-Mui-DataGrid-Header',
    width: 240,
  },
  {
    field: 'site_url',
    headerName: 'Site Url',
    headerAlign: 'center',
    align: 'center',
    headerClassName: 'App-Mui-DataGrid-Header',
    width: 240,
    flex: 1,
  },
  {
    field: 'market',
    headerName: 'Market',
    headerAlign: 'center',
    align: 'center',
    headerClassName: 'App-Mui-DataGrid-Header',
    width: 240,
  },
  {
    field: 'status',
    headerName: 'Status',
    align: 'center',
    headerAlign: 'center',
    headerClassName: 'App-Mui-DataGrid-Header',
    width: 100,
  },
];

type Props = {
  opporId?: number;
};

const SiteTopicReportTable: FC<Props> = ({ opporId }) => {
  const { curIndex } = useUser();
  let { data, isLoading } = useQuery(
    [curIndex, 'site-topic', 'reports'],
    () => getSiteTopicReports(curIndex!.value!),
    { enabled: Boolean(curIndex) }
  );
  let items = data?.items || [];
  for (const item of items) {
    item['status'] = data.report_status;
  }
  if (opporId) {
    items = items.filter((item: any) => item.opportunity_id === opporId);
  }

  return (
    <Paper sx={{ display: 'flex', height: '100%' }}>
      <Box sx={{ flexGrow: 1 }}>
        <DataGridPro
          initialState={{ pagination: { pageSize: 10, page: 0 } }}
          loading={isLoading}
          pagination
          rowsPerPageOptions={[10, 25, 100]}
          autoHeight
          getRowHeight={() => 'auto'}
          columns={columns}
          rows={items}
          disableDensitySelector
          disableColumnFilter
          components={{ Toolbar: GridToolbar }}
          componentsProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
            },
          }}
          sx={{
            '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': {
              py: '12px',
              borderBottomColor: '#eee',
            },
            '&.MuiDataGrid-root--densityStandard .MuiDataGrid-columnSeparator':
              { color: '#ccc' },
          }}
        />
      </Box>
    </Paper>
  );
};
export default SiteTopicReportTable;
