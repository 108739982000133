import Layout from 'components/layout/Layout';
import PIJobResultMain from 'components/settings/views/page/job-result/PIJobResultMain';
import { FC } from 'react';

const PageIngreJobResPage: FC = () => {
  return (
    <Layout>
      <PIJobResultMain />
    </Layout>
  );
};

export default PageIngreJobResPage;
