import { Box, Button, Divider, Typography } from '@mui/material';
import { FC, useState } from 'react';
import type { LabelGroupItemType } from 'types/types';
import CreateLabelDialog, {
  LabelClassiferType,
} from './dialog/CreateLabelDialog';
import { postCreateLabel, postGetPagedLabels } from 'apis/index_labels';
import LoadingSpinner from 'components/shared/ui/LoadingSpinner';
import { useSnackbar } from '../../../../contexts/snackbar-context';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useUser } from 'contexts/user-context';
import ChatGptLabelGroupDlg from './dialog/ChatGptLabelGroupDlg';
import LabelGroup from './LabelGroup';

type Props = {
  indexId: string;
};

const LabelGroups: FC<Props> = ({ indexId }) => {
  const { setSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const [isCreateLabelDialogOpen, setIsCreateLabelDialogOpen] = useState(false);
  const handleCreateLabelDialogOpen = () => {
    setIsCreateLabelDialogOpen(true);
  };
  const handleCreateLabelDialogClose = () => {
    setIsCreateLabelDialogOpen(false);
  };
  const handleCreateLabelDialogSave = async (
    index: string,
    name: string,
    type: string,
    canonical_label: string | null,
    selectedFile: any,
    label_classifier_type: LabelClassiferType | null,
    labelColumn: string | undefined,
    synonymColumn: string | undefined
  ) => {
    setIsCreateLabelDialogOpen(false);
    try {
      await postCreateLabel({
        index,
        name,
        type,
        canonical_label_id: canonical_label,
        selectedFile,
        label_classifier_type,
        labelColumn,
        synonymColumn,
      });
      queryClient.invalidateQueries([curIndex, 'LabelGroups']);
      setSnackbar({ severity: 'success', message: 'Label Group Created!' });
    } catch (err) {
      setSnackbar({ severity: 'error', message: 'Create Label Group failed.' });
    }
  };

  const [isCreateLabelChatGptDialogOpen, setIsCreateLabelChatGptDialogOpen] =
    useState(false);
  const handleCreateLabelChatGptDialogOpen = () => {
    setIsCreateLabelChatGptDialogOpen(true);
  };
  const handleCreateLabelChatGptDialogClose = () => {
    setIsCreateLabelChatGptDialogOpen(false);
  };

  const { curIndex } = useUser();

  const { data, isLoading } = useQuery(
    [curIndex, 'LabelGroups'],
    () => postGetPagedLabels(curIndex!.value, {}),
    { enabled: Boolean(curIndex) }
  );
  let defaultLabelGroups: LabelGroupItemType[] = [];
  if (data?.items) {
    defaultLabelGroups = data.items;
  }

  return (
    <>
      {isCreateLabelDialogOpen && (
        <CreateLabelDialog
          open={isCreateLabelDialogOpen}
          onClose={handleCreateLabelDialogClose}
          onSave={handleCreateLabelDialogSave}
        />
      )}
      {isCreateLabelChatGptDialogOpen && (
        <ChatGptLabelGroupDlg
          open={isCreateLabelChatGptDialogOpen}
          onClose={handleCreateLabelChatGptDialogClose}
          onSave={handleCreateLabelChatGptDialogClose}
        />
      )}
      <Box sx={{ p: 2, display: 'flex', gap: 2, flexDirection: 'column' }}>
        <Box sx={{ display: 'flex', gap: 2 }}>
          <Button variant="outlined" onClick={handleCreateLabelDialogOpen}>
            Add Label Group
          </Button>
          <Button
            variant="outlined"
            onClick={handleCreateLabelChatGptDialogOpen}
          >
            Add ChatGPT Label Group
          </Button>
        </Box>
        <Divider />
        <Typography variant="h4">Label Groups</Typography>
        <Box sx={{ display: 'flex', gap: 2, flexWrap: 'wrap' }}>
          {isLoading && <LoadingSpinner />}
          {!isLoading &&
            defaultLabelGroups.map((labelGroup, idx) => (
              <LabelGroup labelGroupItem={labelGroup} key={idx} />
            ))}
        </Box>
      </Box>
    </>
  );
};

export default LabelGroups;
