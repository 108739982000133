export const getIndexes = async () => {
  const axiosInstance = require('utils/utils-axios-instance').default;
  const data = await axiosInstance.get(`index/api`);
  return data.data;
};

export const postIndex = async (
  name: string,
  domain?: string,
  market?: string
) => {
  const body = {
    name,
    domain,
    market,
  };
  const axiosInstance = require('utils/utils-axios-instance').default;
  const data = await axiosInstance.post(`index/api`, body);
  return data.data;
};

export const getIndex = async (index: string) => {
  const axiosInstance = require('utils/utils-axios-instance').default;
  const data = await axiosInstance.get(`index/${index}/api`);
  return data.data;
};

export const getIndexVersions = async (index: string) => {
  const axiosInstance = require('utils/utils-axios-instance').default;
  const data = await axiosInstance.get(`index/${index}/api/versions`);
  return data.data;
};

export const getIndexGSCChart = async (index: string) => {
  const axiosInstance = require('utils/utils-axios-instance').default;
  const data = await axiosInstance.get(`index/${index}/api/gsc-chart`);
  return data.data;
};

export const updateIndex = async (
  index: string | number,
  name: string,
  domain?: string,
  market?: string
) => {
  const body = {
    name,
    domain,
    market,
  };
  const axiosInstance = require('utils/utils-axios-instance').default;
  const data = await axiosInstance.put(`index/${index}/api`, body);
  return data.data;
};
