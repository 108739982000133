import { v4 as uuid } from 'uuid';
import { useEffect, useMemo } from 'react';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import StarIcon from '@mui/icons-material/Star';
import ShareIcon from '@mui/icons-material/Share';
import {
  Box,
  Button,
  MenuItem,
  Typography,
  Paper,
  Menu,
  IconButton,
} from '@mui/material';
import { FunctionComponent, useState, useCallback } from 'react';
import {
  ColOrderType,
  RecipeType,
  SharedRecipeSourceType,
  WBSourceType,
} from 'types/types';
import {
  Query,
  Builder,
  Utils as QbUtils,
  JsonTree,
} from 'react-awesome-query-builder';
import 'react-awesome-query-builder/lib/css/styles.css';
import 'react-awesome-query-builder/lib/css/compact_styles.css';
import MuiConfig from 'react-awesome-query-builder/lib/config/mui';
import {
  convertQueryBuilderTree,
  convertQueryBuilderTreeB2F,
  getAllFieldsFromRecipeFront,
  url2JsonObj,
} from 'utils/utils-query-builder';
import RecipeSaveAsDialog from './dialog/RecipeSaveAsDialog';
import { useUser } from 'contexts/user-context';
import RecipeSaveDialog from './dialog/RecipeSaveDialog';
import OperationApplyDialog from './dialog/OperationApplyDialog';
import { useSnackbar } from 'contexts/snackbar-context';
import { FIELD_SEPARATOR } from 'constants/constants';
import { useMutation, useQueryClient, useQuery } from '@tanstack/react-query';
import {
  getTemplates,
  postTemplate,
  updateTemplate,
} from 'apis/index_template';
import RecipeSelectDialog from './dialog/RecipeSelectDialog';
import { postOperation } from 'apis/operation';
import OperationBuilder, {
  StateProp as OperationStateProp,
} from 'components/workbench/OperationBuilder';
import { hash, isEmpty } from 'utils';
import { useLocation, useNavigate } from 'react-router-dom';
import CopyLinkBtn from 'components/workbench/CopyLinkBtn';
import useQBFields from 'hooks/useQBFields';
import { getWorkbenchPageVariable } from 'apis/workbench';
import VariableSelectDialog from './dialog/VariableSelectDialog';
import { InvisibleColsType } from 'hooks/useWBCols';
import ExportRecipeBtn from './ExportRecipeBtn';
import ImportRecipeBtn from './ImportRecipeBtn';
import WBWidgets from './WBWidgets';
import WBTopFolder from './WBTopFolder';
import ShareRecipeDlg from 'components/shared/shared-recipe/ShareRecipeDlg';
import SharedRecipeSelectDlg from 'components/shared/shared-recipe/SharedRecipeSelectDlg';
import LoadingSpinner from 'components/shared/ui/LoadingSpinner';

const WARNING_IMPORT_FIELDS = [
  'template',
  'not-template',
  'page-segment',
  'not-in-page-segment',
];

export const InitialConfig = MuiConfig;
// @ts-ignore
delete InitialConfig.operators.proximity;
InitialConfig.settings.showNot = false;
InitialConfig.settings.fieldSeparator = FIELD_SEPARATOR;
InitialConfig.settings.fieldSeparatorDisplay = '.';

const EMPTY_RECIPE: JsonTree = {
  type: 'group',
  id: 'bb89ba8b-89ab-4cde-b012-3183699f09e4',
  children1: [
    {
      type: 'rule',
      properties: {
        field: null,
        operator: null,
        value: [],
        valueSrc: [],
      },
    },
  ],
  properties: {
    conjunction: 'AND',
    not: false,
  },
};

const DEFAULT_RECIPE: RecipeType = {
  id: '0',
  name: '----',
  recipe: EMPTY_RECIPE,
  action: 'nop',
};

export const recipesB2F = (recipesB: RecipeType[]): RecipeType[] => {
  if (!recipesB) return [];
  const recipesF: RecipeType[] = [];
  for (const item of recipesB) {
    recipesF.push({
      ...item,
      recipe: convertQueryBuilderTreeB2F(item.recipe),
    });
  }
  return recipesF;
};

type Props = {
  onRecipeChange: Function;
  onOperationChange: Function;
  col_sorting: ColOrderType;
  col_invisible: string[];
  setOrder: React.Dispatch<React.SetStateAction<ColOrderType>>;
  setInvisibleCols: React.Dispatch<React.SetStateAction<InvisibleColsType>>;
  col_display_order: Record<string, number>;
  col_width: Record<string, number>;
  setColsDisplayOrder: React.Dispatch<
    React.SetStateAction<Record<string, number>>
  >;
  setColsWidth: React.Dispatch<React.SetStateAction<Record<string, number>>>;
  source: WBSourceType;
  tableData: any;
  opporId?: number;
};

const WBQBRecipe: FunctionComponent<Props> = ({
  onRecipeChange,
  onOperationChange,
  col_sorting,
  col_invisible,
  setOrder,
  setInvisibleCols,
  col_display_order,
  col_width,
  setColsDisplayOrder,
  setColsWidth,
  source,
  tableData,
  opporId,
}) => {
  // Copy link
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const jsonObjStr = urlParams.get('jsonObjStr');
  const [importedRecipe, setImportedRecipe] = useState<any>();

  const { setSnackbar } = useSnackbar();
  useEffect(() => {
    if (jsonObjStr) {
      const jsonObj = url2JsonObj(jsonObjStr);
      const { recipeObj, operationObj } = jsonObj;
      if (recipeObj) {
        const tree = QbUtils.loadTree(recipeObj);
        setInputBuilderTree(tree);
        onRecipeChange(recipeObj);
      }
      if (operationObj) {
        setLoadedOperation(operationObj);
      }
    }
  }, [jsonObjStr, onRecipeChange]);
  useEffect(() => {
    if (importedRecipe) {
      const tree = QbUtils.loadTree(importedRecipe);
      const allFields = getAllFieldsFromRecipeFront(importedRecipe);
      for (const field of allFields) {
        if (WARNING_IMPORT_FIELDS.indexOf(field) !== -1) {
          setSnackbar({
            severity: 'warning',
            message:
              'Satisfies recipe or url in segment detected, we may need add them manually',
          });
        }
      }
      setInputBuilderTree(tree);
    }
  }, [importedRecipe, setSnackbar]);

  const [operationState, setOperationState] = useState<OperationStateProp>();

  const { fields, isLoading: isLoadingFields } = useQBFields({ source });
  const config: any = useMemo(
    () => ({
      ...InitialConfig,
      fields,
    }),
    [fields]
  );
  const qbKey = hash(JSON.stringify(config));

  const { curIndex } = useUser();
  const queryClient = useQueryClient();
  let { data: recipesBack } = useQuery(
    [curIndex, 'WBQBRecipe', source],
    () => getTemplates(curIndex!.value, source),
    { enabled: Boolean(curIndex), staleTime: 1000 * 60 }
  );
  const recipes = useMemo(() => recipesB2F(recipesBack), [recipesBack]);

  let { data: variablesBack } = useQuery(
    [curIndex, 'WBPagesVariables', source],
    () => getWorkbenchPageVariable(curIndex!.value, source),
    { enabled: Boolean(curIndex), staleTime: 1000 * 60 }
  );
  const variables = useMemo(() => recipesB2F(variablesBack), [variablesBack]);

  const navigate = useNavigate();
  //Select
  const [isSelectDialogOpen, setIsSelectDialogOpen] = useState(false);
  const handleSelectDialogOpen = () => {
    setIsSelectDialogOpen(true);
  };
  const handleSelectDialogClose = () => {
    setIsSelectDialogOpen(false);
  };
  const handleSelectDialogSelect = useCallback(
    async (recipe: any) => {
      setIsSelectDialogOpen(false);
      setLoadedOperation(recipe.operation);
      setCurVar(undefined);
      setCurRecipe(recipe);
      if (!isEmpty(recipe.col_sorting)) {
        setOrder(recipe.col_sorting);
      }
      if (!isEmpty(recipe.col_invisible)) {
        setInvisibleCols({
          idx: curIndex!.value,
          cols: recipe.col_invisible,
          source,
        });
      }
      if (!isEmpty(recipe.col_display_order)) {
        setColsDisplayOrder(recipe.col_display_order);
      }
      if (!isEmpty(recipe.col_width)) {
        setColsWidth(recipe.col_width);
      }
      const _inputBuilderTree = QbUtils.checkTree(
        QbUtils.loadTree(recipe.recipe),
        config
      );
      setInputBuilderTree(_inputBuilderTree);
      navigate(`${location.pathname}?recipeId=${recipe.id}`);

      onRecipeChange(recipe.recipe);
    },
    [
      config,
      location.pathname,
      navigate,
      setColsDisplayOrder,
      setColsWidth,
      setOrder,
      setInvisibleCols,
      onRecipeChange,
      curIndex,
      source,
    ]
  );

  //Select Variable
  const [curVar, setCurVar] = useState<any>();
  const [isSelectVarDialogOpen, setIsSelectVarDialogOpen] = useState(false);
  const handleSelectVarDialogOpen = () => {
    setIsSelectVarDialogOpen(true);
  };
  const handleSelectVarDialogClose = () => {
    setIsSelectVarDialogOpen(false);
  };
  const handleSelectVarDialogSelect = useCallback(
    (variable: any) => {
      setIsSelectVarDialogOpen(false);
      setLoadedOperation(variable.operation);
      setCurVar(variable);
      setCurRecipe(DEFAULT_RECIPE);

      const _inputBuilderTree = QbUtils.checkTree(
        QbUtils.loadTree(variable.recipe),
        config
      );
      setInputBuilderTree(_inputBuilderTree);
      navigate(`${location.pathname}?variableId=${variable.id}`);
    },
    [config, location.pathname, navigate]
  );

  const recipeId = urlParams.get('recipeId');
  const variableId = urlParams.get('variableId');
  useEffect(() => {
    if (recipeId) {
      const recipe = recipes.find((item) => item.id.toString() === recipeId);
      if (recipe) {
        handleSelectDialogSelect(recipe);
      }
    } else if (variableId) {
      const variable = variables.find(
        (item) => item.id.toString() === variableId
      );
      if (variable) {
        handleSelectVarDialogSelect(variable);
      }
    }
  }, [
    recipeId,
    recipes,
    handleSelectDialogSelect,
    variableId,
    variables,
    handleSelectVarDialogSelect,
  ]);

  const actionGroupMap: { [k: string]: RecipeType[] } = {};
  for (const item of recipes) {
    if (item.action in actionGroupMap) {
      actionGroupMap[item.action].push(item);
    } else {
      actionGroupMap[item.action] = [item];
    }
  }
  const [curRecipe, setCurRecipe] = useState<RecipeType>(DEFAULT_RECIPE);
  let showSaveBtn = true;
  if (curRecipe.id === '0') {
    showSaveBtn = false;
  } else {
    showSaveBtn = true;
  }

  const [inputBuilderTree, setInputBuilderTree] = useState(
    QbUtils.checkTree(QbUtils.loadTree(curRecipe.recipe), config)
  );

  const groups = Object.keys(actionGroupMap);
  let content = [];
  for (const group of groups) {
    content.push(
      <Typography
        variant="caption"
        fontWeight={600}
        color="text.disabled"
        sx={{ ml: 1 }}
        key={uuid()}
      >
        {'--'}
      </Typography>
    );
    for (const recipe of actionGroupMap[group]) {
      content.push(
        <MenuItem key={recipe.id} value={recipe.id}>
          {recipe.name}
        </MenuItem>
      );
    }
  }

  const handleQBChange = useCallback(
    (immutableTree: any, config: any) => {
      const jsonTree = QbUtils.getTree(immutableTree);
      onRecipeChange(jsonTree);
      setInputBuilderTree(immutableTree);
    },
    [onRecipeChange]
  );

  const renderBuilder = useCallback(
    (props: any) => (
      <div className="query-builder-container">
        <div className="query-builder qb-lite" style={{ margin: 0 }}>
          {/* 
                // @ts-ignore */}
          <Builder {...props} />
        </div>
      </div>
    ),
    []
  );

  //Save as
  const { mutate: m_saveAs } = useMutation({
    mutationFn: (body: any) => postTemplate(curIndex!.value, body),
    onSuccess: (data: any) => {
      const id = data?.id;
      queryClient.invalidateQueries([curIndex, 'WBQBRecipe', source]);
      setSnackbar({ severity: 'success', message: 'Saved!' });
      if (id) {
        navigate(`${location.pathname}?recipeId=${id}`);
      }
    },
  });
  const [isSaveAsDialogOpen, setIsSaveAsDialogOpen] = useState(false);
  const handleSaveAsDialogOpen = () => {
    setIsSaveAsDialogOpen(true);
  };
  const handleSaveAsDialogClose = () => {
    setIsSaveAsDialogOpen(false);
  };
  const handleSaveAsDialogSave = async (name: string) => {
    setIsSaveAsDialogOpen(false);
    const recipe_front = QbUtils.getTree(inputBuilderTree);
    const recipe = convertQueryBuilderTree(recipe_front);
    setSnackbar({ severity: 'info', message: 'Saving...' });
    const body = {
      type: source,
      recipe,
      name,
      col_sorting,
      col_invisible,
      col_display_order,
      col_width,
      operation: operationState?.operations[0],
    };
    m_saveAs(body);
  };

  //Save
  const { mutate: m_save } = useMutation({
    mutationFn: (body: any) =>
      updateTemplate(curIndex!.value, curRecipe.id, body),
    onSuccess: () => {
      queryClient.invalidateQueries([curIndex, 'WBQBRecipe', source]);
      setSnackbar({ severity: 'success', message: 'Saved!' });
    },
  });
  const [isSaveDialogOpen, setIsSaveDialogOpen] = useState(false);
  const handleSaveDialogOpen = () => {
    setIsSaveDialogOpen(true);
  };
  const handleSaveDialogClose = () => {
    setIsSaveDialogOpen(false);
  };
  const handleSaveDialogSave = async () => {
    setIsSaveDialogOpen(false);
    const recipe_front = QbUtils.getTree(inputBuilderTree);
    const recipe = convertQueryBuilderTree(recipe_front);
    setSnackbar({ severity: 'info', message: 'Saving...' });
    const body = {
      recipe,
      col_sorting,
      col_invisible,
      col_display_order,
      col_width,
      operation: operationState?.operations[0],
    };
    m_save(body);
  };

  const [loadedOperation, setLoadedOperation] = useState<any>();

  const queryString = QbUtils.queryString(inputBuilderTree, config, true);

  useEffect(() => {
    if (operationState) {
      onOperationChange(operationState);
    }
  }, [operationState, onOperationChange]);

  //Apply operation
  const [isApplyOperationDialogOpen, setIsApplyOperationDialogOpen] =
    useState(false);
  const handleApplyOperationDialogOpen = () => {
    setIsApplyOperationDialogOpen(true);
  };
  const handleApplyOperationDialogClose = () => {
    setIsApplyOperationDialogOpen(false);
  };
  const [saveOrApplyVar, setSaveOrApplyVar] = useState<'Save' | 'Apply'>(
    'Apply'
  );
  const handleApplyOperationDialogSave = async (params: {
    name: string;
    id?: number | string;
  }) => {
    const isSaving = saveOrApplyVar === 'Save';
    setIsApplyOperationDialogOpen(false);
    const { name, id } = params;

    //Apply
    let operation = operationState?.operations[0];

    await postOperation(curIndex!.value!, {
      id,
      name,
      operation,
      isSaving,
      back_query,
      source,
    });
    setSnackbar({
      severity: 'success',
      message: isSaving ? 'Saved!' : 'Applied!',
    });
    setAppliedVarName(name);
    queryClient.invalidateQueries([curIndex, 'WBPagesVariables']);
    queryClient.invalidateQueries([curIndex, 'IndexFields']);
    queryClient.invalidateQueries([curIndex, 'IndexFields2']);
    queryClient.invalidateQueries([curIndex, source]);
    queryClient.invalidateQueries([curIndex, 'ActionFields', source]);
  };

  const [appliedVarName, setAppliedVarName] = useState('');
  let sampleApplyVarData = useMemo(() => {
    if (
      appliedVarName &&
      tableData &&
      tableData.items &&
      tableData.items.length > 0
    ) {
      const item = tableData.items[0];
      return item['$' + appliedVarName];
    }
    return '';
  }, [appliedVarName, tableData]);

  useEffect(() => {
    if (appliedVarName) {
      if (sampleApplyVarData) {
        setSnackbar({
          severity: 'success',
          message: `Variable ${appliedVarName} has been applied`,
        });
        setAppliedVarName('');
      } else {
        queryClient.invalidateQueries([curIndex, 'WB', source]);
      }
    }
  }, [
    appliedVarName,
    curIndex,
    queryClient,
    source,
    setSnackbar,
    sampleApplyVarData,
  ]);

  const recipe_front = QbUtils.getTree(inputBuilderTree);
  const back_query = convertQueryBuilderTree(recipe_front);

  const showTopFolder = source === 'page';

  let exportRecipeName = curRecipe.id === '0' ? 'recipe' : curRecipe.name;

  const [anchor, setAnchor] = useState<null | HTMLElement>(null);
  const menuOpen = Boolean(anchor);
  const handleOpenMenu = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setAnchor(e.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchor(null);
  };

  // Share Recipe
  const [shareRecipeDlgOpen, setShareRecipeDlgOpen] = useState(false);
  const [sharedRecipeSelectDlgOpen, setSharedRecipeSelectDlgOpen] =
    useState(false);
  const handleShareRecipeDlgClose = () => {
    setAnchor(null);
    setShareRecipeDlgOpen(false);
  };
  const handleSelectShareRecipeDlgClose = () => {
    setAnchor(null);
    setSharedRecipeSelectDlgOpen(false);
  };
  const handleSelectSharedRecipe = async (
    recipeFront: any,
    operation?: any
  ) => {
    setSharedRecipeSelectDlgOpen(false);
    const _inputBuilderTree = QbUtils.checkTree(
      QbUtils.loadTree(recipeFront),
      config
    );
    setInputBuilderTree(_inputBuilderTree);
    onRecipeChange(recipeFront);
    if (operation) {
      setLoadedOperation(operation);
    }
    setAnchor(null);
  };

  if (isLoadingFields) return <LoadingSpinner />;

  return (
    <>
      {shareRecipeDlgOpen && (
        <ShareRecipeDlg
          source={source as SharedRecipeSourceType}
          open={shareRecipeDlgOpen}
          recipe={back_query}
          operation={operationState?.operations[0]}
          onClose={handleShareRecipeDlgClose}
        />
      )}
      {sharedRecipeSelectDlgOpen && (
        <SharedRecipeSelectDlg
          source={source as SharedRecipeSourceType}
          onClose={handleSelectShareRecipeDlgClose}
          open={sharedRecipeSelectDlgOpen}
          onSelect={handleSelectSharedRecipe}
        />
      )}
      {isApplyOperationDialogOpen && (
        <OperationApplyDialog
          open={isApplyOperationDialogOpen}
          onClose={handleApplyOperationDialogClose}
          onSave={handleApplyOperationDialogSave}
          variable={curVar}
          saveOrApply={saveOrApplyVar}
        />
      )}
      <RecipeSelectDialog
        open={isSelectDialogOpen}
        onClose={handleSelectDialogClose}
        onSelect={handleSelectDialogSelect}
        recipes={recipes}
        config={config}
      />
      <VariableSelectDialog
        open={isSelectVarDialogOpen}
        onClose={handleSelectVarDialogClose}
        onSelect={handleSelectVarDialogSelect}
        variables={variables}
        config={config}
      />
      <RecipeSaveAsDialog
        open={isSaveAsDialogOpen}
        onClose={handleSaveAsDialogClose}
        onSave={handleSaveAsDialogSave}
      />
      <RecipeSaveDialog
        open={isSaveDialogOpen}
        onClose={handleSaveDialogClose}
        onSave={handleSaveDialogSave}
      />
      <Paper sx={{ p: 4 }}>
        <Box sx={{ display: 'flex', gap: 1, alignItems: 'center', mb: 4 }}>
          <Box>
            {curRecipe.id !== '0' && (
              <Typography
                variant="caption"
                color="text.disabled"
                sx={{ display: 'block' }}
              >
                Recipe: {curRecipe.name}
              </Typography>
            )}
            {curVar && (
              <Typography
                variant="caption"
                color="text.disabled"
                sx={{ my: 2, display: 'block' }}
              >
                Variable: {curVar.name}
              </Typography>
            )}
            <Typography variant="h4">Rules:</Typography>
            <Typography
              variant="caption"
              fontWeight={500}
              color="text.secondary"
            >
              {queryString || 'Please select a recipe add rules below.'}
            </Typography>
          </Box>
          <Box sx={{ ml: 'auto', display: 'flex', gap: 2 }}>
            {/* <Button
              variant="contained"
              color="secondary"
              endIcon={<StarIcon />}
              onClick={() => setSharedRecipeSelectDlgOpen(true)}
            >
              Recipe cookbook
            </Button> */}
            {showSaveBtn && (
              <Button variant="outlined" onClick={handleSaveDialogOpen}>
                Save
              </Button>
            )}
            <Button variant="outlined" onClick={handleSaveAsDialogOpen}>
              Save as
            </Button>
            <Button variant="outlined" onClick={handleSelectDialogOpen}>
              Load Recipe
            </Button>
            <Button variant="outlined" onClick={handleSelectVarDialogOpen}>
              Load Variable
            </Button>
            <IconButton onClick={handleOpenMenu}>
              <MoreHorizIcon />
            </IconButton>
            <Menu
              anchorEl={anchor}
              open={menuOpen}
              onClose={handleCloseMenu}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 2,
                  alignItems: 'flex-start',
                }}
              >
                <CopyLinkBtn
                  jsonObj={{
                    recipeObj: QbUtils.getTree(inputBuilderTree),
                    operationObj: operationState?.operations[0],
                  }}
                />
                <ExportRecipeBtn
                  jsonObj={QbUtils.getTree(inputBuilderTree)}
                  fileName={exportRecipeName}
                />
                <ImportRecipeBtn setImportedRecipe={setImportedRecipe} />
                <Button onClick={() => setShareRecipeDlgOpen(true)}>
                  <ShareIcon sx={{ mr: 0.2 }} />
                  Share to cookbook
                </Button>
                <Button onClick={() => setSharedRecipeSelectDlgOpen(true)}>
                  <StarIcon sx={{ mr: 0.2 }} />
                  Recipe cookbook
                </Button>
              </Box>
            </Menu>
          </Box>
        </Box>
        <Box sx={{ display: 'flex', gap: 2, width: '100%' }}>
          <Box sx={{ flex: 1 }}>
            {/* 
                // @ts-ignore */}
            <Query
              {...config}
              key={qbKey}
              value={inputBuilderTree}
              onChange={handleQBChange}
              renderBuilder={renderBuilder}
            />
            {source === 'page' && (
              <Box sx={{ mt: 2 }}>
                <OperationBuilder
                  fields={fields}
                  setSaveOrApply={setSaveOrApplyVar}
                  onApply={handleApplyOperationDialogOpen}
                  onChange={setOperationState}
                  loadedOperation={loadedOperation}
                />
              </Box>
            )}
          </Box>
          {showTopFolder && (
            <Box sx={{ width: 480 }}>
              <WBTopFolder back_query={back_query} />
            </Box>
          )}
        </Box>
        <WBWidgets
          source={source}
          back_query={back_query}
          parentPosition={'WB'}
          opporId={opporId}
        />
      </Paper>
    </>
  );
};

export default WBQBRecipe;
