import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  MenuItem,
  TextField,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import {
  getFeedIngredients,
  previewFeedIngredientItems,
} from 'apis/feed_ingredient';
import React, { FC, useState } from 'react';
import { convertFileName } from 'utils';
import { useUser } from '../../../../../contexts/user-context';
import { LabelGroupItemType } from 'types/types';

export type LabelClassiferType = 'ai' | 'label_matcher';

export type FileType = {
  id: number;
  file: string;
};

type FileListProps = {
  allFiles: FileType[];
  selectedFile: FileType | undefined;
  setSelectedFile: React.Dispatch<React.SetStateAction<FileType | undefined>>;
  allCurCols: string[] | undefined;
  labelColumn: string | undefined;
  setLabelColumn: React.Dispatch<React.SetStateAction<string | undefined>>;
  synonymColumn: string | undefined;
  setSynonymColumn: React.Dispatch<React.SetStateAction<string | undefined>>;
};
const FileList: FC<FileListProps> = ({
  allFiles,
  selectedFile,
  setSelectedFile,
  allCurCols,
  labelColumn,
  setLabelColumn,
  synonymColumn,
  setSynonymColumn,
}) => {
  const handleCheckFile = (
    e: React.ChangeEvent<HTMLInputElement>,
    file: FileType
  ) => {
    setSelectedFile(file);
  };
  const handleCheckLabelCol = (
    e: React.ChangeEvent<HTMLInputElement>,
    col: string
  ) => {
    const checked = e.target.checked;
    if (checked) {
      setLabelColumn(col);
    } else {
      setLabelColumn(undefined);
    }
  };
  const handleCheckSynonymCol = (
    e: React.ChangeEvent<HTMLInputElement>,
    col: string
  ) => {
    const checked = e.target.checked;
    if (checked) {
      setSynonymColumn(col);
    } else {
      setSynonymColumn(undefined);
    }
  };
  return (
    <Box sx={{ display: 'flex' }}>
      <FormControl component="fieldset" variant="standard">
        <FormLabel component="legend">Files:</FormLabel>
        {allFiles.map((file) => (
          <FormGroup key={file.id}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectedFile?.id === file.id}
                  onChange={(e) => handleCheckFile(e, file)}
                  name={file.file}
                />
              }
              label={file.file}
            />
          </FormGroup>
        ))}
        <FormLabel component="legend">Label Column:</FormLabel>
        <Box sx={{ display: 'flex', gap: 2 }}>
          {allCurCols?.map((col) => (
            <FormGroup key={col}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={labelColumn === col}
                    onChange={(e) => handleCheckLabelCol(e, col)}
                    name={col}
                  />
                }
                label={col}
              />
            </FormGroup>
          ))}
        </Box>
        <FormLabel component="legend">Synonym Column:</FormLabel>
        <Box sx={{ display: 'flex', gap: 2 }}>
          {allCurCols?.map((col) => (
            <FormGroup key={col}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={synonymColumn === col}
                    onChange={(e) => handleCheckSynonymCol(e, col)}
                    name={col}
                  />
                }
                label={col}
              />
            </FormGroup>
          ))}
        </Box>
      </FormControl>
    </Box>
  );
};

type Props = {
  labelGroupItem?: LabelGroupItemType;

  open: boolean;
  onClose: () => void;
  onSave: (
    index: string,
    name: string,
    type: string,
    canonical_label: string | null,
    selectedFile: any,
    label_classifier_type: LabelClassiferType | null,
    labelColumn: string | undefined,
    synonymColumn: string | undefined
  ) => void;
};

const CreateLabelDialog: FC<Props> = ({
  labelGroupItem,
  open,
  onClose,
  onSave,
}) => {
  const canonical_label = labelGroupItem?.id;
  const [name, setName] = useState('');
  const initLabelClassifierType =
    labelGroupItem?.label_classifier_type || 'label_matcher';
  const [labelClassifierType, setLabelClassifierType] =
    useState<LabelClassiferType>(initLabelClassifierType);
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const _name = e.target.value;
    setName(_name);
  };
  const { curIndex } = useUser();

  const submitHandler = (e: React.FormEvent) => {
    e.preventDefault();
    if (curIndex) {
      let type = 'attribute_group';
      if (canonical_label) {
        type = 'attribute';
      }
      onSave(
        curIndex.value,
        name,
        type,
        canonical_label || null,
        selectedFile,
        labelClassifierType || null,
        labelColumn,
        synonymColumn
      );
    }
  };

  // csv files and columns
  let { data: csvFiles } = useQuery(
    [curIndex, 'FeedIngredient'],
    () => getFeedIngredients(curIndex!.value),
    { staleTime: 1000 * 60 }
  );
  if (csvFiles) {
    csvFiles = csvFiles.results;
    csvFiles = csvFiles.map((doc: any) => {
      doc.file = convertFileName(doc.filename);
      return doc;
    });
  }
  const [selectedFile, setSelectedFile] = useState<FileType>();
  const [labelColumn, setLabelColumn] = useState<string>();
  const [synonymColumn, setSynonymColumn] = useState<string>();
  let { data: csvFileItems } = useQuery(
    [curIndex, 'FeedIngredientItems', selectedFile],
    () => previewFeedIngredientItems(curIndex!.value, selectedFile!.id, 0, 10),
    { enabled: Boolean(selectedFile), staleTime: 1000 * 60 }
  );

  return (
    <Dialog open={open} onClose={onClose}>
      <Box component="form" onSubmit={submitHandler}>
        <DialogTitle>
          Please select file and columns or enter label name
        </DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            autoComplete="off"
            margin="dense"
            label="Label Name"
            value={name}
            onChange={handleChange}
            fullWidth
            variant="standard"
          />
          <TextField
            sx={{ width: 300 }}
            margin="dense"
            variant="standard"
            label="Type"
            value={labelClassifierType}
            onChange={(e) =>
              setLabelClassifierType(e.target.value as LabelClassiferType)
            }
            select
          >
            <MenuItem value="ai">AI Classifier</MenuItem>
            <MenuItem value="label_matcher">Phrase matcher</MenuItem>
          </TextField>
          {csvFiles && (
            <Box sx={{ mt: 2 }}>
              <FileList
                allFiles={csvFiles}
                selectedFile={selectedFile}
                setSelectedFile={setSelectedFile}
                allCurCols={csvFileItems?.header}
                labelColumn={labelColumn}
                setLabelColumn={setLabelColumn}
                synonymColumn={synonymColumn}
                setSynonymColumn={setSynonymColumn}
              />
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button type="submit">Save</Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default CreateLabelDialog;
