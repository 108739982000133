import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Box, IconButton, Menu, MenuItem } from '@mui/material';
import { FC, useState } from 'react';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { useSnackbar } from 'contexts/snackbar-context';
import { useNavigate } from 'react-router-dom';
import {
  crawlPageIngredient,
  deletePageIngredientItem,
} from 'apis/page_ingredient';

type MoreMenuBtnProps = {
  ingredientId: number | string;
  indexId: number | string;
};

const MoreMenuBtn: FC<MoreMenuBtnProps> = ({ ingredientId, indexId }) => {
  const queryClient = useQueryClient();
  const { setSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const { mutate } = useMutation({
    mutationFn: () => deletePageIngredientItem(indexId, ingredientId),
    onSuccess: () => {
      queryClient.invalidateQueries([indexId, 'UrlIngredient']);
      setSnackbar({ severity: 'success', message: 'Deleted!' });
    },
  });

  const [anchor, setAnchor] = useState<null | HTMLElement>(null);
  const menuOpen = Boolean(anchor);
  const handleOpenMenu = (e: React.MouseEvent<HTMLButtonElement>) => {
    setAnchor(e.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchor(null);
  };

  const handleCrawl = async (id: string | number) => {
    await crawlPageIngredient(indexId, id);
    setSnackbar({ severity: 'success', message: 'Started!' });
  };

  const handleClickResult = (id: string | number) => {
    navigate(`/settings/${indexId}/pageingredient/${id}/jobs`);
  };

  const handleClickDelete = () => {
    mutate();
  };

  return (
    <Box>
      <IconButton onClick={handleOpenMenu}>
        <MoreHorizIcon />
      </IconButton>
      <Menu
        anchorEl={anchor}
        open={menuOpen}
        onClose={handleCloseMenu}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem onClick={handleCrawl.bind(null, ingredientId)}>
          Crawl
        </MenuItem>
        <MenuItem onClick={handleClickResult.bind(null, ingredientId)}>
          Crawl Result
        </MenuItem>
        <MenuItem onClick={handleClickDelete}>Delete</MenuItem>
      </Menu>
    </Box>
  );
};

export default MoreMenuBtn;
