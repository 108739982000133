import {
  Box,
  FormControlLabel,
  Switch,
  Tooltip,
  Typography,
  Checkbox,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Paper,
  Button,
} from '@mui/material';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { getIndexFields, putIndexField } from 'apis/index_fields';

import LoadingSpinner from 'components/shared/ui/LoadingSpinner';
import { useUser } from 'contexts/user-context';
import { FC } from 'react';
import { WBSourceType } from 'types/types';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { deepCopy } from 'utils';
import { VARIABLE_GROUP_KEY } from 'hooks/useQBFields';

export type ItemGroupType = {
  group: string;
  label: string;
  enabled: boolean;
  source: string;
  fields: ItemType[];
  helpText?: string;
  meta?: any;
};

export type ItemType = {
  field: string;
  label: string;
  enabled: boolean;
  type: string;
  group: string;
  source: string;
  helpText?: string;
  meta?: any;
  varId?: number;
  hiddenInDataTable?: boolean;
};

type ItemProps = {
  item: ItemType;
  disabled: boolean;
};

export const getEnabledFields = (rawData: any, source: WBSourceType) => {
  if (!rawData?.sources) return [];
  rawData = deepCopy(rawData);
  const groups: ItemGroupType[] = rawData['sources'][source]?.groups;
  if (!groups) return [];
  const ret: ItemType[] = [];
  for (const group of groups) {
    if (!group.enabled) continue;
    for (const item of group.fields) {
      if (!item.enabled) continue;
      if (item.hiddenInDataTable) continue;
      ret.push(item);
    }
  }
  return ret;
};

export const getAllFields = (rawData: any, source: WBSourceType) => {
  if (!rawData?.sources) return [];
  rawData = deepCopy(rawData);
  const groups: ItemGroupType[] = rawData['sources'][source]?.groups;
  if (!groups) return [];
  const ret: ItemType[] = [];
  for (const group of groups) {
    const groupEnabled = group.enabled;
    for (const item of group.fields) {
      item.enabled = item.enabled && groupEnabled;
      ret.push(item);
    }
  }
  return ret;
};

export const getEnabledGroups = (rawData: any, source: WBSourceType) => {
  if (!rawData?.sources) return [];
  rawData = deepCopy(rawData);
  const groups: ItemGroupType[] = rawData['sources'][source]?.groups;
  if (!groups) return [];
  const ret: ItemGroupType[] = [];
  for (const group of groups) {
    if (!group.enabled) continue;
    group.fields = group.fields.filter((item) => item.enabled);
    ret.push(group);
  }
  return ret;
};

export const getAllGroups = (rawData: any, source: WBSourceType) => {
  if (!rawData?.sources) return [];
  rawData = deepCopy(rawData);
  const groups: ItemGroupType[] = rawData['sources'][source]?.groups;
  if (!groups) return [];
  const ret: ItemGroupType[] = [];
  for (const group of groups) {
    const groupEnabled = group.enabled;
    for (const item of group.fields) {
      item.enabled = item.enabled && groupEnabled;
    }
    ret.push(group);
  }
  return ret;
};

export const flatGroupFields = (fields: ItemType[]) => {
  const ret: ItemType[] = deepCopy(fields) as ItemType[];
  for (const field of fields) {
    const nestedFields = field?.meta?.nestedFields;
    if (nestedFields) {
      for (const _field of nestedFields) {
        _field.field = field.field + '.' + _field.field;
        ret.push(_field);
      }
    }
  }
  return ret;
};

export const removeSourceAndMergeGroups = (rawData: any) => {
  if (!rawData?.sources) return [];
  rawData = deepCopy(rawData);
  const ret: ItemGroupType[] = [];
  const curGroupMap: any = {};
  for (const source in rawData['sources']) {
    const groups: ItemGroupType[] = rawData['sources'][source]?.groups;
    if (!groups) continue;
    for (const group of groups) {
      if (group.group === VARIABLE_GROUP_KEY) continue;
      group.source = 'any';
      if (!(group.group in curGroupMap)) {
        curGroupMap[group.group] = { group, fields: {} };
      } else {
        const _group: ItemGroupType = curGroupMap[group.group].group;
        _group.enabled = _group.enabled || group.enabled;
      }
      const curFieldMap = curGroupMap[group.group].fields;
      for (const field of group.fields) {
        field.source = 'any';
        if (!(field.field in curFieldMap)) {
          curFieldMap[field.field] = field;
        } else {
          const _field: ItemType = curFieldMap[field.field];
          _field.enabled = _field.enabled || field.enabled;
        }
      }
    }
  }
  for (const group in curGroupMap) {
    const curGroup: ItemGroupType = curGroupMap[group].group;
    const curFields = Object.values(curGroupMap[group].fields) as any;
    curGroup.fields = curFields;
    ret.push(curGroup);
  }
  return ret;
};

const Item: FC<ItemProps> = ({ item, disabled }) => {
  const { curIndex } = useUser();
  const queryClient = useQueryClient();
  const { field, enabled, source, group, label, helpText, varId } = item;

  const handleChangeEnable = async (e: any) => {
    const enabled = e.target.checked;
    const body = { source, group, enabled, field, varId };
    await putIndexField(curIndex!.value, body);
    queryClient.invalidateQueries([curIndex, 'IndexFields']);
  };

  return (
    <Box>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Tooltip title={helpText}>
          <FormControlLabel
            control={
              <Checkbox
                checked={enabled}
                onChange={handleChangeEnable}
                name={field}
                disabled={disabled}
              />
            }
            label={label}
          />
        </Tooltip>
      </Box>
    </Box>
  );
};

type GroupFieldsProps = {
  item: ItemGroupType;
};
const GroupFields: FC<GroupFieldsProps> = ({ item }) => {
  const { curIndex } = useUser();
  const queryClient = useQueryClient();
  let { group, label, enabled, helpText, fields, source } = item;
  fields = fields.map((f) => ({ ...f, group, source }));
  fields = fields.filter((item) => !item.hiddenInDataTable);
  const handleChangeEnable = async (e: any) => {
    const enabled = e.target.checked;
    const body = { source, group, enabled };
    await putIndexField(curIndex!.value, body);
    queryClient.invalidateQueries([curIndex, 'IndexFields']);
  };
  const isVariableGroup = group === VARIABLE_GROUP_KEY;

  const handleHideAllVar = async () => {
    const body = { source, group, enabled: 'HideAll' };
    await putIndexField(curIndex!.value, body);
    queryClient.invalidateQueries([curIndex, 'IndexFields']);
  };

  const handleShowAllVar = async () => {
    const body = { source, group, enabled: 'ShowAll' };
    await putIndexField(curIndex!.value, body);
    queryClient.invalidateQueries([curIndex, 'IndexFields']);
  };

  return (
    <Box sx={{ mr: 2 }}>
      <Accordion sx={{ boxShadow: 'none' }}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            backgroundColor: '#EFF1F9',
            borderRadius: '4px',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="h6" color="primary.main">
                {label}
              </Typography>
            </AccordionSummary>
            <Typography
              sx={{ ml: 2, mt: -2 }}
              variant="body2"
              color="text.secondary"
            >
              {helpText}
            </Typography>
          </Box>
          {!isVariableGroup && (
            <Switch
              size="medium"
              checked={enabled}
              onChange={handleChangeEnable}
              name={group}
            />
          )}
        </Box>
        <AccordionDetails sx={{ backgroundColor: '#F7F8FB' }}>
          {isVariableGroup && (
            <Box sx={{ display: 'flex', gap: 2, my: 1 }}>
              <Button color="info" onClick={handleHideAllVar}>
                Hide All
              </Button>
              <Button color="info" onClick={handleShowAllVar}>
                Show All
              </Button>
            </Box>
          )}
          <Box sx={{ display: 'flex', gap: 0.2, flexDirection: 'column' }}>
            {fields.map((item: ItemType) => (
              <Item key={item.field} item={item} disabled={!enabled} />
            ))}
          </Box>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

type SourceFieldsProps = {
  label?: string;
  source: string;
  groups?: ItemGroupType[];
};
export const SourceFields: FC<SourceFieldsProps> = ({
  label,
  groups,
  source,
}) => {
  groups = groups?.map((g) => ({ ...g, source }));
  const enabledFields: ItemType[] = [];
  if (groups) {
    for (const group of groups) {
      if (!group.enabled) continue;
      for (const item of group.fields) {
        if (!item.enabled) continue;
        if (enabledFields.find((o) => o.field === item.field)) continue;
        enabledFields.push(item);
      }
    }
  }
  return (
    <Box>
      <Box sx={{ display: 'flex', gap: 2 }}>
        <Box sx={{ flex: 1, borderRight: '1px solid #ccc' }}>
          {groups?.map((group) => (
            <GroupFields key={group.group} item={group} />
          ))}
        </Box>

        <Box sx={{ flex: 1 }}>
          <Typography variant="h6" sx={{ mb: 2 }}>
            Ingredients
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            {enabledFields.map((field) => (
              <Box sx={{ p: 1.2, backgroundColor: '#F7F8FB', width: '50%' }}>
                {field.label}
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

const SettingsFields: FC = () => {
  const { curIndex } = useUser();
  let { data, isLoading } = useQuery(
    [curIndex, 'IndexFields'],
    () => getIndexFields(curIndex!.value),
    { enabled: Boolean(curIndex), staleTime: 60 * 1000 }
  );
  const mergedGroups = removeSourceAndMergeGroups(data);

  return (
    <Paper sx={{ p: 2 }}>
      {isLoading && <LoadingSpinner />}
      {!isLoading && mergedGroups && (
        <SourceFields source="any" groups={mergedGroups} />
      )}
    </Paper>
  );
};

export default SettingsFields;
