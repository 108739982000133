export const getJobs = async (
  index: string | number,
  period: string,
  opporId?: number
) => {
  const axiosInstance = require('utils/utils-axios-instance').default;
  let data;
  if (opporId) {
    data = await axiosInstance.get(
      `index/${index}/api/all_jobs?period=${period}&opporId=${opporId}`
    );
  } else {
    data = await axiosInstance.get(
      `index/${index}/api/all_jobs?period=${period}`
    );
  }
  return data.data?.data;
};

export const getConnectedAccounts = async (index: string) => {
  const axiosInstance = require('utils/utils-axios-instance').default;
  const data = await axiosInstance.get(`index/${index}/api/gsc-user-site`);
  return data.data;
};

export const getConfigs = async (index: string) => {
  const axiosInstance = require('utils/utils-axios-instance').default;
  const data = await axiosInstance.get(`index/${index}/api/configs`);
  return data.data;
};

export const updateConfigs = async (index: string, body: any) => {
  const axiosInstance = require('utils/utils-axios-instance').default;
  const data = await axiosInstance.patch(`index/${index}/api/configs/`, body);
  return data.data;
};

export const getOnCrawlAccounts = async (index: string) => {
  const axiosInstance = require('utils/utils-axios-instance').default;
  const data = await axiosInstance.get(`index/${index}/api/oncrawl/account/`);
  return data.data;
};

export const postOnCrawlAccounts = async (index: string, body: any) => {
  const axiosInstance = require('utils/utils-axios-instance').default;
  const data = await axiosInstance.post(
    `index/${index}/api/oncrawl/account/`,
    body
  );
  return data.data;
};

export const getBotifyAccounts = async (index: string) => {
  const axiosInstance = require('utils/utils-axios-instance').default;
  const data = await axiosInstance.get(`index/${index}/api/botify/account/`);
  return data.data;
};

export const postBotifyAccounts = async (index: string, body: any) => {
  const axiosInstance = require('utils/utils-axios-instance').default;
  const data = await axiosInstance.post(
    `index/${index}/api/botify/account/`,
    body
  );
  return data.data;
};

export const getJetOctopusAccounts = async (index: string) => {
  const axiosInstance = require('utils/utils-axios-instance').default;
  const data = await axiosInstance.get(
    `index/${index}/api/jetoctopus/account/`
  );
  return data.data;
};

export const postJetOctopusAccounts = async (index: string, body: any) => {
  const axiosInstance = require('utils/utils-axios-instance').default;
  const data = await axiosInstance.post(
    `index/${index}/api/jetoctopus/account/`,
    body
  );
  return data.data;
};

export const updateJetOctopusAccount = async (index: string, body: any) => {
  const axiosInstance = require('utils/utils-axios-instance').default;
  const data = await axiosInstance.patch(
    `index/${index}/api/jetoctopus/account/`,
    body
  );
  return data.data;
};

export const getXmlrpcAccount = async (index: string) => {
  const axiosInstance = require('utils/utils-axios-instance').default;
  const data = await axiosInstance.get(`index/${index}/api/wordpress/account/`);
  return data.data;
};

export const postXmlrpcAccount = async (index: string, body: any) => {
  const axiosInstance = require('utils/utils-axios-instance').default;
  const data = await axiosInstance.post(
    `index/${index}/api/wordpress/account/`,
    body
  );
  return data.data;
};

export const updateXmlrpcAccount = async (index: string, body: any) => {
  const axiosInstance = require('utils/utils-axios-instance').default;
  const data = await axiosInstance.patch(
    `index/${index}/api/wordpress/account/`,
    body
  );
  return data.data;
};

export const generateApiKey = async () => {
  const axiosInstance = require('utils/utils-axios-instance').default;
  const data = await axiosInstance.get(`generate_api_key`);
  return data.data;
};
