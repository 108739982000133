import { atom } from 'jotai';
import { WBColPreviewType } from 'types/types';

export const opportunityLinkingFinishedRecipeBackAtom = atom<any>(undefined);
export const opportunityLinkingFinishedDataCountAtom = atom<number>(0);

export const opportunityRecipeBackAtom = atom<any>(undefined);

export const WBColPreviewAtom = atom<WBColPreviewType>('None');

export const inventDocRecipeFrontAtom = atom<any>(undefined);

export const selectedNewPageOpportunityTopicIdsAtom = atom<string[]>([]);

export const selectedFunctionIngredientIdAtom = atom<number | undefined>(
  undefined
);
