import { useQuery } from '@tanstack/react-query';
import { getFeedIngredients } from 'apis/feed_ingredient';
import { useUser } from 'contexts/user-context';
import { useState } from 'react';

const useUploadedDoc = () => {
  const { curIndex } = useUser();
  const [uploadedDoc, setUploadedDoc] = useState<any>();
  let { data: uploadedDocData, isLoading: uploadedDocIsLoading } = useQuery(
    [curIndex, 'UploadedDoc'],
    () => getFeedIngredients(curIndex!.value!),
    { enabled: Boolean(curIndex) }
  );
  if (uploadedDocData) {
    uploadedDocData = uploadedDocData.results;
  }
  uploadedDocData = uploadedDocData || [];
  return { uploadedDocData, uploadedDocIsLoading, uploadedDoc, setUploadedDoc };
};

export default useUploadedDoc;
