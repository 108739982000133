import { Button } from '@mui/material';
import { FunctionComponent } from 'react';
import UploadIcon from '@mui/icons-material/Upload';

type Props = {
  setImportedRecipe: React.Dispatch<any>;
};

const ImportRecipeBtn: FunctionComponent<Props> = ({ setImportedRecipe }) => {
  const uploadHandler = (e: any) => {
    const fileReader = new FileReader();
    fileReader.readAsText(e.target.files[0], 'UTF-8');
    fileReader.onload = (e: any) => {
      const result = e.target.result;
      if (result) {
        const recipe = JSON.parse(result);
        setImportedRecipe(recipe);
      }
    };
  };

  return (
    <Button onClick={uploadHandler} component="label">
      <UploadIcon sx={{ mr: 0.2 }} />
      Import
      <input
        hidden
        onChange={uploadHandler}
        multiple
        accept=".json"
        type="file"
      />
    </Button>
  );
};
export default ImportRecipeBtn;
