import { Box, Paper } from '@mui/material';
import { DataGridPro, GridColDef } from '@mui/x-data-grid-pro';
import { FunctionComponent } from 'react';
import { renderUrl } from 'components/shared/table-cell/UrlCell';

export const columns: GridColDef[] = [
  {
    field: 'id',
    headerClassName: 'App-Mui-DataGrid-Header',
  },
  {
    field: 'url',
    headerClassName: 'App-Mui-DataGrid-Header',
    renderCell: renderUrl,
    flex: 1,
  },
  {
    field: 'n_expected_rs',
    headerName: '# expected rs',
    width: 120,
    headerClassName: 'App-Mui-DataGrid-Header',
  },
  {
    field: 'n_valid_target_urls',
    headerName: '# valid target urls',
    width: 140,
    headerClassName: 'App-Mui-DataGrid-Header',
  },
  {
    field: 'n_valid_anchor_texts',
    headerName: '# valid anchor texts',
    width: 148,
    headerClassName: 'App-Mui-DataGrid-Header',
  },
  {
    field: 'n_valid_positions',
    headerName: '# valid positions',
    width: 140,
    headerClassName: 'App-Mui-DataGrid-Header',
  },
  {
    field: 'created',
    headerClassName: 'App-Mui-DataGrid-Header',
    width: 200,
  },
];

type Props = {
  items: any[];
  itemsCount: number;
  isLoading: boolean;
  pageSize: any;
  setPageSize: any;
  page: any;
  setPage: any;
};

const LinkingVerificationResultTable: FunctionComponent<Props> = ({
  items,
  itemsCount,
  isLoading,
  pageSize,
  setPageSize,
  page,
  setPage,
}) => {
  return (
    <Paper sx={{ display: 'flex', height: '100%' }}>
      <Box sx={{ flexGrow: 1 }}>
        <DataGridPro
          initialState={{
            columns: {
              columnVisibilityModel: {
                id: false,
              },
            },
          }}
          pagination
          loading={isLoading}
          autoHeight
          getRowHeight={() => 'auto'}
          columns={columns}
          rows={items}
          disableDensitySelector
          disableColumnFilter
          pageSize={pageSize}
          onPageSizeChange={setPageSize}
          page={page}
          onPageChange={setPage}
          rowsPerPageOptions={[10, 25, 100]}
          paginationMode="server"
          sortingMode="server"
          rowCount={itemsCount}
          sx={{
            display: 'flex',
            flexDirection: 'column-reverse',
            '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': {
              py: '12px',
              borderBottomColor: '#eee',
            },
            '&.MuiDataGrid-root--densityStandard .MuiDataGrid-columnSeparator':
              { color: '#ccc' },
          }}
        />
      </Box>
    </Paper>
  );
};
export default LinkingVerificationResultTable;
