// Refer: https://stackoverflow.com/questions/39479090/read-n-lines-of-a-big-text-file
export class TextReader {
  CHUNK_SIZE = 20000;
  position = 0;
  length = 0;

  byteBuffer = new Uint8Array(0);

  lines: any = [];
  lineCount = 0;
  lineIndexTracker = 0;
  blob: any = null;

  fileReader = new FileReader();
  textDecoder = new TextDecoder(`utf-8`);

  get allCachedLinesAreDispatched() {
    return !(this.lineIndexTracker < this.lineCount);
  }

  get blobIsReadInFull() {
    return !(this.position < this.length);
  }

  get bufferIsEmpty() {
    return this.byteBuffer.length === 0;
  }

  get endOfStream() {
    return (
      this.blobIsReadInFull &&
      this.allCachedLinesAreDispatched &&
      this.bufferIsEmpty
    );
  }

  constructor(blob: any) {
    this.blob = blob;
    this.length = blob.size;
  }

  blob2arrayBuffer(blob: any) {
    return new Promise((resolve, reject) => {
      this.fileReader.onerror = reject;
      this.fileReader.onload = () => {
        resolve(this.fileReader.result);
      };

      this.fileReader.readAsArrayBuffer(blob);
    });
  }

  read(offset: any, count: any) {
    return new Promise(async (resolve, reject) => {
      if (
        !Number.isInteger(offset) ||
        !Number.isInteger(count) ||
        count < 1 ||
        offset < 0 ||
        offset > this.length - 1
      ) {
        resolve(new ArrayBuffer(0));
        return;
      }

      let endIndex = offset + count;

      if (endIndex > this.length) endIndex = this.length;

      let blobSlice = this.blob!.slice(offset, endIndex);

      resolve(await this.blob2arrayBuffer(blobSlice));
    });
  }

  readLine() {
    return new Promise(async (resolve, reject) => {
      if (!this.allCachedLinesAreDispatched) {
        resolve(this.lines[this.lineIndexTracker++] + `\n`);
        return;
      }

      while (!this.blobIsReadInFull) {
        let arrayBuffer: any = await this.read(this.position, this.CHUNK_SIZE);
        this.position += arrayBuffer.byteLength;

        let tempByteBuffer = new Uint8Array(
          this.byteBuffer.length + arrayBuffer.byteLength
        );
        tempByteBuffer.set(this.byteBuffer);
        tempByteBuffer.set(new Uint8Array(arrayBuffer), this.byteBuffer.length);

        this.byteBuffer = tempByteBuffer;

        let lastIndexOfLineFeedCharacter = this.byteBuffer.lastIndexOf(10); // LINE FEED CHARACTER (\n) IS ONE BYTE LONG IN UTF-8 AND IS 10 IN ITS DECIMAL FORM

        if (lastIndexOfLineFeedCharacter > -1) {
          let lines = this.textDecoder.decode(this.byteBuffer).split(`\n`);
          this.byteBuffer = this.byteBuffer.slice(
            lastIndexOfLineFeedCharacter + 1
          );

          let firstLine = lines[0];

          this.lines = lines.slice(1, lines.length - 1);
          this.lineCount = this.lines.length;
          this.lineIndexTracker = 0;

          resolve(firstLine + `\n`);
          return;
        }
      }

      if (!this.bufferIsEmpty) {
        let line = this.textDecoder.decode(this.byteBuffer);
        this.byteBuffer = new Uint8Array(0);
        resolve(line);
        return;
      }

      resolve(null);
    });
  }
}
