import { Box, Paper } from '@mui/material';
import { DataGridPro, GridColDef, GridToolbar } from '@mui/x-data-grid-pro';

import { FC, useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { getKeywordIngredientJobResult } from 'apis/keyword_ingredient';
import { getNumber } from 'components/shared/table-cell';
import { renderUrl } from 'components/shared/table-cell/UrlCell';

const COLUMNS_SEMRUSH: GridColDef[] = [
  {
    field: 'id',
    headerClassName: 'App-Mui-DataGrid-Header',
    sortable: false,
  },
  {
    field: 'keyword',
    headerClassName: 'App-Mui-DataGrid-Header',
    flex: 2,
    sortable: false,
  },
  {
    field: 'url',
    headerClassName: 'App-Mui-DataGrid-Header',
    renderCell: renderUrl,
    flex: 4,
    sortable: false,
  },
  {
    field: 'position',
    headerClassName: 'App-Mui-DataGrid-Header',
    flex: 1,
    valueGetter: getNumber,
    sortable: false,
  },
  {
    field: 'volume',
    headerClassName: 'App-Mui-DataGrid-Header',
    flex: 1,
    valueGetter: getNumber,
    sortable: false,
  },
  {
    field: 'cpc',
    headerClassName: 'App-Mui-DataGrid-Header',
    flex: 1,
    valueGetter: getNumber,
    sortable: false,
  },
  {
    field: 'db',
    headerClassName: 'App-Mui-DataGrid-Header',
    flex: 1,
    sortable: false,
  },
  {
    field: 'timestamp',
    headerClassName: 'App-Mui-DataGrid-Header',
    flex: 1,
    sortable: false,
  },
];

const COLUMNS_BROAD_MATCH: GridColDef[] = [
  {
    field: 'id',
    headerClassName: 'App-Mui-DataGrid-Header',
    sortable: false,
  },
  {
    field: 'phrase',
    headerClassName: 'App-Mui-DataGrid-Header',
    flex: 2,
    sortable: false,
  },
  {
    field: 'keyword',
    headerClassName: 'App-Mui-DataGrid-Header',
    flex: 2,
    sortable: false,
  },
  {
    field: 'volume',
    headerClassName: 'App-Mui-DataGrid-Header',
    flex: 1,
    valueGetter: getNumber,
    sortable: false,
  },
  {
    field: 'cpc',
    headerClassName: 'App-Mui-DataGrid-Header',
    flex: 1,
    valueGetter: getNumber,
    sortable: false,
  },
  {
    field: 'db',
    headerClassName: 'App-Mui-DataGrid-Header',
    flex: 1,
    sortable: false,
  },
];

const COLUMNS_GSC: GridColDef[] = [
  {
    field: 'id',
    headerClassName: 'App-Mui-DataGrid-Header',
    sortable: false,
  },
  {
    field: 'keyword',
    headerClassName: 'App-Mui-DataGrid-Header',
    flex: 2,
    sortable: false,
  },
  {
    field: 'url',
    headerClassName: 'App-Mui-DataGrid-Header',
    renderCell: renderUrl,
    flex: 4,
    sortable: false,
  },
  {
    field: 'position',
    headerClassName: 'App-Mui-DataGrid-Header',
    valueGetter: getNumber,
    flex: 1,
    sortable: false,
  },
  {
    field: 'clicks',
    headerClassName: 'App-Mui-DataGrid-Header',
    valueGetter: getNumber,
    flex: 1,
    sortable: false,
  },
  {
    field: 'ctr',
    headerClassName: 'App-Mui-DataGrid-Header',
    valueGetter: getNumber,
    flex: 1,
    sortable: false,
  },
  {
    field: 'device',
    headerClassName: 'App-Mui-DataGrid-Header',
    flex: 1,
    sortable: false,
  },
  {
    field: 'impressions',
    headerClassName: 'App-Mui-DataGrid-Header',
    valueGetter: getNumber,
    flex: 1,
    sortable: false,
  },
  {
    field: 'search_type',
    headerClassName: 'App-Mui-DataGrid-Header',
    flex: 1,
    sortable: false,
  },
  {
    field: 'search_appearance_type',
    headerClassName: 'App-Mui-DataGrid-Header',
    flex: 2,
    sortable: false,
  },
  {
    field: 'url_parts',
    headerClassName: 'App-Mui-DataGrid-Header',
    flex: 2,
    sortable: false,
  },
  {
    field: 'url_parts_depth',
    headerClassName: 'App-Mui-DataGrid-Header',
    flex: 1,
    sortable: false,
  },
];

type Props = {
  indexId: string;
  kwIngredientId: string | number;
  source: string;
};

const KWJobResultTable: FC<Props> = ({ indexId, kwIngredientId, source }) => {
  let columns = COLUMNS_SEMRUSH;
  const [page, setPage] = useState(0);
  const [offset, setOffset] = useState(10);
  let { data, isLoading } = useQuery(
    [
      indexId,
      'keyword-ingredient-job-result',
      kwIngredientId,
      source,
      page,
      offset,
    ],
    () =>
      getKeywordIngredientJobResult(
        indexId,
        kwIngredientId,
        page,
        offset,
        source
      )
  );

  data = data || { items: [], count: 0 };
  if (data.items.length > 0) {
    data.items = data.items.map((item: any, id: number) => ({ ...item, id }));
    if ('device' in data.items[0]) columns = COLUMNS_GSC;
    if ('phrase' in data.items[0]) columns = COLUMNS_BROAD_MATCH;
  }
  const [rowCount, setRowCount] = useState(0);
  useEffect(() => {
    if (data && !rowCount) {
      setRowCount(data.count);
    }
  }, [data, rowCount]);

  return (
    <Paper
      sx={{ display: 'flex', height: '100%', flexDirection: 'column', gap: 2 }}
    >
      <Box sx={{ flexGrow: 1 }}>
        <DataGridPro
          initialState={{
            columns: {
              columnVisibilityModel: {
                id: false,
              },
            },
          }}
          pagination
          loading={isLoading}
          autoHeight
          getRowHeight={() => 'auto'}
          columns={columns}
          rows={data?.items}
          paginationMode="server"
          rowCount={rowCount}
          pageSize={offset}
          onPageSizeChange={setOffset}
          page={page}
          onPageChange={setPage}
          rowsPerPageOptions={[10, 25, 100]}
          disableDensitySelector
          disableColumnFilter
          components={{ Toolbar: GridToolbar }}
          sx={{
            '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': {
              py: '12px',
              borderBottomColor: '#eee',
            },
            '&.MuiDataGrid-root--densityStandard .MuiDataGrid-columnSeparator':
              { color: '#ccc' },
          }}
        />
      </Box>
    </Paper>
  );
};
export default KWJobResultTable;
