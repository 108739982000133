export const getIndexFields = async (index: string | number) => {
  const axiosInstance = require('utils/utils-axios-instance').default;
  const data = await axiosInstance.get(
    `index/${index}/api/field?needVariable=1`
  );
  return data.data;
};

export const putIndexField = async (index: string | number, body: any) => {
  const axiosInstance = require('utils/utils-axios-instance').default;
  const data = await axiosInstance.put(`index/${index}/api/field/`, body);
  return data.data;
};
