import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { postTopFolders } from 'apis/workbench';
import { useUser } from 'contexts/user-context';
import { FC, useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { toLocaleString } from 'utils';
import LoadingSpinner from 'components/shared/ui/LoadingSpinner';

type Props = {
  back_query: any;
};

const WBTopFolder: FC<Props> = ({ back_query }) => {
  const { curIndex } = useUser();
  const [isExpanded, setIsExpanded] = useState(false);
  let { data: topFolders, isLoading } = useQuery(
    [curIndex, 'TopFolder', back_query],
    () => postTopFolders(curIndex!.value, { back_query }),
    { enabled: Boolean(curIndex) && isExpanded, staleTime: 1000 * 60 * 5 }
  );

  const handleChangeAccordion = (
    event: React.SyntheticEvent<Element, Event>,
    expanded: boolean
  ) => {
    setIsExpanded(expanded);
  };
  return (
    <Box
      sx={{
        fontSize: 14,
        display: 'flex',
        flexDirection: 'column',
        gap: 0.4,
      }}
    >
      <Accordion
        sx={{ width: '100%', boxShadow: 'none' }}
        onChange={handleChangeAccordion}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography color="primary.main">Top folders</Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ overflowWrap: 'break-word', width: '100%' }}>
          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: '3fr 1fr 1fr 1fr',
              gap: 2,
            }}
          >
            <Typography variant="caption" sx={{ fontWeight: 700 }}>
              name
            </Typography>
            <Typography
              variant="caption"
              sx={{ fontWeight: 700, justifySelf: 'start', mb: 1 }}
            >
              SERP
            </Typography>
            <Typography
              variant="caption"
              sx={{ fontWeight: 700, justifySelf: 'start', mb: 1 }}
            >
              keyword
            </Typography>
            <Typography
              variant="caption"
              sx={{ fontWeight: 700, justifySelf: 'end', mb: 1 }}
            >
              page count
            </Typography>
          </Box>
          {isLoading && <LoadingSpinner />}
          {!isLoading &&
            topFolders?.map((item: any, i: number) => (
              <Box
                key={i}
                sx={{
                  display: 'grid',
                  gridTemplateColumns: '3fr 1fr 1fr 1fr',
                  columnGap: 2,
                  mt: 1,
                }}
              >
                <Typography variant="caption">{item.key}</Typography>
                <Typography variant="caption" sx={{ justifySelf: 'start' }}>
                  {toLocaleString(item.SERP_crawl?.doc_count)}
                </Typography>
                <Typography variant="caption" sx={{ justifySelf: 'start' }}>
                  {toLocaleString(item.extracted_keyword?.doc_count)}
                </Typography>
                <Typography variant="caption" sx={{ justifySelf: 'end' }}>
                  {toLocaleString(item.doc_count)}
                </Typography>
              </Box>
            ))}
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default WBTopFolder;
