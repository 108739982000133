import { Box, Link, Paper } from '@mui/material';
import { DataGridPro, GridColDef, GridToolbar } from '@mui/x-data-grid-pro';

import { FC, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import MoreMenuBtn from './MoreMenuBtn';
import { getFeedIngredients } from 'apis/feed_ingredient';
import { convertFileName } from 'utils';
import { getNumber } from 'components/shared/table-cell';

const DocTypeValueLabelMap: Record<string, string> = {
  pf: 'Product Feed',
  tx: 'Taxonomy Label Group',
  hi_tx: 'Hierarchical Taxonomy',
  pd: 'Page Data',
  kd: 'Keyword Data',
  pe: 'JetOctopus Page Export',
  gsc_bulk: 'GSC Bulk Export',
  hl: 'Hardcoded Links',
  sf: 'ScreamingFrog Page Export',
  inv: 'Inventory Data',
  be: 'Botify Page Export',
};

const columns: GridColDef[] = [
  {
    field: 'id',
    headerClassName: 'App-Mui-DataGrid-Header',
  },
  {
    field: 'file',
    headerClassName: 'App-Mui-DataGrid-Header',
    flex: 3,
    renderCell(params) {
      const file = params.row.file;
      let fileName = convertFileName(params.row.filename);
      return (
        <Link href={file} target="_blank" rel="noreferrer">
          {fileName}
        </Link>
      );
    },
  },
  {
    field: 'column_number',
    headerName: 'column',
    headerClassName: 'App-Mui-DataGrid-Header',
    valueGetter: getNumber,
    flex: 1,
  },
  {
    field: 'row_number',
    headerName: 'row',
    headerClassName: 'App-Mui-DataGrid-Header',
    valueGetter: getNumber,
    flex: 1,
  },
  {
    field: 'type',
    headerName: 'document type',
    headerClassName: 'App-Mui-DataGrid-Header',
    width: 200,
    valueGetter: (params) => DocTypeValueLabelMap[params.value],
  },
  {
    field: 'incremental',
    headerName: 'incremental',
    headerClassName: 'App-Mui-DataGrid-Header',
    width: 200,
  },
  {
    field: 'auto_refresh',
    headerName: 'auto refresh',
    headerClassName: 'App-Mui-DataGrid-Header',
    width: 200,
  },
  {
    field: 'created',
    type: 'dateTime',
    headerClassName: 'App-Mui-DataGrid-Header',
    flex: 1,
    valueGetter: (params) => new Date(params.value).toLocaleString(),
  },
  {
    field: 'updated',
    type: 'dateTime',
    headerClassName: 'App-Mui-DataGrid-Header',
    flex: 1,
    valueGetter: (params) => new Date(params.value).toLocaleString(),
  },
  {
    field: 'action',
    headerClassName: 'App-Mui-DataGrid-Header',
    flex: 1,
    renderCell(params) {
      return (
        <MoreMenuBtn documentId={params.id} indexId={params.row.index_id} />
      );
    },
  },
];

type Props = {
  indexId: string;
  isProductFeed: boolean;
};

const UploadCSVTable: FC<Props> = ({ indexId, isProductFeed }) => {
  let { data } = useQuery([indexId, 'FeedIngredient'], () =>
    getFeedIngredients(indexId)
  );
  data = data || { count: 0, next: null, previous: null, results: [], size: 0 };
  if (isProductFeed) {
    data.results = data.results.filter((item: any) => item.type === 'pf');
  } else {
    data.results = data.results.filter((item: any) => item.type !== 'pf');
  }

  const [pageSize, setPageSize] = useState(10);

  return (
    <Paper
      sx={{ display: 'flex', height: '100%', flexDirection: 'column', gap: 2 }}
    >
      <Box sx={{ flexGrow: 1 }}>
        <DataGridPro
          initialState={{
            columns: {
              columnVisibilityModel: {
                created: false,
                type: !isProductFeed,
              },
            },
          }}
          pagination
          autoHeight
          getRowHeight={() => 'auto'}
          columns={columns}
          rows={data.results}
          disableDensitySelector
          disableColumnFilter
          components={{ Toolbar: GridToolbar }}
          pageSize={pageSize}
          rowsPerPageOptions={[10, 25, 100]}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          componentsProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
            },
          }}
          sx={{
            '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': {
              py: '12px',
              borderBottomColor: '#eee',
            },
            '&.MuiDataGrid-root--densityStandard .MuiDataGrid-columnSeparator':
              { color: '#ccc' },
          }}
        />
      </Box>
    </Paper>
  );
};
export default UploadCSVTable;
