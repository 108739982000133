import { JsonTree } from 'react-awesome-query-builder';
import {
  LanguageType,
  MarketType,
  PublishOptionType,
  IntervalType,
  WorkbenchActionLabelType,
  WorkbenchActionType,
} from 'types/types';

export const TABLE_COLUMNS = [
  'url',
  'name',
  'canonical_keyword',
  'page_type',
  'demand',
  'traffic_current',
  'total_traffic',
  'traffic_opportunity',
  'total_impressions',
  'cpc_max',
  'position',
  'botify_user_visit',
  'total_botify_user_visit',
  'product_count',
  'listings_relevance',
  'googlebot_crawl_count',
  'latest_google_crawl_date',
  'google_crawl_days_ago',
  'action',
  'recommendation',
  'redirect_to',
  'ndd_canonical_url',
  'ndd_cluster_size',
  'related_links_incoming',
  'related_links_outgoing',
  'crawled_related_links_incoming',
  'ranking_keywords',
  'cluster_keywords',
  'cluster_keywords_count',
  'topics_count',
  'is_similarai_page',
  'intents',
  'top_ranking_domains',
  'serp_domain_diversity',
  'tags',
  'export_keyword',
  'export_search_volume',
  'export_gender',
  'export_product_type',
  'export_category',
  'export_subcategory',
  'variable_field',
  'status',
  'custom_field',
  'index_category_doc_id',
];

export const JSON_TREE_RECIPE_PAGE_TO_HIDE: any = {
  id: 'bab8b9ba-0123-4456-b89a-b1833edee43f',
  type: 'group',
  children1: [
    {
      type: 'rule',
      id: '9abbbbaa-0123-4456-b89a-b1833efbd8c3',
      properties: {
        field: 'demand',
        operator: 'less',
        value: [100],
        valueSrc: ['value'],
        valueType: ['number'],
      },
    },
    {
      type: 'rule',
      id: 'babaab9a-4567-489a-bcde-f1833efc0d0e',
      properties: {
        field: 'traffic_current',
        operator: 'equal',
        value: [0],
        valueSrc: ['value'],
        valueType: ['number'],
      },
    },
    {
      type: 'rule',
      id: '8ab9a8b8-89ab-4cde-b012-31833efc4e8f',
      properties: {
        field: 'position',
        operator: 'is_empty',
        value: [],
        valueSrc: [],
        valueType: [],
      },
    },
    {
      type: 'rule',
      id: '9988a989-0123-4456-b89a-b1833f057f40',
      properties: {
        field: 'cpc_max',
        operator: 'equal',
        value: [0],
        valueSrc: ['value'],
        valueType: ['number'],
      },
    },
    {
      type: 'rule',
      id: '98bbab89-cdef-4012-b456-71833efc8b8f',
      properties: {
        field: 'ndd_cluster_size',
        operator: 'equal',
        value: [1],
        valueSrc: ['value'],
        valueType: ['number'],
      },
    },
    {
      type: 'rule',
      id: '89b9bb8b-0123-4456-b89a-b1833efd1ae2',
      properties: {
        field: 'new',
        operator: 'not_equal',
        value: [1],
        valueSrc: ['value'],
        valueType: ['number'],
      },
    },
    {
      type: 'group',
      id: '8ba988b9-4567-489a-bcde-f1833efd72a4',
      properties: {
        conjunction: 'OR',
        not: false,
      },
      children1: [
        {
          type: 'rule',
          id: '8b98a9ab-0123-4456-b89a-b1833efd72a5',
          properties: {
            field: 'custom_field.internal_traffic',
            operator: 'is_empty',
            value: [],
            valueSrc: [],
            valueType: [],
          },
        },
        {
          type: 'rule',
          id: 'b99ab9ab-4567-489a-bcde-f1833efe2145',
          properties: {
            field: 'custom_field.internal_traffic',
            operator: 'equal',
            value: [0],
            valueSrc: ['value'],
            valueType: ['number'],
          },
        },
      ],
    },
  ],
  properties: {
    conjunction: 'AND',
  },
};

export const FIELD_SEPARATOR = '^^';

export const PUBLISH_OPTIONS: { label: string; value: PublishOptionType }[] = [
  { label: 'noindex', value: 'noindex' },
  { label: '301', value: '301' },
  { label: '301 to parent', value: '301 to parent' },
  { label: '410', value: '410' },
  { label: 'Create', value: 'create' },
  { label: '--', value: 'nop' },
  { label: 'Linking', value: 'use in linking' },
];

export const PUBLISH_OPTIONS_WITHOUT_LINKING: {
  label: string;
  value: PublishOptionType;
}[] = [
  { label: '--', value: 'nop' },
  { label: 'No index', value: 'noindex' },
  { label: '301', value: '301' },
  { label: '301 to parent', value: '301 to parent' },
  { label: '410', value: '410' },
  { label: 'Create', value: 'create' },
];

export const PUBLISH_OPTIONS_NEW_OPPORTUNITY: {
  label: string;
  value: PublishOptionType;
}[] = [
  { label: 'Create', value: 'create' },
  { label: 'Linking', value: 'use in linking' },
];

export const PUBLISH_OPTIONS_DEDUP_OPPORTUNITY: {
  label: string;
  value: PublishOptionType;
}[] = [
  { label: '301', value: '301' },
  { label: '410', value: '410' },
  { label: 'noindex', value: 'noindex' },
];

export const MARKET_OPTIONS: { label: any; value: MarketType }[] = [
  { label: '🇺🇸 us', value: 'us' },
  { label: '🇬🇧 uk', value: 'uk' },
  { label: '🇩🇪 de', value: 'de' },
  { label: '🇫🇷 fr', value: 'fr' },
  { label: '🇫🇮 fi', value: 'fi' },
  { label: '🇳🇱 nl', value: 'nl' },
  { label: '🇧🇪 be', value: 'be' },
  { label: '🇨🇦 ca', value: 'ca' },
  { label: '🇦🇺 au', value: 'au' },
  { label: '🇮🇹 it', value: 'it' },
  { label: '🇲🇽 mx', value: 'mx' },
  { label: '🇿🇦 za', value: 'za' },
  { label: '🇪🇸 es', value: 'es' },
  { label: '🇦🇫 af', value: 'af' },
  { label: '🇦🇽 ax', value: 'ax' },
  { label: '🇦🇱 al', value: 'al' },
  { label: '🇩🇿 dz', value: 'dz' },
  { label: '🇦🇸 as', value: 'as' },
  { label: '🇦🇩 ad', value: 'ad' },
  { label: '🇦🇴 ao', value: 'ao' },
  { label: '🇦🇮 ai', value: 'ai' },
  { label: '🇦🇶 aq', value: 'aq' },
  { label: '🇦🇬 ag', value: 'ag' },
  { label: '🇦🇷 ar', value: 'ar' },
  { label: '🇦🇲 am', value: 'am' },
  { label: '🇦🇼 aw', value: 'aw' },
  { label: '🇦🇹 at', value: 'at' },
  { label: '🇦🇿 az', value: 'az' },
  { label: '🇧🇸 bs', value: 'bs' },
  { label: '🇧🇭 bh', value: 'bh' },
  { label: '🇧🇩 bd', value: 'bd' },
  { label: '🇧🇧 bb', value: 'bb' },
  { label: '🇧🇾 by', value: 'by' },
  { label: '🇧🇿 bz', value: 'bz' },
  { label: '🇧🇯 bj', value: 'bj' },
  { label: '🇧🇲 bm', value: 'bm' },
  { label: '🇧🇹 bt', value: 'bt' },
  { label: '🇧🇴 bo', value: 'bo' },
  { label: '🇧🇦 ba', value: 'ba' },
  { label: '🇧🇼 bw', value: 'bw' },
  { label: '🇧🇻 bv', value: 'bv' },
  { label: '🇧🇷 br', value: 'br' },
  { label: '🇮🇴 io', value: 'io' },
  { label: '🇧🇳 bn', value: 'bn' },
  { label: '🇧🇬 bg', value: 'bg' },
  { label: '🇧🇫 bf', value: 'bf' },
  { label: '🇧🇮 bi', value: 'bi' },
  { label: '🇰🇭 kh', value: 'kh' },
  { label: '🇨🇲 cm', value: 'cm' },
  { label: '🇨🇻 cv', value: 'cv' },
  { label: '🇰🇾 ky', value: 'ky' },
  { label: '🇨🇫 cf', value: 'cf' },
  { label: '🇹🇩 td', value: 'td' },
  { label: '🇨🇱 cl', value: 'cl' },
  { label: '🇨🇳 cn', value: 'cn' },
  { label: '🇨🇽 cx', value: 'cx' },
  { label: '🇨🇨 cc', value: 'cc' },
  { label: '🇨🇴 co', value: 'co' },
  { label: '🇰🇲 km', value: 'km' },
  { label: '🇨🇬 cg', value: 'cg' },
  { label: '🇨🇩 cd', value: 'cd' },
  { label: '🇨🇰 ck', value: 'ck' },
  { label: '🇨🇷 cr', value: 'cr' },
  { label: '🇨🇮 ci', value: 'ci' },
  { label: '🇭🇷 hr', value: 'hr' },
  { label: '🇨🇺 cu', value: 'cu' },
  { label: '🇨🇾 cy', value: 'cy' },
  { label: '🇨🇿 cz', value: 'cz' },
  { label: '🇩🇰 dk', value: 'dk' },
  { label: '🇩🇯 dj', value: 'dj' },
  { label: '🇩🇲 dm', value: 'dm' },
  { label: '🇩🇴 do', value: 'do' },
  { label: '🇪🇨 ec', value: 'ec' },
  { label: '🇪🇬 eg', value: 'eg' },
  { label: '🇸🇻 sv', value: 'sv' },
  { label: '🇬🇶 gq', value: 'gq' },
  { label: '🇪🇷 er', value: 'er' },
  { label: '🇪🇪 ee', value: 'ee' },
  { label: '🇪🇹 et', value: 'et' },
  { label: '🇫🇰 fk', value: 'fk' },
  { label: '🇫🇴 fo', value: 'fo' },
  { label: '🇫🇯 fj', value: 'fj' },
  { label: '🇬🇫 gf', value: 'gf' },
  { label: '🇵🇫 pf', value: 'pf' },
  { label: '🇹🇫 tf', value: 'tf' },
  { label: '🇬🇦 ga', value: 'ga' },
  { label: '🇬🇲 gm', value: 'gm' },
  { label: '🇬🇪 ge', value: 'ge' },
  { label: '🇬🇭 gh', value: 'gh' },
  { label: '🇬🇮 gi', value: 'gi' },
  { label: '🇬🇷 gr', value: 'gr' },
  { label: '🇬🇱 gl', value: 'gl' },
  { label: '🇬🇩 gd', value: 'gd' },
  { label: '🇬🇵 gp', value: 'gp' },
  { label: '🇬🇺 gu', value: 'gu' },
  { label: '🇬🇹 gt', value: 'gt' },
  { label: '🇬🇬 gg', value: 'gg' },
  { label: '🇬🇳 gn', value: 'gn' },
  { label: '🇬🇼 gw', value: 'gw' },
  { label: '🇬🇾 gy', value: 'gy' },
  { label: '🇭🇹 ht', value: 'ht' },
  { label: '🇭🇲 hm', value: 'hm' },
  { label: '🇻🇦 va', value: 'va' },
  { label: '🇭🇳 hn', value: 'hn' },
  { label: '🇭🇰 hk', value: 'hk' },
  { label: '🇭🇺 hu', value: 'hu' },
  { label: '🇮🇸 is', value: 'is' },
  { label: '🇮🇳 in', value: 'in' },
  { label: '🇮🇩 id', value: 'id' },
  { label: '🇮🇷 ir', value: 'ir' },
  { label: '🇮🇶 iq', value: 'iq' },
  { label: '🇮🇪 ie', value: 'ie' },
  { label: '🇮🇲 im', value: 'im' },
  { label: '🇮🇱 il', value: 'il' },
  { label: '🇯🇲 jm', value: 'jm' },
  { label: '🇯🇵 jp', value: 'jp' },
  { label: '🇯🇪 je', value: 'je' },
  { label: '🇯🇴 jo', value: 'jo' },
  { label: '🇰🇿 kz', value: 'kz' },
  { label: '🇰🇪 ke', value: 'ke' },
  { label: '🇰🇮 ki', value: 'ki' },
  { label: '🇰🇵 kp', value: 'kp' },
  { label: '🇰🇷 kr', value: 'kr' },
  { label: '🇰🇼 kw', value: 'kw' },
  { label: '🇰🇬 kg', value: 'kg' },
  { label: '🇱🇦 la', value: 'la' },
  { label: '🇱🇻 lv', value: 'lv' },
  { label: '🇱🇧 lb', value: 'lb' },
  { label: '🇱🇸 ls', value: 'ls' },
  { label: '🇱🇷 lr', value: 'lr' },
  { label: '🇱🇾 ly', value: 'ly' },
  { label: '🇱🇮 li', value: 'li' },
  { label: '🇱🇹 lt', value: 'lt' },
  { label: '🇱🇺 lu', value: 'lu' },
  { label: '🇲🇴 mo', value: 'mo' },
  { label: '🇲🇰 mk', value: 'mk' },
  { label: '🇲🇬 mg', value: 'mg' },
  { label: '🇲🇼 mw', value: 'mw' },
  { label: '🇲🇾 my', value: 'my' },
  { label: '🇲🇻 mv', value: 'mv' },
  { label: '🇲🇱 ml', value: 'ml' },
  { label: '🇲🇹 mt', value: 'mt' },
  { label: '🇲🇭 mh', value: 'mh' },
  { label: '🇲🇶 mq', value: 'mq' },
  { label: '🇲🇷 mr', value: 'mr' },
  { label: '🇲🇺 mu', value: 'mu' },
  { label: '🇾🇹 yt', value: 'yt' },
  { label: '🇫🇲 fm', value: 'fm' },
  { label: '🇲🇩 md', value: 'md' },
  { label: '🇲🇨 mc', value: 'mc' },
  { label: '🇲🇳 mn', value: 'mn' },
  { label: '🇲🇪 me', value: 'me' },
  { label: '🇲🇸 ms', value: 'ms' },
  { label: '🇲🇦 ma', value: 'ma' },
  { label: '🇲🇿 mz', value: 'mz' },
  { label: '🇲🇲 mm', value: 'mm' },
  { label: '🇳🇦 na', value: 'na' },
  { label: '🇳🇷 nr', value: 'nr' },
  { label: '🇳🇵 np', value: 'np' },
  { label: '🇹🇼 an', value: 'an' },
  { label: '🇳🇨 nc', value: 'nc' },
  { label: '🇳🇿 nz', value: 'nz' },
  { label: '🇳🇮 ni', value: 'ni' },
  { label: '🇳🇪 ne', value: 'ne' },
  { label: '🇳🇬 ng', value: 'ng' },
  { label: '🇳🇺 nu', value: 'nu' },
  { label: '🇳🇫 nf', value: 'nf' },
  { label: '🇲🇵 mp', value: 'mp' },
  { label: '🇳🇴 no', value: 'no' },
  { label: '🇴🇲 om', value: 'om' },
  { label: '🇵🇰 pk', value: 'pk' },
  { label: '🇵🇼 pw', value: 'pw' },
  { label: '🇵🇸 ps', value: 'ps' },
  { label: '🇵🇦 pa', value: 'pa' },
  { label: '🇵🇬 pg', value: 'pg' },
  { label: '🇵🇾 py', value: 'py' },
  { label: '🇵🇪 pe', value: 'pe' },
  { label: '🇵🇭 ph', value: 'ph' },
  { label: '🇵🇳 pn', value: 'pn' },
  { label: '🇵🇱 pl', value: 'pl' },
  { label: '🇵🇹 pt', value: 'pt' },
  { label: '🇵🇷 pr', value: 'pr' },
  { label: '🇶🇦 qa', value: 'qa' },
  { label: '🇷🇪 re', value: 're' },
  { label: '🇷🇴 ro', value: 'ro' },
  { label: '🇷🇺 ru', value: 'ru' },
  { label: '🇷🇼 rw', value: 'rw' },
  { label: '🇧🇱 bl', value: 'bl' },
  { label: '🇸🇭 sh', value: 'sh' },
  { label: '🇰🇳 kn', value: 'kn' },
  { label: '🇱🇨 lc', value: 'lc' },
  { label: '🇲🇫 mf', value: 'mf' },
  { label: '🇵🇲 pm', value: 'pm' },
  { label: '🇻🇨 vc', value: 'vc' },
  { label: '🇼🇸 ws', value: 'ws' },
  { label: '🇸🇲 sm', value: 'sm' },
  { label: '🇸🇹 st', value: 'st' },
  { label: '🇸🇦 sa', value: 'sa' },
  { label: '🇸🇳 sn', value: 'sn' },
  { label: '🇷🇸 rs', value: 'rs' },
  { label: '🇸🇨 sc', value: 'sc' },
  { label: '🇸🇱 sl', value: 'sl' },
  { label: '🇸🇬 sg', value: 'sg' },
  { label: '🇸🇰 sk', value: 'sk' },
  { label: '🇸🇮 si', value: 'si' },
  { label: '🇸🇧 sb', value: 'sb' },
  { label: '🇸🇴 so', value: 'so' },
  { label: '🇬🇸 gs', value: 'gs' },
  { label: '🇱🇰 lk', value: 'lk' },
  { label: '🇸🇩 sd', value: 'sd' },
  { label: '🇸🇷 sr', value: 'sr' },
  { label: '🇸🇯 sj', value: 'sj' },
  { label: '🇸🇿 sz', value: 'sz' },
  { label: '🇸🇪 se', value: 'se' },
  { label: '🇨🇭 ch', value: 'ch' },
  { label: '🇸🇾 sy', value: 'sy' },
  { label: '🇹🇼 tw', value: 'tw' },
  { label: '🇹🇯 tj', value: 'tj' },
  { label: '🇹🇿 tz', value: 'tz' },
  { label: '🇹🇭 th', value: 'th' },
  { label: '🇹🇱 tl', value: 'tl' },
  { label: '🇹🇬 tg', value: 'tg' },
  { label: '🇹🇰 tk', value: 'tk' },
  { label: '🇹🇴 to', value: 'to' },
  { label: '🇹🇹 tt', value: 'tt' },
  { label: '🇹🇳 tn', value: 'tn' },
  { label: '🇹🇷 tr', value: 'tr' },
  { label: '🇹🇲 tm', value: 'tm' },
  { label: '🇹🇨 tc', value: 'tc' },
  { label: '🇹🇻 tv', value: 'tv' },
  { label: '🇺🇬 ug', value: 'ug' },
  { label: '🇺🇦 ua', value: 'ua' },
  { label: '🇦🇪 ae', value: 'ae' },
  { label: '🇺🇲 um', value: 'um' },
  { label: '🇺🇾 uy', value: 'uy' },
  { label: '🇺🇿 uz', value: 'uz' },
  { label: '🇻🇺 vu', value: 'vu' },
  { label: '🇻🇪 ve', value: 've' },
  { label: '🇻🇳 vn', value: 'vn' },
  { label: '🇻🇬 vg', value: 'vg' },
  { label: '🇻🇮 vi', value: 'vi' },
  { label: '🇼🇫 wf', value: 'wf' },
  { label: '🇪🇭 eh', value: 'eh' },
  { label: '🇾🇪 ye', value: 'ye' },
  { label: '🇿🇲 zm', value: 'zm' },
  { label: '🇿🇼 zw', value: 'zw' },
];

export const LANGUAGE_OPTIONS: { label: LanguageType; value: LanguageType }[] =
  [
    { label: 'en', value: 'en' },
    { label: 'de', value: 'de' },
    { label: 'nl', value: 'nl' },
    { label: 'fr', value: 'fr' },
    { label: 'fi', value: 'fi' },
    { label: 'it', value: 'it' },
    { label: 'es', value: 'es' },
  ];

export const LINKING_RULES_BACKEND = {
  valid: true,
  condition: 'AND',
  rules: [
    {
      id: 'related_searches',
      field: 'related_searches',
      input: 'number',
      value: null,
      operator: 'is_not_empty',
      type: 'number',
    },
  ],
};

export const LINKING_RULES_FRONTEND: JsonTree = {
  type: 'group',
  id: 'bb89ba8b-89ab-4cde-b012-3183699f09e4',
  children1: [
    {
      type: 'rule',
      properties: {
        field: 'related_searches',
        operator: 'is_not_empty',
        value: [],
        valueSrc: [],
        valueType: [],
      },
    },
  ],
  properties: {
    conjunction: 'AND',
    not: false,
  },
};

export const LINKING_RULES_FRONTEND_WITH_GROUP: JsonTree = {
  type: 'group',
  id: 'bb89ba8b-89ab-4cde-b012-3183699f09e4',
  children1: [
    {
      type: 'rule',
      properties: {
        field: 'internal_linking^^related_searches',
        operator: 'is_not_null',
        value: [],
        valueSrc: [],
        valueType: [],
      },
    },
  ],
  properties: {
    conjunction: 'AND',
    not: false,
  },
};

export const EMPTY_RECIPE_FRONT: JsonTree = {
  type: 'group',
  id: 'bb89ba8b-89ab-4cde-b012-3183699f09e4',
  children1: [
    {
      type: 'rule',
      properties: {
        field: null,
        operator: null,
        value: [],
        valueSrc: [],
      },
    },
  ],
  properties: {
    conjunction: 'AND',
    not: false,
  },
};

export const VARIABLE_COLUMN_PREFIX = '$';

export const COLUMN_REF_PREFIX = '#.';

export const INTERVAL_OPTIONS: { label: string; value: IntervalType }[] = [
  { label: 'Manual', value: 'manual' },
  { label: 'Hourly', value: 'hourly' },
  { label: 'Daily', value: 'daily' },
  { label: 'Weekly', value: 'weekly' },
  { label: 'Monthly', value: 'monthly' },
];

export const INDEX_CONFIG = {
  v3_new_page_via_proxy: 165,
  enable_workbench_widget: 185,
  enable_opportunity_v2: 191,
};

export const WORKBENCH_ACTIONS: {
  label: WorkbenchActionLabelType;
  value: WorkbenchActionType;
}[] = [
  { value: 'get_serp', label: 'Get SERP' },
  { value: 'get_fast_serp', label: 'Get fast SERP' },
  { value: 'get_search_volume', label: 'Get Search Volume' },
  { value: 'get_duplicates', label: 'Create dedup opportunity' },
  { value: 'get_site', label: 'Create new page opportunity' },
  { value: 'get_product_counts', label: 'Get product count' },
  { value: 'get_topic_cluster', label: 'Get topics' },
  { value: 'get_listing_cluster', label: 'Get listing clusters' },
  { value: 'label_classifier', label: 'Run Label Classifier' },
  { value: 'prep_content', label: 'Create content opportunity' },
  { value: 'build_keyword_index', label: 'Build index' },
  { value: 'refresh_dashboard', label: 'Refresh dashboard metrics' },
  { value: 'process_feedback', label: 'Process feedback' },
  { value: 'process_log_data', label: 'Process daily log data' },
  { value: 'process_monthly_log_data', label: 'Process monthly log data' },
  { value: 'get_monthly_gsc_data', label: 'Get monthly GSC data' },
  { value: 'get_daily_gsc_data', label: 'Get daily GSC data' },
  { value: 'refresh_jetoctopus_crawl', label: 'Refresh JetOctopus Crawl' },
  { value: 'refresh_botify_crawl', label: 'Refresh Botify Crawl' },
  { value: 'refresh_inventory', label: 'Refresh Inventory' },
  { value: 'gsc_bulk_export', label: 'GSC Bulk Export' },
  { value: 'noindex', label: 'noindex' },
  { value: '301 to parent', label: '301 to Parent' },
  { value: 'check_pages', label: 'Page instrumental' },
  { value: 'apply_function_ingredient', label: 'Apply function ingredient' },
  { value: 'refresh_indexability', label: 'Refresh indexability' },
  { value: 'crawler_test', label: 'Crawler test' },
];

export const WORKBENCH_ACTION_LABEL_LOOKUP: Record<string, string> =
  WORKBENCH_ACTIONS.reduce((pre, cur) => ({ ...pre, [cur.value]: cur.label }));
