import { Box, Typography } from '@mui/material';
import { FC } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import KWJobResultTable from './KWJobResultTable';
import HorizontalBarChart from '../../../../chart/HorizontalBarChart';
import { useQuery } from '@tanstack/react-query';
import { getKeywordIngredientJobResult } from '../../../../../apis/keyword_ingredient';

const KWJobResultMain: FC = () => {
  const params = useParams();
  const indexId = params.idx!;
  const kwIngredientId = params.kwIngredientId!;
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const source = queryParams.get('source') || '';
  let { data } = useQuery(
    [indexId, 'keyword-ingredient-job-result', kwIngredientId, source, 0],
    () => getKeywordIngredientJobResult(indexId, kwIngredientId, 0, 10, source)
  );
  const url_path_prefixes_aggregation = data?.url_path_prefixes_aggregation;
  const keys = url_path_prefixes_aggregation?.map((item: any) => item.label);
  const doc_counts = url_path_prefixes_aggregation?.map(
    (item: any) => item.doc_count
  );
  return (
    <Box sx={{ ml: 3, mr: 3, mt: 4 }}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Typography variant="h3">Keyword Ingredient Job Result</Typography>
      </Box>
      <Box sx={{ mt: 4 }}>
        <HorizontalBarChart
          labelX="doc count"
          labels={keys}
          bars={doc_counts}
        />
      </Box>
      <Box sx={{ mt: 4 }}>
        <KWJobResultTable
          indexId={indexId}
          kwIngredientId={kwIngredientId}
          source={source}
        />
      </Box>
    </Box>
  );
};

export default KWJobResultMain;
