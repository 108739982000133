import { Box, Button, Paper } from '@mui/material';
import { DataGridPro, GridColDef, GridToolbar } from '@mui/x-data-grid-pro';
import { useQuery } from '@tanstack/react-query';
import { FC, useState } from 'react';
import { useUser } from 'contexts/user-context';
import { getOpportunities } from 'apis/opportunities';
import MoreMenuBtn from './MoreMenuBtn';
import { useNavigate } from 'react-router-dom';
import { TextEditorView } from 'components/shared/ui/TextEditor';
import { dateComparator } from 'utils';

const ViewBtn: FC<{ row: any }> = ({ row }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`${row.id}`);
  };
  return <Button onClick={handleClick}>View</Button>;
};

const columns: GridColDef[] = [
  {
    field: 'view',
    headerName: '',
    headerAlign: 'center',
    align: 'center',
    headerClassName: 'App-Mui-DataGrid-Header',
    renderCell: (params) => <ViewBtn row={params.row} />,
  },
  {
    field: 'id',
    headerAlign: 'center',
    align: 'center',
    headerClassName: 'App-Mui-DataGrid-Header',
  },
  {
    field: 'name',
    headerAlign: 'center',
    align: 'center',
    headerClassName: 'App-Mui-DataGrid-Header',
    sortable: false,
    width: 400,
  },
  {
    field: 'type',
    headerAlign: 'center',
    align: 'center',
    headerClassName: 'App-Mui-DataGrid-Header',
    width: 100,
  },
  {
    field: 'description',
    headerName: 'description',
    headerAlign: 'center',
    align: 'center',
    headerClassName: 'App-Mui-DataGrid-Header',
    sortable: false,
    flex: 1,
    minWidth: 240,
    renderCell: (param) => {
      const val = param.row.description;
      if (!val) return <></>;
      return <TextEditorView description={param.row.description} />;
    },
  },
  {
    field: 'updated',
    headerAlign: 'center',
    align: 'center',
    headerClassName: 'App-Mui-DataGrid-Header',
    headerName: 'updated',
    width: 200,
    valueGetter: (params) => new Date(params.value).toLocaleString(),
    sortComparator: dateComparator,
  },
  {
    field: 'username',
    headerAlign: 'center',
    align: 'center',
    headerClassName: 'App-Mui-DataGrid-Header',
    headerName: 'user',
    width: 240,
  },
  {
    field: 'action',
    headerAlign: 'center',
    align: 'center',
    width: 120,
    headerClassName: 'App-Mui-DataGrid-Header',
    renderCell(params) {
      return <MoreMenuBtn oppId={params.id} />;
    },
  },
];

type Props = {
  isOptimization?: boolean;
};

const OpportunitiesTable: FC<Props> = ({ isOptimization }) => {
  const { curIndex } = useUser();
  let { data, isLoading } = useQuery(
    [curIndex, 'Opportunities'],
    () => getOpportunities(curIndex!.value),
    { enabled: Boolean(curIndex) }
  );
  data = data || [];
  if (isOptimization && data) {
    data = data.filter((item: any) => item.status === 'published');
  }
  if (!isOptimization && data) {
    data = data.filter((item: any) => item.status !== 'published');
  }
  const [pageSize, setPageSize] = useState(10);
  return (
    <Paper sx={{ display: 'flex', height: '100%' }}>
      <Box sx={{ flexGrow: 1 }}>
        <DataGridPro
          loading={isLoading}
          pagination
          pageSize={pageSize}
          rowsPerPageOptions={[10, 25, 100]}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          autoHeight
          getRowHeight={() => 'auto'}
          columns={columns}
          rows={data}
          disableDensitySelector
          disableColumnFilter
          components={{ Toolbar: GridToolbar }}
          componentsProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
            },
          }}
          sx={{
            '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': {
              py: '12px',
              borderBottomColor: '#eee',
            },
            '&.MuiDataGrid-root--densityStandard .MuiDataGrid-columnSeparator':
              { color: '#ccc' },
          }}
        />
      </Box>
    </Paper>
  );
};
export default OpportunitiesTable;
