import { IconButton, Tooltip } from '@mui/material';
import { FC, useState } from 'react';
import { useSnackbar } from 'contexts/snackbar-context';
import { useUser } from 'contexts/user-context';
import { postIndexExportJob } from 'apis/index_export';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { WBSourceType } from 'types/types';
import ExportDlg, { FieldOptionType } from './dialog/ExportDlg';

type Props = {
  back_query: any;
  source: WBSourceType;
  version?: string;
  opporId?: string | number;
  report_id?: string | number;
};
const WBExportBtn: FC<Props> = ({
  back_query,
  source,
  version,
  opporId,
  report_id,
}) => {
  const { setSnackbar } = useSnackbar();
  const { curIndex } = useUser();

  const handleApply = async (
    fields: FieldOptionType[],
    method: string,
    numOfRows: number,
    numOfSplits: number,
    order: string,
    isCustomerFields: boolean,
    selectedCol: string
  ) => {
    let selector;
    let type;
    if (isCustomerFields) type = 'None';
    else {
      if (source === 'sitetopic/top_topic') {
        type = 'sitetopic/top_topic';
      } else if (source === 'topic') {
        type = 'topic';
      } else if (source === 'page') {
        type = 'page_content';
      }
      // TODO: check opportunity type
      else if (opporId && source === 'keyword') {
        type = 'new_page_oppor/keyword';
      } else {
        type = 'None';
      }
    }
    if (method !== 'None') {
      selector = {
        method,
        col: selectedCol,
        rowNum: numOfRows,
        splitNum: numOfSplits,
        order,
      };
    }
    const columns = fields.filter((item) => item.enabled);
    const columnNames = columns.map((col) => col.field);
    const columnLabels = columns.map((col) => col.label);
    const data = await postIndexExportJob(curIndex!.value, {
      back_query,
      source,
      columns: columnNames,
      columnLabels,
      version,
      opporId,
      selector,
      type,
      report_id,
    });
    setDlgOpen(false);
    setSnackbar({
      severity: 'success',
      message: `Job started. Please go to Setup > Download Tab to see the result, id: ${data.job_id}`,
    });
  };

  const [dlgOpen, setDlgOpen] = useState(false);
  const handleClick = () => {
    if (source === 'sitetopic/top_topic') {
      handleApply([], '', 0, 0, '', false, '');
    } else {
      setDlgOpen(true);
    }
  };

  return (
    <>
      {dlgOpen && (
        <ExportDlg
          open={dlgOpen}
          onClose={() => setDlgOpen(false)}
          onApply={handleApply}
          source={source}
        />
      )}
      <Tooltip title="Export">
        <IconButton onClick={handleClick} color="primary">
          <CloudDownloadIcon />
        </IconButton>
      </Tooltip>
    </>
  );
};

export default WBExportBtn;
