export const postDashboardSegment = async (
  index: string | number,
  body: any
) => {
  const axiosInstance = require('utils/utils-axios-instance').default;
  const data = await axiosInstance.post(
    `index/${index}/dashboard/v3/page-segment/`,
    body
  );
  return data.data;
};

export const getDashboardSegments = async (index: string | number) => {
  const axiosInstance = require('utils/utils-axios-instance').default;
  const data = await axiosInstance.get(
    `index/${index}/dashboard/v3/page-segment/`
  );
  return data.data;
};

export const getDashboardMetrics = async (
  index: string | number,
  segId: string | number,
  start_date: string,
  end_date: string
) => {
  const axiosInstance = require('utils/utils-axios-instance').default;
  const data = await axiosInstance.get(
    `index/${index}/dashboard/v3/page-segment/${segId}/gsc-metrics/?start_date=${start_date}&end_date=${end_date}`
  );
  return data.data;
};
