import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Box,
  Typography,
  TextField,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import { FunctionComponent, useState } from 'react';
import { useForm } from 'react-hook-form';
import { ItemType } from './FunctionIngredientList';
import type { WhiteListItemType } from 'components/shared/ui/MixTagsInput';
import { useAuth } from 'contexts/auth-context';
import ReactJson, { InteractionProps } from 'react-json-view';

const DEFAULT_PARAMS = {
  prompt_template: '',
};

export const getWhitelist = (rawData: any) => {
  if (!rawData) return [];
  const ret: WhiteListItemType[] = [
    { id: 9999, _value: '$previous_result', value: '$previous result' },
  ];
  const lookup = new Map<string, string>();
  for (const sk in rawData['sources']) {
    const groups = rawData['sources'][sk]['groups'];
    for (const group of groups) {
      const fields = group.fields;
      for (const field of fields) {
        lookup.set(field.field, field.label);
      }
    }
  }

  lookup.forEach((v, k) => {
    ret.push({ id: k, value: v, _value: k });
  });
  return ret;
};

export type ApiType = 'dummy' | 'chatgpt';

type Props = {
  open: boolean;
  onClose: () => void;
  onSave: (data: any) => void;
  onCopy?: (data: any) => void;
  initData?: ItemType;
};

const FunctionIngredientDialog: FunctionComponent<Props> = ({
  open,
  onClose,
  onSave,
  onCopy,
  initData,
}) => {
  const { user } = useAuth();
  const isEdit = Boolean(initData);
  const { handleSubmit } = useForm<FormData>();

  const [name, setName] = useState(initData?.name || '');
  const [model_name, set_model_name] = useState(initData?.model_name || '');
  const [params, setParams] = useState<object>(
    initData?.params || DEFAULT_PARAMS
  );
  const [paramStr, setParamStr] = useState(JSON.stringify(params, null, 4));
  const [isJsonView, setIsJsonView] = useState(true);
  const [invalidJsonErr, setInvalidJsonErr] = useState(false);
  const handleChangeParamRawInput = (e: any) => {
    const value = e.target.value;
    setParamStr(value);
    try {
      setParams(JSON.parse(value));
      setInvalidJsonErr(false);
    } catch {
      setInvalidJsonErr(true);
    }
  };

  const handleMutateParams = (interaction: InteractionProps) => {
    setParams(interaction.updated_src);
  };

  const isGlobal = initData?.index === null;

  const onSubmit = () => {
    if (!name.trim()) return;

    onSave({
      api_type: 'chatgpt',
      params,
      model_name,
      name,
    });
    setName('');
    onClose();
  };

  const handleCopy = () => {
    if (!name.trim()) return;
    onCopy!({
      api_type: 'chatgpt',
      params,
      model_name,
      name,
    });
    setName('');
    onClose();
  };

  const title = isEdit
    ? 'Edit Function Ingredient'
    : 'Create Function Ingredient';

  const handleClose = () => {
    setName('');
    onClose();
  };

  const isNameErr = name.trim() === '';

  let showSave = false;
  if (user?.isStaff) showSave = true;
  if (!isGlobal) showSave = true;

  return (
    <Dialog open={open} onClose={handleClose} fullScreen sx={{ p: 8 }}>
      <Box component="form" onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent
          sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}
        >
          <TextField
            size="small"
            variant="standard"
            error={isNameErr}
            helperText={isNameErr && 'Please enter a name'}
            label="Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          {user?.isStaff && (
            <TextField
              size="small"
              variant="standard"
              label="Model name"
              value={model_name}
              onChange={(e) => set_model_name(e.target.value)}
            />
          )}
          <Box>
            <Typography variant="body2" color="text.secondary">
              Params
            </Typography>
            <Box>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isJsonView}
                    onChange={(e) => setIsJsonView(e.target.checked)}
                  />
                }
                label="JSON View"
              />
            </Box>
            {isJsonView && (
              <>
                {/*  @ts-ignore */}
                <ReactJson
                  name={null}
                  src={params}
                  onEdit={handleMutateParams}
                  onAdd={handleMutateParams}
                  onDelete={handleMutateParams}
                />
              </>
            )}
            {!isJsonView && (
              <TextField
                fullWidth
                multiline
                minRows={4}
                variant="standard"
                value={paramStr}
                onChange={handleChangeParamRawInput}
                error={invalidJsonErr}
                helperText={invalidJsonErr && 'Invalid JSON'}
              />
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          {showSave && <Button type="submit">Save</Button>}
          {isGlobal && (
            <Button variant="contained" onClick={handleCopy}>
              Copy
            </Button>
          )}
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default FunctionIngredientDialog;
