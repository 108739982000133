export const getTemplates = async (
  index: string,
  type: string,
  linkingOpporId?: number
) => {
  const axiosInstance = require('utils/utils-axios-instance').default;
  var url = '';
  if (linkingOpporId === undefined) {
    url = `index/${index}/api/template?type=${type}&needRecipeGroup=${true}`;
  } else {
    url = `index/${index}/api/template?type=${type}&needRecipeGroup=${true}&linkingOpporId=${linkingOpporId}`;
  }
  const data = await axiosInstance.get(url);
  return data.data;
};

export const postTemplate = async (index: string, body: any) => {
  const axiosInstance = require('utils/utils-axios-instance').default;
  const url = `index/${index}/api/template`;
  const data = await axiosInstance.post(url, body);
  return data.data;
};

export const updateTemplate = async (
  index: string,
  templateId: string | number,
  body: any
) => {
  const axiosInstance = require('utils/utils-axios-instance').default;
  const url = `index/${index}/api/template/${templateId}`;
  const data = await axiosInstance.put(url, body);
  return data.data;
};

export const deleteTemplate = async (
  index: string,
  templateId: string | number
) => {
  const axiosInstance = require('utils/utils-axios-instance').default;
  const url = `index/${index}/api/template/${templateId}`;
  const data = await axiosInstance.delete(url);
  return data.data;
};
