import { Box, MenuItem, TextField, Typography } from '@mui/material';
import { FC, useEffect, useMemo, useState } from 'react';
import { useUser } from 'contexts/user-context';
import { useQuery } from '@tanstack/react-query';
import { getDashboardMetrics, getDashboardSegments } from 'apis/dashboard';
import type { Props as ChartProps } from 'components/chart/LineChart';
import DateSelector from 'components/shared/ui/DateSelector';
import { Dayjs } from 'dayjs';
import * as dayjs from 'dayjs';
import LineChart from 'components/chart/LineChart';
import LoadingSpinner from 'components/shared/ui/LoadingSpinner';
import { useLocation, useNavigate } from 'react-router-dom';
import { isEmpty } from 'utils';
import DashboardTables from './DashboardTables';

type OptionType = {
  id: number;
  control_segment_id?: number;
  label: string;
  intervention?: string;
};

const calcInterventionDateAverage = (
  chartItem: any,
  interventionDate?: string
) => {
  if (!interventionDate) return;
  if (chartItem?.isCalInterventionAvg) return;
  const before: any[] = [];
  const after: any[] = [];
  for (const item of chartItem.time_series) {
    if (item['date'] <= interventionDate) before.push(item);
    else after.push(item);
  }
  if (before.length === 0 || after.length === 0) return;
  let avgBefore: any =
    before.map((x) => x.value).reduce((a: any, b: any) => a + b, 0) /
    before.length;
  let avgAfter: any =
    after.map((x) => x.value).reduce((a: any, b: any) => a + b, 0) /
    after.length;
  let diff: any = ((avgAfter - avgBefore) / avgBefore) * 100;
  diff = diff.toFixed(2) + '%';
  chartItem.verticalBars = {
    label: `Intervention Date: ${interventionDate} ( from: ${
      before[0]['date']
    }, to: ${after[after.length - 1]['date']} )`,
    data: [interventionDate],
  };
  avgBefore = avgBefore.toFixed(2);
  avgAfter = avgAfter.toFixed(2);
  chartItem.summary = { name: chartItem.metric, avgBefore, avgAfter, diff };
  chartItem.metric =
    chartItem.metric +
    ` ( before: ${avgBefore}, after: ${avgAfter} ) (${diff})`;
  chartItem['isCalInterventionAvg'] = true;
};

const mergeChartData = (chart: ChartProps) => {
  const { labels1, labels2, data1, data2 } = chart;
  if (isEmpty(labels1) || isEmpty(labels2) || isEmpty(data1) || isEmpty(data2))
    return;
  let [i, j] = [0, 0];
  const _labels: string[] = [];
  const _data1: number[] = [];
  const _data2: number[] = [];
  while (i < labels1!.length || j < labels2!.length) {
    if (i === labels1!.length) {
      while (j < labels2!.length) {
        _data1.push(NaN);
        _data2.push(data2![j]);
        _labels.push(labels2![j]);
        j++;
      }
      break;
    }
    if (j === labels2!.length) {
      while (i < labels1!.length) {
        _data1.push(data1![i]);
        _data2.push(NaN);
        _labels.push(labels1![i]);
        i++;
      }
      break;
    }
    if (labels1![i] === labels2![j]) {
      _labels.push(labels1![i]);
      _data1.push(data1[i]);
      _data2.push(data2![j]);
      i++;
      j++;
    } else if (labels1![i] < labels2![j]) {
      _labels.push(labels1![i]);
      _data1.push(data1[i]);
      _data2.push(NaN);
      i++;
    } else {
      _labels.push(labels2![j]);
      _data2?.push(data2![j]);
      _data1.push(NaN);
      j++;
    }
  }
  chart.labels = _labels;
  chart.data1 = _data1;
  chart.data2 = _data2;
};

type Props = {
  setPageSegmentId: any;
};

const canAdd2SegOption = (segItem: any, selectedOpporId: any) => {
  if (!selectedOpporId && !segItem.opportunity_id) return true;
  return +selectedOpporId === segItem.opportunity_id;
};

const DashboardChartMain: FC<Props> = ({ setPageSegmentId }) => {
  const [fromDate, setFromDate] = useState<Dayjs | null>(null);
  const [toDate, setToDate] = useState<Dayjs | null>(null);
  const { curIndex } = useUser();
  const navigate = useNavigate();

  const { data: dataSegs, isLoading: isLoadingDataSegs } = useQuery(
    [curIndex, 'DashboardSegments'],
    () => getDashboardSegments(curIndex!.value)
  );
  let opporOptions: any[] = [{ value: '', label: '---' }];
  if (dataSegs) {
    const opporIdSet = new Set<number>();
    for (const seg of dataSegs) {
      if (!seg.opportunity) continue;
      const oppor = seg.opportunity;
      if (opporIdSet.has(oppor.id)) continue;
      opporIdSet.add(oppor.id);
      opporOptions.push({
        label: `${oppor.id} - ${oppor.name}`,
        value: oppor.id,
      });
    }
  }

  const [selectedOpporId, setSelectedOpporId] = useState<number | string>('');

  const segOptions: OptionType[] = useMemo(() => {
    let ret: OptionType[] = [];
    if (dataSegs) {
      for (const item of dataSegs) {
        if (!canAdd2SegOption(item, selectedOpporId)) continue;
        ret.push({
          label: item.name,
          id: item.id,
          control_segment_id: item.control_segment_id,
          intervention: item.intervention,
        });
      }
    }
    return ret;
  }, [dataSegs, selectedOpporId]);

  const segOptionsExperiment = segOptions.filter((item) =>
    Boolean(item.control_segment_id)
  );
  const segOptionsNonExperiment = segOptions.filter(
    (item) => !Boolean(item.control_segment_id)
  );

  const segOptionsAll: OptionType[] = useMemo(() => {
    let ret: OptionType[] = [];
    if (dataSegs) {
      for (const item of dataSegs) {
        ret.push({
          label: item.name,
          id: item.id,
          control_segment_id: item.control_segment_id,
          intervention: item.intervention,
        });
      }
    }
    return ret;
  }, [dataSegs]);

  const [selectedSegId, setSelectedSegId] = useState<number>();
  const [controlSegId, setControlSegId] = useState<number>();
  const start_date = fromDate?.format('YYYY-MM-DD');
  const end_date = toDate?.format('YYYY-MM-DD');
  const [interventionDate, setInterventionDate] = useState<string>();
  const { data: dataCharts1, isFetching: isLoading1 } = useQuery(
    [curIndex, 'DashboardChart', selectedSegId, start_date, end_date],
    () =>
      getDashboardMetrics(
        curIndex!.value,
        selectedSegId!,
        start_date!,
        end_date!
      ),
    {
      enabled:
        Boolean(start_date) && Boolean(end_date) && Boolean(selectedSegId),
      staleTime: 1000 * 60,
    }
  );
  const { data: dataCharts2, isFetching: isLoading2 } = useQuery(
    [curIndex, 'DashboardChart', controlSegId, start_date, end_date],
    () =>
      getDashboardMetrics(
        curIndex!.value,
        controlSegId!,
        start_date!,
        end_date!
      ),
    {
      enabled:
        Boolean(start_date) && Boolean(end_date) && Boolean(controlSegId),
      staleTime: 1000 * 60,
    }
  );
  const handleSelectSegment = (e: React.ChangeEvent<HTMLInputElement>) => {
    const segId = +e.target.value;
    setSelectedSegId(segId);
    setPageSegmentId(segId);
    const seg = segOptions.find((item) => item.id === segId);
    let _fromDate = new Date();
    if (seg?.intervention) {
      setInterventionDate(seg.intervention);
      _fromDate = new Date(seg.intervention);
    } else {
      setInterventionDate(undefined);
    }
    _fromDate.setMonth(_fromDate.getMonth() - 1);
    setToDate(dayjs.default());
    setFromDate(dayjs.default(_fromDate));

    if (seg?.control_segment_id) {
      setControlSegId(seg.control_segment_id);
    } else {
      setControlSegId(undefined);
    }
  };
  const handleSelectOppor = (e: React.ChangeEvent<HTMLInputElement>) => {
    const opporId = e.target.value;
    setSelectedOpporId(opporId);
    setSelectedSegId(undefined);
    setPageSegmentId(undefined);
    setInterventionDate(undefined);
    setControlSegId(undefined);
  };

  useEffect(() => {
    let urlParm = '';
    if (selectedSegId) {
      urlParm += `segId=${selectedSegId}`;
    }
    if (selectedOpporId) {
      urlParm += `&opporId=${selectedOpporId}`;
    }
    if (fromDate) {
      urlParm += `&fromDate=${fromDate.toISOString().slice(0, 10)}`;
    }
    if (toDate) {
      urlParm += `&toDate=${toDate.toISOString().slice(0, 10)}`;
    }
    if (urlParm) {
      navigate(`?${urlParm}`);
    }
  }, [navigate, selectedSegId, fromDate, toDate, selectedOpporId]);

  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const urlSelectedSegId = urlParams.get('segId');
  const urlFromDate = urlParams.get('fromDate');
  const urlToDate = urlParams.get('toDate');
  const opporId = urlParams.get('opporId');
  useEffect(() => {
    if (
      !isLoadingDataSegs &&
      segOptionsAll &&
      !selectedSegId &&
      !selectedOpporId &&
      !fromDate &&
      !toDate
    ) {
      const seg = segOptionsAll.find(
        (item) => item.id.toString() === urlSelectedSegId
      );
      if (seg) {
        setSelectedSegId(seg.id);
      }
      if (seg?.control_segment_id) {
        setControlSegId(seg.control_segment_id);
      } else {
        setControlSegId(undefined);
      }
      if (opporId) {
        setSelectedOpporId(opporId);
      }
      if (urlFromDate) {
        setFromDate(dayjs.default(urlFromDate));
      }
      if (urlToDate) {
        setToDate(dayjs.default(urlToDate));
      }
      if (seg?.intervention) {
        setInterventionDate(seg.intervention);
      } else {
        setInterventionDate(undefined);
      }
    }
  }, [
    isLoadingDataSegs,
    segOptionsAll,
    urlSelectedSegId,
    urlFromDate,
    urlToDate,
    selectedSegId,
    fromDate,
    toDate,
    opporId,
    selectedOpporId,
  ]);

  const Selectors = (
    <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
      <TextField
        sx={{ width: 200 }}
        select
        label="Opportunity"
        size="small"
        value={selectedOpporId || ''}
        onChange={handleSelectOppor}
      >
        {opporOptions?.map((item) => (
          <MenuItem key={item.value} value={item.value}>
            {item.label}
          </MenuItem>
        ))}
      </TextField>
      <TextField
        sx={{ width: 200 }}
        size="small"
        label="Experiment segment"
        select
        value={selectedSegId || ''}
        onChange={handleSelectSegment}
      >
        {segOptionsExperiment.map((item) => (
          <MenuItem key={item.id} value={item.id}>
            {item.label}
          </MenuItem>
        ))}
      </TextField>
      <TextField
        sx={{ width: 200 }}
        size="small"
        label="Select a segment"
        select
        value={selectedSegId || ''}
        onChange={handleSelectSegment}
      >
        {segOptionsNonExperiment.map((item) => (
          <MenuItem key={item.id} value={item.id}>
            {item.label}
          </MenuItem>
        ))}
      </TextField>
      <DateSelector date={fromDate} setDate={setFromDate} label="From" />
      <DateSelector date={toDate} setDate={setToDate} label="To" />
      {interventionDate && (
        <Box sx={{ ml: 2 }}>
          <Typography sx={{ fontWeight: 700 }} variant="body2">
            Intervention Date:{' '}
          </Typography>
          <Typography sx={{ fontWeight: 700 }} variant="body2">
            {interventionDate}{' '}
          </Typography>
        </Box>
      )}
    </Box>
  );

  if (isLoading1 || isLoading2)
    return (
      <Box>
        {Selectors}
        <Box sx={{ mt: 4 }}>
          <LoadingSpinner />
        </Box>
      </Box>
    );
  const chartData: ChartProps[] = [];
  let summarys1: any[] = [];
  let summarys2: any[] = [];
  if (dataCharts1) {
    for (const item of dataCharts1) {
      calcInterventionDateAverage(item, interventionDate);
      if (item.summary) summarys1.push(item.summary);
      chartData.push({
        title1: item.metric,
        labels: item.time_series.map((o: any) => o.date),
        labels1: item.time_series.map((o: any) => o.date),
        data1: item.time_series.map((o: any) => o.value),
        verticalBars: item.verticalBars,
      });
    }
    if (dataCharts2 && dataCharts2.length === dataCharts1.length) {
      for (const item of dataCharts2) {
        calcInterventionDateAverage(item, interventionDate);
        if (item.summary) summarys2.push(item.summary);
      }
      dataCharts2.forEach((item: any, i: number) => {
        chartData[i]['title2'] = item.metric + ' - control group';
        chartData[i]['data2'] = item.time_series.map((o: any) => o.value);
        chartData[i]['labels2'] = item.time_series.map((o: any) => o.date);
      });
    }
  }
  for (const chart of chartData) {
    mergeChartData(chart);
  }

  return (
    <Box sx={{ mt: 2 }}>
      {Selectors}
      <Box sx={{ mt: 2 }}>
        <DashboardTables
          summarys1={summarys1}
          summarys2={summarys2}
          interventionDate={interventionDate}
        />
      </Box>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: '1fr 1fr',
          mt: 2,
          rowGap: 4,
        }}
      >
        {chartData?.map((item, i) => (
          <Box key={i} sx={{ width: '42vw' }}>
            <LineChart
              labels={item.labels}
              data1={item.data1}
              title1={item.title1}
              title2={item.title2}
              data2={item.data2}
              verticalBars={item.verticalBars}
            />
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default DashboardChartMain;
