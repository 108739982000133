import { Box, Typography } from '@mui/material';
import { FC } from 'react';
import SiteTopicReportTable from './SiteTopicReportTable';

type Props = {
  opporId?: number;
};
const SiteTopicMain: FC<Props> = ({ opporId }) => {
  return (
    <Box sx={{ ml: 3, mr: 3, mt: 4 }}>
      <Typography sx={{ my: 4 }} variant="h3">
        Site topic reports
      </Typography>
      <SiteTopicReportTable opporId={opporId} />
    </Box>
  );
};

export default SiteTopicMain;
