import {
  DataGridPro,
  DataGridProProps,
  GridColDef,
  GridSortModel,
  useGridApiRef,
} from '@mui/x-data-grid-pro';

import { FC, useCallback } from 'react';
import { ColOrderType } from 'types/types';

import { renderUrl } from '../../shared/table-cell/UrlCell';
import { getCurrency, getNumber } from '../../shared/table-cell';
import WBTopicNestedTable from './WBTopicNestedTable';
import { renderClusterKeyword } from '../../shared/table-cell/ClusterKeywordCell';
import renderPreviewChatGPT from '../../shared/table-cell/ChatGPTPreviewCell';
import { renderStrArr } from '../../shared/table-cell/StrArrCell';
import { renderSerpHeadings } from '../../shared/table-cell/SerpHeadingsCell';
import { renderVariable } from 'components/shared/table-cell/VariableCell';

export const getInitCols = (): GridColDef[] => {
  return [
    {
      field: 'id',
      headerClassName: 'App-Mui-DataGrid-Header',
    },
    {
      field: 'preview_chatgpt',
      headerClassName: 'App-Mui-DataGrid-Header',
      headerName: 'Preview ChatGPT',
      renderCell: renderPreviewChatGPT,
      width: 400,
    },
    {
      field: '$preview_variable',
      headerClassName: 'App-Mui-DataGrid-Header',
      headerName: 'Preview Variable',
      renderCell: renderVariable,
      width: 300,
    },
    {
      field: 'canonical_keyword',
      headerName: 'topic',
      headerClassName: 'App-Mui-DataGrid-Header',
      minWidth: 200,
      flex: 1,
    },
    {
      field: 'cluster_keywords',
      headerName: 'topic keywords',
      headerClassName: 'App-Mui-DataGrid-Header',
      width: 300,
      sortable: false,
      renderCell: renderClusterKeyword,
    },
    {
      field: 'cluster_size',
      headerName: '# topic keywords',
      headerClassName: 'App-Mui-DataGrid-Header',
      width: 160,
      valueGetter: getNumber,
    },
    {
      field: 'canonical_url',
      headerName: 'canonical url',
      headerClassName: 'App-Mui-DataGrid-Header',
      renderCell: renderUrl,
      minWidth: 300,
      flex: 1,
    },
    {
      field: 'search_url',
      headerName: 'search url',
      headerClassName: 'App-Mui-DataGrid-Header',
      renderCell: renderUrl,
      minWidth: 300,
      flex: 1,
    },
    {
      field: 'total_demand',
      headerName: 'total demand',
      headerClassName: 'App-Mui-DataGrid-Header',
      width: 100,
      valueGetter: getNumber,
    },
    {
      field: 'total_traffic',
      headerName: 'total traffic',
      headerClassName: 'App-Mui-DataGrid-Header',
      width: 100,
      valueGetter: getNumber,
    },
    {
      field: 'traffic_opportunity',
      headerName: 'traffic opportunity',
      headerClassName: 'App-Mui-DataGrid-Header',
      width: 100,
      valueGetter: getNumber,
    },
    {
      field: 'value_potential',
      headerName: 'value potential ($)',
      headerClassName: 'App-Mui-DataGrid-Header',
      width: 100,
      valueGetter: getCurrency,
    },
    {
      field: 'best_position',
      headerName: 'best position',
      headerClassName: 'App-Mui-DataGrid-Header',
      width: 100,
      valueGetter: getNumber,
    },
    {
      field: 'max_cpc',
      headerName: 'max cpc',
      headerClassName: 'App-Mui-DataGrid-Header',
      width: 100,
      valueGetter: getCurrency,
    },
    {
      field: 'min_cpc',
      headerName: 'min cpc',
      headerClassName: 'App-Mui-DataGrid-Header',
      width: 100,
      valueGetter: getCurrency,
    },
    {
      field: 'product_count',
      headerClassName: 'App-Mui-DataGrid-Header',
      headerName: '# listings',
      width: 120,
      valueGetter: getNumber,
    },
    {
      field: 'listings_relevance',
      headerClassName: 'App-Mui-DataGrid-Header',
      headerName: 'listing relevance',
      width: 120,
    },
    {
      field: 'topics_count',
      headerClassName: 'App-Mui-DataGrid-Header',
      headerName: '# children topic',
      valueGetter: getNumber,
      width: 120,
    },
    {
      field: 'total_competitive_traffic',
      headerName: 'total competitive traffic',
      headerClassName: 'App-Mui-DataGrid-Header',
      width: 100,
    },
    {
      field: 'best_competitive_position',
      headerName: 'best competitive position',
      headerClassName: 'App-Mui-DataGrid-Header',
      width: 100,
    },
    {
      field: 'top_ranking_domains',
      headerClassName: 'App-Mui-DataGrid-Header',
      headerName: 'top ranking domains',
      renderCell: renderStrArr,
      flex: 1,
      minWidth: 300,
    },
    {
      field: 'serp_domain_diversity',
      headerClassName: 'App-Mui-DataGrid-Header',
      headerName: 'serp domain diversity',
      width: 120,
    },
    {
      field: 'serp_headings',
      headerClassName: 'App-Mui-DataGrid-Header',
      headerName: 'SERP headings',
      width: 480,
      renderCell: renderSerpHeadings,
    },
  ];
};

type Props = {
  data: any;
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  offset: number;
  setOffset: React.Dispatch<React.SetStateAction<number>>;
  setOrder: React.Dispatch<React.SetStateAction<ColOrderType>>;
  isLoading: boolean;
  setColsDisplayOrder: React.Dispatch<
    React.SetStateAction<Record<string, number>>
  >;
  setColsWidth: React.Dispatch<React.SetStateAction<Record<string, number>>>;
  columns: GridColDef[];
};

const WBTopicTable: FC<Props> = ({
  data,
  page,
  setPage,
  offset,
  setOffset,
  setOrder,
  isLoading,
  setColsDisplayOrder,
  setColsWidth,
  columns,
}) => {
  const handleSorting = useCallback(
    (sortModel: GridSortModel) => {
      const _sort = sortModel[0];
      if (!_sort) return;
      setOrder({
        name: _sort.field,
        direction: _sort.sort as 'asc' | 'desc',
      });
    },
    [setOrder]
  );

  const getDetailPanelContent = useCallback<
    NonNullable<DataGridProProps['getDetailPanelContent']>
  >(({ row }) => <WBTopicNestedTable row={row} />, []);
  const getDetailPanelHeight = useCallback(() => 400, []);

  const apiRef = useGridApiRef();

  return (
    <DataGridPro
      apiRef={apiRef}
      onColumnOrderChange={() => {
        const cols = apiRef.current.getAllColumns().map((item) => item.field);
        const colsDisOrder: Record<string, number> = {};
        cols.forEach((col, idx) => {
          colsDisOrder[col] = idx;
        });
        setColsDisplayOrder(colsDisOrder);
      }}
      onColumnWidthChange={(params) => {
        setColsWidth((pre) => ({
          ...pre,
          [params.colDef.field]: params.width,
        }));
      }}
      getDetailPanelHeight={getDetailPanelHeight}
      getDetailPanelContent={getDetailPanelContent}
      loading={isLoading}
      pagination
      autoHeight
      getRowHeight={() => 'auto'}
      columns={columns}
      rows={data.items}
      paginationMode="server"
      sortingMode="server"
      rowCount={data?.count || 0}
      pageSize={offset}
      onPageSizeChange={setOffset}
      page={page}
      onPageChange={setPage}
      rowsPerPageOptions={[10, 25, 100]}
      onSortModelChange={handleSorting}
      disableDensitySelector
      disableColumnFilter
      sx={{
        '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': {
          py: '12px',
          borderBottomColor: '#eee',
        },
        '&.MuiDataGrid-root--densityStandard .MuiDataGrid-columnSeparator': {
          color: '#ccc',
        },
        display: 'flex',
        flexDirection: 'column-reverse',
      }}
    />
  );
};
export default WBTopicTable;
