import { Box, Button, Paper } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { getIndexSchduledAction } from 'apis/index_scheduled_actions';
import { useUser } from 'contexts/user-context';
import type { IntervalType, BaseJobType } from 'types/types';
import { FC, useCallback, useState } from 'react';
import {
  DataGridPro,
  DataGridProProps,
  GridColDef,
  GridToolbar,
} from '@mui/x-data-grid-pro';
import MoreMenuBtn from './MoreMenuBtn';
import SACreateEditDialog from './SACreateEditDialog';
import { WORKBENCH_ACTION_LABEL_LOOKUP } from 'constants/constants';
import { renderJson } from 'components/shared/table-cell/JsonCell';

type ScheduledActionJobItemType = {
  base: BaseJobType;
  params: any;
};

const nestedColumns: GridColDef[] = [
  {
    field: 'id',
    headerClassName: 'App-Mui-DataGrid-Header',
  },
  {
    field: 'rq_job_id',
    headerClassName: 'App-Mui-DataGrid-Header',
  },
  {
    field: 'status',
    headerClassName: 'App-Mui-DataGrid-Header',
  },
  {
    field: 'user',
    headerClassName: 'App-Mui-DataGrid-Header',
  },
  {
    field: 'created',
    width: 200,
    valueGetter: (params) => new Date(params.value).toLocaleString(),
    headerClassName: 'App-Mui-DataGrid-Header',
  },
  {
    field: 'updated',
    width: 200,
    valueGetter: (params) => new Date(params.value).toLocaleString(),
    headerClassName: 'App-Mui-DataGrid-Header',
  },
  {
    field: 'params',
    headerClassName: 'App-Mui-DataGrid-Header',
    renderCell: renderJson,
    minWidth: 200,
  },
  {
    field: 'error_message',
    headerClassName: 'App-Mui-DataGrid-Header',
    minWidth: 200,
  },
];

type NestedTableProps = {
  row: any;
};

const NestedTable: FC<NestedTableProps> = ({ row }) => {
  let items: ScheduledActionJobItemType[] = row.jobItems || [];
  items = items.map((item: any) => ({ ...item.base, params: item.params }));
  return (
    <DataGridPro
      components={{ Toolbar: GridToolbar }}
      disableDensitySelector
      disableColumnFilter
      density="compact"
      columns={nestedColumns}
      rows={items}
      sx={{ width: 1440 }}
      hideFooter
    />
  );
};

const getActionLabel = (value: string) => {
  if (value in WORKBENCH_ACTION_LABEL_LOOKUP)
    return WORKBENCH_ACTION_LABEL_LOOKUP[value];
  return value;
};

const columns: GridColDef[] = [
  {
    field: 'id',
    headerClassName: 'App-Mui-DataGrid-Header',
  },
  {
    field: 'name',
    headerClassName: 'App-Mui-DataGrid-Header',
    width: 300,
  },
  {
    field: 'action',
    headerClassName: 'App-Mui-DataGrid-Header',
    headerName: 'action',
    valueGetter: (params) => getActionLabel(params.value),
    width: 120,
  },
  {
    field: 'refresh_interval',
    headerClassName: 'App-Mui-DataGrid-Header',
    headerName: 'refresh interval',
    width: 120,
  },
  {
    field: 'auto_refresh',
    headerClassName: 'App-Mui-DataGrid-Header',
    headerName: 'auto refresh',
    width: 120,
  },
  {
    field: 'params',
    headerClassName: 'App-Mui-DataGrid-Header',
    headerName: 'params',
    renderCell: renderJson,
    minWidth: 500,
    flex: 1,
  },
  {
    field: 'Action',
    headerClassName: 'App-Mui-DataGrid-Header',
    renderCell(params) {
      return <MoreMenuBtn saObj={params.row} />;
    },
  },
];

type ScheduledActionItemType = {
  id: number;
  action: string;
  params: any;
  refresh_interval: IntervalType;
  auto_refresh: boolean;
  jobItems: ScheduledActionJobItemType[];
};

type ScheduledActionsTableProps = {
  items: ScheduledActionItemType[];
};

const ScheduledActionsTable: FC<ScheduledActionsTableProps> = ({ items }) => {
  const getDetailPanelContent = useCallback<
    NonNullable<DataGridProProps['getDetailPanelContent']>
  >(({ row }) => <NestedTable row={row} />, []);
  const getDetailPanelHeight = useCallback(() => 400, []);

  const [open, setOpen] = useState(false);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      {open && (
        <SACreateEditDialog open={open} onClose={() => setOpen(false)} />
      )}
      <Box sx={{ alignSelf: 'flex-end' }}>
        <Button variant="outlined" onClick={() => setOpen(true)}>
          Add Scheduled Action
        </Button>
      </Box>
      <DataGridPro
        getDetailPanelHeight={getDetailPanelHeight}
        getDetailPanelContent={getDetailPanelContent}
        pagination
        autoHeight
        getRowHeight={() => 'auto'}
        columns={columns}
        rows={items}
        rowsPerPageOptions={[10, 25, 100]}
        disableDensitySelector
        disableColumnFilter
        sx={{
          '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': {
            py: '12px',
            borderBottomColor: '#eee',
          },
          '&.MuiDataGrid-root--densityStandard .MuiDataGrid-columnSeparator': {
            color: '#ccc',
          },
          display: 'flex',
          flexDirection: 'column-reverse',
        }}
      />
    </Box>
  );
};

const SettingsScheduledActions: FC = () => {
  const { curIndex } = useUser();
  const { data } = useQuery(
    [curIndex, 'scheduled-action'],
    () => getIndexSchduledAction(curIndex!.value),
    { enabled: Boolean(curIndex), staleTime: 1000 * 60 }
  );
  let items: ScheduledActionItemType[] = [];

  if (data?.items) {
    items = data.items;
  }
  return (
    <Paper sx={{ p: 2 }}>
      <ScheduledActionsTable items={items} />
    </Paper>
  );
};

export default SettingsScheduledActions;
