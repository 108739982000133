import { Box, Paper } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { postWorkbenchTopic } from 'apis/workbench';
import { useUser } from 'contexts/user-context';
import { FC, useCallback, useState } from 'react';
import { ColOrderType } from 'types/types';
import { convertQueryBuilderTree } from 'utils/utils-query-builder';
import WBTopicTable from './WBTopicTable';
import { StateProp as OperationStateProp } from 'components/workbench/OperationBuilder';
import ActionSelector from '../ActionSelector';
import WBExportBtn from '../WBExportBtn';
import useWBActions from 'hooks/useWBActions';
import useWBCols from 'hooks/useWBCols';
import WBQBRecipe from '../WBQBRecipe';
import FieldSetupDlg from '../dialog/FieldSetupDlg';
import SetupColBtn from '../SetupColBtn';
import { initTableDataFromDataRaw } from 'utils';

const WBTopic: FC = () => {
  const { curIndex } = useUser();
  const [page, setPage] = useState(0);
  const [offset, setOffset] = useState(10);
  const [operation, setOperation] = useState<OperationStateProp>();
  const [backQuery, setBackQuery] = useState<any>(null);
  const [order, setOrder] = useState<ColOrderType>({
    name: 'total_demand',
    direction: 'desc',
  });
  let { data: dataRaw, isLoading } = useQuery(
    [curIndex, 'WB', 'topic', page, offset, backQuery, order, operation],
    () =>
      postWorkbenchTopic(curIndex!.value, {
        page,
        offset,
        operation: operation?.operations[0],
        back_query: backQuery,
        order_column: order?.name,
        order_dir: order?.direction,
      }),
    { enabled: Boolean(curIndex), keepPreviousData: true }
  );

  const handleChangeRecipe = useCallback((jsonTree: any) => {
    const _backQuery = convertQueryBuilderTree(jsonTree);
    setBackQuery(_backQuery);
  }, []);

  const handleOperationChange = useCallback((operation: OperationStateProp) => {
    setOperation(operation);
  }, []);

  const {
    invisibleCols,
    setInvisibleCols,
    colsDisplayOrder,
    setColsDisplayOrder,
    colsWidth,
    setColsWidth,
    columns,
  } = useWBCols({ source: 'topic' });

  const data = initTableDataFromDataRaw(dataRaw);

  data['items'] = data.items.map((item: any) => ({
    ...item,
    __operation: operation,
  }));

  const {
    handleSelectAction,
    fieldOptions,
    groupOptions,
    isSupportRowSelector,
  } = useWBActions({
    source: 'topic',
  });

  const [isFieldSetupDlgOpen, setIsFieldSetupDlgOpen] = useState(false);

  return (
    <Box sx={{ ml: 3, mr: 3, mt: 4 }}>
      {isFieldSetupDlgOpen && (
        <FieldSetupDlg
          open={isFieldSetupDlgOpen}
          onClose={() => setIsFieldSetupDlgOpen(false)}
          source="topic"
        />
      )}
      <Box mt={4}>
        <WBQBRecipe
          onRecipeChange={handleChangeRecipe}
          onOperationChange={handleOperationChange}
          col_sorting={order}
          setOrder={setOrder}
          col_invisible={invisibleCols.cols}
          setInvisibleCols={setInvisibleCols}
          col_display_order={colsDisplayOrder}
          col_width={colsWidth}
          setColsDisplayOrder={setColsDisplayOrder}
          setColsWidth={setColsWidth}
          source={'topic'}
          tableData={data}
        />
      </Box>
      <Paper sx={{ mt: 4 }}>
        <ActionSelector
          isSupportRowSelector={isSupportRowSelector}
          fieldOptions={fieldOptions}
          onSelectOption={handleSelectAction}
          backQuery={backQuery}
          source="topic"
          groupOptions={groupOptions}
        />
        <Box
          sx={{
            m: 1,
            display: 'flex',
            gap: 2,
            alignItems: 'center',
            justifyContent: 'flex-end',
          }}
        >
          <WBExportBtn source="topic" back_query={backQuery} />
          <SetupColBtn onClick={() => setIsFieldSetupDlgOpen(true)} />
        </Box>
        <WBTopicTable
          data={data}
          page={page}
          setPage={setPage}
          offset={offset}
          setOffset={setOffset}
          setOrder={setOrder}
          isLoading={isLoading}
          setColsDisplayOrder={setColsDisplayOrder}
          setColsWidth={setColsWidth}
          columns={columns}
        />
      </Paper>
    </Box>
  );
};

export default WBTopic;
