export const getWidgets = async (index: string | number) => {
  const axiosInstance = require('utils/utils-axios-instance').default;
  const data = await axiosInstance.get(`index/${index}/api/widget/`);
  return data.data;
};

export const postWidgetItem = async (
  index: string | number,
  id: number,
  body?: any
) => {
  const axiosInstance = require('utils/utils-axios-instance').default;
  const data = await axiosInstance.post(
    `index/${index}/api/widget/${id}`,
    body
  );
  return data.data;
};

export const postWidget = async (index: string | number, body: any) => {
  const axiosInstance = require('utils/utils-axios-instance').default;
  const data = await axiosInstance.post(`index/${index}/api/widget/`, body);
  return data.data;
};

export const patchWidget = async (
  index: string | number,
  id: number,
  body: any
) => {
  const axiosInstance = require('utils/utils-axios-instance').default;
  const data = await axiosInstance.patch(
    `index/${index}/api/widget/${id}`,
    body
  );
  return data.data;
};

export const deleteWidget = async (index: string | number, id: number) => {
  const axiosInstance = require('utils/utils-axios-instance').default;
  const data = await axiosInstance.delete(`index/${index}/api/widget/${id}`);
  return data.data;
};
