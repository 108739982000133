import Layout from 'components/layout/Layout';
import LinkingVerificationResultMain from 'components/opportunities/detail/linking-verification-result/LinkingVerificationResultMain';
import { FC } from 'react';

const LinkingVerificationResultPage: FC = () => {
  return (
    <Layout>
      <LinkingVerificationResultMain />
    </Layout>
  );
};

export default LinkingVerificationResultPage;
