import { FC, useState } from 'react';
import {
  Box,
  TextField,
  Paper,
  Typography,
  Button,
  Slider,
  MenuItem,
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { postDashboardSegment } from 'apis/dashboard';
import { useUser } from 'contexts/user-context';
import { useSnackbar } from 'contexts/snackbar-context';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { getOpportunities } from 'apis/opportunities';
import { OptionType } from 'types/types';
import LoadingSpinner from 'components/shared/ui/LoadingSpinner';

type FormData = {
  name: string;
  urls: string;
};

const DashboardSegNewEdit: FC = () => {
  const queryClient = useQueryClient();
  const { curIndex } = useUser();
  const { setSnackbar } = useSnackbar();
  const {
    formState: { errors },
    handleSubmit,
    control,
  } = useForm<FormData>();

  const [controlPercentage, setControlPercentage] = useState<number>(0);

  const [selectedOpporId, setSelectedOpporId] = useState<number | string>('');
  let { data: dataOppor, isLoading: isLoadingOppor } = useQuery(
    [curIndex, 'Opportunities'],
    () => getOpportunities(curIndex!.value),
    { enabled: Boolean(curIndex) }
  );
  let opporOptions: OptionType[] = [{ value: '', label: '---' }];
  if (dataOppor) {
    opporOptions = dataOppor.map((item: any) => ({
      label: `${item.id} - ${item.name}`,
      value: item.id,
    }));
    opporOptions.unshift({ value: '', label: '---' });
  }

  const onSubmit = async (data: FormData) => {
    const { name, urls } = data;
    const body = {
      controlPercentage,
      name,
      urls,
      opporId: selectedOpporId,
    };
    await postDashboardSegment(curIndex!.value, body);
    queryClient.invalidateQueries([curIndex, 'DashboardSegments']);
    setSnackbar({ severity: 'success', message: 'Created' });
  };

  return (
    <Box
      component="form"
      sx={{ display: 'flex', justifyContent: 'center' }}
      onSubmit={handleSubmit(onSubmit)}
    >
      <Paper
        sx={{
          p: 2,
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          width: 1200,
        }}
      >
        <Controller
          name="name"
          defaultValue=""
          control={control}
          rules={{
            required: true,
          }}
          render={({ field }) => (
            <TextField
              {...field}
              margin="dense"
              label="Name"
              error={Boolean(errors.name)}
              size="small"
              variant="outlined"
            />
          )}
        />
        <Controller
          name="urls"
          defaultValue=""
          control={control}
          rules={{
            required: true,
          }}
          render={({ field }) => (
            <TextField
              {...field}
              multiline
              placeholder="One url per line."
              margin="dense"
              label="Urls"
              error={Boolean(errors.urls)}
              size="small"
              variant="outlined"
            />
          )}
        />
        <Box>
          {isLoadingOppor && <LoadingSpinner />}
          {!isLoadingOppor && (
            <TextField
              select
              fullWidth
              margin="dense"
              label="Opportunity"
              size="small"
              variant="outlined"
              value={selectedOpporId}
              onChange={(e) => setSelectedOpporId(e.target.value)}
            >
              {opporOptions?.map((item) => (
                <MenuItem key={item.value} value={item.value}>
                  {item.label}
                </MenuItem>
              ))}
            </TextField>
          )}
        </Box>
        <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
          <Typography sx={{ fontWeight: 700 }}>Control group:</Typography>
          <Slider
            sx={{ width: 300 }}
            min={0}
            max={99}
            value={controlPercentage}
            onChange={(e, v) => setControlPercentage(v as number)}
          />
          <Typography>{controlPercentage + '%'}</Typography>
        </Box>
        <Box>
          <Button type="submit" variant="contained">
            Create
          </Button>
        </Box>
      </Paper>
    </Box>
  );
};

export default DashboardSegNewEdit;
