import Layout from 'components/layout/Layout';
import DocumentItemsMain from 'components/settings/views/upload-csv/preview/DocumentItemsMain';
import { FC } from 'react';

const FeedInPreviewPage: FC = () => {
  return (
    <Layout>
      <DocumentItemsMain />
    </Layout>
  );
};

export default FeedInPreviewPage;
