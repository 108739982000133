import { Box, Paper } from '@mui/material';
import { DataGridPro, GridColDef, GridToolbar } from '@mui/x-data-grid-pro';

import { FC, useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { getPageIngredientCrawlItems } from 'apis/page_ingredient';
import { renderUrl } from 'components/shared/table-cell/UrlCell';

const columns: GridColDef[] = [
  {
    field: 'id',
    headerClassName: 'App-Mui-DataGrid-Header',
    sortable: false,
  },
  {
    field: 'url',
    renderCell: renderUrl,
    headerClassName: 'App-Mui-DataGrid-Header',
    flex: 2,
    sortable: false,
  },
  {
    field: 'h1',
    headerClassName: 'App-Mui-DataGrid-Header',
    flex: 2,
    sortable: false,
  },
  {
    field: 'h2',
    headerClassName: 'App-Mui-DataGrid-Header',
    flex: 2,
    sortable: false,
  },
  {
    field: 'title',
    headerClassName: 'App-Mui-DataGrid-Header',
    flex: 2,
    sortable: false,
  },
  {
    field: 'status_code',
    headerClassName: 'App-Mui-DataGrid-Header',
    flex: 1,
    sortable: false,
  },
  {
    field: 'crawl_hits_google',
    headerClassName: 'App-Mui-DataGrid-Header',
    flex: 1,
    sortable: false,
  },
];

type Props = {
  indexId: string;
  pageIngredientId: string | number;
};

const PIJobResultTable: FC<Props> = ({ indexId, pageIngredientId }) => {
  const [page, setPage] = useState(0);
  const [offset, setOffset] = useState(10);
  let { data, isLoading } = useQuery(
    [indexId, 'page-ingredient-job-result', page, offset],
    () => getPageIngredientCrawlItems(indexId, pageIngredientId, page, offset)
  );

  data = data || { items: [], count: 0 };
  if (data.items.length > 0) {
    data.items = data.items.map((item: any, id: number) => ({ ...item, id }));
  }
  const [rowCount, setRowCount] = useState(0);
  useEffect(() => {
    if (data && !rowCount) {
      setRowCount(data.count);
    }
  }, [data, rowCount]);

  return (
    <Paper
      sx={{ display: 'flex', height: '100%', flexDirection: 'column', gap: 2 }}
    >
      <Box sx={{ flexGrow: 1 }}>
        <DataGridPro
          initialState={{
            columns: {
              columnVisibilityModel: {
                id: false,
              },
            },
          }}
          pagination
          loading={isLoading}
          autoHeight
          getRowHeight={() => 'auto'}
          columns={columns}
          rows={data?.items}
          paginationMode="server"
          rowCount={rowCount}
          pageSize={offset}
          onPageSizeChange={setOffset}
          page={page}
          onPageChange={setPage}
          rowsPerPageOptions={[10, 25, 100]}
          disableDensitySelector
          disableColumnFilter
          components={{ Toolbar: GridToolbar }}
          sx={{
            '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': {
              py: '12px',
              borderBottomColor: '#eee',
            },
            '&.MuiDataGrid-root--densityStandard .MuiDataGrid-columnSeparator':
              { color: '#ccc' },
          }}
        />
      </Box>
    </Paper>
  );
};
export default PIJobResultTable;
