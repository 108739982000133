export const getFunctionIngredients = async (index: string) => {
  const axiosInstance = require('utils/utils-axios-instance').default;
  const data = await axiosInstance.get(
    `index/${index}/api/function-ingredient`
  );
  return data.data;
};

export const postFunctionIngredient = async (index: string, body: any) => {
  const axiosInstance = require('utils/utils-axios-instance').default;
  const data = await axiosInstance.post(
    `index/${index}/api/function-ingredient`,
    body
  );
  return data.data;
};

export const deleteFunctionIngredients = async (
  index: string,
  id: string | number
) => {
  const axiosInstance = require('utils/utils-axios-instance').default;
  const data = await axiosInstance.delete(
    `index/${index}/api/function-ingredient/${id}`
  );
  return data.data;
};

export const updateFunctionIngredient = async (
  index: string,
  id: string | number,
  body: any
) => {
  const axiosInstance = require('utils/utils-axios-instance').default;
  const data = await axiosInstance.patch(
    `index/${index}/api/function-ingredient/${id}`,
    body
  );
  return data.data;
};
