import {
  Dialog,
  DialogContent,
  DialogTitle,
  Box,
  Typography,
  IconButton,
  List,
  ListItemButton,
  Input,
  InputAdornment,
  ListItem,
} from '@mui/material';
import { FunctionComponent, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import DeleteIcon from '@mui/icons-material/Delete';
import { addRecipeStrAndOpField2Recipes } from 'utils/utils-query-builder';
import { deleteTemplate } from 'apis/index_template';
import { useUser } from 'contexts/user-context';
import { useSnackbar } from 'contexts/snackbar-context';
import { useMutation, useQueryClient } from '@tanstack/react-query';

type Props = {
  open: boolean;
  onClose: () => void;
  onSelect: (recipe: any) => void;
  recipes: any;
  config: any;
  hideDelete?: boolean;
};
const RecipeSelectDialog: FunctionComponent<Props> = ({
  open,
  onClose,
  onSelect,
  recipes,
  config,
  hideDelete,
}) => {
  const [filterStr, setFilterStr] = useState('');
  recipes = addRecipeStrAndOpField2Recipes(recipes, config);
  let filteredRecipes = recipes;
  if (filterStr) {
    filteredRecipes = recipes?.filter((item: any) => {
      if (item.name.toLowerCase().indexOf(filterStr.toLowerCase()) !== -1)
        return true;
      return false;
    });
  }

  const queryClient = useQueryClient();
  const { curIndex } = useUser();
  const { setSnackbar } = useSnackbar();
  const { mutate: m_delete } = useMutation(
    (id: string | number) => deleteTemplate(curIndex!.value, id),
    {
      onSuccess: () => {
        setSnackbar({ severity: 'success', message: 'Deleted.' });
        queryClient.invalidateQueries([curIndex, 'WBQBRecipe']);
      },
    }
  );
  const handleDelete = (item: any) => {
    m_delete(item.id);
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Typography sx={{ fontWeight: 700, fontSize: 22 }}>
          Select Recipe
        </Typography>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Box>
          <Input
            value={filterStr}
            onChange={(e) => setFilterStr(e.target.value)}
            fullWidth
            startAdornment={
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            }
          />
        </Box>
        <List sx={{ mt: 2 }}>
          {filteredRecipes?.map((item: any) => (
            <ListItem
              key={item.id}
              secondaryAction={
                !hideDelete ? (
                  <IconButton
                    edge="end"
                    onClick={handleDelete.bind(null, item)}
                  >
                    <DeleteIcon />
                  </IconButton>
                ) : undefined
              }
            >
              <Typography variant="caption" color="text.secondary">
                {item.id}
              </Typography>
              <ListItemButton
                onClick={onSelect.bind(null, item)}
                sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}
              >
                <Typography sx={{ fontWeight: 500 }}>
                  {item.name}
                  {item.operationField ? `: (${item.operationField})` : ''}
                </Typography>
                {item.recipeStr && (
                  <Typography variant="caption" color="text.secondary">
                    {item.recipeStr}
                  </Typography>
                )}
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </DialogContent>
    </Dialog>
  );
};

export default RecipeSelectDialog;
