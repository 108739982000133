import { Box, Button } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  uploadPageIngredients,
  postPageIngredients,
  getPageIngredientsPresignedUrl,
  crawlPageIngredients,
} from 'apis/page_ingredient';
import { useSnackbar } from 'contexts/snackbar-context';
import { FC, useState } from 'react';
import { PageIngredientUrlType } from 'types/types';
import PICreateDialog from './PICreateDialog';
import PITable from './PITable';
import axios from 'axios';

type Props = {
  indexId: string;
};

const PIMain: FC<Props> = ({ indexId }) => {
  const queryClient = useQueryClient();
  const { mutate } = useMutation({
    mutationFn: (body: any) => postPageIngredients(indexId, body),
    onSuccess: () => {
      queryClient.invalidateQueries([indexId, 'UrlIngredient']);
      setSnackbar({ severity: 'success', message: 'Created!' });
    },
  });
  const { setSnackbar } = useSnackbar();

  const { mutate: m_upload } = useMutation({
    mutationFn: (body: { filename: string }) =>
      uploadPageIngredients(indexId, body),
    onSuccess: () => {
      queryClient.invalidateQueries([indexId, 'UrlIngredient']);
      setSnackbar({ severity: 'success', message: 'Uploaded!' });
    },
    onError: () => {
      setSnackbar({ severity: 'error', message: 'Upload failed' });
    },
  });

  const uploadHandler = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files && files.length > 0) {
      try {
        const file = files[0];
        setSnackbar({ message: 'Uploading...', severity: 'info' });
        const filename = file.name;
        const filesize = (file.size / 1024 / 1024).toFixed(2);
        const { presigned_url } = await getPageIngredientsPresignedUrl(
          indexId,
          {
            filename,
          }
        );
        const options = {
          headers: {
            'Content-Type': 'application/octet-stream',
          },
          onUploadProgress: (progressEvent: { loaded: number }) =>
            setSnackbar({
              message: `Uploading...${(
                progressEvent.loaded /
                1024 /
                1024
              ).toFixed(2)}MB/${filesize}MB`,
              severity: 'info',
            }),
        };
        await axios.put(presigned_url, file, options);
        m_upload({ filename });
        setSnackbar({ message: 'File Uploaded!', severity: 'info' });
      } catch (err) {
        e.target.value = '';
        setSnackbar({
          message: 'Upload failed, please try again later',
          severity: 'error',
        });
      }
    }
    e.target.value = '';
  };

  const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false);

  const handleCreateDialogClose = () => {
    setIsCreateDialogOpen(false);
  };

  const handleSave = (params: {
    urlType: PageIngredientUrlType;
    url: string;
    sitemap_urls: string[];
    disabled: boolean;
    item?: any;
    urlFilter: any;
  }) => {
    const {
      urlType: url_type,
      url,
      disabled,
      item,
      sitemap_urls,
      urlFilter: recipe,
    } = params;
    const body = { url_type, url, disabled, item, recipe, sitemap_urls };
    mutate(body);
    setIsCreateDialogOpen(false);
  };
  const handleClickCreateBtn = () => {
    setIsCreateDialogOpen(true);
  };

  const [selectedPageIngredientsIds, setSelectedPageIngredientsIds] = useState<
    any[]
  >([]);
  const handleClickCrawlSeleted = async () => {
    await crawlPageIngredients(indexId, {
      page_ingredient_ids: selectedPageIngredientsIds,
    });
    setSnackbar({ severity: 'success', message: 'Job started.' });
  };

  return (
    <Box>
      {isCreateDialogOpen && (
        <PICreateDialog
          open={isCreateDialogOpen}
          onClose={handleCreateDialogClose}
          onSave={handleSave}
        />
      )}
      <Box sx={{ display: 'flex', my: 2, justifyContent: 'flex-end', gap: 2 }}>
        <Button variant="outlined" onClick={handleClickCrawlSeleted}>
          Crawl
        </Button>
        <Button variant="outlined" component="label">
          Upload URL feed
          <input
            hidden
            onChange={uploadHandler}
            multiple
            accept=".csv"
            type="file"
          />
        </Button>
        <Button variant="outlined" onClick={handleClickCreateBtn}>
          Add New
        </Button>
      </Box>
      <PITable
        indexId={indexId}
        selectedPageIngredientIds={selectedPageIngredientsIds}
        setSelectedPageIngredientIds={setSelectedPageIngredientsIds}
      />
    </Box>
  );
};

export default PIMain;
