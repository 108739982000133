import { Typography } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import { FC } from 'react';
import ReactJson from 'react-json-view';

const JsonCell: FC<{ params: GridRenderCellParams<any> }> = ({ params }) => {
  const rawValue = params.value;
  if (!rawValue) return <></>;
  let jsonObj = rawValue;
  if (typeof jsonObj !== 'object') {
    try {
      jsonObj = JSON.parse(rawValue);
    } catch {}
  }
  if (typeof jsonObj !== 'object')
    return <Typography variant="caption">{rawValue}</Typography>;
  // @ts-ignore
  return <ReactJson name={null} src={jsonObj} />;
};

export const renderJson = (params: GridRenderCellParams<any>) => {
  return <JsonCell params={params} />;
};

export default JsonCell;
