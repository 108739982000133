import { Box, Tooltip, Typography } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import { FC, useState } from 'react';
import GridCellExpand from './GridCellExpand';

const getColor = (
  position: number
): { color: string; backgroundColor: string } => {
  const ret = {
    color: '#333',
    backgroundColor: '#ddd',
  };
  if (position > 0 && position <= 3) {
    ret.backgroundColor = '#80F550';
  }
  if (position > 3 && position <= 10) {
    ret.backgroundColor = '#F8D650';
  }
  if (position > 10) {
    ret.backgroundColor = '#7214f5';
    ret.color = '#fff';
  }
  return ret;
};

type KeywordType = {
  best_position: number;
  end_date: string;
  keyword: string;
  start_date: string;
  total_clicks: number;
  total_impressions: number;
};

type Props = {
  params: GridRenderCellParams<KeywordType[]>;
};

const generateContent = (items: KeywordType[]) => {
  items = items.sort((a, b) => {
    if (a.total_clicks === b.total_clicks) {
      return b.total_impressions - a.total_impressions;
    }
    return b.total_clicks - a.total_clicks;
  });
  const content = [];
  let length = items.length;
  for (let i = 0; i < length; i++) {
    const obj = items[i];
    const { color, backgroundColor } = getColor(obj.best_position || 0);
    const showPosition = Boolean(obj.best_position && obj.best_position !== 0);
    const tooltip = `total clicks: ${obj.total_clicks} | total impressions: ${obj.total_impressions}`;
    const item = (
      <Tooltip title={tooltip} key={i}>
        <Box
          sx={{
            color: color,
            backgroundColor: backgroundColor,
            padding: '4px 8px',
            borderRadius: '4px',
            display: 'flex',
            gap: 1,
            alignItems: 'center',
          }}
        >
          <Typography variant="body2" sx={{ whiteSpace: 'nowrap' }}>
            {obj.keyword}
          </Typography>
          {showPosition && (
            <Box
              sx={{
                padding: '1px 4px',
                backgroundColor: '#f8f9fa',
                color: '#212529',
                borderRadius: '4px',
              }}
            >
              <Typography variant="caption">
                {obj.best_position.toFixed(2)}
              </Typography>
            </Box>
          )}
        </Box>
      </Tooltip>
    );
    content.push(item);
  }
  return content;
};

// aka ranking keyword
const GSCKeywordCell: FC<Props> = ({ params }) => {
  let value = params.value;
  if (!value) return <></>;
  const content = generateContent(value);
  return <GridCellExpand value={content} width={params.colDef.computedWidth} />;
};

// render more 100 ranking keywords
const GSCKeywordCell100: FC<Props> = ({ params }) => {
  const allContent = generateContent(params.value!);
  const step = 100;
  const [curContent, setCurContent] = useState(allContent.slice(0, step));
  const handleClickLoadMore = () => {
    const curEnd = curContent.length;
    setCurContent(allContent.slice(0, curEnd + step));
  };
  return (
    <GridCellExpand
      value={curContent}
      width={params.colDef.computedWidth}
      onClickLoadMore={handleClickLoadMore}
    />
  );
};

export const renderGSCKeyword = (
  params: GridRenderCellParams<KeywordType[]>
) => {
  if (params.value && params.value.length > 100) {
    return <GSCKeywordCell100 params={params} />;
  }
  return <GSCKeywordCell params={params} />;
};

export default GSCKeywordCell;
