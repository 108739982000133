import { FC, forwardRef } from 'react';
import Stack from '@mui/material/Stack';
import MuiSnackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { useSnackbar } from 'contexts/snackbar-context';

const Alert = forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Snackbar: FC = () => {
  const { severity, message, duration, setSnackbar } = useSnackbar();
  const open = Boolean(message);

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar({ message: '', severity: 'info' });
  };

  return (
    <Stack spacing={2} sx={{ width: '100%' }}>
      <MuiSnackbar
        open={open}
        autoHideDuration={duration || 6000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
          {message}
        </Alert>
      </MuiSnackbar>
    </Stack>
  );
};

export default Snackbar;
