import { Box, IconButton, Menu, MenuItem } from '@mui/material';
import { FC, useState } from 'react';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

import SACreateEditDialog from './SACreateEditDialog';

type MoreMenuBtnProps = {
  saObj: any;
};

const MoreMenuBtn: FC<MoreMenuBtnProps> = ({ saObj }) => {
  const [editDlgOpen, setEditDlgOpen] = useState(false);

  const handleEditDlgClose = () => {
    setEditDlgOpen(false);
    setAnchor(null);
  };

  const [anchor, setAnchor] = useState<null | HTMLElement>(null);
  const menuOpen = Boolean(anchor);
  const handleOpenMenu = (e: React.MouseEvent<HTMLButtonElement>) => {
    setAnchor(e.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchor(null);
  };

  return (
    <Box>
      {editDlgOpen && (
        <SACreateEditDialog
          saObj={saObj}
          open={editDlgOpen}
          onClose={handleEditDlgClose}
        />
      )}
      <IconButton onClick={handleOpenMenu}>
        <MoreHorizIcon />
      </IconButton>
      <Menu
        anchorEl={anchor}
        open={menuOpen}
        onClose={handleCloseMenu}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem onClick={() => setEditDlgOpen(true)}>Edit</MenuItem>
      </Menu>
    </Box>
  );
};

export default MoreMenuBtn;
