import Layout from 'components/layout/Layout';
import OptimizationMain from 'components/optimization/OptimizationMain';
import { FC } from 'react';

const OptimizationPage: FC = () => {
  return (
    <Layout>
      <OptimizationMain />
    </Layout>
  );
};

export default OptimizationPage;
