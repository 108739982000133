import { useQuery } from '@tanstack/react-query';
import { getActionFields } from 'apis/workbench';
import { useUser } from 'contexts/user-context';
import { useAtom } from 'jotai';
import { useEffect, useState } from 'react';
import { WBColPreviewAtom } from 'store/jotai';
import { OptionType } from 'types/types';
import type { WBSourceType } from 'types/types';

type ParamsType = { source: WBSourceType };

export type GroupOptionsType = {
  step: OptionType[];
  action: OptionType[];
  create_opportunity: OptionType[];
};

const useWBActions = ({ source }: ParamsType) => {
  const { curIndex } = useUser();
  const { data } = useQuery(
    [curIndex, 'ActionFields', source],
    () => getActionFields(curIndex!.value, source),
    { enabled: Boolean(curIndex), staleTime: 1000 * 60 }
  );
  let groupOptions: GroupOptionsType = {
    step: [],
    action: [],
    create_opportunity: [],
  };
  if (data?.groups) {
    const groups = data.groups;
    const actionKeys = Object.keys(groups.action);
    const actionOptions = actionKeys.map((item) => ({
      label: groups.action[item].label,
      value: item,
    }));
    groupOptions.action = actionOptions;
    const stepKeys = Object.keys(groups.step);
    const stepOptions = stepKeys.map((item) => ({
      label: groups.step[item].label,
      value: item,
    }));
    const opporKeys = Object.keys(groups.create_opportunity);
    const opporOptions = opporKeys.map((item) => ({
      label: groups.create_opportunity[item].label,
      value: item,
    }));
    groupOptions.action = actionOptions;
    groupOptions.step = stepOptions;
    groupOptions.create_opportunity = opporOptions;
  }
  const [curAction, setCurAction] = useState<string>();
  const [fieldOptions, setFieldOptions] = useState<OptionType[]>([]);
  const handleSelectAction = (value: string) => {
    setCurAction(value);
  };
  const [options2, setOptions2] = useState<OptionType[]>([]);
  const [isSupportRowSelector, setIsSupportRowSelector] = useState(false);

  const [, setPreviewCol] = useAtom(WBColPreviewAtom);
  useEffect(() => {
    if (curAction && data?.groups) {
      const groupsCombine = {
        ...data.groups.step,
        ...data.groups.action,
        ...data.groups.create_opportunity,
      };
      const dataAction = groupsCombine[curAction];
      const fields = dataAction?.value?.map((item: string) => ({
        value: item,
        label: item,
      }));
      const valLabels = dataAction?.valueLabel?.map((item: string) => item);
      setIsSupportRowSelector(dataAction?.isSupportRowSelector);
      if (dataAction.previewChatGpt) {
        setPreviewCol('GPT');
      } else {
        setPreviewCol('None');
      }
      if (fields) {
        if (valLabels && valLabels.length === fields.length) {
          for (let i = 0; i < valLabels.length; i++) {
            fields[i].label = valLabels[i];
          }
        }
        setFieldOptions(fields);
      }
      if (dataAction?.options) {
        setOptions2(dataAction.options);
      } else {
        setOptions2([]);
      }
    }
  }, [curAction, data, setPreviewCol]);

  return {
    isSupportRowSelector,
    fieldOptions,
    handleSelectAction,
    options2,
    groupOptions,
  };
};

export default useWBActions;
