import {
  Dialog,
  DialogContent,
  DialogTitle,
  Box,
  Typography,
  IconButton,
  List,
  ListItemButton,
  Input,
  InputAdornment,
  ListItem,
} from '@mui/material';
import { FunctionComponent, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import DeleteIcon from '@mui/icons-material/Delete';
import { addRecipeStrAndOpField2Recipes } from 'utils/utils-query-builder';
import { updateWorkbenchPageVariable } from 'apis/workbench';
import { useUser } from 'contexts/user-context';
import { useMutation, useQueryClient } from '@tanstack/react-query';

type Props = {
  open: boolean;
  onClose: () => void;
  onSelect: (recipe: any) => void;
  variables: any;
  config: any;
};
const VariableSelectDialog: FunctionComponent<Props> = ({
  open,
  onClose,
  onSelect,
  variables,
  config,
}) => {
  const [filterStr, setFilterStr] = useState('');
  variables = addRecipeStrAndOpField2Recipes(variables, config);
  variables = variables.filter((item: any) => item.enabled);
  variables = variables.filter((item: any) => Object.keys(item.operation).length);
  let filteredVariables = variables;
  if (filterStr) {
    filteredVariables = variables?.filter((item: any) => {
      if (item.name.toLowerCase().indexOf(filterStr.toLowerCase()) !== -1)
        return true;
      return false;
    });
  }

  const queryClient = useQueryClient();
  const { curIndex } = useUser();

  const { mutate } = useMutation({
    mutationFn: (body: any) =>
      updateWorkbenchPageVariable(curIndex!.value, body.id, body),
    onSuccess: () => {
      queryClient.invalidateQueries([curIndex, 'WBPagesVariables']);
    },
  });

  const handleDelete = (item: any) => {
    mutate({ enabled: false, id: item.id });
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Typography sx={{ fontWeight: 700, fontSize: 22 }}>
          Select Variable
        </Typography>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Box>
          <Input
            value={filterStr}
            onChange={(e) => setFilterStr(e.target.value)}
            fullWidth
            startAdornment={
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            }
          />
        </Box>
        <List sx={{ mt: 2 }}>
          {filteredVariables?.map((item: any) => (
            <ListItem
              key={item.id}
              secondaryAction={
                <IconButton edge="end" onClick={handleDelete.bind(null, item)}>
                  <DeleteIcon />
                </IconButton>
              }
            >
              <Typography variant="caption" color="text.secondary">
                {item.id}
              </Typography>
              <ListItemButton
                onClick={onSelect.bind(null, item)}
                sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}
              >
                <Typography sx={{ fontWeight: 500 }}>
                  {item.name}
                  {item.operationField ? `: (${item.operationField})` : ''}
                </Typography>
                {item.recipeStr && (
                  <Typography variant="caption" color="text.secondary">
                    {item.recipeStr}
                  </Typography>
                )}
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </DialogContent>
    </Dialog>
  );
};

export default VariableSelectDialog;
