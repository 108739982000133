import { Paper, Box, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { getAccountUsageV2, getUsageV2 } from 'apis/usage';
import { useUser } from 'contexts/user-context';
import { FC } from 'react';

const SettingsUsage: FC = () => {
  const { curIndex } = useUser();
  const { data: usageData } = useQuery(
    [curIndex, 'usage'],
    () => getUsageV2(curIndex!.value),
    { enabled: Boolean(curIndex) }
  );
  const usageCurrentMonth = usageData?.current_billing_month_data;
  const usageLastMonth = usageData?.last_billing_month_data;
  const usageTotal = usageData?.total;

  const { data: accountUsageData } = useQuery(
    [curIndex, 'accountUsage'],
    () => getAccountUsageV2(curIndex!.value),
    { enabled: Boolean(curIndex) }
  );
  const accountUsageCurrentMonth = accountUsageData?.current_billing_month_data;
  const accountUsageLastMonth = accountUsageData?.last_billing_month_data;
  const accountUsageTotal = accountUsageData?.total;
  return (
    <Box>
      <Paper
        sx={{
          p: 2,
          display: 'grid',
          gridTemplateColumns: '1fr 1fr 1fr ',
          rowGap: 2,
        }}
      >
        <Typography sx={{ gridColumn: '1 / span 3' }} variant="h6">
          Usage in current month
        </Typography>
        {usageCurrentMonth &&
          Object.entries(usageCurrentMonth).map(
            ([key, value]: [key: any, value: any]) => (
              <Box
                key={key}
                sx={{ display: 'flex', gap: 1, alignItems: 'center' }}
              >
                <Typography variant="body2" sx={{ fontWeight: 700 }}>
                  {key}:
                </Typography>
                <Typography>
                  {' '}
                  {Number.isInteger(value)
                    ? value.toLocaleString('en-US')
                    : value}
                </Typography>
              </Box>
            )
          )}
      </Paper>
      <Paper
        sx={{
          p: 2,
          display: 'grid',
          gridTemplateColumns: '1fr 1fr 1fr ',
          rowGap: 2,
          mt: 4,
        }}
      >
        <Typography sx={{ gridColumn: '1 / span 3' }} variant="h6">
          Usage in last month
        </Typography>
        {usageLastMonth &&
          Object.entries(usageLastMonth).map(
            ([key, value]: [key: any, value: any]) => (
              <Box
                key={key}
                sx={{ display: 'flex', gap: 1, alignItems: 'center' }}
              >
                <Typography variant="body2" sx={{ fontWeight: 700 }}>
                  {key}:
                </Typography>
                <Typography>
                  {' '}
                  {Number.isInteger(value)
                    ? value.toLocaleString('en-US')
                    : value}
                </Typography>
              </Box>
            )
          )}
      </Paper>
      <Paper
        sx={{
          p: 2,
          display: 'grid',
          gridTemplateColumns: '1fr 1fr 1fr ',
          rowGap: 2,
          mt: 4,
        }}
      >
        <Typography sx={{ gridColumn: '1 / span 3' }} variant="h6">
          Total usage
        </Typography>
        {usageTotal &&
          Object.entries(usageTotal).map(
            ([key, value]: [key: any, value: any]) => (
              <Box
                key={key}
                sx={{ display: 'flex', gap: 1, alignItems: 'center' }}
              >
                <Typography variant="body2" sx={{ fontWeight: 700 }}>
                  {key}:
                </Typography>
                <Typography>
                  {' '}
                  {Number.isInteger(value)
                    ? value.toLocaleString('en-US')
                    : value}
                </Typography>
              </Box>
            )
          )}
      </Paper>
      <Paper
        sx={{
          mt: 4,
          p: 2,
          display: 'grid',
          gridTemplateColumns: '1fr 1fr 1fr ',
          rowGap: 2,
        }}
      >
        <Typography sx={{ gridColumn: '1 / span 3' }} variant="h6">
          Account usage in current month
        </Typography>
        {accountUsageCurrentMonth &&
          Object.entries(accountUsageCurrentMonth).map(
            ([key, value]: [key: any, value: any]) => (
              <Box
                key={key}
                sx={{ display: 'flex', gap: 1, alignItems: 'center' }}
              >
                <Typography variant="body2" sx={{ fontWeight: 700 }}>
                  {key}:
                </Typography>
                <Typography>
                  {' '}
                  {Number.isInteger(value)
                    ? value.toLocaleString('en-US')
                    : value}
                </Typography>
              </Box>
            )
          )}
      </Paper>
      <Paper
        sx={{
          mt: 4,
          p: 2,
          display: 'grid',
          gridTemplateColumns: '1fr 1fr 1fr ',
          rowGap: 2,
        }}
      >
        <Typography sx={{ gridColumn: '1 / span 3' }} variant="h6">
          Account usage in last month
        </Typography>
        {accountUsageLastMonth &&
          Object.entries(accountUsageLastMonth).map(
            ([key, value]: [key: any, value: any]) => (
              <Box
                key={key}
                sx={{ display: 'flex', gap: 1, alignItems: 'center' }}
              >
                <Typography variant="body2" sx={{ fontWeight: 700 }}>
                  {key}:
                </Typography>
                <Typography>
                  {' '}
                  {Number.isInteger(value)
                    ? value.toLocaleString('en-US')
                    : value}
                </Typography>
              </Box>
            )
          )}
      </Paper>
      <Paper
        sx={{
          mt: 4,
          p: 2,
          display: 'grid',
          gridTemplateColumns: '1fr 1fr 1fr ',
          rowGap: 2,
        }}
      >
        <Typography sx={{ gridColumn: '1 / span 3' }} variant="h6">
          Total Account usage
        </Typography>
        {accountUsageTotal &&
          Object.entries(accountUsageTotal).map(
            ([key, value]: [key: any, value: any]) => (
              <Box
                key={key}
                sx={{ display: 'flex', gap: 1, alignItems: 'center' }}
              >
                <Typography variant="body2" sx={{ fontWeight: 700 }}>
                  {key}:
                </Typography>
                <Typography>
                  {Number.isInteger(value)
                    ? value.toLocaleString('en-US')
                    : value}
                </Typography>
              </Box>
            )
          )}
      </Paper>
    </Box>
  );
};

export default SettingsUsage;
