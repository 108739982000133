import { Box } from '@mui/material';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import {
  FunctionComponent,
  forwardRef,
  useImperativeHandle,
  useRef,
} from 'react';

export type EditorStateType = { text: string };

const config = {
  theme: 'snow',
  modules: {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ['bold', 'italic', 'underline', 'strike'],
      [{ list: 'ordered' }, { list: 'bullet' }],
      ['clean'],
    ],
    clipboard: {
      matchVisual: false,
    },
  },
};

type Props = {
  editorState: EditorStateType;
  onChange: (content: string) => void;
  ref?: any;
  placeholder?: string;
};

const TextEditor: FunctionComponent<Props> = forwardRef(
  ({ editorState, onChange, placeholder }, ref) => {
    const inputRef = useRef<any>();
    const focus = () => inputRef.current.focus();
    useImperativeHandle(ref, () => {
      return { focus };
    });
    return (
      <Box
        sx={{
          '& .ql-editor': {
            minHeight: 120,
            whiteSpace: 'normal',
          },
        }}
      >
        <ReactQuill
          placeholder={placeholder}
          ref={inputRef}
          value={editorState.text}
          onChange={onChange}
          {...config}
        />
      </Box>
    );
  }
);

type TextEditorViewProps = {
  description: string;
};
export const TextEditorView: FunctionComponent<TextEditorViewProps> = ({
  description,
}) => {
  return (
    <Box
      sx={{
        width: '100%',
        borderRadius: '4px',
      }}
    >
      <div
        className="ql-editor"
        data-gramm="false"
        contentEditable={false}
        dangerouslySetInnerHTML={{ __html: description }}
      ></div>
    </Box>
  );
};

export default TextEditor;
