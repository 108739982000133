import { DataGridPro, GridColDef, GridToolbar } from '@mui/x-data-grid-pro';
import { getNumber } from 'components/shared/table-cell';
import { FC } from 'react';

export const columns: GridColDef[] = [
  {
    field: 'status',
    headerName: 'status',
    flex: 1,
    width: 120,
    valueGetter: (params) => params.row.status,
  },
  {
    field: 'number_of_pages_crawled',
    headerName: '# pages crawled',
    flex: 1,
    width: 140,
    valueGetter: getNumber,
  },
  {
    field: 'latest_crawl',
    headerName: 'crawled at',
    flex: 1,
    width: 200,
    valueGetter: (params) => params.row.latest_crawl,
  },
];

type Props = {
  row: any;
};
const NestedPITable: FC<Props> = ({ row }) => {
  let items = row.crawl_history || [];
  items = items.map((item: any, id: number) => ({ ...item, id }));
  return (
    <DataGridPro
      components={{ Toolbar: GridToolbar }}
      disableDensitySelector
      disableColumnFilter
      density="compact"
      columns={columns}
      rows={items}
      sx={{ width: 1100 }}
      hideFooter
    />
  );
};

export default NestedPITable;
