import Layout from 'components/layout/Layout';
import Settings2Main from 'components/settings2/Settings2Main';
import { FC } from 'react';

const Settings2Page: FC = () => {
  return (
    <Layout>
      <Settings2Main />
    </Layout>
  );
};

export default Settings2Page;
