import {
  Button,
  List,
  Box,
  Typography,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Card,
  IconButton,
  ListItemButton,
} from '@mui/material';
import { useUser } from 'contexts/user-context';
import { FC, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import { deepPurple } from '@mui/material/colors';

type IndexItemProps = {
  label: string;
  id: number;
  onEdit: () => void;
};

const IndexItem: FC<IndexItemProps> = ({ id, label, onEdit }) => {
  const navigate = useNavigate();
  const handleClickItem = () => {
    navigate(`/workbench/${id}`);
    window.location.reload();
  };
  return (
    <Card sx={{ mb: 2 }}>
      <ListItem
        secondaryAction={
          <IconButton edge="end" onClick={onEdit}>
            <EditIcon />
          </IconButton>
        }
      >
        <ListItemButton onClick={handleClickItem}>
          <ListItemAvatar>
            <Box
              sx={{
                bgcolor: deepPurple[500],
                width: 40,
                height: 40,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                color: '#fff',
                borderRadius: '50%',
              }}
            >
              {id}
            </Box>
          </ListItemAvatar>
          <ListItemText>{label}</ListItemText>
        </ListItemButton>
      </ListItem>
    </Card>
  );
};

const IndexList: FC = () => {
  const { allIndexes } = useUser();
  const navigate = useNavigate();
  const handleClickNew = () => {
    navigate(`/settings/New-Index`);
  };

  const handleEdit = (id: number) => {
    navigate(`/settings/${id}/edit`);
  };

  const sortedIndexes = useMemo(
    () => allIndexes.slice().sort((a, b) => b.id - a.id),
    [allIndexes]
  );
  if (allIndexes.length === 0)
    return (
      <Box textAlign={'center'} mt={12}>
        <Typography variant="h4">No Index found.</Typography>
        <Button
          sx={{ textAlign: 'center', mt: 2 }}
          variant="contained"
          size="large"
          onClick={handleClickNew}
        >
          Create New Index
        </Button>
      </Box>
    );
  return (
    <List>
      {sortedIndexes.map((item) => (
        <IndexItem
          key={item.id}
          id={item.id}
          label={item.display_name}
          onEdit={handleEdit.bind(null, item.id)}
        />
      ))}
    </List>
  );
};

export default IndexList;
