import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Box,
} from '@mui/material';
import React, { FunctionComponent } from 'react';
import InfoIcon from '@mui/icons-material/Info';

type Props = {
  open: boolean;
  onClose: () => void;
  onSave: () => void;
};
const RecipeSaveDialog: FunctionComponent<Props> = ({
  open,
  onClose,
  onSave,
}) => {
  const submitHandler = (e: React.FormEvent) => {
    e.preventDefault();
    onSave();
  };
  return (
    <Dialog open={open} onClose={onClose}>
      <Box component="form" onSubmit={submitHandler}>
        <DialogTitle sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <InfoIcon color="info" /> Save the recipe?
        </DialogTitle>
        <DialogContent>
          The recipe will be saved and overwrite the original one. Are you sure
          you want to continue?
        </DialogContent>
        <DialogActions>
          <Button color="inherit" onClick={onClose}>
            Cancel
          </Button>
          <Button color="inherit" type="submit">
            Save
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default RecipeSaveDialog;
