import { Box } from '@mui/material';
import { FunctionComponent } from 'react';
import styled from 'styled-components';

const Styles = styled(Box)<any>`
  margin-top: 10px;
  .logo {
    width: ${(props) => props._width};
    img {
      width: 100%;
      object-fit: contain;
    }
  }
`;

type Props = {
  width?: string;
  color?: 'white' | 'black';
};

const Logo: FunctionComponent<Props> = ({ width, color }) => {
  const _width = width || '120px';
  return (
    <Styles _width={_width}>
      <Box className="logo">
        {color !== 'white' && <img src="/logo.svg" alt="Similar.ai"></img>}
        {color === 'white' && (
          <img src="/logo-white.svg" alt="Similar.ai"></img>
        )}
      </Box>
    </Styles>
  );
};

export default Logo;
