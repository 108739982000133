import { AppBar, Box, Toolbar } from '@mui/material';
import { FC } from 'react';
import Logo from './Logo';
import HeaderNav from './HeaderNav';
import IdxSelector from './IdxSelector';
import UserAvatar from './UserAvatar';

const Header: FC = () => {
  return (
    <AppBar
      sx={{ backgroundColor: '#fff', color: 'text.primary' }}
      elevation={2}
    >
      <Toolbar
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Logo />
        <Box sx={{ display: 'flex', gap: '1rem', minHeight: 64 }}>
          <HeaderNav />
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              p: '0 24px',
            }}
          >
            <Box
              sx={{ height: 16, width: 2, backgroundColor: 'grey.A400', mr: 3 }}
            />
            <IdxSelector />
            <Box
              sx={{ height: 16, width: 2, backgroundColor: 'grey.A400', ml: 3 }}
            />
          </Box>
          <UserAvatar />
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
