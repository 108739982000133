import type { WBSourceType } from 'types/types';

export const postWorkbenchKeyword = async (index: string, body: any) => {
  const axiosInstance = require('utils/utils-axios-instance').default;
  const data = await axiosInstance.post(
    `index/${index}/api/all-keywords-index`,
    body
  );
  return data.data;
};

export const postWorkbenchPages = async (index: string, body: any) => {
  const axiosInstance = require('utils/utils-axios-instance').default;
  const data = await axiosInstance.post(
    `index/${index}/api/all-pages-index`,
    body
  );
  return data.data;
};

export const postWorkbenchTopic = async (index: string, body: any) => {
  const axiosInstance = require('utils/utils-axios-instance').default;
  const data = await axiosInstance.post(
    `index/${index}/api/all-topics-index`,
    body
  );
  return data.data;
};

export const postWorkbenchKeywordAction = async (index: string, body: any) => {
  const axiosInstance = require('utils/utils-axios-instance').default;
  const data = await axiosInstance.post(
    `index/${index}/api/all-keywords-index/action`,
    body
  );
  return data.data;
};

export const postWorkbenchPagesAction = async (index: string, body: any) => {
  const axiosInstance = require('utils/utils-axios-instance').default;
  const data = await axiosInstance.post(
    `index/${index}/api/all-pages-index/action`,
    body
  );
  return data.data;
};

export const postWorkbenchTopicsAction = async (index: string, body: any) => {
  const axiosInstance = require('utils/utils-axios-instance').default;
  const data = await axiosInstance.post(
    `index/${index}/api/all-topics-index/action`,
    body
  );
  return data.data;
};

export const postWorkbenchActionConfirm = async (
  index: string,
  body: any,
  source: WBSourceType
) => {
  const axiosInstance = require('utils/utils-axios-instance').default;
  let data: any = { data: { warning_message: '' } };
  if (source === 'keyword') {
    data = await axiosInstance.post(
      `index/${index}/api/all-keywords-index/action/confirm`,
      body
    );
  } else if (source === 'page') {
    data = await axiosInstance.post(
      `index/${index}/api/all-pages-index/action/confirm`,
      body
    );
  } else if (source === 'topic') {
    data = await axiosInstance.post(
      `index/${index}/api/all-topics-index/action/confirm`,
      body
    );
  }
  return data.data;
};

export const getWorkbenchPageVariable = async (
  index: string,
  source: string
) => {
  const axiosInstance = require('utils/utils-axios-instance').default;
  let data;
  if (source !== 'all') {
    data = await axiosInstance.get(
      `index/${index}/api/page-variable?source=${source}&needRecipeGroup=${true}`
    );
  } else {
    data = await axiosInstance.get(
      `index/${index}/api/page-variable?source=all`
    );
  }
  return data.data;
};

export const updateWorkbenchPageVariable = async (
  index: string,
  id: number,
  body: any
) => {
  const axiosInstance = require('utils/utils-axios-instance').default;
  const data = await axiosInstance.patch(
    `index/${index}/api/page-variable/${id}/`,
    body
  );
  return data.data;
};

export const deleteWorkbenchPageVariable = async (
  index: string,
  id: number | string
) => {
  const axiosInstance = require('utils/utils-axios-instance').default;
  const data = await axiosInstance.delete(
    `index/${index}/api/page-variable/${id}/`
  );
  return data.data;
};

export const postWorkbenchSite = async (index: string, body: any) => {
  const axiosInstance = require('utils/utils-axios-instance').default;
  const data = await axiosInstance.post(
    `index/${index}/api/all-site-index`,
    body
  );
  return data.data;
};

export const getActionFields = async (index: string, source: WBSourceType) => {
  const axiosInstance = require('utils/utils-axios-instance').default;
  const data = await axiosInstance.get(
    `index/${index}/api/action-fields?source=${source}`
  );
  return data.data;
};

export const postPagesDuplicateAction = async (index: string, body: any) => {
  const axiosInstance = require('utils/utils-axios-instance').default;
  const data = await axiosInstance.post(
    `index/${index}/api/all-pages-index/duplicate-action`,
    body
  );
  return data.data;
};

export const postTopFolders = async (index: string, body: any) => {
  const axiosInstance = require('utils/utils-axios-instance').default;
  const data = await axiosInstance.post(
    `index/${index}/api/all-pages-index/top-folders`,
    body
  );
  return data.data;
};

export const getWordpressCategories = async (index: string) => {
  const axiosInstance = require('utils/utils-axios-instance').default;
  const data = await axiosInstance.get(
    `index/${index}/api/wordpress/terms/category/`
  );
  return data.data;
};

export const publishToWordpress = async (index: string, body: any) => {
  const axiosInstance = require('utils/utils-axios-instance').default;
  const data = await axiosInstance.post(
    `index/${index}/api/wordpress/publish/`,
    body
  );
  return data.data;
};
