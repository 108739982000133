import { Box, Button, Typography } from '@mui/material';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { getXmlrpcAccount, postXmlrpcAccount } from 'apis/settings';
import { useSnackbar } from 'contexts/snackbar-context';
import { useUser } from 'contexts/user-context';
import { FC, useState } from 'react';
import AddXmlrpcDialog, { FormData } from '../dialog/AddXmlrpcDialog';

export type XmlrpcItemProps = {
  id: number;
  index_id: number;
  endpoint: string;
  username: string;
  is_active: boolean;
};

const Item: FC<XmlrpcItemProps> = ({
  id,
  index_id,
  endpoint,
  username,
  is_active,
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        gap: 2,
        alignItems: 'center',
        mt: 1,
      }}
    >
      <Typography sx={{ fontWeight: 700, textDecoration: 'none' }}>
        {endpoint}
      </Typography>
      <Typography sx={{ fontWeight: 700, textDecoration: 'none' }}>
        {username}
      </Typography>
    </Box>
  );
};

const XmlRpcConnection: FC = () => {
  const { curIndex } = useUser();
  const { setSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const { mutate } = useMutation({
    mutationFn: (body: any) => postXmlrpcAccount(curIndex!.value, body),
    onSuccess: () => {
      queryClient.invalidateQueries([curIndex, 'SettingsXmlrpc']);
      setSnackbar({ severity: 'success', message: 'Saved' });
    },
  });

  let { data } = useQuery(
    [curIndex, 'SettingsXmlrpc'],
    () => getXmlrpcAccount(curIndex!.value),
    { enabled: Boolean(curIndex) }
  );

  data = data || {};

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleSave = async ({ endpoint, username, password }: FormData) => {
    mutate({ endpoint, username, password });
  };

  return (
    <Box>
      <AddXmlrpcDialog open={open} onClose={handleClose} onSave={handleSave} />
      <Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Typography sx={{ fontSize: 18, fontWeight: 700 }}>
            XmlRPC Connections:
          </Typography>
          <Button onClick={handleOpen}>Connect</Button>
        </Box>
        <Box sx={{ mt: 2 }}>
          <Item
            key={data.id}
            id={data.id}
            index_id={data.index_id}
            endpoint={data.endpoint}
            username={data.username}
            is_active={data.is_active}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default XmlRpcConnection;
