import { Box, Button } from '@mui/material';
import { useUser } from 'contexts/user-context';
import { FC } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

type PathType =
  | '/workbench'
  | '/opportunities'
  | '/optimization'
  | '/index'
  | '/settings'
  | '/settings2'
  | '/dashboard';

type NavType = {
  label: string;
  path: PathType;
};

const NAV_ITEMS: NavType[] = [
  { label: 'Setup', path: '/settings' },
  { label: 'Workbench', path: '/workbench' },
  { label: 'Opportunities', path: '/opportunities' },
  { label: 'Optimization', path: '/optimization' },
  { label: 'Dashboard', path: '/dashboard' },
  { label: 'Settings', path: '/settings2' },
  { label: 'Index', path: '/index' },
];

type NavItemProps = {
  active: boolean;
  label: string;
  disabled: boolean;
  onClick: () => void;
};

const NavItem: FC<NavItemProps> = ({ active, label, disabled, onClick }) => {
  if (active) {
    return (
      <Button
        disabled={disabled}
        onClick={onClick}
        sx={{
          height: '100%',
          borderBottom: '4px solid',
          borderColor: 'primary.main',
          borderRadius: 0,
        }}
      >
        {label}
      </Button>
    );
  }
  return (
    <Button
      disabled={disabled}
      onClick={onClick}
      color="inherit"
      sx={{
        height: '100%',
        borderBottom: '4px solid',
        borderBottomColor: 'transparent',
      }}
    >
      {label}
    </Button>
  );
};

const HeaderNav: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { curIndex } = useUser();
  const pathName = location.pathname.split('/')[1];
  const pathIdx = curIndex?.value;
  const disabled = !curIndex;

  const handleClickNav = (path: PathType) => {
    if (path === '/settings') {
      navigate(`/settings/${pathIdx}`);
    } else if (path === '/index') {
      navigate('/index');
    } else {
      navigate(path + '/' + pathIdx);
    }
  };

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1.5 }}>
      {NAV_ITEMS.map((item) => (
        <NavItem
          disabled={disabled}
          key={item.path}
          label={item.label}
          active={'/' + pathName === item.path}
          onClick={handleClickNav.bind(null, item.path)}
        />
      ))}
    </Box>
  );
};

export default HeaderNav;
