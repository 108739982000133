import { FC, useState } from 'react';
import {
  IconButton,
  Box,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';
import { useAuth } from 'contexts/auth-context';
import StringAvatar from './StringAvatar';

const UserAvatar: FC = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleOpenMenu = (e: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(e.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  const { logout, user } = useAuth();
  const handleLogout = () => {
    logout();
    setAnchorEl(null);
  };

  return (
    <Box>
      <IconButton sx={{ borderRadius: 0 }} onClick={handleOpenMenu}>
        <Box sx={{ display: 'flex', gap: '0.8rem', alignItems: 'center' }}>
          <StringAvatar name={user!.username} />
        </Box>
      </IconButton>
      <Menu
        open={open}
        anchorEl={anchorEl}
        onClose={handleCloseMenu}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
      >
        <MenuItem onClick={handleLogout}>
          <ListItemIcon>
            <LogoutIcon />
          </ListItemIcon>
          <ListItemText>Log out</ListItemText>
        </MenuItem>
      </Menu>
    </Box>
  );
};

export default UserAvatar;
