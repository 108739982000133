import { Box, Paper } from '@mui/material';
import {
  DataGridPro,
  GridColDef,
  GridFilterModel,
  GridSortModel,
  GridToolbar,
} from '@mui/x-data-grid-pro';
import { FC, useCallback, useState } from 'react';
import { renderArray } from 'components/shared/table-cell/ArrayCell';
import { useQuery } from '@tanstack/react-query';
import { useUser } from 'contexts/user-context';
import { getSiteTopicTopGrammar } from 'apis/site_topic';
import { renderTopPages } from '../table-cell/TopPagesCell';
import { stringOperators } from '../top-topics/SiteTopicTopTopicsTable';
import LinearProgressWithLabel from 'components/shared/ui/LinearProgressWithLabel';

const COLUMNS: GridColDef[] = [
  {
    field: 'id',
    headerName: 'ID',
    type: 'number',
    filterable: false,
    headerAlign: 'center',
    align: 'center',
    headerClassName: 'App-Mui-DataGrid-Header',
    width: 240,
  },
  {
    field: 'grammar',
    headerName: 'Grammar',
    headerAlign: 'center',
    sortable: false,
    align: 'center',
    headerClassName: 'App-Mui-DataGrid-Header',
    filterOperators: stringOperators,
    width: 240,
  },
  {
    field: 'keyword_count',
    headerName: 'Keyword count',
    filterable: false,
    headerAlign: 'center',
    align: 'center',
    headerClassName: 'App-Mui-DataGrid-Header',
    width: 160,
  },
  {
    field: 'total_impression',
    headerName: 'Impression',
    filterable: false,
    type: 'number',
    headerAlign: 'center',
    align: 'center',
    headerClassName: 'App-Mui-DataGrid-Header',
    width: 160,
  },
  {
    field: 'total_impression_per',
    headerName: '% Impression',
    filterable: false,
    headerAlign: 'center',
    align: 'center',
    headerClassName: 'App-Mui-DataGrid-Header',
    renderCell: (params) => (
      <Box sx={{ width: '80%' }}>
        <LinearProgressWithLabel
          value={
            (params.row.total_impression / params.row.agg_total_impression) *
            100
          }
        />
      </Box>
    ),
    width: 200,
  },
  {
    field: 'total_volume',
    headerName: 'Volume',
    filterable: false,
    type: 'number',
    headerAlign: 'center',
    align: 'center',
    headerClassName: 'App-Mui-DataGrid-Header',
    width: 160,
  },
  {
    field: 'total_volume_per',
    headerName: '% Volume',
    filterable: false,
    headerAlign: 'center',
    align: 'center',
    headerClassName: 'App-Mui-DataGrid-Header',
    renderCell: (params) => (
      <Box sx={{ width: '80%' }}>
        <LinearProgressWithLabel
          value={(params.row.total_volume / params.row.agg_total_volume) * 100}
        />
      </Box>
    ),
    width: 200,
  },
  {
    field: 'total_gsc_traffic',
    headerName: 'GSC Traffic',
    filterable: false,
    type: 'number',
    headerAlign: 'center',
    align: 'center',
    headerClassName: 'App-Mui-DataGrid-Header',
    width: 160,
  },
  {
    field: 'total_gsc_traffic_per',
    headerName: '% GSC Traffic',
    filterable: false,
    headerAlign: 'center',
    align: 'center',
    headerClassName: 'App-Mui-DataGrid-Header',
    renderCell: (params) => (
      <Box sx={{ width: '80%' }}>
        <LinearProgressWithLabel
          value={
            (params.row.total_gsc_traffic / params.row.agg_total_gsc_traffic) *
            100
          }
        />
      </Box>
    ),
    width: 200,
  },
  {
    field: 'competitive_traffic',
    headerName: 'Competitive Traffic',
    filterable: false,
    type: 'number',
    headerAlign: 'center',
    align: 'center',
    headerClassName: 'App-Mui-DataGrid-Header',
    width: 160,
  },
  {
    field: 'competitive_traffic_per',
    headerName: '% Competitive Traffic',
    filterable: false,
    headerAlign: 'center',
    align: 'center',
    headerClassName: 'App-Mui-DataGrid-Header',
    renderCell: (params) => (
      <Box sx={{ width: '80%' }}>
        <LinearProgressWithLabel
          value={
            (params.row.competitive_traffic_per /
              params.row.agg_competitive_traffic_per) *
            100
          }
        />
      </Box>
    ),
    width: 200,
  },
  {
    field: 'keywords',
    headerName: 'Keywords',
    align: 'center',
    headerAlign: 'center',
    headerClassName: 'App-Mui-DataGrid-Header',
    sortable: false,
    renderCell: renderArray,
    minWidth: 300,
    flex: 1,
    filterable: false,
  },
  {
    field: 'pages',
    headerName: 'Pages',
    sortable: false,
    align: 'center',
    headerAlign: 'center',
    headerClassName: 'App-Mui-DataGrid-Header',
    renderCell: renderTopPages,
    minWidth: 300,
    flex: 1,
    filterable: false,
  },
];

type Props = {
  report_id: string | number;
};

const SiteTopicTopGrammarTable: FC<Props> = ({ report_id }) => {
  const { curIndex } = useUser();
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [orderBy, setOrderBy] = useState('-keyword_count');
  const [filterGrammar, setFilterGrammar] = useState('');
  const handleSortModelChange = useCallback(
    (sortModel: GridSortModel) => {
      const _sort = sortModel[0];
      if (!_sort) return;
      setOrderBy(`${_sort.sort === 'desc' ? '-' : ''}${_sort.field}`);
    },
    [setOrderBy]
  );
  const handleFilterModelChange = useCallback(
    (filterModel: GridFilterModel) => {
      if (!filterModel?.items) return;
      const items = filterModel.items;
      const grammar = items.find((item) => item.columnField === 'grammar');
      if (grammar) {
        setFilterGrammar(grammar.operatorValue + grammar.value);
      } else {
        setFilterGrammar('');
      }
    },
    []
  );
  const { data, isLoading } = useQuery(
    [
      curIndex,
      'site-topic',
      report_id,
      'top-grammar',
      page,
      pageSize,
      orderBy,
      filterGrammar,
    ],
    () =>
      getSiteTopicTopGrammar(
        curIndex!.value,
        report_id,
        page,
        pageSize,
        orderBy,
        filterGrammar
      ),
    { enabled: Boolean(curIndex) }
  );
  const items = data?.items || [];
  const count = data?.count || 0;

  return (
    <Paper sx={{ height: '100%' }}>
      <Box sx={{ flexGrow: 1 }}>
        <DataGridPro
          onSortModelChange={handleSortModelChange}
          onFilterModelChange={handleFilterModelChange}
          initialState={{ pagination: { pageSize: 10, page: 0 } }}
          loading={isLoading}
          filterMode="server"
          paginationMode="server"
          sortingMode="server"
          onPageChange={setPage}
          onPageSizeChange={setPageSize}
          pagination
          rowCount={count}
          rowsPerPageOptions={[10, 25, 100]}
          autoHeight
          getRowHeight={() => 'auto'}
          columns={COLUMNS}
          rows={items}
          disableDensitySelector
          components={{ Toolbar: GridToolbar }}
          componentsProps={{
            toolbar: {
              printOptions: { disableToolbarButton: true },
              csvOptions: { disableToolbarButton: true },
              showQuickFilter: false,
              quickFilterProps: { debounceMs: 500 },
            },
          }}
          sx={{
            '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': {
              py: '12px',
              borderBottomColor: '#eee',
            },
            '&.MuiDataGrid-root--densityStandard .MuiDataGrid-columnSeparator':
              { color: '#ccc' },
          }}
        />
      </Box>
    </Paper>
  );
};
export default SiteTopicTopGrammarTable;
