import {
  createContext,
  FunctionComponent,
  ReactNode,
  useState,
  useContext,
  Dispatch,
  SetStateAction,
} from 'react';
import { AlertSeverityType } from 'types/types';

type CtxProps = {
  severity: AlertSeverityType;
  message: string;
  duration?: number;
  setSnackbar: Dispatch<SetStateAction<SnackbarType>>;
};

const ctxInit: CtxProps = {
  severity: 'info',
  message: '',
  setSnackbar: () => {},
};

type SnackbarType = {
  severity: AlertSeverityType;
  message: string;
  duration?: number;
};

const SnackbarContext = createContext<CtxProps>(ctxInit);
export default SnackbarContext;

type Props = {
  children: ReactNode;
};

export const SnackbarProvider: FunctionComponent<Props> = ({ children }) => {
  const [state, setState] = useState<SnackbarType>({
    severity: 'info',
    message: '',
    duration: 6000,
  });

  return (
    <SnackbarContext.Provider
      value={{
        severity: state.severity,
        message: state.message,
        duration: state.duration,
        setSnackbar: setState,
      }}
    >
      {children}
    </SnackbarContext.Provider>
  );
};

export const useSnackbar = () => {
  const ctx = useContext(SnackbarContext);
  return ctx;
};
