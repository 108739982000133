import axios from 'axios';

export const postSendPasswordLink = async (email: string) => {
  const BACKEND_API = process.env.REACT_APP_BACKEND_BASE!;
  const api = `${BACKEND_API}auth/send-reset-password-link/`;
  const data = await axios.post(api, { "login": email });
  return data.data;
};

export const postResetPassword = async (password: string, user_id: number, timestamp: number, signature: string) => {
  const BACKEND_API = process.env.REACT_APP_BACKEND_BASE!;
  const api = `${BACKEND_API}auth/reset-password/`;
  const data = await axios.post(api, { "password": password, "user_id": user_id, "timestamp": timestamp, "signature": signature });
  return data.data;
};
