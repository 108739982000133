import { SharedRecipeSourceType } from 'types/types';

export const postSharedRecipe = async (index: string, body: any) => {
  const axiosInstance = require('utils/utils-axios-instance').default;
  const data = await axiosInstance.post(
    `index/${index}/api/shared-recipe/`,
    body
  );
  return data.data;
};

export const getSharedRecipes = async (
  index: string,
  source: SharedRecipeSourceType
) => {
  const axiosInstance = require('utils/utils-axios-instance').default;
  const data = await axiosInstance.get(
    `index/${index}/api/shared-recipe?source=${source}`
  );
  return data.data;
};
