import { Box, MenuItem, TextField } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import {
  getSiteTopicReportItem,
  getSiteTopicTopLabelGroups,
  getSiteTopicTopLabels,
} from 'apis/site_topic';
import LoadingSpinner from 'components/shared/ui/LoadingSpinner';
import { useUser } from 'contexts/user-context';
import { FC, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { OptionType } from 'types/types';
import SiteTopicReportLabelGroupTable from './SiteTopicReportLabelGroupTable';

const SiteTopicReportLabelGroupMain: FC = () => {
  const params = useParams();
  const reportId = params.reportId!;
  const { curIndex } = useUser();
  const { data: reportItemData } = useQuery(
    [curIndex, 'site-topic', reportId, 'report-item'],
    () => getSiteTopicReportItem(curIndex!.value, reportId),
    { enabled: Boolean(curIndex) }
  );
  let columns: string[] = [];
  const reportItemCols = reportItemData?.item?.columns;
  if (Array.isArray(reportItemCols)) {
    if (reportItemCols.length > 0) {
      columns = reportItemCols.map((item) => item.label);
    }
  }
  const { data: labelGroupsData, isLoading: isLoadingLabelGroups } = useQuery(
    [curIndex, 'site-topic', reportId, 'label-groups'],
    () => getSiteTopicTopLabelGroups(curIndex!.value, reportId),
    { enabled: Boolean(curIndex) }
  );

  const labelGroupsOptions: OptionType[] = useMemo(() => {
    const ret = [];
    if (labelGroupsData?.items) {
      for (const item of labelGroupsData.items) {
        const { label_group1, label_group2, amount } = item;
        ret.push({
          value: `${label_group1}+${label_group2}`,
          label: `${label_group1} + ${label_group2} ( ${amount} )`,
        });
      }
    }
    return ret;
  }, [labelGroupsData?.items]);

  const [selectedLabelGroup, setSelectedLabelGroup] = useState('');
  let labelGroup1 = '';
  let labelGroup2 = '';
  if (selectedLabelGroup) {
    const arr = selectedLabelGroup.split('+');
    labelGroup1 = arr[0];
    labelGroup2 = arr[1];
  }

  const [selectedLabel1, setSelectedLabel1] = useState('');

  const [selectedLabel2, setSelectedLabel2] = useState('');

  useEffect(() => {
    if (!selectedLabelGroup && labelGroupsOptions.length > 0) {
      setSelectedLabelGroup(labelGroupsOptions[0].value);
    }
  }, [selectedLabelGroup, labelGroupsOptions]);

  let { data: dataLabels, isLoading: isLoadingLabels } = useQuery(
    [
      curIndex,
      'site-topic',
      reportId,
      labelGroup1,
      labelGroup2,
      selectedLabel1,
      selectedLabel2,
    ],
    () =>
      getSiteTopicTopLabels(
        curIndex!.value,
        reportId,
        labelGroup1,
        labelGroup2,
        selectedLabel1,
        selectedLabel2
      ),
    {
      enabled:
        Boolean(curIndex) && Boolean(labelGroup1) && Boolean(labelGroup2),
    }
  );

  const labels1Options: OptionType[] = useMemo(() => {
    let ret = [{ value: '', label: '---- Please select ----' }];
    if (!dataLabels?.items) return ret;
    const labels = dataLabels.items.map((item: any) => item.label1) as string[];
    const labelCounter: Record<string, number> = {};
    for (const label of labels) {
      if (!(label in labelCounter)) {
        labelCounter[label] = 0;
      }
      labelCounter[label] += 1;
    }
    for (const label in labelCounter) {
      ret.push({
        value: label,
        label: `${label} ( ${labelCounter[label]} )`,
      });
    }
    ret = ret.sort((x, y) => labelCounter[y.value] - labelCounter[x.value]);
    return ret;
  }, [dataLabels?.items]);

  const labels2Options: OptionType[] = useMemo(() => {
    let ret = [{ value: '', label: '---- Please select ----' }];
    if (!dataLabels?.items) return ret;
    const labels = dataLabels.items.map((item: any) => item.label2) as string[];
    const labelCounter: Record<string, number> = {};
    for (const label of labels) {
      if (!(label in labelCounter)) {
        labelCounter[label] = 0;
      }
      labelCounter[label] += 1;
    }
    for (const label in labelCounter) {
      ret.push({
        value: label,
        label: `${label} ( ${labelCounter[label]} )`,
      });
    }
    ret = ret.sort((x, y) => labelCounter[y.value] - labelCounter[x.value]);
    return ret;
  }, [dataLabels?.items]);

  const handleSelectLabelGroup = (e: any) => {
    setSelectedLabelGroup(e.target.value);
    setSelectedLabel1('');
    setSelectedLabel2('');
  };

  return (
    <Box sx={{ ml: 3, mr: 3, mt: 4 }}>
      <Box>
        <Box sx={{ my: 2 }}>
          {isLoadingLabelGroups && <LoadingSpinner />}
          {!isLoadingLabelGroups && (
            <TextField
              sx={{ minWidth: 200 }}
              label="Label groups"
              variant="standard"
              select
              value={selectedLabelGroup}
              onChange={handleSelectLabelGroup}
            >
              {labelGroupsOptions.map((item) => (
                <MenuItem key={item.value} value={item.value}>
                  {item.label}
                </MenuItem>
              ))}
            </TextField>
          )}
        </Box>
        <Box sx={{ display: 'flex', gap: 4 }}>
          <Box>
            {!isLoadingLabels && (
              <TextField
                sx={{ minWidth: 200 }}
                label="Labels"
                variant="standard"
                select
                value={selectedLabel1}
                onChange={(e) => setSelectedLabel1(e.target.value)}
              >
                {labels1Options.map((item) => (
                  <MenuItem key={item.value} value={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
              </TextField>
            )}
          </Box>
          <Box>
            {!isLoadingLabels && (
              <TextField
                sx={{ minWidth: 200 }}
                label="Labels"
                variant="standard"
                select
                value={selectedLabel2}
                onChange={(e) => setSelectedLabel2(e.target.value)}
              >
                {labels2Options.map((item) => (
                  <MenuItem key={item.value} value={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
              </TextField>
            )}
          </Box>
        </Box>
        <Box my={4}>
          {selectedLabelGroup && (
            <SiteTopicReportLabelGroupTable
              columns={columns}
              report_id={reportId}
              labelGroup1={labelGroup1}
              labelGroup2={labelGroup2}
              label1={selectedLabel1}
              label2={selectedLabel2}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default SiteTopicReportLabelGroupMain;
