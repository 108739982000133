import { Box, Paper } from '@mui/material';
import { DataGridPro, GridColDef } from '@mui/x-data-grid-pro';
import { FunctionComponent } from 'react';

export const columns: GridColDef[] = [
  {
    field: 'id',
    headerClassName: 'App-Mui-DataGrid-Header',
  },
  {
    field: 'name',
    headerName: 'config name',
    flex: 1,
    headerClassName: 'App-Mui-DataGrid-Header',
  },

  {
    field: 'p_expected_rs',
    headerName: '% expected rs',
    width: 120,
    headerClassName: 'App-Mui-DataGrid-Header',
  },
  {
    field: 'p_valid_target_urls',
    headerName: '% valid target urls',
    width: 140,
    headerClassName: 'App-Mui-DataGrid-Header',
  },
  {
    field: 'p_valid_anchor_texts',
    headerName: '% valid anchor texts',
    width: 148,
    headerClassName: 'App-Mui-DataGrid-Header',
  },
  {
    field: 'p_valid_positions',
    headerName: '% valid positions',
    width: 140,
    headerClassName: 'App-Mui-DataGrid-Header',
  },
];

const percentage = (a: number, b: number) => {
  let ret: any = a / b;
  if (Number.isNaN(ret)) return '0%';
  ret *= 100;
  ret = ret.toFixed(2);
  ret = ret.toString() + '%';
  return ret;
};

type Props = {
  dataRaw: any;
  isLoading: boolean;
};

const LVRSummarytTable: FunctionComponent<Props> = ({ dataRaw, isLoading }) => {
  const items: any = [];
  if (dataRaw) {
    const configs = dataRaw.configs;
    for (const id in configs) {
      const obj = configs[id];
      const {
        name,
        n_expected_rs,
        n_valid_target_urls,
        n_valid_anchor_texts,
        n_valid_positions,
      } = obj;
      const p_expected_rs = '100%';
      const p_valid_target_urls = percentage(
        n_valid_target_urls,
        n_expected_rs
      );
      const p_valid_anchor_texts = percentage(
        n_valid_anchor_texts,
        n_expected_rs
      );
      const p_valid_positions = percentage(n_valid_positions, n_expected_rs);
      const item = {
        id,
        name: id + ' - ' + name,
        p_expected_rs,
        p_valid_target_urls,
        p_valid_anchor_texts,
        p_valid_positions,
      };
      items.push(item);
    }
  }
  return (
    <Paper sx={{ display: 'flex', height: '100%' }}>
      <Box sx={{ flexGrow: 1 }}>
        <DataGridPro
          initialState={{
            columns: {
              columnVisibilityModel: {
                id: false,
              },
            },
          }}
          pagination
          loading={isLoading}
          autoHeight
          getRowHeight={() => 'auto'}
          columns={columns}
          rows={items}
          disableDensitySelector
          disableColumnFilter
          sx={{
            display: 'flex',
            flexDirection: 'column-reverse',
            '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': {
              py: '12px',
              borderBottomColor: '#eee',
            },
            '&.MuiDataGrid-root--densityStandard .MuiDataGrid-columnSeparator':
              { color: '#ccc' },
          }}
        />
      </Box>
    </Paper>
  );
};
export default LVRSummarytTable;
