import { Box, Tab, Tabs } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import KWIngredientMain from './keyword/KWIngredientMain';
import PIMain from './page/PIMain';
import UploadCSVMain from './upload-csv/UploadCSVMain';
import LabelGroups from './taxonomy/LabelGroups';
import { useLocation, useNavigate } from 'react-router-dom';
import JobsMain from './job/JobsMain';
import DownloadMain from './download/DownloadMain';
import FunctionIngredientMain from './function-ingredient/FunctionIngredientMain';

const PATH_TAB_MAP: Record<string, number> = {
  pages: 1,
  function: 2,
  upload: 3,
  taxonomy: 4,
  jobs: 5,
  download: 6,
};
const TAB_PATH_LOOKUP: string[] = [
  '',
  'pages',
  'function',
  'upload',
  'taxonomy',
  'jobs',
  'download',
];

type Props = {
  indexId: string;
};
const SetupViews: FC<Props> = ({ indexId }) => {
  const [tab, setTab] = useState(0);
  const navigate = useNavigate();
  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setTab(newValue);
    const tabPath = TAB_PATH_LOOKUP[newValue];
    navigate(`/settings/${indexId}/${tabPath}`);
  };
  const { pathname } = useLocation();
  const paths = pathname.split('/');
  const path = paths[3];
  useEffect(() => {
    if (path && path in PATH_TAB_MAP) {
      setTab(PATH_TAB_MAP[path]);
    } else {
      setTab(0);
    }
  }, [path, setTab]);
  return (
    <Box sx={{ ml: 3, mr: 3, mt: 4 }}>
      <Tabs value={tab} onChange={handleChangeTab}>
        <Tab label="Keyword Ingredient" />
        <Tab label="Page Ingredient" />
        <Tab label="Function Ingredient" />
        <Tab label="Upload CSV files" />
        <Tab label="Taxonomy" />
        <Tab label="Jobs" />
        <Tab label="Download" />
      </Tabs>
      <Box sx={{ mt: 4 }}>
        {tab === 0 && <KWIngredientMain indexId={indexId} />}
        {tab === 1 && <PIMain indexId={indexId} />}
        {tab === 2 && <FunctionIngredientMain indexId={indexId} />}
        {tab === 3 && <UploadCSVMain indexId={indexId} />}
        {tab === 4 && <LabelGroups indexId={indexId} />}
        {tab === 5 && <JobsMain indexId={indexId} />}
        {tab === 6 && <DownloadMain indexId={indexId} />}
      </Box>
    </Box>
  );
};

export default SetupViews;
