import { Box, Paper } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { postWorkbenchKeyword } from 'apis/workbench';
import { useUser } from 'contexts/user-context';
import { FC, useCallback, useState } from 'react';
import { convertQueryBuilderTree } from 'utils/utils-query-builder';
import WBKWTable from './WBKWTable';
import { ColOrderType } from 'types/types';
import { StateProp as OperationStateProp } from 'components/workbench/OperationBuilder';
import ActionSelector from '../ActionSelector';
import WBExportBtn from '../WBExportBtn';
import useWBActions from 'hooks/useWBActions';
import useWBCols from 'hooks/useWBCols';
import WBQBRecipe from '../WBQBRecipe';
import FieldSetupDlg from '../dialog/FieldSetupDlg';
import SetupColBtn from '../SetupColBtn';
import { initTableDataFromDataRaw } from 'utils';

type Props = {
  opporId?: number;
};

const WBKeyword: FC<Props> = ({ opporId }) => {
  const { curIndex } = useUser();
  const [page, setPage] = useState(0);
  const [offset, setOffset] = useState(10);
  const [backQuery, setBackQuery] = useState<any>(null);
  const [operation, setOperation] = useState<OperationStateProp>();
  const [order, setOrder] = useState<ColOrderType>({
    name: 'volume',
    direction: 'desc',
  });

  let { data: dataRaw, isLoading } = useQuery(
    [
      curIndex,
      'WB',
      'keyword',
      page,
      offset,
      backQuery,
      order,
      operation,
      opporId,
    ],
    () =>
      postWorkbenchKeyword(curIndex!.value, {
        page,
        offset,
        operation: operation?.operations[0],
        back_query: backQuery,
        order_column: order.name,
        order_dir: order.direction,
        opporId,
      }),
    { enabled: Boolean(curIndex), keepPreviousData: true }
  );

  const handleChangeRecipe = useCallback((jsonTree: any) => {
    const _backQuery = convertQueryBuilderTree(jsonTree);
    setBackQuery(_backQuery);
  }, []);

  const handleOperationChange = useCallback((operation: OperationStateProp) => {
    setOperation(operation);
  }, []);

  const {
    invisibleCols,
    setInvisibleCols,
    colsDisplayOrder,
    setColsDisplayOrder,
    colsWidth,
    setColsWidth,
    columns,
  } = useWBCols({ source: 'keyword' });

  const data = initTableDataFromDataRaw(dataRaw);

  data.items = data.items.map((item: any) => ({
    ...item,
    __operation: operation,
  }));

  const {
    fieldOptions,
    handleSelectAction,
    options2,
    groupOptions,
    isSupportRowSelector,
  } = useWBActions({
    source: 'keyword',
  });

  const [isFieldSetupDlgOpen, setIsFieldSetupDlgOpen] = useState(false);

  return (
    <Box sx={{ ml: 3, mr: 3, mt: 4 }}>
      {isFieldSetupDlgOpen && (
        <FieldSetupDlg
          open={isFieldSetupDlgOpen}
          onClose={() => setIsFieldSetupDlgOpen(false)}
          source="keyword"
        />
      )}
      <Box mt={4}>
        <WBQBRecipe
          onRecipeChange={handleChangeRecipe}
          onOperationChange={handleOperationChange}
          col_sorting={order}
          col_invisible={invisibleCols.cols}
          col_display_order={colsDisplayOrder}
          col_width={colsWidth}
          setOrder={setOrder}
          setInvisibleCols={setInvisibleCols}
          setColsDisplayOrder={setColsDisplayOrder}
          setColsWidth={setColsWidth}
          source={'keyword'}
          tableData={data}
          opporId={opporId}
        />
      </Box>
      <Paper sx={{ mt: 4 }}>
        <ActionSelector
          isSupportRowSelector={isSupportRowSelector}
          fieldOptions={fieldOptions}
          options2={options2}
          backQuery={backQuery}
          source="keyword"
          onSelectOption={handleSelectAction}
          opporId={opporId}
          groupOptions={groupOptions}
        />
        <Box
          sx={{
            m: 1,
            display: 'flex',
            gap: 2,
            alignItems: 'center',
            justifyContent: 'flex-end',
          }}
        >
          <WBExportBtn
            source="keyword"
            back_query={backQuery}
            opporId={opporId}
          />
          <SetupColBtn onClick={() => setIsFieldSetupDlgOpen(true)} />
        </Box>

        <WBKWTable
          data={data}
          isLoading={isLoading}
          page={page}
          offset={offset}
          setOffset={setOffset}
          setPage={setPage}
          setOrder={setOrder}
          setColsDisplayOrder={setColsDisplayOrder}
          setColsWidth={setColsWidth}
          columns={columns}
        />
      </Paper>
    </Box>
  );
};

export default WBKeyword;
