import { FC, useCallback, useMemo, useState } from 'react';
import { Box, TextField, Typography } from '@mui/material';
import { editDistance } from 'utils';

export const replaceLabelWithField = (
  rawStr: string,
  labelFieldLookup: Record<string, string>,
  reverse = false
) => {
  const length = rawStr.length;
  const fieldLabelLookup: Record<string, string> = {};
  const extendedLookup: Record<string, string> = {};
  for (const label in labelFieldLookup) {
    const field = labelFieldLookup[label];
    fieldLabelLookup[field] = label;
    extendedLookup[label] = field;
    extendedLookup[field] = field;
  }
  let lookup = reverse ? fieldLabelLookup : extendedLookup;
  const isTagStart = (i: number) => {
    if (i >= length - 5) return false;
    if (rawStr[i] === '{' && rawStr[i + 1] === '{') return true;
    return false;
  };

  const isTagEnd = (i: number) => {
    if (rawStr[i] === '}' && rawStr[i + 1] === '}') return true;
    return false;
  };

  let errMsg = '';

  const arr: string[] = [];
  let [start, end] = [0, 0];
  while (end <= length - 1) {
    if (isTagStart(end)) {
      start = end + 2;
      end = start + 1;
      while (!isTagEnd(end)) end += 1;
      const field = rawStr.slice(start, end).trim();
      if (!(field in lookup) && !reverse) {
        const distance: any[] = [];
        for (const key in lookup) {
          const d = editDistance(key, field);
          distance.push([key, d]);
        }
        distance.sort((x, y) => x[1] - y[1]);
        const potentialFields = distance
          .map((item) => item[0])
          .slice(0, 3)
          .join(', ');
        if (!errMsg)
          errMsg = `Invalid field name: ${field}, \n did you mean ${potentialFields}?`;
      }
      if (field in lookup) {
        arr.push(`{{${lookup[field]}}}`);
      } else {
        arr.push(`{{${field}}}`);
      }
      start = end + 2;
      if (start >= length - 1) break;
      end = start;
    }
    arr.push(rawStr[end]);
    end += 1;
  }

  //remove '\n'
  end = arr.length - 1;
  while (arr[end] === '\n') {
    arr.pop();
    end -= 1;
  }

  return [arr.join(''), errMsg];
};

export type WhiteListItemType = {
  id?: number | string;
  value: string;
  _value: string;
};

type Props = {
  whitelist: WhiteListItemType[];
  onBlur?: (value: string, hasError: boolean) => void;
  initValue: string;
  minWidth?: number;
  isView?: boolean;
};

const MixTagsInput: FC<Props> = ({
  whitelist,
  onBlur,
  initValue,
  minWidth,
  isView,
}) => {
  const labelFieldLookup: Record<string, string> = useMemo(() => {
    const ret: Record<string, string> = {};
    for (const item of whitelist) {
      ret[item.value] = item._value;
    }
    return ret;
  }, [whitelist]);
  const [valueUsingLabel] = replaceLabelWithField(
    initValue,
    labelFieldLookup,
    true
  );

  const [value, setValue] = useState(valueUsingLabel);
  const [errMsg, setErrMsg] = useState('');

  const handleBlur = useCallback(
    (e) => {
      const [value, errMsg] = replaceLabelWithField(
        e.target.value,
        labelFieldLookup
      );
      onBlur!(value, Boolean(errMsg));
      setErrMsg(errMsg);
    },
    [onBlur, labelFieldLookup]
  );

  return (
    <Box
      sx={{
        minWidth: minWidth || 600,
        '& .tagify': { border: 'none', borderBottom: '1px solid #999' },
      }}
    >
      {isView && <Typography variant="body2">{value}</Typography>}
      {!isView && (
        <Box>
          <TextField
            variant="standard"
            multiline
            fullWidth
            minRows={2}
            value={value}
            onChange={(e) => setValue(e.target.value)}
            onBlur={handleBlur}
            error={Boolean(errMsg)}
            helperText={errMsg}
          />
        </Box>
      )}
    </Box>
  );
};

export default MixTagsInput;
