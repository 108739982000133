import {
  createContext,
  FunctionComponent,
  ReactNode,
  useState,
  useContext,
  useEffect,
  Dispatch,
  SetStateAction,
} from 'react';
import { IndexType } from 'types/types';

type CtxProps = {
  allIndexes: any[];
  setAllIndexes: Dispatch<SetStateAction<any[]>>;
  curIndex: IndexType | undefined;
  setCurIndex: Dispatch<SetStateAction<IndexType | undefined>>;
};

const ctxInit: CtxProps = {
  allIndexes: [],
  setAllIndexes: () => {},
  curIndex: undefined,
  setCurIndex: () => {},
};

const UserContext = createContext<CtxProps>(ctxInit);
export default UserContext;

type Props = {
  children: ReactNode;
};

export const UserProvider: FunctionComponent<Props> = ({ children }) => {
  const [allIndexes, setAllIndexes] = useState<any[]>([]);
  const [curIndex, setCurIndex] = useState<IndexType>();

  const initiate = async () => {};

  useEffect(() => {
    initiate();
  }, []);

  return (
    <UserContext.Provider
      value={{
        allIndexes,
        setAllIndexes,
        curIndex,
        setCurIndex,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => {
  const ctx = useContext(UserContext);
  return ctx;
};
