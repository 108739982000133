export const getUsageV2 = async (index: string | number) => {
  const axiosInstance = require('utils/utils-axios-instance').default;
  const data = await axiosInstance.get(`index/${index}/api/usage/v2/`);
  return data.data;
};

export const getAccountUsageV2 = async (index: string | number) => {
  const axiosInstance = require('utils/utils-axios-instance').default;
  const data = await axiosInstance.get(`index/${index}/api/account_usage/v2/`);
  return data.data;
};
