import {
  Button,
  TextField,
  Box,
  Typography,
  Container,
  Link,
} from '@mui/material';
import { LayoutBlank } from 'components/layout/LayoutBlank';
import { useState } from 'react';
import { useAuth } from 'contexts/auth-context';
import Logo from 'components/header/Logo';

export default function SignIn() {
  const { login } = useAuth();
  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      await login(username, password);
    } catch (err) {
      setError(true);
    }
  };
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(false);

  return (
    <LayoutBlank>
      <Container component="main" maxWidth="sm">
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            border: '1px solid black',
            pt: 8,
            borderRadius: '4px',
          }}
        >
          <Box sx={{ mb: 4 }}>
            <Logo width="200px" color="black" />
          </Box>
          <Typography component="h3" variant="h3">
            Sign in
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              margin="normal"
              size="small"
              required
              fullWidth
              label="Email"
              name="username"
              autoFocus
              value={username}
              onChange={(e) => setUsername(e.currentTarget.value)}
              error={error}
              helperText={error && 'Incorrect username or password'}
            />
            <TextField
              size="small"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              value={password}
              onChange={(e) => setPassword(e.currentTarget.value)}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign In
            </Button>
          </Box>
          {/* <Link href="/signup">Sign up</Link> */}
          <Box sx={{ mt: 4, p: 2, backgroundColor:'#edf0f6', width:'100%', textAlign:'center' }}>
            <Link href="/forgot-password" sx={{ color:'#777' }}>Forgot your password?</Link>
          </Box>
        </Box>
      </Container>
    </LayoutBlank>
  );
}
