import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Typography,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { getWidgets } from 'apis/widgets';
import WidgetItem, {
  WidgetItemType,
} from 'components/monitoring/widget/WidgetItem';
import LoadingSpinner from 'components/shared/ui/LoadingSpinner';
import { useUser } from 'contexts/user-context';
import { FC, useState } from 'react';
import { WBSourceType } from 'types/types';
import AddIcon from '@mui/icons-material/Add';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AddEditWidgetDlg from 'components/monitoring/widget/AddEditWidgetDlg';
import { INDEX_CONFIG } from 'constants/constants';
import { getIndexConfig } from 'apis/index_config';

type WidgetChartItemProps = {
  item: WidgetItemType;
  opporId?: number;
  back_query?: any;
};
const WidgetChartItem: FC<WidgetChartItemProps> = ({
  item,
  back_query,
  opporId,
}) => {
  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        {item.name}
      </AccordionSummary>
      <AccordionDetails>
        <WidgetItem
          key={item.id}
          item={item}
          isSimpleView={true}
          back_query={back_query}
          opporId={opporId}
        />
      </AccordionDetails>
    </Accordion>
  );
};

type Props = {
  source: WBSourceType;
  opporType?: any;
  opporId?: number;
  version?: string;
  back_query?: any;
  fromWBOpporId?: number;
  parentPosition: 'WB' | 'OPPOR';
};
const WBWidgets: FC<Props> = ({
  source,
  opporId,
  version,
  back_query,
  opporType,
  parentPosition,
}) => {
  const { curIndex } = useUser();
  let { data, isLoading } = useQuery(
    [curIndex, 'Widget'],
    () => getWidgets(curIndex!.value),
    { enabled: Boolean(curIndex), staleTime: 60 * 1000 }
  );
  if (data?.length > 0) {
    data = data.filter((item: WidgetItemType) => item.source === source);
    if (opporId && parentPosition === 'OPPOR') {
      data = data.filter(
        (item: WidgetItemType) => item?.opportunity === opporId
      );
    } else {
      data = data.filter((item: WidgetItemType) => !item?.opportunity);
    }
  }

  const singleValWidgetItems: WidgetItemType[] =
    data?.filter((item: WidgetItemType) => item.graph === 'value') || [];
  const multiValWidgetItems: WidgetItemType[] =
    data?.filter((item: WidgetItemType) => item.graph !== 'value') || [];

  const [addSummaryDlgOpen, setAddSummaryDlgOpen] = useState(false);
  const [addChartDlgOpen, setAddChartDlgOpen] = useState(false);
  let showWidget = false;
  const { data: wbWdgetConfig } = useQuery(
    [curIndex, 'config', 'enable_workbench_widget'],
    () => getIndexConfig(curIndex!.value, INDEX_CONFIG.enable_workbench_widget),
    { enabled: Boolean(curIndex) }
  );
  if (
    wbWdgetConfig &&
    wbWdgetConfig['value'].toString().toLowerCase() === 'true'
  ) {
    showWidget = true;
  }
  if (!showWidget) return <></>;

  return (
    <Box sx={{ my: 4 }}>
      {addSummaryDlgOpen && (
        <AddEditWidgetDlg
          version={version}
          opporId={opporId}
          opporType={opporType}
          openFrom={{ button: 'AddSummary', source }}
          open={addSummaryDlgOpen}
          onClose={() => setAddSummaryDlgOpen(false)}
        />
      )}
      {addChartDlgOpen && (
        <AddEditWidgetDlg
          version={version}
          opporId={opporId}
          opporType={opporType}
          openFrom={{ button: 'AddChart', source }}
          open={addChartDlgOpen}
          onClose={() => setAddChartDlgOpen(false)}
        />
      )}
      <Typography variant="h6">Summary:</Typography>
      <Box sx={{ my: 2 }}>
        <Button
          startIcon={<AddIcon />}
          onClick={() => setAddSummaryDlgOpen(true)}
        >
          Add summary
        </Button>
        {isLoading && <LoadingSpinner />}
        <Box sx={{ display: 'flex', gap: 2, flexWrap: 'wrap' }}>
          {singleValWidgetItems.map((item: WidgetItemType) => (
            <WidgetItem
              key={item.id}
              item={item}
              isSimpleView={true}
              back_query={back_query}
              opporId={opporId}
            />
          ))}
        </Box>
      </Box>
      <Box sx={{ my: 2 }}>
        <Button
          startIcon={<AddIcon />}
          onClick={() => setAddChartDlgOpen(true)}
        >
          Add chart
        </Button>
        {isLoading && <LoadingSpinner />}
        <Box sx={{ display: 'flex', gap: 2, flexWrap: 'wrap' }}>
          {multiValWidgetItems.map((item: WidgetItemType) => (
            <WidgetChartItem
              key={item.id}
              item={item}
              back_query={back_query}
              opporId={opporId}
            />
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default WBWidgets;
