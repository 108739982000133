import { useEffect, useState } from 'react';
import { getInitCols as getColsKeyword } from 'components/workbench/keyword/WBKWTable';
import { getInitCols as getColsPage } from 'components/workbench/page/WBPageTable';
import { getInitCols as getColsTopic } from 'components/workbench/topic/WBTopicTable';
import { getInitCols as getColsSite } from 'components/workbench/site/WBSiteTable';
import { isEmpty } from 'utils';
import { GridColDef } from '@mui/x-data-grid-pro';
import { useUser } from 'contexts/user-context';
import { useQuery } from '@tanstack/react-query';
import type { WBColPreviewType, WBSourceType } from 'types/types';
import {
  getEnabledGroups,
  getEnabledFields,
  type ItemType as FieldType,
  type ItemGroupType as FieldGroupType,
} from 'components/settings2/SettingsFields';
import { getIndexFields } from 'apis/index_fields';
import { useAtom } from 'jotai';
import { WBColPreviewAtom } from 'store/jotai';
import { renderUrl } from 'components/shared/table-cell/UrlCell';
import { renderVariable } from 'components/shared/table-cell/VariableCell';

const PREIVEW_CHATGPT = 'preview_chatgpt';
const PREIVEW_VARIABLE = '$preview_variable';

type ParamsType = { source: WBSourceType };

export type InvisibleColsType = {
  idx: string;
  cols: string[];
  source: WBSourceType;
};

const WORKBENCH = '-workbench-';
const COLS_WIDTH = '-colsWidth-';
const COLS_DISPLAY_ORDER = '-colsDisplayOrder-';

const storeColsWidth = (
  source: WBSourceType,
  colsWidth: Record<string, number>
) => {
  localStorage.setItem(
    WORKBENCH + source + COLS_WIDTH,
    JSON.stringify(colsWidth)
  );
};

const storeColsDisplayOrder = (
  source: WBSourceType,
  colsDisplayOrder: Record<string, number>
) => {
  localStorage.setItem(
    WORKBENCH + source + COLS_DISPLAY_ORDER,
    JSON.stringify(colsDisplayOrder)
  );
};

const initColsFromStorage = (
  source: WBSourceType,
  cols: GridColDef[],
  indexId: string
) => {
  const initColsWidthStr = localStorage.getItem(
    WORKBENCH + source + COLS_WIDTH
  );
  if (initColsWidthStr) {
    const initColsWidth = JSON.parse(initColsWidthStr) as Record<
      string,
      number
    >;
    Object.keys(initColsWidth).forEach((key) => {
      const idx = cols.findIndex((col) => col.field === key);
      if (idx !== -1) {
        cols[idx].width = initColsWidth[key];
        cols[idx].flex = undefined;
      }
    });
  }
  const initColsDisplayOrderStr = localStorage.getItem(
    WORKBENCH + source + COLS_DISPLAY_ORDER
  );
  if (initColsDisplayOrderStr) {
    const initColsDisplayOrder = JSON.parse(initColsDisplayOrderStr) as Record<
      string,
      number
    >;
    cols = cols.sort(
      (a: GridColDef, b: GridColDef) =>
        initColsDisplayOrder[a.field] - initColsDisplayOrder[b.field]
    );
  }
};

const getCols = (
  source: WBSourceType,
  enabledFields: FieldType[],
  previewType: WBColPreviewType
) => {
  let _cols: GridColDef[] = [];
  switch (source) {
    case 'keyword':
      _cols = getColsKeyword();
      break;
    case 'page':
      _cols = getColsPage();
      break;
    case 'topic':
      _cols = getColsTopic();
      break;
    default:
      _cols = getColsSite();
  }
  const cols: GridColDef[] = [];
  const enableFieldsMap: any = {};
  for (const field of enabledFields) enableFieldsMap[field.field] = field;

  for (const _col of _cols) {
    if (_col.field === PREIVEW_CHATGPT && previewType === 'GPT') {
      cols.push(_col);
      continue;
    }
    if (_col.field === PREIVEW_VARIABLE && previewType === 'Var') {
      cols.push(_col);
      continue;
    }
    if (_col.field in enableFieldsMap) {
      cols.push(_col);
      delete enableFieldsMap[_col.field];
      continue;
    }
  }
  for (const key in enableFieldsMap) {
    const fieldObj: FieldType = enableFieldsMap[key];
    const isUrl = fieldObj.field.toLocaleLowerCase().includes('url');
    const isVar = fieldObj.field.startsWith('$');
    cols.push({
      width: 200,
      field: fieldObj.field,
      headerName: fieldObj.label,
      headerClassName: 'App-Mui-DataGrid-Header',
      renderCell: isUrl ? renderUrl : isVar ? renderVariable : undefined,
    });
  }
  return cols;
};

const useWBCols = ({ source }: ParamsType) => {
  const { curIndex } = useUser();

  const [columns, setColumns] = useState<GridColDef[]>([]);

  const { data: dataFields, isLoading: isLoadingFields } = useQuery(
    [curIndex, 'IndexFields'],
    () => getIndexFields(curIndex!.value),
    { enabled: Boolean(curIndex), staleTime: 60 * 1000 }
  );
  const [enabledFieldGroups, setEnabledFieldGroups] = useState<
    FieldGroupType[]
  >([]);
  const [enabledFields, setEnabledFields] = useState<FieldType[]>([]);
  useEffect(() => {
    if (dataFields) {
      const groups = getEnabledGroups(dataFields, source);
      const fields = getEnabledFields(dataFields, source);
      setEnabledFields(fields);
      setEnabledFieldGroups(groups);
    }
  }, [dataFields, source]);

  const [invisibleCols, setInvisibleCols] = useState<InvisibleColsType>({
    source,
    idx: '',
    cols: [],
  });
  const [colsDisplayOrder, setColsDisplayOrder] = useState<
    Record<string, number>
  >({});
  const [colsWidth, setColsWidth] = useState<Record<string, number>>({});
  const [previewType] = useAtom(WBColPreviewAtom);

  useEffect(() => {
    if (curIndex?.value && !isEmpty(enabledFields)) {
      let _cols = getCols(source, enabledFields, previewType);
      initColsFromStorage(source, _cols, curIndex.value);
      if (!isEmpty(colsWidth)) {
        storeColsWidth(source, colsWidth);
        for (const col of _cols) {
          if (col.field in colsWidth) {
            col.width = colsWidth[col.field];
            col.flex = undefined;
          }
        }
      }
      if (!isEmpty(colsDisplayOrder)) {
        storeColsDisplayOrder(source, colsDisplayOrder);
        _cols = _cols.sort(
          (a: GridColDef, b: GridColDef) =>
            colsDisplayOrder[a.field] - colsDisplayOrder[b.field]
        );
      }
      setColumns(_cols);
    }
  }, [
    source,
    colsWidth,
    curIndex,
    enabledFields,
    colsDisplayOrder,
    enabledFieldGroups,
    previewType,
  ]);

  return {
    invisibleCols,
    setInvisibleCols,
    colsDisplayOrder,
    setColsDisplayOrder,
    colsWidth,
    setColsWidth,
    columns,
    isLoadingFields,
  };
};

export default useWBCols;
