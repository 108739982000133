import Layout from 'components/layout/Layout';
import DocumentEdit from 'components/settings/views/upload-csv/update/DocumentEdit';
import { FC } from 'react';

const DocumentEditPage: FC = () => {
  return (
    <Layout>
      <DocumentEdit />
    </Layout>
  );
};

export default DocumentEditPage;
