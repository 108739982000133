import { IconButton, Tooltip } from '@mui/material';
import { FunctionComponent } from 'react';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useSnackbar } from 'contexts/snackbar-context';

type Props = {
  text: string;
};

const CopyTxtBtn: FunctionComponent<Props> = ({ text }) => {
  const { setSnackbar } = useSnackbar();
  const clickHandler = () => {
    navigator.clipboard.writeText(text);
    setSnackbar({ severity: 'success', message: 'Copied' });
  };
  return (
    <Tooltip title="Copy">
      <IconButton onClick={clickHandler}>
        <ContentCopyIcon />
      </IconButton>
    </Tooltip>
  );
};
export default CopyTxtBtn;
