import { LabelClassiferType } from 'components/settings/views/taxonomy/dialog/CreateLabelDialog';

// export const getIndexLabels = async (index: string | number) => {
//   const axiosInstance = require('utils/utils-axios-instance').default;
//   const data = await axiosInstance.get(`index/${index}/api/labels/`);
//   return data.data;
// };

export const uploadIndexLabels = async (index: string, body: FormData) => {
  const axiosInstance = require('utils/utils-axios-instance').default;
  const data = await axiosInstance.post(`index/${index}/api/labels/`);
  return data.data;
};

export const postCreateLabel = async ({
  index,
  name,
  type,
  canonical_label_id,
  canonical_label_name,
  selectedFile,
  label_names,
  label_classifier_type,
  labelColumn,
  synonymColumn,
}: {
  index: string;
  name?: string;
  type?: string;
  canonical_label_id?: string | null;
  canonical_label_name?: string | null;
  selectedFile?: any;
  label_names?: string[];
  label_classifier_type?: LabelClassiferType | null;
  labelColumn?: string | undefined;
  synonymColumn?: string | undefined;
}) => {
  const axiosInstance = require('utils/utils-axios-instance').default;
  const data = await axiosInstance.post(`index/${index}/api/labels/`, {
    name,
    type,
    canonical_label_id,
    canonical_label_name,
    selectedFile,
    label_names,
    label_classifier_type,
    labelColumn,
    synonymColumn,
  });
  return data.data;
};

export const deleteLabel = async (index: string, labelId: string | number) => {
  const axiosInstance = require('utils/utils-axios-instance').default;
  const data = await axiosInstance.delete(
    `index/${index}/api/labels/${labelId}`
  );
  return data.data;
};

export const patchLabel = async (
  index: string,
  labelId: string | number,
  body: any
) => {
  const axiosInstance = require('utils/utils-axios-instance').default;
  const data = await axiosInstance.patch(
    `index/${index}/api/labels/${labelId}`,
    body
  );
  return data.data;
};

export const postNewLabelsByChatGPT = async (index: string, body: any) => {
  const axiosInstance = require('utils/utils-axios-instance').default;
  const data = await axiosInstance.post(
    `index/${index}/api/labels/new_labels_by_chatgpt`,
    body
  );
  return data.data;
};

export const postMoreLabelsByChatGPT = async (index: string, body: any) => {
  const axiosInstance = require('utils/utils-axios-instance').default;
  const data = await axiosInstance.post(
    `index/${index}/api/labels/more_labels_by_chatgpt`,
    body
  );
  return data.data;
};

export const postGetPagedLabels = async (index: string, body: any) => {
  const axiosInstance = require('utils/utils-axios-instance').default;
  const data = await axiosInstance.post(
    `index/${index}/api/labels/get-paginated-labels`,
    body
  );
  return data.data;
};
