import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
  DialogTitle,
  Box,
  MenuItem,
  TextareaAutosize,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import { FunctionComponent, useEffect, useState } from 'react';
import { WORKBENCH_ACTIONS } from 'constants/constants';
import type { WorkbenchActionType, IntervalType } from 'types/types';
import { useUser } from 'contexts/user-context';
import { useSnackbar } from 'contexts/snackbar-context';
import {
  patchIndexSchduledAction,
  postIndexSchduledAction,
} from 'apis/index_scheduled_actions';
import { useQueryClient } from '@tanstack/react-query';

type Props = {
  saObj?: any;
  open: boolean;
  onClose: () => void;
};

const SACreateEditDialog: FunctionComponent<Props> = ({
  saObj,
  open,
  onClose,
}) => {
  const queryClient = useQueryClient();
  const { curIndex } = useUser();
  const { setSnackbar } = useSnackbar();
  const isEdit = Boolean(saObj);
  const title = isEdit
    ? `Edit Scheduled Action ${saObj?.id}`
    : 'Create Scheduled Action';

  const [action, setAction] = useState<WorkbenchActionType | ''>('');
  const [params, setParams] = useState('{}');
  const [refreshInterval, setRefreshInterval] =
    useState<IntervalType>('weekly');
  const [autoRefresh, setAutoRefresh] = useState<boolean>(false);
  useEffect(() => {
    if (saObj) {
      setAction(saObj.action);
      if (saObj.params) {
        setParams(JSON.stringify(saObj.params));
      }
      setRefreshInterval(saObj.refresh_interval);
      setAutoRefresh(saObj.auto_refresh);
    }
  }, [saObj]);

  const handleSave = async () => {
    let parmsObj = {};
    try {
      parmsObj = JSON.parse(params);
    } catch (e) {
      setSnackbar({ severity: 'error', message: `Invalid params ${params}` });
    }
    const body = {
      action,
      refresh_interval: refreshInterval,
      auto_refresh: autoRefresh,
      params: parmsObj,
    };
    if (isEdit) {
      await patchIndexSchduledAction(curIndex!.value, saObj!.id, body);
      setSnackbar({ severity: 'success', message: 'Edited.' });
    } else {
      await postIndexSchduledAction(curIndex!.value, body);
      setSnackbar({ severity: 'success', message: 'Created.' });
    }
    queryClient.invalidateQueries([curIndex, 'scheduled-action']);
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <Box>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent
          sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 2 }}
        >
          <TextField
            sx={{ minWidth: 200 }}
            select
            variant="standard"
            size="small"
            defaultValue=""
            label="Workbench action"
            value={action}
            onChange={(e) => setAction(e.target.value as WorkbenchActionType)}
          >
            {WORKBENCH_ACTIONS.map((item) => (
              <MenuItem key={item.value} value={item.value}>
                {item.label}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            select
            variant="standard"
            label="Refresh interval"
            size="small"
            onChange={(e) => setRefreshInterval(e.target.value as IntervalType)}
            value={refreshInterval}
          >
            <MenuItem value="manual">Manual</MenuItem>
            <MenuItem value="hourly">Hourly</MenuItem>
            <MenuItem value="daily">Daily</MenuItem>
            <MenuItem value="weekly">Weekly</MenuItem>
            <MenuItem value="monthly">Monthly</MenuItem>
          </TextField>
          <FormControlLabel
            control={
              <Checkbox
                checked={autoRefresh}
                onChange={(e) => setAutoRefresh(e.target.checked)}
              />
            }
            label="Auto refresh"
          />
          <TextareaAutosize
            placeholder="Params"
            minRows={4}
            value={params}
            onChange={(e) => setParams(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button onClick={handleSave}>{isEdit ? 'Edit' : 'Create'}</Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default SACreateEditDialog;
