import { Box, Tab, Tabs } from '@mui/material';
import { useUser } from 'contexts/user-context';
import { FC, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import SettingsConfig from './SettingsConfig';
import SettingsVariables from './SettingsVariables';
import SettingsUsage from './SettingsUsage';
import SettingsProduct from './SettingsProduct';
import SettingsIndexSettings from './SettingsIndexSettings';
import SettingsFields from './SettingsFields';
import SettingsConnections from './connections/SettingsConnections';
import SettingsScheduledActions from './scheduled-actions/SettingsScheduledActions';

const PATH_TAB_MAP: Record<string, number> = {
  variables: 1,
  product: 2,
  connections: 3,
  usage: 4,
  'index-settings': 5,
  fields: 6,
  'scheduled-actions': 7,
};
const TAB_PATH_LOOKUP: string[] = [
  '',
  'variables',
  'product',
  'connections',
  'usage',
  'index-settings',
  'fields',
  'scheduled-actions',
];

type Props = {};
const Settings2Main: FC<Props> = () => {
  const { curIndex } = useUser();
  const [tab, setTab] = useState(0);
  const navigate = useNavigate();
  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setTab(newValue);
    const tabPath = TAB_PATH_LOOKUP[newValue];
    navigate(`/settings2/${curIndex!.value}/${tabPath}`);
  };
  const { pathname } = useLocation();
  const paths = pathname.split('/');
  const path = paths[3];
  useEffect(() => {
    if (path && path in PATH_TAB_MAP) {
      setTab(PATH_TAB_MAP[path]);
    } else {
      setTab(0);
    }
  }, [path, setTab]);
  return (
    <Box sx={{ ml: 3, mr: 3, display: 'flex', gap: 2 }}>
      <Tabs
        value={tab}
        onChange={handleChangeTab}
        orientation="vertical"
        sx={{ minWidth: 120 }}
      >
        <Tab label="Index Config" />
        <Tab label="Variables" />
        <Tab label="Product List" />
        <Tab label="Connections" />
        <Tab label="Usage" />
        <Tab label="Index Settings" />
        <Tab label="Ingredients" />
        <Tab label="Scheduled Actions" />
      </Tabs>
      <Box sx={{ width: '100%' }}>
        {tab === 0 && <SettingsConfig />}
        {tab === 1 && <SettingsVariables />}
        {tab === 2 && <SettingsProduct />}
        {tab === 3 && <SettingsConnections />}
        {tab === 4 && <SettingsUsage />}
        {tab === 5 && <SettingsIndexSettings />}
        {tab === 6 && <SettingsFields />}
        {tab === 7 && <SettingsScheduledActions />}
      </Box>
    </Box>
  );
};

export default Settings2Main;
