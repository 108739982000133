import { Box } from '@mui/material';
import OpportunitiesTable from 'components/opportunities/OpportunitiesTable';
import { FC } from 'react';

const OptimizationMain: FC = () => {
  return (
    <Box sx={{ ml: 3, mr: 3, mt: 4 }}>
      <Box sx={{ mt: 4 }}>
        <OpportunitiesTable isOptimization={true} />
      </Box>
    </Box>
  );
};

export default OptimizationMain;
