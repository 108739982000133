import {
  DataGridPro,
  GridColDef,
  GridSortModel,
  useGridApiRef,
} from '@mui/x-data-grid-pro';

import { FC, useCallback } from 'react';
import { ColOrderType } from 'types/types';

import { renderGSCKeyword } from '../../shared/table-cell/GSCKeywordCell';
import { renderUrl } from '../../shared/table-cell/UrlCell';
import { renderStrArr } from '../../shared/table-cell/StrArrCell';
import renderPreviewChatGPT from '../../shared/table-cell/ChatGPTPreviewCell';
import { renderArray } from '../../shared/table-cell/ArrayCell';
import { getNumber } from '../../shared/table-cell';
import { renderVariable } from 'components/shared/table-cell/VariableCell';

export const getInitCols = (): GridColDef[] => {
  return [
    {
      field: 'id',
      headerClassName: 'App-Mui-DataGrid-Header',
    },
    {
      field: 'preview_chatgpt',
      headerClassName: 'App-Mui-DataGrid-Header',
      headerName: 'Preview ChatGPT',
      renderCell: renderPreviewChatGPT,
      width: 400,
    },
    {
      field: '$preview_variable',
      headerClassName: 'App-Mui-DataGrid-Header',
      headerName: 'Preview Variable',
      renderCell: renderVariable,
      width: 300,
    },
    {
      field: 'url',
      headerClassName: 'App-Mui-DataGrid-Header',
      renderCell: renderUrl,
      minWidth: 300,
    },
    {
      field: 'ndd_is_canonical',
      headerName: 'is canonical page',
      headerClassName: 'App-Mui-DataGrid-Header',
      hide: true,
      width: 200,
    },
    {
      field: 'status',
      headerName: 'indexable',
      headerClassName: 'App-Mui-DataGrid-Header',
      valueGetter: (params) =>
        params.row.status?.indexable !== undefined
          ? Number(params.row.status.indexable)
          : '',
    },
    {
      field: 'h1',
      headerClassName: 'App-Mui-DataGrid-Header',
      renderCell: renderArray,
      width: 200,
    },
    {
      field: 'h2',
      headerClassName: 'App-Mui-DataGrid-Header',
      renderCell: renderArray,
      width: 200,
    },
    {
      field: 'title',
      headerClassName: 'App-Mui-DataGrid-Header',
      width: 200,
    },
    {
      field: 'gsc_keywords',
      headerName: 'ranking keywords',
      headerClassName: 'App-Mui-DataGrid-Header',
      width: 400,
      sortable: false,
      renderCell: renderGSCKeyword,
    },
    {
      field: 'n_gsc_keywords',
      headerName: '# ranking keywords',
      headerClassName: 'App-Mui-DataGrid-Header',
      width: 150,
    },
    {
      field: 'top_gsc_keywords',
      headerName: 'top ranking keywords',
      headerClassName: 'App-Mui-DataGrid-Header',
      width: 400,
      sortable: false,
      renderCell: renderGSCKeyword,
    },
    {
      field: 'related_links_incoming',
      headerName: '# inlinks',
      headerClassName: 'App-Mui-DataGrid-Header',
      hide: true,
      width: 80,
    },
    {
      field: 'gsc_best_position',
      headerClassName: 'App-Mui-DataGrid-Header',
      headerName: 'position',
      width: 120,
    },
    {
      field: 'total_traffic',
      headerClassName: 'App-Mui-DataGrid-Header',
      headerName: 'total traffic',
      width: 120,
      valueGetter: getNumber,
    },
    {
      field: 'total_impressions',
      headerClassName: 'App-Mui-DataGrid-Header',
      headerName: 'total impression',
      width: 120,
      valueGetter: getNumber,
    },
    {
      field: 'status_code',
      headerName: 'status code',
      headerClassName: 'App-Mui-DataGrid-Header',
      width: 100,
    },
    {
      field: 'crawled',
      headerName: 'crawled',
      headerClassName: 'App-Mui-DataGrid-Header',
      valueGetter: (params) => new Date(params.value).toLocaleDateString(),
      width: 100,
    },
    {
      field: 'sources',
      headerName: 'source',
      headerClassName: 'App-Mui-DataGrid-Header',
      renderCell: renderArray,
      width: 200,
    },
    {
      field: 'url_path_prefixes',
      hide: true,
      headerName: 'top folders',
      headerClassName: 'App-Mui-DataGrid-Header',
      renderCell: renderArray,
      width: 300,
    },
    {
      field: 'url_parts',
      hide: true,
      headerName: 'url parts',
      headerClassName: 'App-Mui-DataGrid-Header',
      renderCell: renderArray,
      width: 300,
    },
    {
      field: 'url_parts_depth',
      headerName: 'url depth',
      headerClassName: 'App-Mui-DataGrid-Header',
      width: 100,
    },
    {
      field: 'volume',
      headerName: 'volume',
      headerClassName: 'App-Mui-DataGrid-Header',
      width: 80,
      valueGetter: getNumber,
    },
    {
      field: 'ndd_cluster_size',
      headerName: '# duplicates',
      headerClassName: 'App-Mui-DataGrid-Header',
      hide: true,
      width: 80,
      valueGetter: getNumber,
    },
    {
      field: 'top_ranking_domains',
      headerClassName: 'App-Mui-DataGrid-Header',
      headerName: 'top ranking domains',
      hide: true,
      renderCell: renderStrArr,
      minWidth: 300,
    },
    {
      field: 'serp_domain_diversity',
      headerClassName: 'App-Mui-DataGrid-Header',
      headerName: 'serp domain diversity',
      hide: true,
      width: 120,
    },
    {
      field: 'traject_serp',
      headerClassName: 'App-Mui-DataGrid-Header',
      headerName: 'SERP crawled',
      valueGetter: () => '',
      hide: true,
      width: 80,
    },
  ];
};

type Props = {
  data: any;
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  offset: number;
  setOffset: React.Dispatch<React.SetStateAction<number>>;
  setOrder: React.Dispatch<React.SetStateAction<ColOrderType>>;
  isLoading: boolean;
  setColsDisplayOrder: React.Dispatch<
    React.SetStateAction<Record<string, number>>
  >;
  setColsWidth: React.Dispatch<React.SetStateAction<Record<string, number>>>;
  columns: GridColDef[];
};

const WBPageTable: FC<Props> = ({
  data,
  page,
  setPage,
  offset,
  setOffset,
  setOrder,
  isLoading,
  setColsDisplayOrder,
  setColsWidth,
  columns,
}) => {
  const handleSorting = useCallback(
    (sortModel: GridSortModel) => {
      const _sort = sortModel[0];
      if (!_sort) return;
      setOrder({
        name: _sort.field,
        direction: _sort.sort as 'asc' | 'desc',
      });
    },
    [setOrder]
  );

  const apiRef = useGridApiRef();

  return (
    <DataGridPro
      apiRef={apiRef}
      onColumnOrderChange={() => {
        const cols = apiRef.current.getAllColumns().map((item) => item.field);
        const colsDisOrder: Record<string, number> = {};
        cols.forEach((col, idx) => {
          colsDisOrder[col] = idx;
        });
        setColsDisplayOrder(colsDisOrder);
      }}
      onColumnWidthChange={(params) => {
        setColsWidth((pre) => ({
          ...pre,
          [params.colDef.field]: params.width,
        }));
      }}
      loading={isLoading}
      pagination
      autoHeight
      getRowHeight={() => 'auto'}
      columns={columns}
      rows={data?.items}
      paginationMode="server"
      sortingMode="server"
      rowCount={data?.count || 0}
      pageSize={offset}
      onPageSizeChange={setOffset}
      page={page}
      onPageChange={setPage}
      rowsPerPageOptions={[10, 25, 100]}
      onSortModelChange={handleSorting}
      disableDensitySelector
      disableColumnFilter
      sx={{
        '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': {
          py: '12px',
          borderBottomColor: '#eee',
        },
        '&.MuiDataGrid-root--densityStandard .MuiDataGrid-columnSeparator': {
          color: '#ccc',
        },
        display: 'flex',
        flexDirection: 'column-reverse',
      }}
    />
  );
};
export default WBPageTable;
