import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Box,
  TextField,
  MenuItem,
  ListItem,
  ListItemButton,
  ListItemIcon,
  Checkbox,
  ListItemText,
  List,
  Typography,
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { FC, useEffect, useState } from 'react';
import InfoIcon from '@mui/icons-material/Info';
import { PageIngredientUrlType } from 'types/types';
import { useQuery } from '@tanstack/react-query';
import { useUser } from 'contexts/user-context';
import { convertFileName } from 'utils';
import {
  getBotifyCrawls,
  postPageIngredientParseSitemap,
} from 'apis/page_ingredient';
import PIUrlFilter from './PIUrlFilter';
import { convertQueryBuilderTree } from 'utils/utils-query-builder';
import { useSnackbar } from 'contexts/snackbar-context';
import LoadingSpinner from 'components/shared/ui/LoadingSpinner';

type UploadedDocItemProps = {
  doc: any;
  curUploadedDoc: any;
  setCurUploadedDoc: React.Dispatch<any>;
};

export type BotifyCrawlItemType = {
  id: number;
  exported_file_url: string;
  last_analysis_name: string;
  index: number;
  project: number;
  is_active: boolean;
};

export const UploadedDocItem: FC<UploadedDocItemProps> = ({
  doc,
  curUploadedDoc,
  setCurUploadedDoc,
}) => {
  let checked = false;
  if (curUploadedDoc) {
    checked = doc.id === curUploadedDoc.id;
  }
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const marked = event.target.checked;
    if (marked) {
      setCurUploadedDoc(doc);
    }
  };
  let fileName = convertFileName(doc.filename);
  return (
    <ListItem>
      <ListItemButton>
        <ListItemIcon>
          <Checkbox edge="start" checked={checked} onChange={handleChange} />
        </ListItemIcon>
        <ListItemText primary={fileName || doc.file} />
      </ListItemButton>
    </ListItem>
  );
};

type BotifyCrawlProps = {
  item: BotifyCrawlItemType;
  curItem?: BotifyCrawlItemType;
  setCurItem: React.Dispatch<BotifyCrawlItemType>;
};
const BotifyCrawlItem: FC<BotifyCrawlProps> = ({
  item,
  curItem,
  setCurItem,
}) => {
  let checked = false;
  if (curItem) {
    checked = curItem.id === item.id;
  }
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const marked = event.target.checked;
    if (marked) {
      setCurItem(item);
    }
  };
  return (
    <ListItem>
      <ListItemButton>
        <ListItemIcon>
          <Checkbox edge="start" checked={checked} onChange={handleChange} />
        </ListItemIcon>
        <ListItemText primary={item.last_analysis_name} />
      </ListItemButton>
    </ListItem>
  );
};

type SubSiteItemProps = {
  item: string;
  selectedItems: string[];
  setSelectedItems: React.Dispatch<string[]>;
};
const SubSiteItem: FC<SubSiteItemProps> = ({
  item,
  selectedItems,
  setSelectedItems,
}) => {
  const checked = selectedItems.indexOf(item) !== -1;
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const marked = event.target.checked;
    let newItems = selectedItems.slice();
    if (marked) {
      if (selectedItems.indexOf(item) === -1) {
        newItems.push(item);
        setSelectedItems(newItems);
      }
    } else {
      newItems = newItems.filter((_item) => _item !== item);
      setSelectedItems(newItems);
    }
  };
  return (
    <ListItem sx={{ overflowWrap: 'break-word', width: '100%' }}>
      <ListItemButton>
        <ListItemIcon>
          <Checkbox edge="start" checked={checked} onChange={handleChange} />
        </ListItemIcon>
        <ListItemText primary={item} />
      </ListItemButton>
    </ListItem>
  );
};

type Props = {
  open: boolean;
  onClose: () => void;
  onSave: (params: {
    urlType: PageIngredientUrlType;
    sitemap_urls: string[];
    url: string;
    disabled: boolean;
    item?: any;
    urlFilter: any;
  }) => void;
};

type FormData = {
  url: string;
};

const PICreateDialog: FC<Props> = ({ open, onClose, onSave }) => {
  const { curIndex } = useUser();
  const { setSnackbar } = useSnackbar();
  const { handleSubmit, control, getValues } = useForm<FormData>();

  const [urlType, setUrlType] = useState<PageIngredientUrlType>('st');
  const [botifyItem, setBotifyItem] = useState<BotifyCrawlItemType>();
  const [subSites, setSubSites] = useState<string[]>([]);
  const [isFetchingSubSites, setIsFetchingSubSites] = useState(false);
  const [selectedSubSites, setSelectedSubSites] = useState<string[]>([]);

  let { data: botifyData, isLoading: isLoadingBotify } = useQuery(
    [curIndex, 'botifyCrawls'],
    () => getBotifyCrawls(curIndex!.value),
    { enabled: Boolean(curIndex) }
  );

  botifyData = botifyData?.items || [];

  const onSubmit = (data: FormData) => {
    let { url } = data;
    let item;
    if (urlType === 'bt') {
      url = botifyItem!.exported_file_url;
    }
    onSave({
      urlType,
      url,
      disabled: false,
      item,
      urlFilter,
      sitemap_urls: selectedSubSites,
    });
  };

  const showUrlInput = ['st', 'rg'].includes(urlType);

  useEffect(() => {
    if (botifyData && botifyData.length > 0) {
      setBotifyItem(botifyData[0]);
    }
  }, [botifyData]);

  const [urlFilter, setUrlFilter] = useState<any>('');

  const handleUrlFilterChange = (jsonTree: any) => {
    const _filter = convertQueryBuilderTree(jsonTree);
    setUrlFilter(_filter);
  };

  const handleClickFetchSubSites = async () => {
    let data: any;
    try {
      setIsFetchingSubSites(true);
      data = await postPageIngredientParseSitemap(curIndex!.value, {
        url: getValues('url'),
      });
      setIsFetchingSubSites(false);
    } catch (err: any) {
      setIsFetchingSubSites(false);
      if (err?.response?.status === 400) {
        const msg = err.response.data?.errMsg;
        setSnackbar({
          severity: 'error',
          message: msg || 'Fetch sub sitemaps failed',
        });
      }
    }
    if (data && Array.isArray(data)) {
      setSubSites(data);
    }
  };

  const handleClickMarkAllSiteMaps = () => {
    setSelectedSubSites(subSites);
  };

  const [urlFilterArray] = useState(['bt', 'st', 'jo']);

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <Box component="form" onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <InfoIcon color="info" /> Create Page Ingredient
        </DialogTitle>
        <DialogContent>
          <TextField
            value={urlType}
            onChange={(e) =>
              setUrlType(e.target.value as PageIngredientUrlType)
            }
            select
            margin="dense"
            label="Source"
            fullWidth
            variant="standard"
          >
            <MenuItem value={'st'}>Sitemap</MenuItem>
            <MenuItem value={'bt'}>Botify</MenuItem>
          </TextField>
          {urlType === 'bt' && (
            <List>
              {isLoadingBotify && <LoadingSpinner />}
              {!isLoadingBotify && botifyData?.length === 0 && (
                <Typography
                  sx={{ my: 2 }}
                  variant="body2"
                  color="text.secondary"
                >
                  no crawl found
                </Typography>
              )}
              {botifyData &&
                botifyData.map((item: BotifyCrawlItemType) => (
                  <BotifyCrawlItem
                    item={item}
                    key={item.id}
                    curItem={botifyItem}
                    setCurItem={setBotifyItem}
                  />
                ))}
            </List>
          )}

          {showUrlInput && (
            <Controller
              name="url"
              defaultValue=""
              control={control}
              render={({ field }) => (
                <TextField
                  margin="dense"
                  label="Url"
                  fullWidth
                  variant="standard"
                  {...field}
                />
              )}
            />
          )}
          {urlType === 'st' && (
            <Box sx={{ my: 2 }}>
              <Button
                variant="outlined"
                onClick={handleClickFetchSubSites}
                disabled={isFetchingSubSites}
              >
                {isFetchingSubSites ? 'Fetching...' : 'Fetch sub sitemaps'}
              </Button>
              <List>
                <Box>
                  <Button onClick={handleClickMarkAllSiteMaps}>Mark all</Button>
                </Box>
                {subSites.map((item: string) => (
                  <SubSiteItem
                    item={item}
                    key={item}
                    selectedItems={selectedSubSites}
                    setSelectedItems={setSelectedSubSites}
                  />
                ))}
              </List>
            </Box>
          )}
          {urlFilterArray.indexOf(urlType) > -1 && (
            <PIUrlFilter onQBChange={handleUrlFilterChange} />
          )}
        </DialogContent>
        <DialogActions>
          <Button color="inherit" onClick={onClose}>
            Cancel
          </Button>
          <Button variant="contained" type="submit">
            Save
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default PICreateDialog;
