import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Box,
} from '@mui/material';
import { FunctionComponent } from 'react';
import InfoIcon from '@mui/icons-material/Info';
import { WBSourceType } from 'types/types';
import { SourceFields } from 'components/settings2/SettingsFields';
import { useQuery } from '@tanstack/react-query';
import { useUser } from 'contexts/user-context';
import LoadingSpinner from 'components/shared/ui/LoadingSpinner';
import { getIndexFields } from 'apis/index_fields';

type Props = {
  open: boolean;
  source: WBSourceType;
  onClose: () => void;
};
const FieldSetupDlg: FunctionComponent<Props> = ({ open, onClose, source }) => {
  const { curIndex } = useUser();
  let { data, isLoading } = useQuery(
    [curIndex, 'IndexFields'],
    () => getIndexFields(curIndex!.value),
    { enabled: Boolean(curIndex), staleTime: 60 * 1000 }
  );

  let groups = null;
  if (data?.sources) {
    groups = data.sources[source].groups;
  }
  return (
    <Dialog open={open} onClose={onClose} fullScreen sx={{ m: 8 }}>
      <DialogTitle sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        <InfoIcon color="info" /> Ingredients Settings
      </DialogTitle>
      <DialogContent>
        <Box>
          {isLoading && <LoadingSpinner />}
          {!isLoading && groups && (
            <SourceFields label={source} source={source} groups={groups} />
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button color="inherit" onClick={onClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default FieldSetupDlg;
