import {
  Box,
  Button,
  Divider,
  IconButton,
  Paper,
  Tooltip,
  Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { FC, useEffect, useState } from 'react';
import { LabelGroupItemType } from 'types/types';

import CreateLabelDialog, {
  LabelClassiferType,
} from './dialog/CreateLabelDialog';
import { useSnackbar } from '../../../../contexts/snackbar-context';
import {
  deleteLabel,
  postCreateLabel,
  postGetPagedLabels,
} from '../../../../apis/index_labels';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useUser } from 'contexts/user-context';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import EditLabelDialog from './dialog/EditLabelDialog';

type LabelItemProps = {
  item: LabelGroupItemType;
  setItems: React.Dispatch<React.SetStateAction<LabelGroupItemType[]>>;
};

const LabelItem: FC<LabelItemProps> = ({ item, setItems }) => {
  const queryClient = useQueryClient();
  const { curIndex } = useUser();
  const [isEditLabelDlgOpen, setIsEditLabelDlgOpen] = useState(false);
  const handleClickEdit = () => {
    setIsEditLabelDlgOpen(true);
  };

  const handleClickDelete = async (id: string | number) => {
    await deleteLabel(curIndex!.value, id);
    setItems((pre) => pre.filter((item) => +item.id !== +id));
    queryClient.invalidateQueries([curIndex, 'LabelGroups']);
  };
  return (
    <Box
      sx={{
        my: 0.2,
        p: 0.2,
        ':hover': { backgroundColor: '#f4f4f4' },
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      {isEditLabelDlgOpen && (
        <EditLabelDialog
          open={isEditLabelDlgOpen}
          onClose={() => setIsEditLabelDlgOpen(false)}
          label={item}
          setItems={setItems}
        />
      )}
      <Typography>{item.name}</Typography>
      <Box>
        <Tooltip title="Edit Label">
          <IconButton color="info" onClick={handleClickEdit}>
            <EditIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Delete Label">
          <IconButton
            color="error"
            onClick={handleClickDelete.bind(null, item.id)}
          >
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      </Box>
    </Box>
  );
};

type LabelItemsProps = {
  parentItem: LabelGroupItemType;
};

const pageSize = 10;

const LabelItems: FC<LabelItemsProps> = ({ parentItem }) => {
  const { curIndex } = useUser();
  const [items, setItems] = useState<LabelGroupItemType[]>([]);
  const showLoadMoreBtn =
    items.length > 0 && parentItem.children_count! > items.length;
  const handleClickLoad = async () => {
    const page = Math.floor(items.length / pageSize);
    const data = await postGetPagedLabels(curIndex!.value, {
      page,
      pageSize,
      rootId: parentItem.id,
    });

    setItems((pre) => {
      const ret = [...pre];
      const preIdSet = new Set<number>();
      for (const item of items) {
        preIdSet.add(+item.id);
      }
      for (const item of data.items) {
        if (preIdSet.has(item.id)) continue;
        ret.push(item);
      }
      return ret;
    });
  };
  const { data: initData } = useQuery(
    [curIndex, 'LabelGroups', parentItem.id, 0, 10],
    () =>
      postGetPagedLabels(curIndex!.value, {
        page: 0,
        pageSize: 10,
        rootId: parentItem.id,
      }),
    {
      enabled:
        Boolean(curIndex) &&
        items.length === 0 &&
        parentItem.children_count! !== 0,
    }
  );

  useEffect(() => {
    if (initData && items.length === 0) {
      setItems(initData.items);
    }
  }, [initData, items]);

  return (
    <Box>
      <Box>
        {items.map((item) => (
          <LabelItem item={item} key={item.id} setItems={setItems} />
        ))}
      </Box>
      {showLoadMoreBtn && (
        <Button
          endIcon={<ExpandMoreIcon />}
          sx={{ ml: 4 }}
          onClick={handleClickLoad}
        >
          Load More
        </Button>
      )}
    </Box>
  );
};

type Props = {
  labelGroupItem: LabelGroupItemType;
};

const LabelGroup: FC<Props> = ({ labelGroupItem }) => {
  const { setSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const { curIndex } = useUser();

  const [isCreateLabelDialogOpen, setIsCreateLabelDialogOpen] = useState(false);
  const [canonicalLabelId, setCanonicalLabelId] = useState<string | null>(null);
  const handleCreateLabelDialogOpen = (canonicalLabel: string | null) => {
    setIsCreateLabelDialogOpen(true);
    setCanonicalLabelId(canonicalLabel);
  };
  const handleCreateLabelDialogClose = () => {
    setIsCreateLabelDialogOpen(false);
  };

  const handleCreateLabelDialogSave = async (
    index: string,
    name: string,
    type: string,
    canonical_label: string | null,
    selectedFile: any,
    label_classifier_type: LabelClassiferType | null,
    labelColumn: string | undefined,
    synonymColumn: string | undefined
  ) => {
    setIsCreateLabelDialogOpen(false);
    try {
      await postCreateLabel({
        index,
        name,
        type,
        canonical_label_id: canonicalLabelId,
        selectedFile,
        label_classifier_type,
        labelColumn,
        synonymColumn,
      });
      queryClient.invalidateQueries([curIndex, 'LabelGroups']);
      setSnackbar({ severity: 'success', message: 'Created!' });
    } catch (err) {
      setSnackbar({ severity: 'error', message: 'Create label failed.' });
    }
  };

  const [isEditLabelDlgOpen, setIsEditLabelDlgOpen] = useState(false);
  const handleClickEdit = () => {
    setIsEditLabelDlgOpen(true);
  };

  const handleClickDelete = async (id: string | number) => {
    await deleteLabel(curIndex!.value, id);
    queryClient.invalidateQueries([curIndex, 'LabelGroups']);
  };

  return (
    <>
      {isCreateLabelDialogOpen && (
        <CreateLabelDialog
          labelGroupItem={labelGroupItem}
          open={isCreateLabelDialogOpen}
          onClose={handleCreateLabelDialogClose}
          onSave={handleCreateLabelDialogSave}
        />
      )}
      {isEditLabelDlgOpen && (
        <EditLabelDialog
          open={isEditLabelDlgOpen}
          onClose={() => setIsEditLabelDlgOpen(false)}
          label={labelGroupItem}
        />
      )}
      <Paper
        elevation={3}
        sx={{
          p: 2,
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          width: 400,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Typography variant="h5" sx={{ lineHeight: 1.6 }}>
            {labelGroupItem.id} - {labelGroupItem.name} #children:{' '}
            {labelGroupItem.children_count}
          </Typography>
          <Box>
            <Tooltip title="Add Label">
              <IconButton
                color="info"
                onClick={handleCreateLabelDialogOpen.bind(
                  null,
                  labelGroupItem.id
                )}
              >
                <AddIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Edit Label">
              <IconButton color="info" onClick={handleClickEdit}>
                <EditIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Delete Label">
              <IconButton
                color="error"
                onClick={handleClickDelete.bind(null, labelGroupItem.id)}
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </Box>
        </Box>
        <Divider />
        <Box>
          <LabelItems parentItem={labelGroupItem} />
        </Box>
      </Paper>
    </>
  );
};

export default LabelGroup;
