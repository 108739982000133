import { Box, IconButton, Menu, MenuItem } from '@mui/material';
import { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { deleteOpportunityItem } from 'apis/opportunities';
import { useUser } from 'contexts/user-context';
import { useSnackbar } from 'contexts/snackbar-context';
import OpporDelDlg from './OpporDelDlg';

type MoreMenuBtnProps = {
  oppId: number | string;
};

const MoreMenuBtn: FC<MoreMenuBtnProps> = ({ oppId }) => {
  const { setSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const { curIndex } = useUser();

  const { mutate: m_delete } = useMutation({
    mutationFn: () => deleteOpportunityItem(curIndex!.value, oppId),
    onSuccess: () => {
      queryClient.invalidateQueries([curIndex, 'Opportunities']);
      setSnackbar({ severity: 'success', message: 'Deleted!' });
    },
  });

  const navigate = useNavigate();

  const handleClickView = () => {
    navigate(`${oppId}`);
    setAnchor(null);
  };
  const [anchor, setAnchor] = useState<null | HTMLElement>(null);
  const menuOpen = Boolean(anchor);
  const handleOpenMenu = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setAnchor(e.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchor(null);
  };

  const handleClickDelete = async () => {
    setDelDlgOpen(true);
  };

  const handleDelete = async () => {
    m_delete();
    setAnchor(null);
  };

  const handleClickLinkingResult = () => {
    navigate(`${oppId}/linking-verification-result`);
    setAnchor(null);
  };

  const [delDlgOpen, setDelDlgOpen] = useState(false);

  return (
    <Box>
      {delDlgOpen && (
        <OpporDelDlg
          opporId={oppId}
          open={delDlgOpen}
          onClose={() => setDelDlgOpen(false)}
          onApply={handleDelete}
        />
      )}
      <IconButton onClick={handleOpenMenu}>
        <MoreHorizIcon />
      </IconButton>
      <Menu
        anchorEl={anchor}
        open={menuOpen}
        onClose={handleCloseMenu}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem sx={{ minWidth: 148 }} onClick={handleClickView}>
          View
        </MenuItem>
        <MenuItem onClick={handleClickDelete}>Delete</MenuItem>
        <MenuItem onClick={handleClickLinkingResult}>
          Linking verification result
        </MenuItem>
      </Menu>
    </Box>
  );
};

export default MoreMenuBtn;
