export const getIndexExportJobs = async (index: string | number) => {
  const axiosInstance = require('utils/utils-axios-instance').default;
  const data = await axiosInstance.get(`index/${index}/api/all_exports/`);
  return data.data;
};

export const postIndexExportJob = async (index: string | number, body: any) => {
  const axiosInstance = require('utils/utils-axios-instance').default;
  const data = await axiosInstance.post(`index/${index}/api/export/`, body);
  return data.data;
};

export const postExportOpportunityJob = async (
  index: string | number,
  body: any
) => {
  const axiosInstance = require('utils/utils-axios-instance').default;
  const data = await axiosInstance.post(
    `index/${index}/api/export_opportunity/`,
    body
  );
  return data.data;
};

export const postExportPageSegmentUrlsJob = async (
  index: string | number,
  body: any
) => {
  const axiosInstance = require('utils/utils-axios-instance').default;
  const data = await axiosInstance.post(
    `index/${index}/api/export_page_segment_urls/`,
    body
  );
  return data.data;
};
