import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormGroup,
  MenuItem,
  Paper,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  getWorkbenchPageVariable,
  updateWorkbenchPageVariable,
} from 'apis/workbench';
import LoadingSpinner from 'components/shared/ui/LoadingSpinner';
import { useUser } from 'contexts/user-context';
import { FC, useState } from 'react';
import { addRecipeStrAndOpField2Recipes } from 'utils/utils-query-builder';
import { InitialConfig, recipesB2F } from 'components/workbench/WBQBRecipe';
import DelVarDlg from './dialog/DelVarDlg';
import useQBFields from 'hooks/useQBFields';

type ItemProps = {
  id: number;
  name: string;
  enabled: boolean;
  recipeStr: string | undefined;
  type: string;
  operationField: string | undefined;
  initPriority: number;
};

const Item: FC<ItemProps> = ({
  id,
  name,
  enabled,
  recipeStr,
  type,
  operationField,
  initPriority,
}) => {
  const queryClient = useQueryClient();
  const { curIndex } = useUser();

  const { mutate } = useMutation({
    mutationFn: (body: any) =>
      updateWorkbenchPageVariable(curIndex!.value, id, body),
    onSuccess: () => {
      queryClient.invalidateQueries([curIndex, 'WBPagesVariables']);
    },
  });

  const handleChangeEnable = () => {
    mutate({ enabled: !enabled });
  };
  const handleChangeType = (e: React.ChangeEvent<HTMLInputElement>) => {
    const type = e.target.value;
    mutate({ type });
  };

  const [delDlgOpen, setDelDlgOpen] = useState(false);
  const [priority, setPriority] = useState(initPriority);

  const handleBlurPriority = () => {
    mutate({ priority });
  };

  return (
    <Box>
      <DelVarDlg
        open={delDlgOpen}
        onClose={() => setDelDlgOpen(false)}
        varId={id}
        varName={name}
      />
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <FormControlLabel
          control={
            <Switch
              checked={enabled}
              onChange={handleChangeEnable}
              name={name}
            />
          }
          label={id + ' - ' + name}
        />
        {operationField && <Typography>{`(${operationField})`}</Typography>}
        <Box sx={{ ml: 2, display: 'flex', alignItems: 'center', gap: 2 }}>
          <TextField
            size="small"
            select
            value={type}
            onChange={handleChangeType}
            sx={{ minWidth: 80 }}
          >
            <MenuItem value="text">text</MenuItem>
            <MenuItem value="number">number</MenuItem>
            <MenuItem value="boolean">boolean</MenuItem>
            <MenuItem value="date">datetime</MenuItem>
            <MenuItem value="enum">enum</MenuItem>
          </TextField>
          <TextField
            size="small"
            value={priority}
            label={'Priority'}
            type="number"
            onChange={(e) => setPriority(+e.target.value)}
            onBlur={handleBlurPriority}
            sx={{ width: 80 }}
          />
        </Box>
        <Button color="error" onClick={() => setDelDlgOpen(true)}>
          Delete
        </Button>
      </Box>
      <Typography variant="caption" color="text.secondary">
        {recipeStr}
      </Typography>
    </Box>
  );
};

const SettingsVariables: FC = () => {
  const { curIndex } = useUser();
  let { data, isLoading } = useQuery(
    [curIndex, 'WBPagesVariables', 'all'],
    () => getWorkbenchPageVariable(curIndex!.value, 'all'),
    { enabled: Boolean(curIndex) }
  );
  const { fields } = useQBFields({
    source: 'AllFieldsWithoutGroup',
  });

  const config: any = {
    ...InitialConfig,
    fields,
  };

  data = addRecipeStrAndOpField2Recipes(recipesB2F(data), config);
  const varKeywords = data.filter((item: any) => item.source === 'keyword');
  const varPages = data.filter((item: any) => item.source === 'page');
  const varTopics = data.filter((item: any) => item.source === 'topic');
  const varSites = data.filter((item: any) => item.source === 'site');
  const recordsMap = {
    'Keyword Variables': varKeywords,
    'Page Variables': varPages,
    'Topic Variables': varTopics,
    'Site Variables': varSites,
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      {Object.entries(recordsMap).map(([key, value]) => (
        <Paper sx={{ p: 2 }} key={key}>
          <Typography variant="h4">{key}</Typography>
          <FormControl
            component="fieldset"
            variant="standard"
            sx={{ mt: 3, width: '100%' }}
          >
            <FormGroup>
              <Box
                sx={{
                  display: 'grid',
                  gridTemplateColumns: '1fr 1fr',
                  gap: 4,
                  width: '100%',
                }}
              >
                {isLoading && <LoadingSpinner />}
                {!isLoading &&
                  value.map((item: any) => (
                    <Item
                      key={item.id}
                      name={item.name}
                      id={item.id}
                      enabled={item.enabled}
                      recipeStr={item.recipeStr}
                      operationField={item.operationField}
                      type={item.type}
                      initPriority={item.priority}
                    />
                  ))}
                {!isLoading && value.length === 0 && (
                  <Typography>No variable yet</Typography>
                )}
              </Box>
            </FormGroup>
          </FormControl>
        </Paper>
      ))}
    </Box>
  );
};

export default SettingsVariables;
