import Layout from 'components/layout/Layout';
import SiteTopicMain from 'components/site-topic/SiteTopicMain';
import { FC } from 'react';

const SiteTopicPage: FC = () => {
  return (
    <Layout>
      <SiteTopicMain />
    </Layout>
  );
};

export default SiteTopicPage;
