import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';

import React, { FC, useEffect, useState } from 'react';
import { useSnackbar } from 'contexts/snackbar-context';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useUser } from 'contexts/user-context';
import { OpportunityType, OptionType, WBSourceType } from 'types/types';
import {
  WidgetItemAggType,
  WidgetItemFieldType,
  WidgetItemGraphType,
  WidgetItemType,
} from './WidgetItem';
import useQBFields from 'hooks/useQBFields';
import { getTemplates } from 'apis/index_template';
import LoadingSpinner from 'components/shared/ui/LoadingSpinner';
import { InitialConfig } from 'components/workbench/WBQBRecipe';
import { addSingleRecipeStrFromRecipeBak } from 'utils/utils-query-builder';
import { patchWidget, postWidget } from 'apis/widgets';
import { getFieldsFromGroups } from 'utils';

type openFrom = {
  button: 'AddSummary' | 'Edit' | 'AddChart';
  source: WBSourceType;
};

type Props = {
  widget?: WidgetItemType;
  openFrom?: openFrom;
  opporId?: number;
  opporType?: OpportunityType;
  version?: string;
  open: boolean;
  onClose: () => void;
};

const AddEditWidgetDlg: FC<Props> = ({
  widget,
  open,
  onClose,
  openFrom,
  opporId,
  opporType,
  version,
}) => {
  let linkingOpporId: number | undefined = undefined;
  if (opporType === 'linking') linkingOpporId = opporId!;
  const isEdit = Boolean(widget);
  const queryClient = useQueryClient();
  const [name, setName] = useState('');
  const [field, setField] = useState('');
  const [by_field, setBy_field] = useState('');
  const [cutoff, setCutoff] = useState<number>();

  const initSource = openFrom ? openFrom.source : 'page';
  const [source, setSource] = useState<WBSourceType>(initSource);

  const [recipe, setRecipe] = useState<any>();
  const [field_type, setField_type] =
    useState<WidgetItemFieldType>('categorical');
  const [aggregation, setAggreation] =
    useState<WidgetItemAggType>('value_count');
  const initGraph = openFrom?.button === 'AddSummary' ? 'value' : 'histogram';
  const [graph, setGraph] = useState<WidgetItemGraphType>(initGraph);
  const { curIndex } = useUser();
  const { setSnackbar } = useSnackbar();

  const { fields, isLoadingFields } = useQBFields({ source });
  const fieldItems: any[] = getFieldsFromGroups(fields);

  let { data: recipesBack, isLoading: isLoadingRecipe } = useQuery(
    [curIndex, 'WBQBRecipe', source, linkingOpporId],
    () => getTemplates(curIndex!.value, source, linkingOpporId),
    { enabled: Boolean(curIndex) }
  );
  const recipeItems: (OptionType & { recipe_str: string })[] = [
    { value: '', label: '--EMPTY--', recipe_str: '' },
  ];

  const config: any = {
    ...InitialConfig,
    fields,
  };

  if (recipesBack) {
    for (let item of recipesBack) {
      item = addSingleRecipeStrFromRecipeBak(item, config);
      recipeItems.push({
        label: item.name,
        value: item.id.toString(),
        recipe_str: item.recipe_str,
      });
    }
  }
  const recipeStr = recipeItems.find(
    (item) => item.value === recipe
  )?.recipe_str;

  const submitHandler = async (e: React.FormEvent) => {
    e.preventDefault();
    onClose();
    const body = {
      name,
      source,
      field,
      by_field,
      field_type,
      aggregation,
      graph,
      cutoff,
      opporId,
      version,
      template: recipe || undefined,
    };
    if (!isEdit) {
      await postWidget(curIndex!.value, body);
      queryClient.invalidateQueries([curIndex, 'Widget']);
      setSnackbar({ severity: 'success', message: 'Added' });
    } else {
      await patchWidget(curIndex!.value, widget!.id, body);
      queryClient.invalidateQueries([curIndex, 'Widget']);
      queryClient.invalidateQueries([curIndex, 'Widget', widget!.id]);
      setSnackbar({ severity: 'success', message: 'Updated' });
    }
  };

  const dlgTitle = isEdit ? 'Edit widget' : 'Create a widget';

  useEffect(() => {
    if (widget) {
      setName(widget.name);
      setSource(widget.source);
      setField(widget.field);
      if (widget.by_field) {
        setBy_field(widget.by_field);
      }
      if (widget.cutoff) {
        setCutoff(widget.cutoff);
      }
      setField_type(widget.field_type);
      setAggreation(widget.aggregation);
      setGraph(widget.graph);
      setRecipe(widget.template);
    }
  }, [widget]);

  return (
    <Dialog open={open} onClose={onClose}>
      <Box component="form" onSubmit={submitHandler}>
        <DialogTitle>{dlgTitle}</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            autoComplete="off"
            margin="dense"
            label="Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            fullWidth
            variant="standard"
          />
          <TextField
            select
            margin="dense"
            label="Source"
            value={source}
            disabled={Boolean(openFrom)}
            onChange={(e) => setSource(e.target.value as WBSourceType)}
            fullWidth
            variant="standard"
          >
            <MenuItem value="keyword">keyword</MenuItem>
            <MenuItem value="page">page</MenuItem>
            <MenuItem value="topic">topic</MenuItem>
            <MenuItem value="site">site</MenuItem>
          </TextField>
          {isLoadingFields && <LoadingSpinner />}
          {!isLoadingFields && (
            <TextField
              select
              margin="dense"
              label="Field"
              value={field}
              onChange={(e) => setField(e.target.value)}
              fullWidth
              variant="standard"
            >
              {fieldItems.map((item) => (
                <MenuItem key={item.value} value={item.value}>
                  {item.label}
                </MenuItem>
              ))}
            </TextField>
          )}
          {isLoadingFields && <LoadingSpinner />}
          {!isLoadingFields && (
            <TextField
              select
              margin="dense"
              label="By Field"
              value={by_field}
              onChange={(e) => setBy_field(e.target.value)}
              fullWidth
              variant="standard"
            >
              {fieldItems.map((item) => (
                <MenuItem key={item.value} value={item.value}>
                  {item.label}
                </MenuItem>
              ))}
            </TextField>
          )}
          <TextField
            select
            margin="dense"
            label="Field Type"
            value={field_type}
            onChange={(e) =>
              setField_type(e.target.value as WidgetItemFieldType)
            }
            fullWidth
            variant="standard"
          >
            <MenuItem value="continuous">continuous</MenuItem>
            <MenuItem value="less_than_1">less_than_1</MenuItem>
            <MenuItem value="categorical">categorical</MenuItem>
          </TextField>
          <TextField
            select
            margin="dense"
            label="Aggregation"
            value={aggregation}
            onChange={(e) => setAggreation(e.target.value as WidgetItemAggType)}
            fullWidth
            variant="standard"
          >
            <MenuItem value="sum">sum</MenuItem>
            <MenuItem value="avg">avg</MenuItem>
            <MenuItem value="min">min</MenuItem>
            <MenuItem value="max">max</MenuItem>
            <MenuItem value="value_count">value_count</MenuItem>
            <MenuItem value="cardinality">cardinality</MenuItem>
          </TextField>
          <TextField
            select
            margin="dense"
            label="Graph"
            value={graph}
            disabled={Boolean(openFrom)}
            onChange={(e) => setGraph(e.target.value as WidgetItemGraphType)}
            fullWidth
            variant="standard"
          >
            <MenuItem value="histogram">histogram</MenuItem>
            <MenuItem value="pie_chart">pie_chart</MenuItem>
            <MenuItem value="value">value</MenuItem>
          </TextField>
          <TextField
            margin="dense"
            label="Cutoff"
            type="number"
            value={cutoff}
            onChange={(e) => setCutoff(+e.target.value)}
            fullWidth
            variant="standard"
          />
          {isLoadingRecipe && <LoadingSpinner />}
          {!isLoadingRecipe && (
            <TextField
              select
              margin="dense"
              label="Recipe"
              value={recipe || ''}
              onChange={(e) => setRecipe(e.target.value)}
              fullWidth
              variant="standard"
            >
              {recipeItems.map((item) => (
                <MenuItem key={item.value} value={item.value}>
                  {item.label}
                </MenuItem>
              ))}
            </TextField>
          )}

          {recipeStr && (
            <Typography variant="caption" color="body2">
              {recipeStr}
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button type="submit">Save</Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default AddEditWidgetDlg;
