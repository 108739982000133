import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Link,
  MenuItem,
  Paper,
  TextField,
  Typography,
} from '@mui/material';
import { DataGridPro, GridColDef, GridToolbar } from '@mui/x-data-grid-pro';
import { FC, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { getJobs } from 'apis/settings';
import MoreMenuBtn from './MoreMenuBtn';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { dateComparator } from 'utils';

const BACKEND_URL = process.env.REACT_APP_BACKEND_BASE;

const ErrorStackMsgCell: FC<{ errMsg: string }> = ({ errMsg }) => {
  if (!errMsg || errMsg.length < 20) return <Typography>{errMsg}</Typography>;
  return (
    <Accordion sx={{ width: '100%', boxShadow: 'none' }}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography color="error">Show Error</Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ overflowWrap: 'break-word', width: '100%' }}>
        <Typography variant="caption" fontWeight={500}>
          {errMsg}
        </Typography>
      </AccordionDetails>
    </Accordion>
  );
};

const columns: GridColDef[] = [
  {
    field: 'id',
    headerClassName: 'App-Mui-DataGrid-Header',
  },
  {
    field: 'rq_job_id',
    headerName: 'rq job id',
    headerClassName: 'App-Mui-DataGrid-Header',
    minWidth: 200,
    renderCell(params) {
      const showLink = params.row.show_log_link;
      if (showLink) {
        return (
          <Link
            href={`${BACKEND_URL}index/${params.row.indexId}/jobs/log?job_id=${params.value}`}
            target="_blank"
          >
            {params.value}
          </Link>
        );
      } else {
        return <Typography variant="caption">{params.value}</Typography>;
      }
    },
  },
  {
    field: 'type',
    headerClassName: 'App-Mui-DataGrid-Header',
    width: 120,
    valueGetter: (params) => params.value.replace('_', ' '),
  },
  {
    field: 'created',
    headerClassName: 'App-Mui-DataGrid-Header',
    width: 180,
    valueGetter: (params) => new Date(params.value).toLocaleString(),
    sortComparator: dateComparator,
  },
  {
    field: 'updated',
    headerClassName: 'App-Mui-DataGrid-Header',
    width: 180,
    valueGetter: (params) => new Date(params.value).toLocaleString(),
    sortComparator: dateComparator,
  },
  {
    field: 'duration',
    headerClassName: 'App-Mui-DataGrid-Header',
    width: 120,
  },
  {
    field: 'status',
    headerClassName: 'App-Mui-DataGrid-Header',
    width: 120,
  },
  {
    field: 'opportunity_id',
    headerName: 'opportunity',
    headerClassName: 'App-Mui-DataGrid-Header',
  },
  {
    field: 'info',
    headerName: 'extra info',
    headerClassName: 'App-Mui-DataGrid-Header',
    width: 200,
  },
  {
    field: 'error_message',
    headerName: 'error',
    headerClassName: 'App-Mui-DataGrid-Header',
    renderCell: (params: any) => (
      <ErrorStackMsgCell errMsg={params.value || ''} />
    ),
    flex: 1,
    width: 120,
  },
  {
    field: 'username',
    headerName: 'user',
    headerClassName: 'App-Mui-DataGrid-Header',
    width: 100,
  },
  {
    field: 'action',
    headerClassName: 'App-Mui-DataGrid-Header',
    width: 80,
    renderCell(params) {
      return (
        <MoreMenuBtn
          jobId={params.id}
          indexId={params.row.indexId}
          status={params.row.status}
          type={params.row.type}
        />
      );
    },
  },
];

type Props = {
  indexId: string;
  opporId?: number;
};

const JobsTable: FC<Props> = ({ indexId, opporId }) => {
  const [period, setPeriod] = useState('7days');
  let { data, isLoading } = useQuery(
    [indexId, 'SetupJobs', period, opporId],
    () => getJobs(indexId, period, opporId)
  );
  data = data || [];
  data = data.map((item: any) => ({
    ...item,
    id: item.base,
    indexId,
  }));
  const [pageSize, setPageSize] = useState(10);
  return (
    <Paper
      sx={{ display: 'flex', height: '100%', flexDirection: 'column', gap: 2 }}
    >
      <Box m={2}>
        <TextField
          select
          size="small"
          variant="standard"
          value={period}
          onChange={(e) => setPeriod(e.target.value)}
          sx={{ width: 200 }}
        >
          <MenuItem value="7days">Jobs in last 7 days</MenuItem>
          <MenuItem value="30days">Jobs in last 30 days</MenuItem>
          <MenuItem value="90days">Jobs in last 90 days</MenuItem>
          <MenuItem value="all">All jobs</MenuItem>
        </TextField>
      </Box>
      <Box sx={{ flexGrow: 1 }}>
        <DataGridPro
          initialState={{
            columns: {
              columnVisibilityModel: {
                id: true,
              },
            },
          }}
          pagination
          loading={isLoading}
          autoHeight
          getRowHeight={() => 'auto'}
          columns={columns}
          rows={data}
          disableDensitySelector
          components={{ Toolbar: GridToolbar }}
          pageSize={pageSize}
          rowsPerPageOptions={[10, 25, 100]}
          onPageSizeChange={setPageSize}
          componentsProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
            },
          }}
          sx={{
            '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': {
              py: '12px',
              borderBottomColor: '#eee',
            },
            '&.MuiDataGrid-root--densityStandard .MuiDataGrid-columnSeparator':
              { color: '#ccc' },
          }}
        />
      </Box>
    </Paper>
  );
};
export default JobsTable;
