import { Box, MenuItem, Paper, Switch, TextField } from '@mui/material';
import {
  DataGridPro,
  DataGridProProps,
  GridColDef,
  GridToolbar,
  useGridApiRef,
} from '@mui/x-data-grid-pro';
import { CONFIG } from './PIUrlFilter';

import { FC, useState, useCallback } from 'react';
import { useQuery } from '@tanstack/react-query';
import MoreMenuBtn from './MoreMenuBtn';
import {
  getPageIngredients,
  updatePageIngredientItem,
} from 'apis/page_ingredient';
import { recipesB2F } from 'components/workbench/WBQBRecipe';
import { addRecipeStrAndOpField2Recipes } from 'utils/utils-query-builder';
import { IntervalType } from 'types/types';
import { getNumber } from 'components/shared/table-cell';
import NestedPITable from 'components/settings/views/page/NestedPITable';
import { renderUrl } from 'components/shared/table-cell/UrlCell';

type EnabledSwitchProps = {
  indexId: string | number;
  ingredientId: string | number;
  enabled: boolean;
};
const EnabledSwitch: FC<EnabledSwitchProps> = ({
  ingredientId,
  enabled,
  indexId,
}) => {
  const [checked, setChecked] = useState(enabled);
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
    updatePageIngredientItem(indexId, ingredientId, {
      disabled: !event.target.checked,
    });
  };
  return <Switch checked={checked} onChange={handleChange} />;
};

type RefreshIntervalSelectorProps = {
  indexId: string | number;
  ingredientId: string | number;
  refreshInterval: IntervalType;
};
const RefreshIntervalSelector: FC<RefreshIntervalSelectorProps> = ({
  ingredientId,
  indexId,
  refreshInterval,
}) => {
  const [curRefreshInterval, setCurRefreshInterval] =
    useState<IntervalType>(refreshInterval);
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const interval = event.target.value as IntervalType;
    setCurRefreshInterval(interval);
    updatePageIngredientItem(indexId, ingredientId, {
      refresh_interval: interval,
    });
  };
  return (
    <TextField
      select
      size="small"
      onChange={handleChange}
      value={curRefreshInterval}
    >
      <MenuItem value="manual">Manual</MenuItem>
      <MenuItem value="hourly">Hourly</MenuItem>
      <MenuItem value="daily">Daily</MenuItem>
      <MenuItem value="weekly">Weekly</MenuItem>
      <MenuItem value="monthly">Monthly</MenuItem>
    </TextField>
  );
};

const columns: GridColDef[] = [
  {
    field: 'id',
    headerClassName: 'App-Mui-DataGrid-Header',
  },
  {
    field: 'url_type',
    headerName: 'type',
    headerClassName: 'App-Mui-DataGrid-Header',
    width: 80,
  },
  {
    field: 'url',
    headerName: 'feed URI',
    headerClassName: 'App-Mui-DataGrid-Header',
    renderCell: renderUrl,
    flex: 1,
    minWidth: 200,
  },
  {
    field: 'url_column',
    headerName: 'url column name (url by default)',
    headerClassName: 'App-Mui-DataGrid-Header',
    flex: 1,
    minWidth: 200,
  },
  {
    field: 'disabled',
    headerName: 'enabled',
    headerClassName: 'App-Mui-DataGrid-Header',
    width: 80,
    valueGetter: (params) => !params.value,
    renderCell(params) {
      const enabled = !params.row.disabled;
      return (
        <EnabledSwitch
          ingredientId={params.id}
          indexId={params.row.indexId}
          enabled={enabled}
        />
      );
    },
  },
  {
    field: 'refresh_interval',
    headerName: 'crawl frequency',
    headerClassName: 'App-Mui-DataGrid-Header',
    renderCell: (params) => (
      <RefreshIntervalSelector
        ingredientId={params.id}
        indexId={params.row.indexId}
        refreshInterval={params.value || 'manual'}
      />
    ),
    width: 150,
  },
  {
    field: 'status',
    headerClassName: 'App-Mui-DataGrid-Header',
    width: 120,
    valueGetter: (params) => params.row.status,
  },
  {
    field: 'number_of_pages_crawled',
    headerName: '# pages crawled',
    headerClassName: 'App-Mui-DataGrid-Header',
    width: 140,
    valueGetter: getNumber,
  },
  {
    field: 'crawled at',
    headerClassName: 'App-Mui-DataGrid-Header',
    width: 200,
    valueGetter: (params) => params.row.latest_crawl,
  },
  {
    field: 'recipe',
    headerClassName: 'App-Mui-DataGrid-Header',
    flex: 1,
    minWidth: 200,
    valueGetter: (params) => params.row.recipeStr,
  },
  {
    field: 'action',
    headerClassName: 'App-Mui-DataGrid-Header',
    width: 80,
    renderCell(params) {
      return (
        <MoreMenuBtn ingredientId={params.id} indexId={params.row.indexId} />
      );
    },
  },
];
const URL_TYPE_MAP: any = {
  st: 'Sitemap',
  rg: 'Regular',
  fd: 'Feed',
  up: 'Uploaded Document',
  oc: 'OnCrawl',
  bt: 'Botify',
};

type Props = {
  indexId: string;
  selectedPageIngredientIds: any[];
  setSelectedPageIngredientIds: React.Dispatch<React.SetStateAction<any[]>>;
};

const PITable: FC<Props> = ({
  indexId,
  selectedPageIngredientIds,
  setSelectedPageIngredientIds,
}) => {
  let { data } = useQuery([indexId, 'UrlIngredient'], () =>
    getPageIngredients(indexId)
  );

  if (data) {
    data = addRecipeStrAndOpField2Recipes(recipesB2F(data), CONFIG);
    data = data.map((item: any) => {
      return {
        ...item,
        indexId,
        url_type: URL_TYPE_MAP[item.url_type],
      };
    });
  }
  data = data || [];

  const getDetailPanelContent = useCallback<
    NonNullable<DataGridProProps['getDetailPanelContent']>
  >(({ row }) => <NestedPITable row={row} />, []);
  const getDetailPanelHeight = useCallback(() => 280, []);

  const [pageSize, setPageSize] = useState(10);
  const apiRef = useGridApiRef();

  return (
    <Paper
      sx={{ display: 'flex', height: '100%', flexDirection: 'column', gap: 2 }}
    >
      <Box sx={{ flexGrow: 1 }}>
        <DataGridPro
          initialState={{
            columns: {
              columnVisibilityModel: {
                id: false,
              },
            },
          }}
          onSelectionModelChange={(newSelectionModel) => {
            const { pageSize, page, rowCount } =
              apiRef.current.state.pagination;
            // If user select all, then set to only select current page
            if (newSelectionModel.length === rowCount) {
              newSelectionModel = newSelectionModel.slice(
                page * pageSize,
                (page + 1) * pageSize
              );
            }
            setSelectedPageIngredientIds(newSelectionModel);
          }}
          apiRef={apiRef}
          selectionModel={selectedPageIngredientIds}
          checkboxSelection
          pagination
          autoHeight
          getRowHeight={() => 'auto'}
          columns={columns}
          rows={data}
          disableDensitySelector
          disableColumnFilter
          components={{ Toolbar: GridToolbar }}
          pageSize={pageSize}
          rowsPerPageOptions={[10, 25, 100]}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          componentsProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
            },
          }}
          sx={{
            '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': {
              py: '12px',
              borderBottomColor: '#eee',
            },
            '&.MuiDataGrid-root--densityStandard .MuiDataGrid-columnSeparator':
              { color: '#ccc' },
          }}
          getDetailPanelHeight={getDetailPanelHeight}
          getDetailPanelContent={getDetailPanelContent}
        />
      </Box>
    </Paper>
  );
};
export default PITable;
