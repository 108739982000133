export const getCurrency = (params: any) => {
  const value = params?.value;
  if (value != null) {
    return `$${Number(value).toLocaleString()}`;
  }
  return '';
};

export const getNumber = (params: any) => {
  const value = params?.value;
  if (value != null) {
    return Number(value).toLocaleString();
  }
  return '';
};
