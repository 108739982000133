import {
  DataGridPro,
  GridSortModel,
  GridColDef,
  useGridApiRef,
} from '@mui/x-data-grid-pro';

import { FC, useCallback } from 'react';
import { ColOrderType } from 'types/types';

import { renderGSCKeyword } from '../../shared/table-cell/GSCKeywordCell';
import { renderClusterKeyword } from '../../shared/table-cell/ClusterKeywordCell';
import { renderUrl } from '../../shared/table-cell/UrlCell';
import { renderStrArr } from '../../shared/table-cell/StrArrCell';
import {
  renderAdCount,
  renderShopping,
  renderTitle,
} from '../../shared/table-cell/CanonicalSerpCtxCell';
import renderPreviewChatGPT from '../../shared/table-cell/ChatGPTPreviewCell';
import { renderArray } from '../../shared/table-cell/ArrayCell';
import { getNumber } from '../../shared/table-cell';
import { renderSerpHeadings } from '../../shared/table-cell/SerpHeadingsCell';

export const getInitCols = (isFinished?: boolean): GridColDef[] => {
  let ret = [
    {
      field: 'id',
      headerClassName: 'App-Mui-DataGrid-Header',
      hide: true,
    },
    {
      field: 'preview_chatgpt',
      headerClassName: 'App-Mui-DataGrid-Header',
      headerName: 'Preview ChatGPT',
      renderCell: renderPreviewChatGPT,
      width: 400,
    },
    {
      field: '$preview_variable',
      headerClassName: 'App-Mui-DataGrid-Header',
      headerName: 'Preview Variable',
      width: 300,
    },
    {
      field: 'url',
      headerClassName: 'App-Mui-DataGrid-Header',
      flex: 1,
      minWidth: 300,
      renderCell: renderUrl,
    },
    {
      field: 'status',
      headerName: 'indexable',
      headerClassName: 'App-Mui-DataGrid-Header',
      valueGetter: (params: { row: { status: { indexable: any } } }) =>
        params.row.status?.indexable !== undefined
          ? Number(params.row.status.indexable)
          : '',
    },
    {
      field: 'url_path',
      headerName: 'url path',
      headerClassName: 'App-Mui-DataGrid-Header',
      width: 300,
    },
    {
      field: 'url_path_prefixes',
      headerName: 'url folder',
      headerClassName: 'App-Mui-DataGrid-Header',
      renderCell: renderArray,
      width: 300,
      hide: true,
    },
    {
      field: 'url_parts',
      headerName: 'url parts',
      headerClassName: 'App-Mui-DataGrid-Header',
      renderCell: renderArray,
      width: 300,
      hide: true,
    },
    {
      field: 'url_parts_depth',
      headerName: 'url depth',
      headerClassName: 'App-Mui-DataGrid-Header',
      width: 100,
    },
    {
      field: 'search_url',
      headerName: 'search url',
      headerClassName: 'App-Mui-DataGrid-Header',
      renderCell: renderUrl,
      minWidth: 300,
    },
    {
      field: 'product_count',
      headerClassName: 'App-Mui-DataGrid-Header',
      headerName: '# listings',
      width: 120,
      valueGetter: getNumber,
    },
    {
      field: 'feature',
      headerClassName: 'App-Mui-DataGrid-Header',
      width: 100,
    },
    {
      field: 'related_links_incoming',
      headerName: '# inlinks',
      headerClassName: 'App-Mui-DataGrid-Header',
      width: 80,
      hide: true,
    },
    {
      field: 'gsc_keywords',
      headerName: 'ranking keywords',
      headerClassName: 'App-Mui-DataGrid-Header',
      width: 400,
      sortable: false,
      renderCell: renderGSCKeyword,
    },
    {
      field: 'n_gsc_keywords',
      headerName: '# ranking keywords',
      headerClassName: 'App-Mui-DataGrid-Header',
      width: 150,
      valueGetter: getNumber,
    },
    {
      field: 'top_gsc_keywords',
      headerName: 'top ranking keywords',
      headerClassName: 'App-Mui-DataGrid-Header',
      width: 400,
      sortable: false,
      renderCell: renderGSCKeyword,
    },
    {
      field: 'gsc_best_position',
      headerClassName: 'App-Mui-DataGrid-Header',
      headerName: 'position',
      width: 120,
    },
    {
      field: 'total_traffic',
      headerClassName: 'App-Mui-DataGrid-Header',
      headerName: 'total traffic',
      width: 120,
      valueGetter: getNumber,
    },
    {
      field: 'total_impressions',
      headerClassName: 'App-Mui-DataGrid-Header',
      headerName: 'total impression',
      width: 120,
      valueGetter: getNumber,
    },
    {
      field: 'canonical_keyword',
      headerClassName: 'App-Mui-DataGrid-Header',
      headerName: 'topic',
      width: 200,
    },
    {
      field: 'cluster_keywords',
      headerName: 'topic keywords',
      headerClassName: 'App-Mui-DataGrid-Header',
      width: 300,
      sortable: false,
      renderCell: renderClusterKeyword,
    },
    {
      field: 'cluster_size',
      headerClassName: 'App-Mui-DataGrid-Header',
      headerName: '# topic keywords',
      width: 120,
      valueGetter: getNumber,
    },
    {
      field: 'total_demand',
      headerClassName: 'App-Mui-DataGrid-Header',
      headerName: 'total demand',
      width: 120,
      valueGetter: getNumber,
    },
    {
      field: 'traffic_opportunity',
      headerClassName: 'App-Mui-DataGrid-Header',
      headerName: 'traffic opportunity',
      width: 140,
    },
    {
      field: 'ndd_cluster_size',
      headerClassName: 'App-Mui-DataGrid-Header',
      headerName: '# duplicates',
      width: 120,
      valueGetter: getNumber,
    },
    {
      field: 'ndd_is_canonical',
      headerClassName: 'App-Mui-DataGrid-Header',
      headerName: 'is canonical',
      width: 120,
    },
    {
      field: 'ndd_canonical_url',
      headerClassName: 'App-Mui-DataGrid-Header',
      headerName: 'canonical url',
      flex: 1,
      minWidth: 300,
      renderCell: renderUrl,
    },
    {
      field: 'top_ranking_domains',
      headerClassName: 'App-Mui-DataGrid-Header',
      headerName: 'top ranking domains',
      renderCell: renderStrArr,
      flex: 1,
      minWidth: 300,
    },
    {
      field: 'serp_domain_diversity',
      headerClassName: 'App-Mui-DataGrid-Header',
      headerName: 'serp domain diversity',
      width: 120,
    },
    {
      field: 'language',
      headerClassName: 'App-Mui-DataGrid-Header',
      headerName: 'language',
      width: 120,
    },
    {
      field: 'listings_relevance',
      headerClassName: 'App-Mui-DataGrid-Header',
      headerName: 'listing relevance',
      width: 120,
    },
    {
      field: 'canonical_serp_context.knowledge_graph.title',
      headerClassName: 'App-Mui-DataGrid-Header',
      headerName: 'SERP Knowledge Panel Title',
      width: 300,
      renderCell: renderTitle,
    },
    {
      field: 'canonical_serp_context.shopping',
      headerClassName: 'App-Mui-DataGrid-Header',
      headerName: 'SERP has shopping',
      width: 120,
      renderCell: renderShopping,
    },
    {
      field: 'canonical_serp_context.ad_count',
      headerClassName: 'App-Mui-DataGrid-Header',
      headerName: 'SERP ad count',
      width: 120,
      renderCell: renderAdCount,
    },
    {
      field: 'serp_headings',
      headerClassName: 'App-Mui-DataGrid-Header',
      headerName: 'SERP headings',
      width: 480,
      renderCell: renderSerpHeadings,
    },
    {
      field: 'crawled',
      valueGetter: (params: any) => new Date(params.value).toLocaleDateString(),
      headerName: 'crawled',
      headerClassName: 'App-Mui-DataGrid-Header',
      width: 100,
    },
  ];

  if (isFinished) {
    ret = ret.map((col) => {
      if (col.field !== 'url') {
        col.hide = true;
      }
      return col;
    });
  }

  return ret;
};

type Props = {
  data: any;
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  offset: number;
  setOffset: React.Dispatch<React.SetStateAction<number>>;
  setOrder: React.Dispatch<React.SetStateAction<ColOrderType>>;
  isLoading: boolean;
  setColsDisplayOrder?: React.Dispatch<
    React.SetStateAction<Record<string, number>>
  >;
  setColsWidth?: React.Dispatch<React.SetStateAction<Record<string, number>>>;
  columns?: GridColDef[];
  getDetailPanelHeight?: any;
  getDetailPanelContent?: any;
  isFinished?: boolean;
};

const WBSiteTable: FC<Props> = ({
  data,
  page,
  setPage,
  offset,
  setOffset,
  setOrder,
  isLoading,
  setColsDisplayOrder,
  setColsWidth,
  columns,
  getDetailPanelContent,
  getDetailPanelHeight,
  isFinished,
}) => {
  const handleSorting = useCallback(
    (sortModel: GridSortModel) => {
      const _sort = sortModel[0];
      if (!_sort) return;
      setOrder({
        name: _sort.field,
        direction: _sort.sort as 'asc' | 'desc',
      });
    },
    [setOrder]
  );

  const apiRef = useGridApiRef();

  return (
    <DataGridPro
      apiRef={apiRef}
      onColumnOrderChange={() => {
        if (!setColsDisplayOrder) return;
        const cols = apiRef.current.getAllColumns().map((item) => item.field);
        const colsDisOrder: Record<string, number> = {};
        cols.forEach((col, idx) => {
          colsDisOrder[col] = idx;
        });
        setColsDisplayOrder(colsDisOrder);
      }}
      onColumnWidthChange={(params) => {
        if (!setColsWidth) return;
        setColsWidth((pre) => ({
          ...pre,
          [params.colDef.field]: params.width,
        }));
      }}
      getDetailPanelHeight={getDetailPanelHeight}
      getDetailPanelContent={getDetailPanelContent}
      loading={isLoading}
      pagination
      autoHeight
      getRowHeight={() => 'auto'}
      columns={columns || getInitCols(isFinished)}
      rows={data.items}
      paginationMode="server"
      sortingMode="server"
      rowCount={data?.count || 0}
      pageSize={offset}
      onPageSizeChange={setOffset}
      page={page}
      onPageChange={setPage}
      rowsPerPageOptions={[10, 25, 100]}
      onSortModelChange={handleSorting}
      disableDensitySelector
      disableColumnFilter
      sx={{
        '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': {
          py: '12px',
          borderBottomColor: '#eee',
        },
        '&.MuiDataGrid-root--densityStandard .MuiDataGrid-columnSeparator': {
          color: '#ccc',
        },
        display: 'flex',
        flexDirection: 'column-reverse',
      }}
    />
  );
};
export default WBSiteTable;
