import {
  JsonTree,
  JsonGroup,
  JsonAnyRule,
  JsonItem,
  GroupProperties,
  ValueSource,
  Utils as QbUtils,
} from 'react-awesome-query-builder';
import { v4 as uuid } from 'uuid';
import { FIELD_SEPARATOR } from 'constants/constants';
import { VARIABLE_GROUP_KEY } from 'hooks/useQBFields';

/**
 *
 * Convert input tree generated by https://github.com/ukrbublik/react-awesome-query-builder
 *  to https://querybuilder.js.org/api/Rule.html
 * @param inputJsonTree from react-awesome-query-builder
 * @returns JQuery json tree
 *
 */
export const convertQueryBuilderTree = (
  inputJsonTree: JsonTree | undefined | null
) => {
  if (!inputJsonTree || !inputJsonTree.children1) return null;
  const ret = { valid: true };

  const parseGroup = (ret: any, groupNode: JsonGroup) => {
    const item = {} as any;
    if ('properties' in groupNode) {
      if ('conjunction' in groupNode.properties!) {
        item['condition'] = groupNode.properties.conjunction;
      }
    }
    if ('children1' in groupNode) {
      const children = groupNode.children1 as JsonItem[];
      item['rules'] = [];
      for (const child of children) {
        if (child.type === 'group') {
          parseGroup(item['rules'], child);
        }
        if (child.type === 'rule') {
          parseRule(item['rules'], child);
        }
      }
    }
    ret.push(item);
  };

  const getType = (prop: any) => {
    let ret = 'number';
    switch (prop.operator) {
      case 'is_not_null':
      case 'is_null':
        ret = 'boolean';
        break;
      case 'select_equals':
      case 'select_not_equals':
      case 'multiselect_equals':
      case 'multiselect_not_equals':
        ret = 'string';
        break;
      default:
        ret = 'number';
    }
    if (prop.valueType[0] === 'text') {
      ret = 'string';
    }
    if (prop.valueType[0] === 'date') {
      ret = 'date';
    }
    return ret;
  };

  const getOperator = (op: string) => {
    const opMap: { [k: string]: string } = {
      is_not_null: 'is_not_empty',
      is_null: 'is_empty',
      select_equals: 'equal',
      select_not_equals: 'not_equal',
      multiselect_equals: 'in',
      multiselect_not_equals: 'not_in',
      select_any_in: 'in',
      select_not_any_in: 'not_in',
      like: 'contains',
      not_like: 'not_contains',
      starts_with: 'begins_with',
    };
    if (!(op in opMap)) return op;
    return opMap[op];
  };

  const getInput = (prop: any) => {
    let ret = 'number';
    switch (prop.operator) {
      case 'multiselect_equals':
      case 'multiselect_not_equals':
        ret = 'checkbox';
        break;
      default:
        ret = 'number';
    }
    if (prop.valueType[0] === 'select') {
      ret = 'select';
    }
    if (prop.valueType[0] === 'text') {
      ret = 'text';
    }
    if (prop.valueType[0] === 'date') {
      ret = 'date';
    }
    return ret;
  };

  const parseRule = (ret: any, ruleNode: JsonAnyRule) => {
    const properties = ruleNode.properties as any;
    if (properties && properties.field) {
      const value = properties.value;
      //skip value = [undefined] for uncomplete rule
      if (value.length === 1 && value[0] === undefined) return;
      const item = {} as any;
      let field = properties.field as string;
      if (field.indexOf(FIELD_SEPARATOR) !== -1) {
        field = field.split(FIELD_SEPARATOR)[1];
      }
      item['id'] = field;
      item['field'] = field;
      item['input'] = getInput(properties);
      item['value'] = value[0] ?? null;
      item['operator'] = getOperator(properties.operator);
      item['type'] = getType(properties);
      // if (item['type'] === 'date') {
      //   item['value'] = value;
      // }
      ret.push(item);
    }
  };

  const parse = (ret: any, inputJsonTree: JsonTree) => {
    if ('properties' in inputJsonTree) {
      if ('conjunction' in inputJsonTree.properties!) {
        ret['condition'] = inputJsonTree.properties.conjunction;
      }
    }
    if ('children1' in inputJsonTree) {
      const children = inputJsonTree.children1 as JsonItem[];
      ret['rules'] = [];
      for (const child of children) {
        if (child.type === 'group') {
          parseGroup(ret['rules'], child);
        }
        if (child.type === 'rule') {
          parseRule(ret['rules'], child);
        }
      }
    }
  };

  parse(ret, inputJsonTree);

  return ret;
};

/**
 *
 * @param backend config json
 * @returns frontend config matches react-awsome query builder
 */
const GROUP_PROP = 'optgroup';
export const convertQueryBuilderConfig = (backConfig: any[]) => {
  if (!backConfig || backConfig.length === 0) return {};
  const ret = {} as any;
  const createGroups = () => {
    for (const backItem of backConfig) {
      if (GROUP_PROP in backItem) {
        ret[backItem[GROUP_PROP]] = {
          label: backItem[GROUP_PROP],
          type: '!struct',
          subfields: {},
        };
      }
    }
  };
  createGroups();
  const convertSelect = (backItem: any, ret: any) => {
    ret.type = 'select';
    ret.operators = ['select_equals'];
    const listValues = [];
    const values = backItem.values;
    if (Array.isArray(values)) {
      for (const v of values) {
        listValues.push({ value: v.value, title: v.label });
      }
    } else {
      for (const v in values) {
        listValues.push({ value: v, title: values[v] });
      }
    }
    ret.fieldSettings = { listValues };
  };
  const convertMultiSelect = (backItem: any, ret: any) => {
    ret.type = 'multiselect';
    ret.operators = [
      'multiselect_equals',
      'multiselect_not_equals',
      'is_empty',
      'is_not_empty',
    ];
    const listValues: Record<string, string> = {};
    const values = backItem.values;
    if (Array.isArray(values)) {
      for (const v of values) {
        listValues[v] = v;
      }
    } else {
      for (const v in values) {
        listValues[v] = v;
      }
    }
    ret.fieldSettings = { listValues };
  };
  for (const backItem of backConfig) {
    const frontKey = backItem.id as string;
    const group = backItem[GROUP_PROP];
    let cur: any;
    if (!group) {
      ret[frontKey] = {};
      cur = ret[frontKey];
    } else {
      ret[group].subfields[frontKey] = {};
      cur = ret[group].subfields[frontKey];
    }
    cur.label = backItem.label || frontKey;
    cur.valueSources = ['value'];
    if (backItem.type === 'boolean') {
      cur.type = 'boolean';
      cur.operators = ['equal', 'is_empty', 'is_not_empty'];
    } else if (backItem.input === 'select') {
      convertSelect(backItem, cur);
    } else if (backItem.type === 'integer' || backItem.type === 'double') {
      cur.type = 'number';
      cur.operators = [
        'equal',
        'not_equal',
        'less',
        'less_or_equal',
        'greater',
        'greater_or_equal',
        'is_empty',
        'is_not_empty',
      ];
    } else if (backItem.input === 'checkbox' && backItem.multiple) {
      convertMultiSelect(backItem, cur);
    } else if (backItem.type === 'string') {
      cur.type = 'text';
      cur.operators = [
        'equal',
        'not_equal',
        'starts_with',
        'like',
        'not_like',
        'is_empty',
        'is_not_empty',
      ];
    } else if (backItem.type === 'date') {
      cur.type = 'date';
    } else {
      throw Error(
        `Not support input ${backItem.input} and type ${backItem.type} yet.`
      );
    }
  }
  return ret;
};

/**
 * check if jsonTree is empty (RAQB format)
 */
export const checkIsTreeEmpty = (jsonTree: any) => {
  if (!jsonTree) return true;
  if (!('children1' in jsonTree)) return true;
  let children1 = jsonTree.children1;
  if (children1.length === 0) return true;
  if (children1.length === 1 && children1[0].type === 'rule') {
    const properties = children1[0].properties;
    if (
      !properties ||
      (properties.field === null &&
        properties.operator === null &&
        properties.value.length === 0 &&
        properties.valueSrc.length === 0)
    ) {
      return true;
    }
  }
  return false;
};

//Reference: https://stackoverflow.com/questions/201183/how-to-determine-equality-for-two-javascript-objects
export const deepEqual = (x: any, y: any): boolean => {
  const ok = Object.keys,
    tx = typeof x,
    ty = typeof y;
  return x && y && tx === 'object' && tx === ty
    ? ok(x).length === ok(y).length &&
        ok(x).every((key) => deepEqual(x[key], y[key]))
    : x === y;
};

/**
 * check if two json tree (frontend) deep equal except Id for rules, the method will be used in unit test.
 * @param tree1
 * @param tree2
 * @returns
 */
export const isQueryBuilderTreeDeepEqual = (
  tree1: JsonItem,
  tree2: JsonItem
): boolean => {
  const checkType = (t1: JsonItem, t2: JsonItem): boolean => {
    return t1.type === t2.type;
  };

  const checkProperties = (t1: JsonItem, t2: JsonItem): boolean => {
    const p1 = t1.properties;
    const p2 = t2.properties;
    if (!p1 && !p2) return true;
    if (p1 && !p2) return false;
    if (!p1 && p2) return false;
    if (p1 && p2) {
      const keys1 = Object.keys(p1);
      const keys2 = Object.keys(p2);
      if (keys1.length !== keys2.length) {
        return false;
      } else {
        let key: keyof typeof p1;
        for (key in p1) {
          if (!deepEqual(p1[key], p2[key])) {
            return false;
          }
        }
      }
    }
    return true;
  };

  const checkChildren = (t1: JsonGroup, t2: JsonGroup): boolean => {
    const c1 = t1.children1 as [JsonItem];
    const c2 = t2.children1 as [JsonItem];
    if (!c1 && !c2) return true;
    if (c1 && !c2) return false;
    if (!c1 && c2) return false;
    if (c1 && c2) {
      if (c1.length !== c2.length) return false;
      for (let i = 0; i < c1.length; i++) {
        const item1 = c1[i];
        const item2 = c2[i];
        if (!checkType(item1, item2)) return false;
        if (item1.type === 'rule' && item2.type === 'rule') {
          if (!checkRule(item1, item2)) return false;
        }
        if (item1.type === 'group' && item2.type === 'group') {
          if (!checkGroup(item1, item2)) return false;
        }
      }
    }
    return true;
  };

  const checkRule = (r1: JsonAnyRule, r2: JsonAnyRule): boolean => {
    return checkProperties(r1, r2);
  };

  const checkGroup = (r1: JsonGroup, r2: JsonGroup): boolean => {
    if (!checkProperties(r1, r2)) return false;
    if (!checkChildren(r1, r2)) return false;
    return true;
  };

  const check = (t1: JsonItem, t2: JsonItem): boolean => {
    if (!checkType(t1, t2)) return false;
    if (t1.type === 'rule' && t2.type === 'rule') return checkRule(t1, t2);
    if (t1.type === 'group' && t2.type === 'group') return checkGroup(t1, t2);
    return true;
  };

  return check(tree1, tree2);
};

/**
 *
 * @param JQuery json tree (backend)
 * @returns  inputJsonTree from react-awesome-query-builder (frontend)
 *
 */

export const convertQueryBuilderTreeB2F = (backendTree: any): JsonTree => {
  const ret: JsonItem = {
    type: 'group',
    children1: [
      {
        type: 'rule',
        properties: {
          field: null,
          operator: null,
          value: [],
          valueSrc: [],
        },
      },
    ],
    properties: {
      conjunction: 'AND',
      not: false,
    },
  };
  if (!backendTree || Object.keys(backendTree).length === 0) return ret;

  const getOperator = (bRule: any) => {
    if (bRule.operator === 'is_empty' || bRule.operator === 'is_not_empty') {
      return bRule.operator;
    } else if (bRule.input === 'select') {
      if (bRule.operator === 'not_equal') return 'select_not_equals';
      return 'select_equals';
    } else if (bRule.input === 'checkbox') {
      if (bRule.operator === 'in') {
        return 'multiselect_equals';
      }
      // bRule.operator === 'not_in'
      else {
        return 'multiselect_not_equals';
      }
    } else if (bRule.operator === 'in') {
      return 'select_any_in';
    } else if (bRule.operator === 'not_in') {
      return 'select_not_any_in';
    }
    //boolean
    else if (bRule.operator === 'is') {
      return 'equal';
    }
    //string begins_with
    else if (bRule.operator === 'begins_with') {
      return 'starts_with';
    }
    //string contains
    else if (bRule.operator === 'contains') {
      return 'like';
    }
    //string not_contains
    else if (bRule.operator === 'not_contains') {
      return 'not_like';
    } else {
      //equal, not_equal
      return bRule.operator;
    }
  };
  const getValue = (bRule: any) => {
    let value = [bRule.value];
    if (bRule.value === undefined || bRule.value === null) {
      value = [];
    }
    return value;
  };
  const getValueSrc = (bRule: any) => {
    let value = ['value'];
    if (bRule.value === undefined || bRule.value === null) {
      value = [];
    }
    return value;
  };
  const getValueType = (bRule: any) => {
    let valueType: [string] | [] = ['text'];
    if (bRule.value === 0) {
      valueType = ['number'];
    } else if (!bRule.value) {
      valueType = [];
    } else if (bRule.operator === 'in' || bRule.operator === 'not_in') {
      valueType = ['multiselect'];
    } else if (bRule.input === 'select') {
      valueType = ['select'];
    } else if (bRule.input === 'checkbox') {
      valueType = ['multiselect'];
    } else if (bRule.operator === 'is' || typeof bRule.value === typeof true) {
      valueType = ['boolean'];
    } else if (bRule.input === 'number') {
      valueType = ['number'];
    } else if (bRule.input === 'date') {
      valueType = ['date'];
    }
    return valueType;
  };

  const buildChildren1 = (bRules: any[]): JsonItem[] => {
    const children1: JsonItem[] = [];
    for (const bRule of bRules) {
      if (!('condition' in bRule)) {
        const operator = getOperator(bRule);
        const value = getValue(bRule);
        const valueSrc = getValueSrc(bRule) as ValueSource[];
        const valueType = getValueType(bRule);
        let field = bRule.field;
        // field is varaible field
        if (field && field[0] === '$') {
          field = VARIABLE_GROUP_KEY + FIELD_SEPARATOR + field;
        }
        const fRule: JsonAnyRule = {
          type: 'rule',
          properties: {
            field,
            operator,
            value,
            valueSrc,
            valueType,
          },
        };
        children1.push(fRule);
      } else {
        const conjunction = bRule.condition;
        const fGroup: JsonGroup = {
          type: 'group',
          properties: {
            conjunction,
            not: false,
          },
        };
        fGroup.children1 = buildChildren1(bRule.rules) as [JsonItem];
        children1.push(fGroup);
      }
    }
    return children1;
  };

  const parse = (fTree: JsonTree, bTree: any) => {
    if (fTree.properties) {
      const prop = fTree.properties as GroupProperties;
      prop.conjunction = bTree.condition;
    }
    fTree.children1 = buildChildren1(bTree.rules) as [JsonItem];
  };

  const assignId = (fTree: any) => {
    fTree.id = uuid();
    if ('children1' in fTree) {
      for (const child of fTree.children1) {
        if (child.type === 'rule') {
          child.id = uuid();
        } else if (child.type === 'group') {
          assignId(child);
        }
      }
    }
  };

  parse(ret, backendTree);
  let ret2 = ret as any;
  assignId(ret2);

  return ret2;
};

export const jsonObj2Url = (jsonObj: any): string => {
  return encodeURIComponent(JSON.stringify(jsonObj));
};

export const url2JsonObj = (urlStr: string) => {
  return JSON.parse(decodeURIComponent(urlStr));
};

export const addGroup2RecipeBack = (rules: any, recipe: any) => {
  const fieldGroupMap = new Map<string, string>();
  for (const rule of rules) {
    if ('optgroup' in rule) {
      fieldGroupMap.set(rule.id, rule.optgroup);
    }
  }
  const parseRecipe = (recipe: any) => {
    if ('id' in recipe) {
      const originField = recipe.id;
      if (fieldGroupMap.has(originField)) {
        const newField =
          fieldGroupMap.get(originField) + FIELD_SEPARATOR + originField;
        recipe.id = newField;
        recipe.field = newField;
      }
    } else if ('rules' in recipe) {
      for (const rule of recipe.rules) {
        parseRecipe(rule);
      }
    }
  };
  parseRecipe(recipe);
};

export const addRecipeStrAndOpField2Recipes = (recipes: any[], config: any) => {
  let ret = [];
  if (recipes) {
    ret = recipes.map((item: any) => {
      const recipe_front = item.recipe;
      let recipeStr: any = '';
      if (recipe_front && !item.recipeStr) {
        const inputBuilderTree = QbUtils.checkTree(
          QbUtils.loadTree(recipe_front),
          config
        );
        recipeStr = QbUtils.queryString(inputBuilderTree, config, true);
      }
      let operationField = '';
      if (item.operation && item.operation.value) {
        operationField = item.operation.value;
      }
      return {
        ...item,
        recipeStr,
        operationField,
      };
    });
  }
  return ret;
};

const POTENTIAL_RECIPES = [
  'recipe',
  'source_recipe',
  'target_recipe',
  'boost_recipe',
];
export const addRecipeStrFromRecipeBak = (recipes: any[], config: any) => {
  let ret: any[] = [];
  if (recipes) {
    ret = recipes.map((item: any) => {
      for (const recipeKey of POTENTIAL_RECIPES) {
        if (!(recipeKey in item)) continue;
        if (!item[recipeKey]) continue;
        const recipe_back = item[recipeKey].recipe;
        const recipe_front = convertQueryBuilderTreeB2F(recipe_back);
        let recipeStr: any = '';
        if (recipe_front && !item[recipeKey + '_str']) {
          const inputBuilderTree = QbUtils.checkTree(
            QbUtils.loadTree(recipe_front),
            config
          );
          recipeStr = QbUtils.queryString(inputBuilderTree, config, true);
          item[recipeKey + '_str'] = recipeStr;
        }
      }
      return item;
    });
  }
  return ret;
};

export const addSingleRecipeStrFromRecipeBak = (item: any, config: any) => {
  if (item && item.recipe) {
    const recipe_back = item.recipe;
    const recipe_front = convertQueryBuilderTreeB2F(recipe_back);
    let recipeStr: any = '';
    if (recipe_front && !item['recipe_str']) {
      const inputBuilderTree = QbUtils.checkTree(
        QbUtils.loadTree(recipe_front),
        config
      );
      recipeStr = QbUtils.queryString(inputBuilderTree, config, true);
      item['recipe_str'] = recipeStr;
    }
  }
  return item;
};

export const getAllFieldsFromRecipeFront = (recipe: any) => {
  if (!recipe) return [];
  const ret: string[] = [];
  const dfs = (node: any) => {
    if (!('children1' in node)) return;
    for (const child of node['children1']) {
      if (child['type'] === 'rule') {
        ret.push(child['properties']['field']);
      } else if (child['type'] === 'group') {
        dfs(child);
      }
    }
  };
  dfs(recipe);
  return ret;
};
