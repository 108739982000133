import {
  Button,
  TextField,
  Box,
  Typography,
  Container,
  Link,
} from '@mui/material';
import { LayoutBlank } from 'components/layout/LayoutBlank';
import { useState } from 'react';
import Logo from 'components/header/Logo';
import { postSignup } from 'apis/signup';
import { AxiosError } from 'axios';

export default function SignUp() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [password2, setPassword2] = useState('');
  const [error, setError] = useState({
    username: [],
    email: [],
    password: [],
  });
  const [success, setSuccess] = useState(false);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!password) return;
    if (password !== password2) {
      return;
    }
    try {
      await postSignup(username, password, password2);
      setSuccess(true);
    } catch (err) {
      if (err instanceof AxiosError) {
        const errorData = err.response?.data;
        setError({
          username: errorData.username || [],
          email: errorData.email || [],
          password: errorData.password || [],
        });
      } else {
        setError({
          username: [],
          email: [],
          password: [],
        });
      }
    }
  };

  return (
    <LayoutBlank>
      <Container component="main" maxWidth="sm">
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            border: '1px solid black',
            pt: 8,
            pb: 1,
            borderRadius: '4px',
          }}
        >
          <Box sx={{ mb: 4 }}>
            <Logo width="200px" color="black" />
          </Box>
          {!success ? <Typography component="h3" variant="h3">
            Sign up
          </Typography> : null }
          {!success ? <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1, p: 2 }}
          >
            <TextField
              margin="normal"
              size="small"
              required
              fullWidth
              label="Email"
              name="username"
              autoFocus
              value={username}
              onChange={(e) => setUsername(e.currentTarget.value)}
              error={Boolean(error.username.length) || Boolean(error.email.length)}
              helperText={(Boolean(error.username.length) && error.username) || (Boolean(error.email.length) && error.email)}
            />
            <TextField
              size="small"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.currentTarget.value)}
              error={Boolean(error.password.length)}
              helperText={error.password}
            />
            <TextField
              size="small"
              margin="normal"
              required
              fullWidth
              name="password2"
              label="Confirm Password"
              type="password"
              value={password2}
              error={password !== password2}
              helperText={password !== password2 && 'Password not equal.'}
              onChange={(e) => setPassword2(e.currentTarget.value)}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign Up
            </Button>
          </Box> : null}
          {success ? <Typography align="center" sx={{ mb: 4 }}>
            <h3>Thanks for signing up</h3>
            <p>Please sign in to your email to verify your account</p>
          </Typography> : null}
          <Link href="/signin">Sign In</Link>
        </Box>
      </Container>
    </LayoutBlank>
  );
}
