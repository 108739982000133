import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Box,
  TextField,
} from '@mui/material';
import { FunctionComponent, useState } from 'react';
import InfoIcon from '@mui/icons-material/Info';
import { SharedRecipeSourceType } from 'types/types';
import { useUser } from 'contexts/user-context';
import { postSharedRecipe } from 'apis/shared_recipe';
import { useSnackbar } from 'contexts/snackbar-context';

type Props = {
  open: boolean;
  source: SharedRecipeSourceType;
  recipe: any;
  operation?: any;
  onClose: () => void;
};
const ShareRecipeDlg: FunctionComponent<Props> = ({
  open,
  onClose,
  operation,
  source,
  recipe,
}) => {
  const { curIndex } = useUser();
  const { setSnackbar } = useSnackbar();
  const [name, setName] = useState('');

  const handleChange = async (e: any) => {
    const _name = e.target.value;
    setName(_name);
  };

  const isNameEmpty = !name.trim();

  const handleClickShare = async () => {
    await postSharedRecipe(curIndex!.value, {
      name,
      source,
      recipe,
      operation,
    });
    onClose();
    setSnackbar({ severity: 'success', message: 'Shared!' });
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        <InfoIcon color="info" /> Please enter recipe name
      </DialogTitle>
      <DialogContent>
        <Box>
          <TextField
            autoFocus
            autoComplete="off"
            margin="dense"
            label="Name"
            value={name}
            onChange={handleChange}
            fullWidth
            variant="standard"
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button color="inherit" onClick={onClose}>
          Close
        </Button>
        <Button disabled={isNameEmpty} onClick={handleClickShare}>
          Share
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ShareRecipeDlg;
