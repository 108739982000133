import { Box, Typography } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import { FC } from 'react';
import GridCellExpand from './GridCellExpand';

type Props = {
  params: GridRenderCellParams<string[]>;
};

const StrArrCell: FC<Props> = ({ params }) => {
  let value = params.value;
  if (!value) return <></>;
  const content = [];
  let length = value.length;
  for (let i = 0; i < length; i++) {
    const str = value[i];
    const item = (
      <Box
        key={i}
        sx={{
          color: '#333',
          backgroundColor: '#ddd',
          padding: '4px 8px',
          borderRadius: '4px',
          display: 'flex',
          gap: 1,
          alignItems: 'center',
        }}
      >
        <Typography variant="body2" sx={{ whiteSpace: 'nowrap' }}>
          {str}
        </Typography>
      </Box>
    );
    content.push(item);
  }
  return <GridCellExpand value={content} width={params.colDef.computedWidth} />;
};

export const renderStrArr = (params: GridRenderCellParams<string[]>) => {
  return <StrArrCell params={params} />;
};

export default StrArrCell;
