import {
  Box,
  Button,
  Paper,
  Typography,
  Select,
  SelectChangeEvent,
  InputLabel,
  MenuItem,
  FormControl,
} from '@mui/material';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  getJetOctopusAccounts,
  postJetOctopusAccounts,
  updateJetOctopusAccount,
} from 'apis/settings';
import { useSnackbar } from 'contexts/snackbar-context';
import { useUser } from 'contexts/user-context';
import { FC, useState } from 'react';
import AddJetOctopusDialog, { FormData } from '../dialog/AddJetOctopusDialog';

export type JetOctopusItemProps = {
  id: number;
  index_id: number;
  name: string;
  token: string;
  is_active: boolean;
};

const Item: FC<JetOctopusItemProps> = ({
  id,
  index_id,
  name,
  token,
  is_active,
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        gap: 2,
        alignItems: 'center',
        mt: 1,
      }}
    >
      <Typography sx={{ fontWeight: 700, textDecoration: 'none' }}>
        {name}
      </Typography>
      <Typography sx={{ fontWeight: 700, textDecoration: 'none' }}>
        {token?.replace(/^(.{4}).*(.{4})$/, '$1******$2')}
      </Typography>
    </Box>
  );
};

const JetOctopusConnection: FC = () => {
  const { curIndex } = useUser();
  const { setSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const [projectId, setProjectId] = useState('');

  const { mutate } = useMutation({
    mutationFn: (body: any) => postJetOctopusAccounts(curIndex!.value, body),
    onSuccess: () => {
      queryClient.invalidateQueries([curIndex, 'SettingsJetOctopus']);
      setSnackbar({ severity: 'success', message: 'Saved' });
    },
  });

  let { data } = useQuery(
    [curIndex, 'SettingsJetOctopus'],
    () => getJetOctopusAccounts(curIndex!.value),
    { enabled: Boolean(curIndex) }
  );

  data = data || {};

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleSave = async ({ name, token }: FormData) => {
    mutate({ name, token });
  };

  const handleChangeProject = (event: SelectChangeEvent) => {
    const project_id = event.target.value as string;
    setProjectId(project_id);
    try {
      updateJetOctopusAccount(curIndex!.value, { project_id: project_id });
      setSnackbar({ severity: 'success', message: 'Saved' });
    } catch (err: any) {
      if (err?.response?.status === 403) {
        const msg = err.response.data?.errMsg;
        setSnackbar({
          severity: 'error',
          message: msg || 'Only admin can update.',
        });
      }
    }
  };
  return (
    <Box>
      <AddJetOctopusDialog
        open={open}
        onClose={handleClose}
        onSave={handleSave}
      />
      <Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Typography sx={{ fontSize: 18, fontWeight: 700 }}>
            JetOctopus Connections:
          </Typography>
          <Button onClick={handleOpen}>Connect</Button>
        </Box>
        <Box sx={{ mt: 2 }}>
          <Item
            key={data.id}
            id={data.id}
            index_id={data.index_id}
            name={data.name}
            token={data.token}
            is_active={data.is_active}
          />
        </Box>
      </Box>

      {data.id && (
        <Paper sx={{ mt: 4, p: 2 }}>
          <Typography variant="h4">Current Project:</Typography>
          <Box sx={{ mt: 2 }}>
            <FormControl fullWidth>
              <InputLabel>Project</InputLabel>
              <Select
                sx={{ width: 400 }}
                value={projectId}
                onChange={handleChangeProject}
              >
                {Array.isArray(data.projects) &&
                  data.projects?.map((item: any) => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.id} - {item.title}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Box>
        </Paper>
      )}
    </Box>
  );
};

export default JetOctopusConnection;
