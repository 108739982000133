import { Box, IconButton, Menu, MenuItem } from '@mui/material';
import { FC, useState } from 'react';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { useSnackbar } from 'contexts/snackbar-context';
import {
  deleteKeywordIngredientItem,
  postKeywordIngredientFetch,
  updateKeywordIngredientItem,
} from 'apis/keyword_ingredient';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import KWICreateEditDialog from './KWICreateEditDialog';
import { KeywordIngredientSourceType } from 'types/types';

type MoreMenuBtnProps = {
  indexId: number | string;
  source: KeywordIngredientSourceType;
  opporId?: number;
  ingredient: any;
};

const MoreMenuBtn: FC<MoreMenuBtnProps> = ({
  indexId,
  source,
  opporId,
  ingredient,
}) => {
  const showEditBtn = source === 'semrush' || source === 'labels';
  const ingredientId = ingredient.id;
  const { setSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const handleClickFetch = async () => {
    setAnchor(null);
    try {
      await postKeywordIngredientFetch(indexId, ingredientId, source, {
        opporId,
      });
      setSnackbar({ severity: 'success', message: 'Started.' });
      setTimeout(() => {
        queryClient.invalidateQueries([indexId, 'keyword-ingredient']);
      }, 3000);
      setTimeout(() => {
        queryClient.invalidateQueries([indexId, 'keyword-ingredient']);
      }, 10000);
    } catch (err: any) {
      if (err?.response?.status === 400) {
        const msg = err.response.data?.errMsg;
        if (msg) {
          setSnackbar({
            severity: 'error',
            message: msg,
            duration: 9999999999,
          });
        }
      }
    }
  };

  const { mutate: m_delete } = useMutation({
    mutationFn: () =>
      deleteKeywordIngredientItem(indexId, ingredientId, source),
    onSuccess: () => {
      queryClient.invalidateQueries([indexId, 'keyword-ingredient']);
      setSnackbar({ severity: 'success', message: 'Deleted!' });
    },
  });

  const [editDlgOpen, setEditDlgOpen] = useState(false);

  const handleEditDlgClose = () => {
    setEditDlgOpen(false);
    setAnchor(null);
  };

  const handleSaveEdit = async (params: any) => {
    const { domain, db, urlFilter: recipe, source } = params;
    const body = {
      domain,
      db,
      recipe,
      source,
    };
    await updateKeywordIngredientItem(indexId, ingredient.id, source, body);
    queryClient.invalidateQueries([indexId, 'keyword-ingredient']);
    setEditDlgOpen(false);
    setAnchor(null);
  };

  const handleClickResult = async () => {
    navigate(
      `/settings/${indexId}/kwingredient/${ingredientId}/jobs?source=${source}`
    );
  };
  const [anchor, setAnchor] = useState<null | HTMLElement>(null);
  const menuOpen = Boolean(anchor);
  const handleOpenMenu = (e: React.MouseEvent<HTMLButtonElement>) => {
    setAnchor(e.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchor(null);
  };

  const handleClickDelete = () => {
    setAnchor(null);
    m_delete();
  };

  return (
    <Box>
      {editDlgOpen && (
        <KWICreateEditDialog
          open={editDlgOpen}
          onClose={handleEditDlgClose}
          onSave={handleSaveEdit}
          ingredient={ingredient}
          initSource={source}
        />
      )}
      <IconButton onClick={handleOpenMenu}>
        <MoreHorizIcon />
      </IconButton>
      <Menu
        anchorEl={anchor}
        open={menuOpen}
        onClose={handleCloseMenu}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {showEditBtn && (
          <MenuItem onClick={() => setEditDlgOpen(true)}>Edit</MenuItem>
        )}
        <MenuItem sx={{ minWidth: 148 }} onClick={handleClickFetch}>
          Fetch
        </MenuItem>
        <MenuItem onClick={handleClickResult}>Result</MenuItem>
        <MenuItem onClick={handleClickDelete}>Delete</MenuItem>
      </Menu>
    </Box>
  );
};

export default MoreMenuBtn;
