import Layout from 'components/layout/Layout';
import KWJobResultMain from 'components/settings/views/keyword/job-result/KWJobResultMain';
import { FC } from 'react';

const KWIngreJobResPage: FC = () => {
  return (
    <Layout>
      <KWJobResultMain />
    </Layout>
  );
};

export default KWIngreJobResPage;
