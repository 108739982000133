import { Box, Button, Paper } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useUser } from 'contexts/user-context';
import { FC } from 'react';
import { useSnackbar } from 'contexts/snackbar-context';
import {
  getDocumentImportPresignedUrl,
  uploadFeedIngredients,
} from 'apis/feed_ingredient';
import axios from 'axios';
import UploadCSVTable from 'components/settings/views/upload-csv/UploadCSVTable';
import LoadingSpinner from 'components/shared/ui/LoadingSpinner';
import {
  REQUIRED_COLS,
  checkCSV,
} from 'components/settings/views/upload-csv/UploadCSVMain';

const SettingsProduct: FC = () => {
  const { curIndex } = useUser();

  // upload product feed
  const queryClient = useQueryClient();
  const { setSnackbar } = useSnackbar();
  const { mutate: m_upload } = useMutation({
    mutationFn: (body: { filename: string; type: string }) =>
      uploadFeedIngredients(curIndex!.value, body),
    onSuccess: () => {
      queryClient.invalidateQueries([curIndex, 'FeedIngredient']);
      setSnackbar({ severity: 'success', message: 'Uploaded!' });
    },
    onError: () => {
      setSnackbar({ severity: 'error', message: 'Upload failed' });
    },
  });
  const requiredCols = REQUIRED_COLS['pf'];

  const uploadHandler = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files && files.length > 0) {
      try {
        const file = files[0];
        const check = await checkCSV(file, requiredCols);
        if (!check) {
          e.target.value = '';
          setSnackbar({
            severity: 'error',
            message: `Column "${requiredCols.join(',')}" is not in csv file`,
          });
          return;
        }
        setSnackbar({ message: 'Uploading...', severity: 'info' });
        const filename = file.name;
        const filesize = (file.size / 1024 / 1024).toFixed(2);

        const { presigned_url } = await getDocumentImportPresignedUrl(
          curIndex!.value,
          {
            filename,
            type: 'pf',
          }
        );
        const options = {
          headers: {
            'Content-Type': 'application/octet-stream',
          },
          onUploadProgress: (progressEvent: { loaded: number }) =>
            setSnackbar({
              message: `Uploading...${(
                progressEvent.loaded /
                1024 /
                1024
              ).toFixed(2)}MB/${filesize}MB`,
              severity: 'info',
            }),
        };
        await axios.put(presigned_url, file, options);
        m_upload({ filename, type: 'pf' });
        setSnackbar({ message: 'File Uploaded!', severity: 'info' });
      } catch (err) {
        e.target.value = '';
        setSnackbar({
          message: 'Upload failed, please try again later',
          severity: 'error',
        });
      }
    }
    e.target.value = '';
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      <Paper sx={{ p: 2 }}>
        <Button variant="contained" component="label">
          Upload Product Feed
          <input
            hidden
            onChange={uploadHandler}
            multiple
            accept=".csv"
            type="file"
          />
        </Button>
        <Box sx={{ mt: 2 }}>
          {!curIndex && <LoadingSpinner />}
          {curIndex && (
            <UploadCSVTable indexId={curIndex!.value} isProductFeed={true} />
          )}
        </Box>
      </Paper>
    </Box>
  );
};

export default SettingsProduct;
