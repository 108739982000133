import React, { Suspense } from 'react';
import { useAuth } from 'contexts/auth-context';
import SignIn from 'pages/SignIn';
import { Route, Routes, Navigate } from 'react-router-dom';
import OpportunitesPage from 'pages/OpportunitiesPage';
import OptimizationPage from 'pages/OptimizationPage';
import WorkbenchPage from 'pages/WorkbenchPage';
import LoadingSpinner from 'components/shared/ui/LoadingSpinner';
import IndexDetailPage from 'pages/IndexDetailPage';
import SignUp from 'pages/SignUp';
import KWIngreJobResPage from 'pages/KWIngreJobResPage';
import PageIngreJobResPage from 'pages/PageIngreJobResPage';
import FeedInPreviewPage from 'pages/FeedInPreviewPage';
import DocumentEditPage from 'pages/DocumentEditPage';
import IndexPage from 'pages/IndexPage';

import { LicenseInfo } from '@mui/x-license-pro';
import DashboardPage from 'pages/DashboardPage';
import DashboardSegDetailPage from 'pages/DashboardSegDetailPage';
import Settings2Page from 'pages/Settings2Page';
import VerifyUser from 'pages/VerifyUser';
import ForgotPassword from 'pages/ForgotPassword';
import VerifyResetPassword from 'pages/VerifyResetPassword';
import LinkingVerificationResultPage from 'pages/LinkingVerificationResultPage';
import SiteTopicPage from 'pages/SiteTopicPage';
import SiteTopicReportLabelGroupPage from 'pages/SiteTopicReportLabelGroupPage';
import SiteTopicItemPage from 'pages/SiteTopicItemPage';

const muiXProlicense = process.env.REACT_APP_MUI_X_PRO_LICENSE!;
LicenseInfo.setLicenseKey(muiXProlicense);

const OptiDetailPage = React.lazy(() => import('pages/OptiDetailPage'));
const OpporDetailPage = React.lazy(() => import('pages/OpporDetailPage'));

function App() {
  const { user } = useAuth();
  let route = (
    <Routes>
      <Route path="/signin" element={<SignIn />} />
      <Route path="/signup" element={<SignUp />} />
      <Route path="/verify-user" element={<VerifyUser />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/verify-reset-password" element={<VerifyResetPassword />} />
      <Route path="*" element={<Navigate replace to="/signin" />} />
    </Routes>
  );
  if (user) {
    route = (
      <Suspense fallback={<LoadingSpinner />}>
        <Routes>
          <Route path="/index" element={<IndexPage />} />
          <Route path="/settings/New-Index" element={<IndexDetailPage />} />
          <Route path="/settings/:idx/edit" element={<IndexDetailPage />} />
          <Route path="/settings/:idx" element={<IndexDetailPage />} />
          <Route path="/settings/:idx/pages" element={<IndexDetailPage />} />
          <Route path="/settings/:idx/settings" element={<IndexDetailPage />} />
          <Route path="/settings/:idx/upload" element={<IndexDetailPage />} />
          <Route path="/settings/:idx/taxonomy" element={<IndexDetailPage />} />
          <Route path="/settings/:idx/jobs" element={<IndexDetailPage />} />
          <Route path="/settings/:idx/download" element={<IndexDetailPage />} />
          <Route path="/settings/:idx/function" element={<IndexDetailPage />} />
          <Route path="/settings2/:idx" element={<Settings2Page />} />
          <Route
            path="/settings2/:idx/index-config"
            element={<Settings2Page />}
          />
          <Route path="/settings2/:idx/variables" element={<Settings2Page />} />
          <Route path="/settings2/:idx/product" element={<Settings2Page />} />
          <Route
            path="/settings2/:idx/connections"
            element={<Settings2Page />}
          />
          <Route path="/settings2/:idx/usage" element={<Settings2Page />} />
          <Route
            path="/settings2/:idx/index-settings"
            element={<Settings2Page />}
          />
          <Route path="/settings2/:idx/fields" element={<Settings2Page />} />
          <Route
            path="/settings2/:idx/scheduled-actions"
            element={<Settings2Page />}
          />
          <Route
            path="/settings/:idx/kwingredient/:kwIngredientId/jobs"
            element={<KWIngreJobResPage />}
          />
          <Route
            path="/settings/:idx/pageingredient/:pageIngredientId/jobs"
            element={<PageIngreJobResPage />}
          />
          <Route
            path="/settings/:idx/feedingredient/:documentId/preview"
            element={<FeedInPreviewPage />}
          />
          <Route
            path="/settings/:idx/feedingredient/:documentId/edit"
            element={<DocumentEditPage />}
          />
          <Route path="/workbench/:idx" element={<WorkbenchPage />} />
          <Route path="/workbench/:idx/page" element={<WorkbenchPage />} />
          <Route path="/workbench/:idx/topic" element={<WorkbenchPage />} />
          <Route path="/workbench/:idx/report" element={<WorkbenchPage />} />
          <Route
            path="/workbench/:idx/report/:reportId"
            element={<SiteTopicItemPage />}
          />
          <Route
            path="/workbench/:idx/report/:reportId/top-label-groups"
            element={<SiteTopicItemPage />}
          />
          <Route
            path="/workbench/:idx/report/:reportId/top-topics"
            element={<SiteTopicItemPage />}
          />
          <Route
            path="/workbench/:idx/report/:reportId/top-grammar"
            element={<SiteTopicItemPage />}
          />
          <Route path="/opportunities/:idx" element={<OpportunitesPage />} />
          <Route path="/optimization/:idx" element={<OptimizationPage />} />
          <Route
            path="/optimization/:idx/:oppId"
            element={<OptiDetailPage />}
          />
          <Route
            path="/opportunities/:idx/New-Opportunities"
            element={<OpporDetailPage />}
          />
          <Route
            path="/opportunities/:idx/:oppId"
            element={<OpporDetailPage />}
          />
          <Route
            path="/opportunities/:idx/:oppId/Edit"
            element={<OpporDetailPage />}
          />
          <Route
            path="/opportunities/:idx/:oppId/keyword-ingredient"
            element={<OpporDetailPage />}
          />
          <Route
            path="/opportunities/:idx/:oppId/keyword"
            element={<OpporDetailPage />}
          />
          <Route
            path="/opportunities/:idx/:oppId/page"
            element={<OpporDetailPage />}
          />
          <Route
            path="/opportunities/:idx/:oppId/jobs"
            element={<OpporDetailPage />}
          />
          <Route
            path="/opportunities/:idx/:oppId/reports"
            element={<OpporDetailPage />}
          />
          <Route
            path="/opportunities/:idx/:oppId/reports/:reportId/top-label-groups"
            element={<SiteTopicItemPage />}
          />
          <Route
            path="/opportunities/:idx/:oppId/reports/:reportId"
            element={<SiteTopicItemPage />}
          />
          <Route
            path="/opportunities/:idx/:oppId/reports/:reportId/top-topics"
            element={<SiteTopicItemPage />}
          />
          <Route
            path="/opportunities/:idx/:oppId/reports/:reportId/top-grammar"
            element={<SiteTopicItemPage />}
          />
          <Route
            path="/opportunities/:idx/:oppId/downloads"
            element={<OpporDetailPage />}
          />
          <Route
            path="/opportunities/:idx/:oppId/linking-verification-result"
            element={<LinkingVerificationResultPage />}
          />
          <Route
            path="/optimization/:idx/:oppId/linking-verification-result"
            element={<LinkingVerificationResultPage />}
          />
          <Route path="/dashboard/:idx" element={<DashboardPage />} />
          <Route
            path="/dashboard/:idx/New-Segment"
            element={<DashboardSegDetailPage />}
          />
          <Route path="/site-topic/:idx" element={<SiteTopicPage />} />
          <Route
            path="/site-topic/:idx/:reportId/top-label-groups"
            element={<SiteTopicReportLabelGroupPage />}
          />
          <Route path="*" element={<Navigate replace to="/index" />} />
        </Routes>
      </Suspense>
    );
  }
  return route;
}

export default App;
