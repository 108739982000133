import {
  Button,
  Card,
  CardActions,
  CardContent,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { getIndex, postIndex, updateIndex } from 'apis';
import { MARKET_OPTIONS } from 'constants/constants';
import { useSnackbar } from 'contexts/snackbar-context';
import { useUser } from 'contexts/user-context';
import React, { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { MarketType } from 'types/types';

type Props = {
  indexId?: string;
  hideCancelBtn?: boolean;
};

const IndexNewEdit: FC<Props> = ({ indexId, hideCancelBtn }) => {
  const { allIndexes, setCurIndex, setAllIndexes } = useUser();
  const { setSnackbar } = useSnackbar();
  useEffect(() => {
    const curIdx = allIndexes.find((item) => item.id.toString() === indexId);
    if (curIdx) {
      setCurIndex({
        value: indexId!,
        label: curIdx.display_name,
        market: curIdx.market,
      });
    }
  }, [indexId, allIndexes, setCurIndex]);

  const isEdit = Boolean(indexId);
  const navigate = useNavigate();
  const { data, isLoading } = useQuery(
    ['index_item', indexId],
    () => getIndex(indexId!),
    {
      enabled: isEdit,
    }
  );
  const [name, setName] = useState('');
  const [domain, setDomain] = useState('');
  const [market, setMarket] = useState<MarketType>('us');
  useEffect(() => {
    if (data) {
      setName(data.name);
      setDomain(data.domain);
      setMarket(data.market);
    }
  }, [isLoading, data]);

  useEffect(() => {
    if (!isEdit) {
      const marketOption = MARKET_OPTIONS.find((item) => item.value === market);
      const _name = `${domain} ${marketOption?.label}`;
      setName(_name);
    }
  }, [isEdit, domain, market]);

  const handleChangeName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  };
  const handleChangeDomain = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDomain(e.target.value);
  };
  const handleChangeMarket = (e: React.ChangeEvent<HTMLInputElement>) => {
    const option = e.target.value as MarketType;
    setMarket(option);
  };
  const handleClickCancel = () => {
    navigate('/index');
  };
  const handleClickSave = async () => {
    if (isEdit) {
      await updateIndex(indexId!, name, domain, market);
      setAllIndexes((pre) =>
        pre.map((item) => {
          if (item.id.toString() === indexId?.toString()) {
            return { id: indexId, name, display_name: name, market: market };
          }
          return item;
        })
      );
      setCurIndex({ value: indexId!, label: name, market });
      setSnackbar({ severity: 'success', message: 'Updated.' });
    } else {
      const { id, msg, name: new_name } = await postIndex(name, domain, market);
      setAllIndexes((pre) => {
        return [
          ...pre,
          { id, name: new_name, display_name: new_name, market: market },
        ];
      });
      setCurIndex({ value: id.toString(), label: name, market });
      setSnackbar({
        severity: 'success',
        message: msg || `Index ${name} has been created.`,
      });
      navigate(`/settings/${id}`);
    }
  };
  const title = isEdit ? 'Edit Index' : 'Create Index';
  return (
    <Card sx={{}}>
      <CardContent sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <Typography variant="h3">{title}</Typography>
        <TextField
          autoFocus
          autoComplete="off"
          margin="dense"
          size="small"
          label="Index name"
          value={name}
          onChange={handleChangeName}
        />
        <TextField
          autoFocus
          autoComplete="off"
          margin="dense"
          size="small"
          label="domain"
          value={domain}
          onChange={handleChangeDomain}
        />
        <TextField
          select
          label="Select Market"
          value={market}
          onChange={handleChangeMarket}
          size="small"
          InputProps={{ style: { borderRadius: 0 } }}
        >
          {MARKET_OPTIONS.map((item) => (
            <MenuItem key={item.value} value={item.value}>
              {item.label}
            </MenuItem>
          ))}
        </TextField>
      </CardContent>
      <CardActions>
        {!hideCancelBtn && (
          <Button variant="outlined" onClick={handleClickCancel}>
            Cancel
          </Button>
        )}
        <Button variant="contained" onClick={handleClickSave}>
          Save
        </Button>
      </CardActions>
    </Card>
  );
};

export default IndexNewEdit;
