import Layout from 'components/layout/Layout';
import WorkbenchMain from 'components/workbench/WorkbenchMain';
import { FC } from 'react';

const WorkbenchPage: FC = () => {
  return (
    <Layout>
      <WorkbenchMain />
    </Layout>
  );
};

export default WorkbenchPage;
