import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Box,
  Typography,
} from '@mui/material';
import { FunctionComponent } from 'react';
import InfoIcon from '@mui/icons-material/Info';
import { useUser } from 'contexts/user-context';
import { useQuery } from '@tanstack/react-query';
import { postOpportunityItem } from 'apis/opportunities';
import LoadingSpinner from 'components/shared/ui/LoadingSpinner';
import { postWorkbenchPages, postWorkbenchSite } from 'apis/workbench';

type Props = {
  open: boolean;
  opporId: number | string;
  onClose: () => void;
  onApply: () => void;
};

const OpporDelDlg: FunctionComponent<Props> = ({
  open,
  opporId,
  onClose,
  onApply,
}) => {
  const { curIndex } = useUser();
  let { data: oppDetailData, isLoading } = useQuery(
    [curIndex, 'OpportunityDetail', opporId, 0, 0],
    () => postOpportunityItem(curIndex!.value, opporId!, null, 0, 0)
  );
  const isCleanupDup =
    oppDetailData?.type === 'cleanup' && oppDetailData?.feature === 'dup';

  let { data: dataCleanupRawSite } = useQuery(
    [curIndex, 'WB', 'site', 0, 0, oppDetailData],
    () =>
      postWorkbenchSite(curIndex!.value, {
        page: 0,
        offset: 0,
        back_query: oppDetailData?.recipe,
      }),
    {
      enabled:
        Boolean(curIndex) && oppDetailData?.type === 'cleanup' && !isCleanupDup,
    }
  );

  let { data: dataCleanupRawPage } = useQuery(
    [curIndex, 'WB', 'page', 0, 0, oppDetailData],
    () =>
      postWorkbenchPages(curIndex!.value, {
        page: 0,
        offset: 0,
        back_query: oppDetailData?.recipe,
      }),
    {
      enabled: Boolean(curIndex) && isCleanupDup,
    }
  );

  let warnMsg = ``;
  if (oppDetailData?.type === 'cleanup') {
    if (dataCleanupRawSite) {
      warnMsg = `There are ${dataCleanupRawSite?.count} entries in the opportunity.`;
    }
    if (dataCleanupRawPage) {
      warnMsg = `There are ${dataCleanupRawPage?.count} entries in the opportunity.`;
    }
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <Box>
        <DialogTitle sx={{ display: 'flex', alignItems: 'flex-start', gap: 1 }}>
          <InfoIcon color="info" />
          <Typography
            sx={{ fontWeight: 700 }}
          >{`Opportunity ${opporId} will be deleted, are you sure you want to continue?`}</Typography>
        </DialogTitle>
        <DialogContent>
          {isLoading && <LoadingSpinner />}
          {!isLoading && warnMsg && (
            <Typography color="error">{warnMsg}</Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button color="inherit" onClick={onClose}>
            Cancel
          </Button>
          <Button color="error" onClick={onApply}>
            Delete
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default OpporDelDlg;
