export const getPageIngredients = async (index: string) => {
  const axiosInstance = require('utils/utils-axios-instance').default;
  const data = await axiosInstance.get(`index/${index}/api/index-url`);
  return data.data;
};

export const postPageIngredientParseSitemap = async (
  index: string | number,
  body: any
) => {
  const axiosInstance = require('utils/utils-axios-instance').default;
  const data = await axiosInstance.post(
    `index/${index}/api/index-url/parse_sitemap`,
    body
  );
  return data.data;
};

export const postPageIngredients = async (
  index: string | number,
  body: any
) => {
  const axiosInstance = require('utils/utils-axios-instance').default;
  const data = await axiosInstance.post(`index/${index}/api/index-url`, body);
  return data.data;
};

export const uploadPageIngredients = async (
  index: string | number,
  body: { filename: string }
) => {
  const axiosInstance = require('utils/utils-axios-instance').default;
  const data = await axiosInstance.post(
    `index/${index}/api/index-url/upload`,
    body
  );
  return data.data;
};

export const deletePageIngredientItem = async (
  index: string | number,
  url_id: string | number
) => {
  const axiosInstance = require('utils/utils-axios-instance').default;
  const data = await axiosInstance.delete(
    `index/${index}/api/index-url/${url_id}`
  );
  return data.data;
};

export const updatePageIngredientItem = async (
  index: string | number,
  url_id: string | number,
  body: any
) => {
  const axiosInstance = require('utils/utils-axios-instance').default;
  const data = await axiosInstance.put(
    `index/${index}/api/index-url/${url_id}`,
    body
  );
  return data.data;
};

export const crawlPageIngredient = async (
  index: string | number,
  url_id: string | number
) => {
  const axiosInstance = require('utils/utils-axios-instance').default;
  const data = await axiosInstance.post(
    `index/${index}/api/index-url/${url_id}/crawl`
  );
  return data.data;
};
export const crawlPageIngredients = async (
  index: string | number,
  body: any
) => {
  const axiosInstance = require('utils/utils-axios-instance').default;
  const data = await axiosInstance.post(
    `index/${index}/api/index-url/crawl`,
    body
  );
  return data.data;
};

export const getPageIngredientCrawlItems = async (
  index: string | number,
  url_id: string | number,
  page: number,
  offset: number
) => {
  const axiosInstance = require('utils/utils-axios-instance').default;
  const data = await axiosInstance.get(
    `index/${index}/api/index-url/${url_id}/crawl/items?page=${page}&offset=${offset}`
  );
  return data.data;
};

export const getPageIngredientsPresignedUrl = async (
  index: string | number,
  body: { filename: string }
) => {
  const axiosInstance = require('utils/utils-axios-instance').default;
  const data = await axiosInstance.post(
    `index/${index}/api/presigned_url_page_ingredients`,
    body
  );
  return data.data;
};

export const getBotifyProjects = async (index: string) => {
  const axiosInstance = require('utils/utils-axios-instance').default;
  const data = await axiosInstance.get(
    `index/${index}/api/botify/project/list/`
  );
  return data.data;
};

export const getBotifyCrawls = async (index: string) => {
  const axiosInstance = require('utils/utils-axios-instance').default;
  const data = await axiosInstance.get(`index/${index}/api/botify/crawl/`);
  return data.data;
};
