import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  TextField,
} from '@mui/material';

import React, { FC, useState } from 'react';
import { useUser } from '../../../../../contexts/user-context';
import { LabelGroupItemType } from 'types/types';
import { useSnackbar } from 'contexts/snackbar-context';
import { patchLabel } from 'apis/index_labels';
import { useQueryClient } from '@tanstack/react-query';
import { LabelClassiferType } from './CreateLabelDialog';

type Props = {
  label: LabelGroupItemType;
  open: boolean;
  onClose: () => void;
  setItems?: React.Dispatch<React.SetStateAction<LabelGroupItemType[]>>;
};

const EditLabelDialog: FC<Props> = ({ label, open, onClose, setItems }) => {
  const queryClient = useQueryClient();
  const [name, setName] = useState(label?.name || '');
  const [labelClassifierType, setLabelClassifierType] = useState<
    LabelClassiferType | ''
  >(label?.label_classifier_type || '');
  const [description, setDescription] = useState(label?.description || '');
  const { curIndex } = useUser();
  const { setSnackbar } = useSnackbar();

  const submitHandler = async (e: React.FormEvent) => {
    e.preventDefault();
    onClose();
    await patchLabel(curIndex!.value, label!.id, {
      name,
      description,
      label_classifier_type: labelClassifierType || null,
    });
    if (setItems) {
      setItems((pre) => {
        const ret = [...pre];
        for (const item of pre) {
          if (+item.id === +label.id) {
            item.name = name;
          }
        }
        return ret;
      });
    } else {
      queryClient.invalidateQueries([curIndex, 'LabelGroups']);
    }
    setSnackbar({ severity: 'success', message: 'Updated' });
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <Box component="form" onSubmit={submitHandler}>
        <DialogTitle>Edit Label</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            autoComplete="off"
            margin="dense"
            label="Label Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            fullWidth
            variant="standard"
          />
          <TextField
            autoComplete="off"
            margin="dense"
            label="Label description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            fullWidth
            variant="standard"
          />
          <TextField
            sx={{ width: 300 }}
            margin="dense"
            variant="standard"
            label="Type"
            value={labelClassifierType}
            onChange={(e) =>
              setLabelClassifierType(e.target.value as LabelClassiferType)
            }
            select
          >
            <MenuItem value="ai">AI classifier</MenuItem>
            <MenuItem value="label_matcher">Phrase matcher</MenuItem>
          </TextField>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button type="submit">Save</Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default EditLabelDialog;
