import { FunctionComponent } from 'react';
import { Box, CircularProgress } from '@mui/material';

type Props = {
  color?:
    | 'primary'
    | 'inherit'
    | 'secondary'
    | 'error'
    | 'info'
    | 'success'
    | 'warning';
};
const LoadingSpinner: FunctionComponent<Props> = ({ color }) => {
  const _color = color || 'primary';
  return (
    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
      <CircularProgress color={_color} />
    </Box>
  );
};

export default LoadingSpinner;
