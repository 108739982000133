import { Box, Button, Typography } from '@mui/material';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { getOnCrawlAccounts, postOnCrawlAccounts } from 'apis/settings';
import { useSnackbar } from 'contexts/snackbar-context';
import { useUser } from 'contexts/user-context';
import { FC, useState } from 'react';
import AddOnCrawlDialog, { FormData } from '../dialog/AddOnCrawlDialog';

export type OnCrawlItemProps = {
  id: number;
  index_id: number;
  name: string;
  token: string;
  is_active: boolean;
};

const Item: FC<OnCrawlItemProps> = ({
  id,
  index_id,
  name,
  token,
  is_active,
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        gap: 2,
        alignItems: 'center',
        mt: 1,
      }}
    >
      <Typography sx={{ fontWeight: 700, textDecoration: 'none' }}>
        {name}
      </Typography>
      <Typography sx={{ fontWeight: 700, textDecoration: 'none' }}>
        {token?.replace(/^(.{4}).*(.{4})$/, '$1******$2')}
      </Typography>
    </Box>
  );
};

const OnCrawlConnection: FC = () => {
  const { curIndex } = useUser();
  const { setSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const { mutate } = useMutation({
    mutationFn: (body: any) => postOnCrawlAccounts(curIndex!.value, body),
    onSuccess: () => {
      queryClient.invalidateQueries([curIndex, 'SettingsOnCrawl']);
      setSnackbar({ severity: 'success', message: 'Saved' });
    },
  });

  let { data } = useQuery(
    [curIndex, 'SettingsOnCrawl'],
    () => getOnCrawlAccounts(curIndex!.value),
    { enabled: Boolean(curIndex) }
  );
  data = data || {};

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleSave = async ({ name, token }: FormData) => {
    mutate({ name, token });
  };

  return (
    <Box>
      <AddOnCrawlDialog open={open} onClose={handleClose} onSave={handleSave} />
      <Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Typography sx={{ fontSize: 18, fontWeight: 700 }}>
            OnCrawl Connections:
          </Typography>
          <Button onClick={handleOpen}>Connect</Button>
        </Box>
        <Box sx={{ mt: 2 }}>
          <Item
            key={data.id}
            id={data.id}
            index_id={data.index_id}
            name={data.name}
            token={data.token}
            is_active={data.is_active}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default OnCrawlConnection;
