import {
  Dialog,
  DialogContent,
  DialogTitle,
  Box,
  Typography,
  IconButton,
  List,
  ListItemButton,
  Input,
  InputAdornment,
  ListItem,
} from '@mui/material';
import { FunctionComponent, useMemo, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import { addRecipeStrAndOpField2Recipes } from 'utils/utils-query-builder';
import { useUser } from 'contexts/user-context';
import useQBFields from 'hooks/useQBFields';
import { InitialConfig, recipesB2F } from 'components/workbench/WBQBRecipe';
import { useQuery } from '@tanstack/react-query';
import { SharedRecipeSourceType } from 'types/types';
import { getSharedRecipes } from 'apis/shared_recipe';
import { postFunctionIngredient } from 'apis/function_ingredient';
import { isEmpty } from 'utils';

type Props = {
  open: boolean;
  onClose: () => void;
  source: SharedRecipeSourceType;
  onSelect: (recipe: any, operation?: any) => void;
};
const SharedRecipeSelectDlg: FunctionComponent<Props> = ({
  open,
  source,
  onClose,
  onSelect,
}) => {
  const { curIndex } = useUser();
  const { fields } = useQBFields({ source: 'site' });
  const config: any = useMemo(
    () => ({
      ...InitialConfig,
      fields,
    }),
    [fields]
  );
  const { data } = useQuery([curIndex, 'SharedRecipes', source], () =>
    getSharedRecipes(curIndex!.value, source)
  );

  let recipes: any = [];
  if (data) {
    recipes = recipesB2F(data);
  }
  const [filterStr, setFilterStr] = useState('');
  recipes = addRecipeStrAndOpField2Recipes(recipes, config);
  let filteredRecipes = recipes;
  if (filterStr) {
    filteredRecipes = recipes?.filter((item: any) => {
      if (item.name.toLowerCase().indexOf(filterStr.toLowerCase()) !== -1)
        return true;
      return false;
    });
  }

  const handleOnSelect = async (recipe: any, operation?: any) => {
    if (operation?.type === 'CHAT-GPT' && !isEmpty(operation.actions)) {
      const indexId = curIndex!.value;
      const dataFI = await postFunctionIngredient(indexId, {
        index: indexId,
        api_type: 'chatgpt',
        name: '',
        params: { prompt_template: operation.actions[0].param },
      });
      operation.actions[0].subject = dataFI.id;
    }
    onSelect(recipe, operation);
  };

  return (
    <Dialog open={open} onClose={onClose} fullScreen sx={{ m: 4 }}>
      <DialogTitle
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          backgroundColor: '#f2f2f2',
        }}
      >
        <Typography sx={{ fontWeight: 700, fontSize: 22 }}>
          Select a recipe from Cookbook
        </Typography>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ backgroundColor: '#f2f2f2' }}>
        <Box>
          <Input
            sx={{ backgroundColor: '#fff' }}
            value={filterStr}
            onChange={(e) => setFilterStr(e.target.value)}
            startAdornment={
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            }
          />
        </Box>
        <List
          sx={{
            mt: 2,
            display: 'grid',
            gridTemplateColumns: '1fr 1fr 1fr 1fr',
            gap: 2,
            backgroundColor: '#f2f2f2',
          }}
        >
          {filteredRecipes?.map((item: any) => (
            <ListItem key={item.id} sx={{ p: 2, backgroundColor: '#fff' }}>
              <ListItemButton
                onClick={handleOnSelect.bind(null, item.recipe, item.operation)}
                sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}
              >
                <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                  <Typography sx={{ fontWeight: 500 }}>
                    {item.id + ' - ' + item.name}
                  </Typography>
                  <Typography variant="body2" color="text.disabled">
                    by {item.username}
                  </Typography>
                </Box>
                {item.recipeStr && (
                  <Typography variant="caption" color="text.secondary">
                    {item.recipeStr}
                  </Typography>
                )}
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </DialogContent>
    </Dialog>
  );
};

export default SharedRecipeSelectDlg;
