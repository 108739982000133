import { Box, Typography } from '@mui/material';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { FunctionComponent } from 'react';

ChartJS.register(ArcElement, Tooltip, Legend);

type Props = {
  labels: (string | number)[];
  _data: number[];
  title: string;
};

const DoughnutChart: FunctionComponent<Props> = ({ labels, _data, title }) => {
  const data = {
    labels,
    datasets: [
      {
        label: '',
        data: _data,
        backgroundColor: [
          'rgba(255, 99, 132, 0.2)',
          'rgba(54, 162, 235, 0.2)',
          'rgba(255, 206, 86, 0.2)',
          'rgba(75, 192, 192, 0.2)',
          'rgba(153, 102, 255, 0.2)',
          'rgba(255, 159, 64, 0.2)',
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
          'rgba(153, 102, 255, 1)',
          'rgba(255, 159, 64, 1)',
        ],
        borderWidth: 1,
      },
    ],
  };
  const options = {
    responsive: true,
    maintainAspectRatio: false,
  };

  return (
    <Box>
      <Typography
        sx={{ textAlign: 'center', fontSize: 12, fontWeight: 700, mb: 1 }}
      >
        {title}
      </Typography>
      <Box sx={{ height: '280px' }}>
        <Doughnut data={data} options={options} />
      </Box>
    </Box>
  );
};

export default DoughnutChart;
