import { GridColDef, GridComparatorFn } from '@mui/x-data-grid-pro';
import { OperationOptionType } from 'components/workbench/OperationBuilder';
import { VARIABLE_COLUMN_PREFIX } from 'constants/constants';
import { OptionType } from 'types/types';

export const deepCopy = (obj: Object): Object => {
  return JSON.parse(JSON.stringify(obj));
};

export const isEmpty = (item: any): boolean => {
  if (!item) return true;
  if (Array.isArray(item)) return item.length === 0;
  // empty query builder
  if (typeof item === 'object' && 'children1' in item) {
    const children1 = item.children1;
    if (Array.isArray(children1) && children1.length === 1) {
      const child = children1[0];
      if ('properties' in child) {
        if ('field' in child.properties) {
          if (child.properties.field === null) {
            return true;
          }
        }
      }
    }
  }
  if (typeof item === 'object') {
    return Object.keys(item).length === 0;
  }

  return false;
};

//https://stackoverflow.com/questions/7616461/generate-a-hash-from-string-in-javascript
export const hash = (str: string, seed = 0) => {
  let h1 = 0xdeadbeef ^ seed,
    h2 = 0x41c6ce57 ^ seed;
  for (let i = 0, ch; i < str.length; i++) {
    ch = str.charCodeAt(i);
    h1 = Math.imul(h1 ^ ch, 2654435761);
    h2 = Math.imul(h2 ^ ch, 1597334677);
  }

  h1 =
    Math.imul(h1 ^ (h1 >>> 16), 2246822507) ^
    Math.imul(h2 ^ (h2 >>> 13), 3266489909);
  h2 =
    Math.imul(h2 ^ (h2 >>> 16), 2246822507) ^
    Math.imul(h1 ^ (h1 >>> 13), 3266489909);

  return 4294967296 * (2097151 & h2) + (h1 >>> 0);
};

export const queryBuilderFields2OperationOptions = (
  fields: any
): OperationOptionType[] => {
  const ret: OperationOptionType[] = [];

  for (const groupKey in fields) {
    const group = fields[groupKey];
    if ('subfields' in group) {
      for (const fieldKey in group.subfields) {
        const _field = group.subfields[fieldKey];
        ret.push({ label: _field.label, type: 'ARRAY', value: fieldKey });
      }
    }
  }
  return ret;
};

export const columns2ActionOptions = (
  columns: GridColDef[],
  variableFields?: string[]
): OptionType[] => {
  const ret: OptionType[] = [];
  for (const col of columns) {
    ret.push({ value: col.field, label: col.headerName || col.field });
  }
  const columnsFieldsSet = new Set(columns.map((col) => col.field));
  if (variableFields) {
    const variableFieldsSet = new Set(variableFields);
    variableFieldsSet.forEach((field) => {
      const value = VARIABLE_COLUMN_PREFIX + field;
      if (columnsFieldsSet.has(value)) return;
      ret.push({
        value,
        label: value,
      });
    });
  }
  return ret;
};

/**
 * 
 * @param varable 
{
    "id": 32,
    "name": "product_type",
    "enabled": true,
    "type": "enum",
    "options": [
        "Clothing",
        "Shoes",
        "Bags",
        "Accessories",
        "Jewelry"
    ]
}
 */
export const variable2QBConfig = (variable: any) => {
  const label = VARIABLE_COLUMN_PREFIX + variable.name;
  let type = variable.type || 'text';
  let fieldSettings: unknown;
  if (variable.options && variable.options.length > 0 && type === 'enum') {
    type = 'select';
    fieldSettings = {
      listValues: variable.options.map((option: string) => ({
        value: option,
        title: option,
      })),
    };
  }
  return { label, type, fieldSettings, valueSources: ['value'] };
};

/**
 *
 * @param backFileName: dev_import-documents/20230106/import_doc.csv
 * @returns import_doc.csv
 */
export const convertFileName = (backFileName: string) => {
  let ret = backFileName;
  const fileNameArr = backFileName.split('/');
  if (fileNameArr.length > 0) {
    const len = fileNameArr.length;
    ret = fileNameArr[len - 1];
  }
  return ret;
};

export const getFieldsFromGroups = (groups: any) => {
  if (!groups) return [];
  const ret: any[] = [];
  for (const groupKey in groups) {
    const group = groups[groupKey];
    if ('subfields' in group) {
      for (const fieldKey in group.subfields) {
        const _field = group.subfields[fieldKey];
        ret.push({
          type: _field.type,
          value: fieldKey,
          label: _field.label,
        });
      }
    }
  }
  return ret;
};

export const initTableDataFromDataRaw = (dataRaw: any) => {
  let ret: any = { count: 0, items: [] };
  if (dataRaw) {
    ret.count = dataRaw.count;
    ret.items = dataRaw.items.map((o: any, id: number) => ({ id, ...o }));
  }
  for (let item of ret.items) {
    const vars = [];
    if ('variables' in item) {
      const variables = item.variables;
      for (const key in variables) {
        const value = variables[key];
        vars.push({ key: '$' + key, value });
      }
    }
    for (const variable of vars) {
      item[variable.key] = variable.value;
    }
  }
  return ret;
};

export const toLocaleString = (value: any) => {
  if (Number.isNaN(value)) return value;
  const val = value.toLocaleString('en-US');
  return val;
};

export const sleep = (ms: number) =>
  new Promise((resolve) => setTimeout(resolve, ms));

export const addDelimiter = (
  str: string,
  offset: number,
  delimiter: string
) => {
  const len = str.length;
  if (len <= offset) return str;
  const arr = [];
  let i = 0;
  while (i < len) {
    arr.push(str.slice(i, i + offset));
    i += offset;
  }
  return arr.join(delimiter);
};

export const generateBackQueryFromTopicIds = (ids: string[]) => {
  return {
    valid: true,
    condition: 'AND',
    rules: [
      {
        id: '_id',
        field: '_id',
        input: 'text',
        value: ids,
        operator: 'in',
        type: 'string',
      },
    ],
  };
};

export const dateComparator: GridComparatorFn<string> = (
  v1: string,
  v2: string
) => {
  return new Date(v1).getTime() - new Date(v2).getTime();
};

export const editDistance = (s1: string, s2: string) => {
  const [N1, N2] = [s1.length, s2.length];
  const cache = new Map<string, number>();
  const dp = (i: number, j: number) => {
    if (i === N1) return N2 - j;
    if (j === N2) return N1 - i;
    const key = i + ',' + j;
    if (cache.has(key)) return cache.get(key)!;
    const [c1, c2] = [s1[i], s2[j]];
    const ret1 = 1 + dp(i + 1, j);
    const ret2 = 1 + dp(i, j + 1);
    let ret3 = dp(i + 1, j + 1);
    if (c1 !== c2) {
      ret3 += 1;
    }
    const ret = Math.min(ret1, ret2, ret3);
    cache.set(key, ret);
    return ret;
  };
  const ret = dp(0, 0);
  return ret;
};

export const getErrMsg = (e: any) => {
  let errMsg;
  const errStatus = e?.response?.status;
  const errData = e.response?.data;
  if (errData) {
    if (errData.error) {
      errMsg = errData.error;
    }
    if (errData.errMsg) {
      errMsg = errData.errMsg;
    }
  }
  if (!errMsg && errStatus < 500) {
    errMsg = 'Bad request';
  }
  if (!errMsg) {
    errMsg = 'Server error';
  }
  return errMsg;
};
