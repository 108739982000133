import {
  DataGridPro,
  GridColDef,
  GridSortModel,
  useGridApiRef,
} from '@mui/x-data-grid-pro';

import { FC, useCallback } from 'react';
import { ColOrderType } from 'types/types';
import renderPreviewChatGPT from '../../shared/table-cell/ChatGPTPreviewCell';
import { renderArray } from '../../shared/table-cell/ArrayCell';
import { getNumber } from '../../shared/table-cell';
import { renderUrl } from '../../shared/table-cell/UrlCell';
import { renderVariable } from 'components/shared/table-cell/VariableCell';
import { renderJson } from 'components/shared/table-cell/JsonCell';

export const getInitCols = (): GridColDef[] => {
  return [
    {
      field: 'id',
      headerClassName: 'App-Mui-DataGrid-Header',
    },
    {
      field: 'preview_chatgpt',
      headerClassName: 'App-Mui-DataGrid-Header',
      headerName: 'Preview ChatGPT',
      renderCell: renderPreviewChatGPT,
      width: 400,
    },
    {
      field: '$preview_variable',
      headerClassName: 'App-Mui-DataGrid-Header',
      headerName: 'Preview Variable',
      renderCell: renderVariable,
      width: 300,
    },
    {
      field: 'keyword',
      headerClassName: 'App-Mui-DataGrid-Header',
      width: 200,
    },
    {
      field: 'sitetopic_labels',
      headerClassName: 'App-Mui-DataGrid-Header',
      renderCell: renderJson,
      width: 300,
    },
    {
      field: 'volume',
      headerClassName: 'App-Mui-DataGrid-Header',
      valueGetter: getNumber,
      width: 120,
    },
    {
      field: 'impressions',
      headerClassName: 'App-Mui-DataGrid-Header',
      valueGetter: getNumber,
      width: 120,
    },
    {
      field: 'url',
      renderCell: renderUrl,
      headerClassName: 'App-Mui-DataGrid-Header',
      minWidth: 300,
    },
    {
      field: 'position',
      headerName: 'position',
      headerClassName: 'App-Mui-DataGrid-Header',
      valueGetter: (param) => (param?.value ? param.value.toFixed(2) : ''),
      width: 120,
    },
    {
      field: 'traffic',
      headerClassName: 'App-Mui-DataGrid-Header',
      valueGetter: getNumber,
      width: 120,
    },
    {
      field: 'competitive_url',
      headerName: 'best competitive url',
      headerClassName: 'App-Mui-DataGrid-Header',
      minWidth: 300,
      renderCell: renderUrl
    },
    {
      field: 'competitive_position',
      headerName: 'best competitive position',
      headerClassName: 'App-Mui-DataGrid-Header',
      width: 180,
    },
    {
      field: 'cpc',
      headerClassName: 'App-Mui-DataGrid-Header',
      width: 120,
      valueGetter: getNumber,
    },
    {
      field: 'source',
      headerClassName: 'App-Mui-DataGrid-Header',
      renderCell: renderArray,
      width: 200,
    },
    {
      field: 'created',
      headerClassName: 'App-Mui-DataGrid-Header',
      width: 140,
    },
  ];
};
type Props = {
  data: any;
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  offset: number;
  setOffset: React.Dispatch<React.SetStateAction<number>>;
  setOrder: React.Dispatch<React.SetStateAction<ColOrderType>>;
  isLoading: boolean;
  setColsDisplayOrder: React.Dispatch<
    React.SetStateAction<Record<string, number>>
  >;
  setColsWidth: React.Dispatch<React.SetStateAction<Record<string, number>>>;
  columns: GridColDef[];
};

const WBKWTable: FC<Props> = ({
  data,
  page,
  setPage,
  offset,
  setOffset,
  isLoading,
  setOrder,
  setColsDisplayOrder,
  setColsWidth,
  columns,
}) => {
  const handleSorting = useCallback(
    (sortModel: GridSortModel) => {
      const _sort = sortModel[0];
      if (!_sort) return;
      setOrder({
        name: _sort.field,
        direction: _sort.sort as 'asc' | 'desc',
      });
    },
    [setOrder]
  );
  const apiRef = useGridApiRef();

  return (
    <DataGridPro
      apiRef={apiRef}
      pagination
      loading={isLoading}
      autoHeight
      getRowHeight={() => 'auto'}
      columns={columns}
      rows={data.items}
      paginationMode="server"
      sortingMode="server"
      rowCount={data?.count || 0}
      pageSize={offset}
      onPageSizeChange={setOffset}
      page={page}
      onPageChange={setPage}
      rowsPerPageOptions={[10, 25, 100]}
      onSortModelChange={handleSorting}
      onColumnOrderChange={() => {
        const cols = apiRef.current.getAllColumns().map((item) => item.field);
        const colsDisOrder: Record<string, number> = {};
        cols.forEach((col, idx) => {
          colsDisOrder[col] = idx;
        });
        setColsDisplayOrder(colsDisOrder);
      }}
      onColumnWidthChange={(params) => {
        setColsWidth((pre) => ({
          ...pre,
          [params.colDef.field]: params.width,
        }));
      }}
      disableDensitySelector
      disableColumnFilter
      sx={{
        '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': {
          py: '12px',
          borderBottomColor: '#eee',
        },
        '&.MuiDataGrid-root--densityStandard .MuiDataGrid-columnSeparator': {
          color: '#ccc',
        },
        display: 'flex',
        flexDirection: 'column-reverse',
      }}
    />
  );
};
export default WBKWTable;
