import { Box, Button, MenuItem, TextField, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import {
  linkingVerificationResult,
  linkingVerificationResultSummary,
  verify,
} from 'apis/opportunities';
import { useUser } from 'contexts/user-context';
import { FC, useState } from 'react';
import { useParams } from 'react-router-dom';
import LinkingVerificationResultTable from './LinkingVerificationResultTable';
import LVRSummarytTable from './LVRSummaryTable';
import ScheduleIcon from '@mui/icons-material/Schedule';
import { useSnackbar } from 'contexts/snackbar-context';

type VerificationSelectorType = 'all' | 'pass' | 'not_pass';

const LinkingVerificationResultMain: FC = () => {
  const { curIndex } = useUser();
  const { setSnackbar } = useSnackbar();
  const params = useParams();
  const oppId = params.oppId!;
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [verificationSelector, setVerificationSelector] =
    useState<VerificationSelectorType>('all');
  let valid: any = null;
  if (verificationSelector === 'not_pass') valid = false;
  if (verificationSelector === 'pass') valid = true;
  const { data, isLoading } = useQuery(
    [curIndex, 'LinkingVerificationResult', oppId, page, pageSize, valid],
    () =>
      linkingVerificationResult(curIndex!.value, oppId, {
        page,
        pageSize,
        valid,
      }),
    { enabled: Boolean(curIndex) }
  );
  const items = data?.items || [];
  const itemsCount = data?.count || 0;

  const { data: dataSummary, isLoading: isLoadingSummary } = useQuery(
    [curIndex, 'LinkingVerificationResultSummary', oppId],
    () => linkingVerificationResultSummary(curIndex!.value, oppId),
    { enabled: Boolean(curIndex), staleTime: 60 * 1000 * 5 }
  );

  const handleScheduleVerification = async () => {
    await verify(curIndex!.value, oppId);
    setSnackbar({ severity: 'success', message: 'Job started.' });
  };

  return (
    <Box sx={{ m: 3 }}>
      <Box sx={{ my: 2, display: 'flex ', justifyContent: 'flex-end' }}>
        <Button
          disabled={!Boolean(curIndex)}
          onClick={handleScheduleVerification}
          variant="contained"
          size="large"
          endIcon={<ScheduleIcon />}
        >
          Schedule verification
        </Button>
      </Box>
      <Box>
        <Typography variant="h6">
          Linking verification Result Summary
        </Typography>
        <Box sx={{ mt: 2 }}>
          <LVRSummarytTable
            dataRaw={dataSummary}
            isLoading={isLoadingSummary}
          />
        </Box>
      </Box>
      <Box sx={{ mt: 4 }}>
        <Typography variant="h6">Linking verification Result</Typography>
        <Box sx={{ my: 2, display: 'flex', justifyContent: 'flex-end' }}>
          <TextField
            sx={{ minWidth: 200 }}
            select
            size="small"
            label="Verification filter"
            value={verificationSelector}
            onChange={(e: any) => setVerificationSelector(e.target.value)}
          >
            <MenuItem value="all">All</MenuItem>
            <MenuItem value="pass">Passed verification</MenuItem>
            <MenuItem value="not_pass">Not passed verification</MenuItem>
          </TextField>
        </Box>
        <Box sx={{ mt: 2 }}>
          <LinkingVerificationResultTable
            page={page}
            setPage={setPage}
            setPageSize={setPageSize}
            pageSize={pageSize}
            isLoading={isLoading}
            items={items}
            itemsCount={itemsCount}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default LinkingVerificationResultMain;
