import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@mui/material';
import React, { FC, useState } from 'react';
import { useUser } from '../../../../../contexts/user-context';
import {
  postCreateLabel,
  postMoreLabelsByChatGPT,
  postNewLabelsByChatGPT,
} from 'apis/index_labels';
import LoadingSpinner from 'components/shared/ui/LoadingSpinner';
import { useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'contexts/snackbar-context';
import { getErrMsg } from 'utils';

type Props = {
  open: boolean;
  onClose: () => void;
  onSave: () => void;
};

const ChatGptLabelGroupDlg: FC<Props> = ({ open, onClose, onSave }) => {
  const { curIndex } = useUser();
  const queryClient = useQueryClient();
  const [labelDescription, setLabelDescription] = useState('');
  const [labelName, setLabelName] = useState('');
  const [count, setCount] = useState(10);
  const [loading, setLoading] = useState(false);
  const [labels, setLabels] = useState<string[]>([]);
  const { setSnackbar } = useSnackbar();

  const submitHandler = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      await postCreateLabel({
        index: curIndex!.value,
        canonical_label_name: labelName || labelDescription,
        label_names: labels,
      });
      queryClient.invalidateQueries([curIndex, 'LabelGroups']);
      onSave();
    } catch (e) {
      const errMsg = getErrMsg(e);
      setSnackbar({ severity: 'error', message: errMsg });
    }
  };

  const handleClickGenerateBaseOnLabelDescription = async () => {
    setLoading(true);
    let data: any;
    try {
      data = await postNewLabelsByChatGPT(curIndex!.value, {
        label_name: labelDescription,
        number: count,
      });
      setLoading(false);
    } catch (e: any) {
      setLoading(false);
      const errMsg = getErrMsg(e);
      setSnackbar({ severity: 'error', message: errMsg });
    }
    if (data?.labels) {
      setLabels(data.labels);
    }
  };

  const handleClickGenerateMore = async () => {
    setLoading(true);
    let data: any;
    try {
      data = await postMoreLabelsByChatGPT(curIndex!.value, {
        labels: labels,
        number: count,
      });
      setLoading(false);
    } catch (e: any) {
      setLoading(false);
    }
    if (data?.labels) {
      setLabels((pre) => [...pre, ...data.labels]);
    }
  };

  const [manualLabel, setManualLabel] = useState('');
  const handleClickAddLabel = () => {
    setLabels((pre) => [manualLabel, ...pre]);
  };

  const handleDeleteLabel = (label: string) => {
    setLabels((pre) => pre.filter((item) => item !== label));
  };

  return (
    <Dialog open={open} onClose={onClose} fullScreen sx={{ p: 8 }}>
      <Box component="form" onSubmit={submitHandler}>
        <DialogTitle>AI label group</DialogTitle>
        <DialogContent>
          <Box sx={{ mb: 2 }}>
            <TextField
              autoComplete="off"
              margin="dense"
              label="Label group name"
              value={labelName}
              onChange={(e) => setLabelName(e.target.value)}
              fullWidth
              variant="standard"
            />
          </Box>
          <Box sx={{ display: 'flex', gap: 2 }}>
            <TextField
              autoComplete="off"
              margin="dense"
              label="Describe your label group"
              value={labelDescription}
              onChange={(e) => setLabelDescription(e.target.value)}
              fullWidth
              variant="standard"
            />
            <TextField
              autoComplete="off"
              type="number"
              margin="dense"
              label="Label Count"
              value={count}
              onChange={(e) => setCount(parseInt(e.target.value))}
              variant="standard"
            />
          </Box>
          <Box sx={{ mt: 2 }}>
            <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
              <Button
                variant="contained"
                onClick={handleClickGenerateBaseOnLabelDescription}
              >
                Generate labels
              </Button>
              <Button
                disabled={labels.length === 0}
                variant="contained"
                onClick={handleClickGenerateMore}
              >
                Generate More
              </Button>
            </Box>
            <Box sx={{ display: 'flex', gap: 2, my: 2 }}>
              <TextField
                variant="standard"
                label="Please enter label"
                value={manualLabel}
                onChange={(e) => setManualLabel(e.target.value)}
              />
              <Button color="info" onClick={handleClickAddLabel}>
                Add label
              </Button>
            </Box>
            <Box sx={{ display: 'flex', gap: 2, flexWrap: 'wrap', mt: 4 }}>
              {labels.map((item: string, i: number) => (
                <Chip
                  key={i}
                  label={item}
                  onDelete={handleDeleteLabel.bind(null, item)}
                />
              ))}
            </Box>
            <Box sx={{ mt: 1 }}>{loading && <LoadingSpinner />}</Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button type="submit">Save</Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default ChatGptLabelGroupDlg;
