import { Box, Button, Tab, Tabs } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { postKeywordIngredient } from 'apis/keyword_ingredient';
import { useSnackbar } from 'contexts/snackbar-context';
import { FC, useState } from 'react';
import KWICreateEditDialog, { OnSaveParams } from './KWICreateEditDialog';
import KWIngredientTable from './KWIngredientTable';
import { KeywordIngredientSourceType } from 'types/types';
import { getErrMsg } from 'utils';

type Props = {
  indexId: string;
  opporId?: number;
};

const INGREDIENT_SOURCE_LOOKUP: KeywordIngredientSourceType[] = [
  'gsc',
  'semrush',
  'labels',
  'upload',
  'product',
  'site_topic',
];

const KWIngredientMain: FC<Props> = ({ indexId, opporId }) => {
  const queryClient = useQueryClient();
  const { setSnackbar } = useSnackbar();
  const { mutate } = useMutation({
    mutationFn: (body: any) => postKeywordIngredient(indexId, body),
    onSuccess: () => {
      queryClient.invalidateQueries([indexId, 'keyword-ingredient']);
      setSnackbar({ severity: 'success', message: 'Created' });
      setIsCreateDialogOpen(false);
    },
    onError: (e) => {
      const errMsg = getErrMsg(e);
      setSnackbar({ severity: 'error', message: errMsg });
    },
  });

  const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false);

  const handleCreateDialogClose = () => {
    setIsCreateDialogOpen(false);
  };

  const handleSave = (params: OnSaveParams) => {
    const {
      domain,
      siteUrl: site_url,
      db,
      urlFilter: url_filter,
      source,
      selectedLabelGroups,
      upload_url,
      gsc_month_range,
      docId,
      name,
      grammar,
      min_listing,
      max_listing,
    } = params;
    const body = {
      domain,
      site_url,
      db,
      recipe: url_filter,
      source,
      selected_label_groups: selectedLabelGroups,
      gsc_month_range,
      upload_url,
      docId,
      opporId,
      name,
      grammar,
      min_listing,
      max_listing,
    };
    mutate(body);
  };
  const handleClickCreateBtn = () => {
    setIsCreateDialogOpen(true);
  };
  const [tab, setTab] = useState(0);
  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setTab(newValue);
  };
  return (
    <Box>
      {isCreateDialogOpen && (
        <KWICreateEditDialog
          open={isCreateDialogOpen}
          onClose={handleCreateDialogClose}
          onSave={handleSave}
          initSource={INGREDIENT_SOURCE_LOOKUP[tab]}
        />
      )}
      <Box
        sx={{
          display: 'flex',
          my: 2,
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Tabs value={tab} onChange={handleChangeTab}>
          <Tab label="GSC" />
          <Tab label="Competitor" />
          <Tab label="Labels" />
          <Tab label="Upload" />
          <Tab label="Product" />
          <Tab label="Site Topic" />
        </Tabs>
        <Button variant="outlined" onClick={handleClickCreateBtn}>
          Add New
        </Button>
      </Box>
      <KWIngredientTable
        indexId={indexId}
        ingredientSource={INGREDIENT_SOURCE_LOOKUP[tab]}
        opporId={opporId}
      />
    </Box>
  );
};

export default KWIngredientMain;
