import { List, Paper, Typography, Box, Button, Avatar } from '@mui/material';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  deleteFunctionIngredients,
  getFunctionIngredients,
  postFunctionIngredient,
  updateFunctionIngredient,
} from 'apis/function_ingredient';
import { useSnackbar } from 'contexts/snackbar-context';
import { useUser } from 'contexts/user-context';
import { FC, useState } from 'react';
import FunctionIngredientDialog, { ApiType } from './FunctionIngredientDialog';
import ReactJson from 'react-json-view';

type TagTextProps = {
  rawStr: string;
  maxLen?: number;
};
export const TagText: FC<TagTextProps> = ({ rawStr, maxLen }) => {
  const length = rawStr?.length || 0;

  const isTagStart = (i: number) => {
    if (i >= length - 7) return false;
    if (rawStr[i] === '[' && rawStr[i + 1] === '[' && rawStr[i + 2] === '{')
      return true;
    return false;
  };

  const isTagEnd = (i: number) => {
    if (rawStr[i] === '}' && rawStr[i + 1] === ']' && rawStr[i + 2] === ']')
      return true;
    return false;
  };

  const arr: string[] = [];
  let [start, end] = [0, 0];
  try {
    while (end <= length - 1) {
      if (isTagStart(end)) {
        start = end + 2;
        end = start + 1;
        while (!isTagEnd(end)) end += 1;
        const tagObj = JSON.parse(rawStr.slice(start, end + 1));
        arr.push('##.' + tagObj.value);
        start = end + 3;
        if (start >= length - 1) break;
        end = start;
      }
      arr.push(rawStr[end]);
      if (maxLen && arr.length > maxLen) {
        arr.push(' ...');
        break;
      }
      end += 1;
    }
  } catch {}
  return (
    <Typography
      variant="body2"
      sx={{
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
      }}
    >
      {arr.map((str, i) => {
        if (str.startsWith('##.')) {
          str = str.slice(3);
          return (
            <Box
              key={i}
              sx={{ p: 0.6, backgroundColor: '#ddd', borderRadius: 1 }}
            >
              {str}
            </Box>
          );
        }
        const isSpace = str === ' ';
        return (
          <Box key={i} component="span" sx={{ mx: isSpace ? 0.3 : 0 }}>
            {str}
          </Box>
        );
      })}
    </Typography>
  );
};

export type FunctionIngredientParamsType = {
  prompt_template: string;
  voice?: string;
  validation_limits?: {
    seo_title_char_min_count: number;
    seo_title_char_max_count: number;
  };
};

export type ItemType = {
  id: number;
  api_key: string;
  name?: string;
  api_type: ApiType;
  model_name: string;
  disabled: boolean;
  index: number | null;
  created: string;
  updated: string;
  params: FunctionIngredientParamsType;
};

type ItemProps = {
  item: ItemType;
};

const Item: FC<ItemProps> = ({ item }) => {
  const { curIndex } = useUser();
  const queryClient = useQueryClient();
  const { setSnackbar } = useSnackbar();
  const { mutate: m_delete } = useMutation(
    () => deleteFunctionIngredients(curIndex!.value, item.id),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([curIndex, 'FunctionIngredient']);
        setSnackbar({ severity: 'success', message: 'Deleted' });
      },
    }
  );
  const handleClickDelete = () => {
    m_delete();
  };

  const { mutate: m_update } = useMutation(
    (body: any) => updateFunctionIngredient(curIndex!.value, item.id, body),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([curIndex, 'FunctionIngredient']);
        setSnackbar({ severity: 'success', message: 'Updated' });
      },
    }
  );

  const { mutate: m_copy } = useMutation(
    (body: any) => postFunctionIngredient(curIndex!.value, body),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([curIndex, 'FunctionIngredient']);
        setSnackbar({ severity: 'success', message: 'Copied' });
      },
    }
  );

  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const handleEditDialogClose = () => {
    setIsEditDialogOpen(false);
  };
  const handleEdit = (data: any) => {
    const { api_key, api_type, model_name, params, name } = data;
    const body = {
      api_key,
      name,
      api_type,
      model_name,
      params,
    };
    m_update(body);
  };

  const handleCopy = (data: any) => {
    const { api_type, params, name } = data;
    const body = {
      index: curIndex!.value,
      api_type,
      name,
      params,
    };
    m_copy(body);
  };

  const params = item.params;

  return (
    <>
      {isEditDialogOpen && (
        <FunctionIngredientDialog
          key={item.id}
          open={isEditDialogOpen}
          onClose={handleEditDialogClose}
          onSave={handleEdit}
          onCopy={handleCopy}
          initData={item}
        />
      )}
      <Paper
        sx={{
          p: 2,
          display: 'grid',
          gridTemplateColumns: '1fr 1fr 1fr',
          gap: 2,
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          <Avatar sizes="small">{item.id}</Avatar>
          <Box>
            <Typography fontWeight={700}>{item.name}</Typography>
            <Typography fontWeight={700} color="text.secondary">
              {item.model_name}
            </Typography>
            <Typography
              variant="caption"
              color="text.secondary"
              fontWeight={700}
            >
              {item.api_type}
            </Typography>
          </Box>
        </Box>
        <Box>
          <Typography variant="caption" color="text.secondary">
            Params:
          </Typography>
          {/*  @ts-ignore */}
          <ReactJson
            name={null}
            src={params}
            collapseStringsAfterLength={100}
          />
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Box sx={{ display: 'flex', gap: 2 }}>
            <Button color="info" onClick={() => setIsEditDialogOpen(true)}>
              Edit
            </Button>
            {item.index !== null && (
              <Button color="error" onClick={handleClickDelete}>
                Delete
              </Button>
            )}
          </Box>
        </Box>
      </Paper>
    </>
  );
};

const FunctionIngredientList: FC = () => {
  const { curIndex } = useUser();
  const { data } = useQuery(
    [curIndex, 'FunctionIngredient'],
    () => getFunctionIngredients(curIndex!.value),
    { enabled: Boolean(curIndex) }
  );

  let globalFnIngs: ItemType[] = [];
  let nonGlobalFnIngs: ItemType[] = [];
  if (data) {
    globalFnIngs = data
      .filter((item: ItemType) => item.index === null)
      .sort((x: any, y: any) => y.id - x.id);
    nonGlobalFnIngs = data
      .filter((item: ItemType) => item.index !== null)
      .sort((x: any, y: any) => y.id - x.id);
  }
  return (
    <Box>
      <Box sx={{ my: 4, display: 'flex', flexDirection: 'column', gap: 4 }}>
        <Box>
          <Typography variant="h6" sx={{ ml: 2 }}>
            Global Function Ingredients
          </Typography>
          <List sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            {globalFnIngs.map((item: ItemType) => (
              <Item key={item.id} item={item} />
            ))}
          </List>
        </Box>
        <Box>
          <Typography variant="h6" sx={{ ml: 2 }}>
            Function Ingredients
          </Typography>
          <List sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            {nonGlobalFnIngs.map((item: ItemType) => (
              <Item key={item.id} item={item} />
            ))}
          </List>
        </Box>
      </Box>
    </Box>
  );
};

export default FunctionIngredientList;
