import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  TextField,
  DialogTitle,
  Box,
} from '@mui/material';
import React, { FunctionComponent, useState } from 'react';

type Props = {
  open: boolean;
  onClose: () => void;
  onSave: (name: string) => void;
};
const RecipeSaveAsDialog: FunctionComponent<Props> = ({
  open,
  onClose,
  onSave,
}) => {
  const [name, setName] = useState('');
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const _name = e.target.value;
    setName(_name);
  };
  const canSave = Boolean(name.trim());
  const submitHandler = (e: React.FormEvent) => {
    e.preventDefault();
    onSave(name.trim());
  };
  return (
    <Dialog open={open} onClose={onClose}>
      <Box component="form" onSubmit={submitHandler}>
        <DialogTitle>Enter Recipe Name</DialogTitle>
        <DialogContent>
          <DialogContentText>
            To save the new created recipe, please enter the recipe name here.
          </DialogContentText>
          <TextField
            autoFocus
            autoComplete="off"
            margin="dense"
            label="Recipe Name"
            value={name}
            onChange={handleChange}
            fullWidth
            variant="standard"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button type="submit" disabled={!canSave}>
            Save
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default RecipeSaveAsDialog;
