import {
  Box,
  Button,
  Card,
  Switch,
  CardActions,
  CardContent,
  MenuItem,
  TextField,
  TextareaAutosize,
  Typography,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import React, { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  getDocumentIngredient,
  previewFeedIngredientItems,
  saveDocumentIngredient,
} from 'apis/feed_ingredient';
import { INTERVAL_OPTIONS } from 'constants/constants';
import { IntervalType } from 'types/types';
import { useUser } from 'contexts/user-context';
import LoadingSpinner from 'components/shared/ui/LoadingSpinner';
import { useSnackbar } from 'contexts/snackbar-context';

const DocumentEdit: FC = () => {
  const { curIndex } = useUser();
  const { setSnackbar } = useSnackbar();
  const params = useParams();
  const indexId = params.idx!;
  const documentId = params.documentId!;
  let { data: dataDocument, isLoading: isLoadingDocument } = useQuery(
    [indexId, 'getDocumentIngredient', documentId],
    () => getDocumentIngredient(indexId, documentId)
  );

  const [uri, setUri] = useState('');
  const [autoRefresh, setAutoRefresh] = useState(false);
  const [refreshInterval, setRefreshInterval] = useState('');
  const [replaceRules, setReplaceRules] = useState('');
  useEffect(() => {
    if (dataDocument) {
      setUri(dataDocument.uri);
      setAutoRefresh(dataDocument.auto_refresh);
      setRefreshInterval(dataDocument.refresh_interval);
      setReplaceRules(dataDocument.replace_rules);
      if (dataDocument.inventory_mapping) {
        const {
          brand_column,
          category_column,
          product_count_column,
          phrase_columns,
        } = dataDocument.inventory_mapping;
        const [phrase_column_1, phrase_column_2] = phrase_columns.split(',');
        setBrandColumn(brand_column);
        setCategoryColumn(category_column);
        setProductCountColumn(product_count_column);
        setPhraseColumn1(phrase_column_1);
        setPhraseColumn2(phrase_column_2);
      }
    }
  }, [isLoadingDocument, dataDocument]);

  const handleChangeUri = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUri(e.target.value);
  };
  const handleChangeAutoRefresh = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAutoRefresh(e.target.checked);
  };
  const handleChangeReplaceRules = (e: React.ChangeEvent<HTMLInputElement>) => {
    setReplaceRules(e.target.value);
  };

  // Inventory mapping
  let { data: csvFileItems, isLoading: isLoadingColumns } = useQuery(
    [curIndex, 'FeedIngredientItems', documentId],
    () => previewFeedIngredientItems(curIndex!.value, documentId, 0, 10),
    { staleTime: 1000 * 60 }
  );
  let fileColumns: string[] = [];
  if (csvFileItems) {
    fileColumns = csvFileItems.header;
  }
  let fileColumnOptions = fileColumns.map((col) => (
    <MenuItem key={col} value={col}>
      {col}
    </MenuItem>
  ));
  const [brandColumn, setBrandColumn] = useState('');
  const [categoryColumn, setCategoryColumn] = useState('');
  const [productCountColumn, setProductCountColumn] = useState('');
  const [phraseColumn1, setPhraseColumn1] = useState('');
  const [phraseColumn2, setPhraseColumn2] = useState('');

  const handleClickSave = async () => {
    let inventory_mapping = null;
    if (
      dataDocument?.type === 'inv' &&
      brandColumn &&
      categoryColumn &&
      productCountColumn &&
      phraseColumn1 &&
      phraseColumn2
    ) {
      inventory_mapping = {
        brandColumn,
        categoryColumn,
        productCountColumn,
        phraseColumns: [phraseColumn1, phraseColumn2].join(','),
      };
    }
    await saveDocumentIngredient(indexId!, documentId, {
      auto_refresh: autoRefresh,
      refresh_interval: refreshInterval,
      replace_rules: replaceRules,
      uri: uri,
      inventory_mapping,
    });
    setSnackbar({ severity: 'success', message: 'Saved.' });
  };
  return (
    <Card sx={{}}>
      <CardContent sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <Typography variant="h3">Edit Document</Typography>
        <TextField disabled value={dataDocument?.filename} />
        <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
          <Typography variant="body2">Auto Refresh</Typography>
          <Switch
            checked={autoRefresh as boolean}
            onChange={handleChangeAutoRefresh}
          />
        </Box>
        <TextField
          sx={{ flexGrow: 1, minWidth: 200 }}
          select
          label="Select Interval Type"
          value={refreshInterval}
          onChange={(e) => setRefreshInterval(e.target.value as IntervalType)}
          size="small"
          InputProps={{ style: { borderRadius: 0 } }}
        >
          {INTERVAL_OPTIONS.map((item) => (
            <MenuItem key={item.value} value={item.value}>
              {item.label}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          multiline
          InputProps={{
            inputComponent: TextareaAutosize,
            rows: 3,
          }}
          autoComplete="off"
          margin="dense"
          size="small"
          label="Replace Rules"
          value={replaceRules}
          onChange={handleChangeReplaceRules}
        />
        <TextField
          autoComplete="off"
          margin="dense"
          size="small"
          label="Uri"
          value={uri}
          onChange={handleChangeUri}
        />
        {dataDocument?.type === 'inv' && (
          <Box sx={{ my: 2 }}>
            <Typography variant="h6">Inventory mapping:</Typography>
            {isLoadingColumns && <LoadingSpinner />}
            {!isLoadingColumns && (
              <Box
                sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 2 }}
              >
                <TextField
                  size="small"
                  select
                  value={brandColumn}
                  label="Brand column"
                  onChange={(e) => setBrandColumn(e.target.value)}
                >
                  {fileColumnOptions}
                </TextField>
                <TextField
                  size="small"
                  select
                  value={categoryColumn}
                  label="Category column"
                  onChange={(e) => setCategoryColumn(e.target.value)}
                >
                  {fileColumnOptions}
                </TextField>
                <TextField
                  size="small"
                  select
                  value={productCountColumn}
                  label="Product count column"
                  onChange={(e) => setProductCountColumn(e.target.value)}
                >
                  {fileColumnOptions}
                </TextField>
                <TextField
                  size="small"
                  select
                  value={phraseColumn1}
                  label="Phrase column 1"
                  onChange={(e) => setPhraseColumn1(e.target.value)}
                >
                  {fileColumnOptions}
                </TextField>
                <TextField
                  size="small"
                  select
                  value={phraseColumn2}
                  label="Phrase column 2"
                  onChange={(e) => setPhraseColumn2(e.target.value)}
                >
                  {fileColumnOptions}
                </TextField>
              </Box>
            )}
          </Box>
        )}
      </CardContent>
      <CardActions>
        <Button variant="contained" onClick={handleClickSave}>
          Save
        </Button>
      </CardActions>
    </Card>
  );
};

export default DocumentEdit;
