import { Button, Dialog, DialogActions, DialogTitle, Box } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { deleteWidget } from 'apis/widgets';
import { useSnackbar } from 'contexts/snackbar-context';
import { useUser } from 'contexts/user-context';
import { FunctionComponent } from 'react';

type Props = {
  open: boolean;
  onClose: () => void;
  id: number;
  name: string;
};

const DelWidgetDlg: FunctionComponent<Props> = ({
  open,
  onClose,
  id,
  name,
}) => {
  const queryClient = useQueryClient();
  const { setSnackbar } = useSnackbar();
  const { curIndex } = useUser();
  const { mutate: m_delete } = useMutation({
    mutationFn: () => deleteWidget(curIndex!.value, id),
    onSuccess: () => {
      queryClient.invalidateQueries([curIndex, 'Widget']);
      setSnackbar({ severity: 'success', message: 'Deleted' });
    },
  });
  const handleDelete = () => {
    onClose();
    m_delete();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <Box>
        <DialogTitle>Are you sure you want to delete widget {name}</DialogTitle>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button color="error" onClick={handleDelete}>
            Delete
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default DelWidgetDlg;
