import { Box, IconButton, Menu, MenuItem } from '@mui/material';
import { FC, useState } from 'react';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { useSnackbar } from 'contexts/snackbar-context';
import {
  postCloneJob,
  postCancelJob,
  postDeleteJob,
  postStopJob,
  postFlushLogJob,
  postMarkJobDone,
} from 'apis/jobs';
import { useQueryClient } from '@tanstack/react-query';

type MoreMenuBtnProps = {
  jobId: number | string;
  indexId: number | string;
  status: string;
  type: string;
};

const MoreMenuBtn: FC<MoreMenuBtnProps> = ({
  jobId,
  indexId,
  status,
  type,
}) => {
  const queryClient = useQueryClient();
  const { setSnackbar } = useSnackbar();
  const isTrajectJob = type === 'SERP Crawl';

  const [anchor, setAnchor] = useState<null | HTMLElement>(null);
  const menuOpen = Boolean(anchor);
  const handleOpenMenu = (e: React.MouseEvent<HTMLButtonElement>) => {
    setAnchor(e.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchor(null);
  };

  const handleClone = async (id: string | number) => {
    setAnchor(null);
    try {
      await postCloneJob(indexId, id);
      queryClient.invalidateQueries([indexId, 'SetupJobs']);
      setSnackbar({ severity: 'success', message: 'Started!' });
    } catch (err) {
      if (err && (err as any).response) {
        setSnackbar({
          severity: 'error',
          message: (err as any).response.data.error || 'Clone Failed!',
        });
      } else {
        setSnackbar({ severity: 'error', message: 'Clone Failed!' });
      }
    }
  };

  const handleCancel = async (id: string | number) => {
    setAnchor(null);
    try {
      await postCancelJob(indexId, id);
      queryClient.invalidateQueries([indexId, 'SetupJobs']);
      setSnackbar({ severity: 'success', message: 'Canceled.' });
    } catch (err) {
      if (err && (err as any).response) {
        setSnackbar({
          severity: 'error',
          message: (err as any).response.data.error || 'Cancel Failed!',
        });
      } else {
        setSnackbar({ severity: 'error', message: 'Cancel Failed!' });
      }
    }
  };

  const handleStop = async (id: string | number) => {
    setAnchor(null);
    try {
      await postStopJob(indexId, id);
      queryClient.invalidateQueries([indexId, 'SetupJobs']);
      setSnackbar({ severity: 'success', message: 'Stopped!' });
    } catch (err) {
      if (err && (err as any).response) {
        setSnackbar({
          severity: 'error',
          message: (err as any).response.data.error || 'Stop Failed!',
        });
      } else {
        setSnackbar({ severity: 'error', message: 'Stop Failed!' });
      }
    }
  };

  const handleFlushLog = async (id: string | number) => {
    setAnchor(null);
    try {
      await postFlushLogJob(indexId, id);
      queryClient.invalidateQueries([indexId, 'SetupJobs']);
      setSnackbar({ severity: 'success', message: 'Log flushed!' });
    } catch (err) {
      if (err && (err as any).response) {
        setSnackbar({
          severity: 'error',
          message: (err as any).response.data.error || 'Log flush failed!',
        });
      } else {
        setSnackbar({ severity: 'error', message: 'Log flush failed!' });
      }
    }
  };

  const handleDelete = async (id: string | number) => {
    setAnchor(null);
    try {
      await postDeleteJob(indexId, id);
      queryClient.invalidateQueries([indexId, 'SetupJobs']);
      setSnackbar({ severity: 'success', message: 'Deleted!' });
    } catch (err) {
      if (err && (err as any).response) {
        setSnackbar({
          severity: 'error',
          message: (err as any).response.data.error || 'Delete Failed!',
        });
      } else {
        setSnackbar({ severity: 'error', message: 'Delete Failed!' });
      }
    }
  };

  const handleMarkDone = async (id: string | number) => {
    setAnchor(null);
    try {
      await postMarkJobDone(indexId, id);
      queryClient.invalidateQueries([indexId, 'SetupJobs']);
      setSnackbar({ severity: 'success', message: 'Marked done!' });
    } catch (err) {
      if (err && (err as any).response) {
        setSnackbar({
          severity: 'error',
          message: (err as any).response.data.error || 'Mark done Failed!',
        });
      } else {
        setSnackbar({ severity: 'error', message: 'Mark done Failed!' });
      }
    }
  };

  return (
    <Box>
      <IconButton onClick={handleOpenMenu}>
        <MoreHorizIcon />
      </IconButton>
      <Menu
        anchorEl={anchor}
        open={menuOpen}
        onClose={handleCloseMenu}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem onClick={handleClone.bind(null, jobId)}>Clone</MenuItem>
        {status === 'pending' && (
          <MenuItem onClick={handleCancel.bind(null, jobId)}>Cancel</MenuItem>
        )}
        {status === 'running' && (
          <MenuItem onClick={handleStop.bind(null, jobId)}>Stop</MenuItem>
        )}
        {status === 'running' && (
          <MenuItem onClick={handleFlushLog.bind(null, jobId)}>
            Flush Log
          </MenuItem>
        )}
        {status === 'running' && isTrajectJob && (
          <MenuItem onClick={handleMarkDone.bind(null, jobId)}>
            Mark done
          </MenuItem>
        )}
        {(status === 'succeeded' ||
          status === 'failed' ||
          status === 'cancelled') && (
          <MenuItem onClick={handleDelete.bind(null, jobId)}>Delete</MenuItem>
        )}
      </Menu>
    </Box>
  );
};

export default MoreMenuBtn;
