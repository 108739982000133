import Layout from 'components/layout/Layout';
import SiteTopicItemMain from 'components/site-topic/detail/SiteTopicItemMain';
import { FC } from 'react';

const SiteTopicItemPage: FC = () => {
  return (
    <Layout>
      <SiteTopicItemMain />
    </Layout>
  );
};

export default SiteTopicItemPage;
