import { FC } from 'react';
import { Box, Typography } from '@mui/material';
import {
  DataGridPro,
  GridColDef,
  GridRenderCellParams,
} from '@mui/x-data-grid-pro';

const DiffCell: FC<{ params: GridRenderCellParams<string> }> = ({ params }) => {
  let value = params.value;
  if (!value) return <></>;
  let isGreen = true;
  if (value[0] === '-') isGreen = false;
  const row = params.row;
  if (row.name?.includes('position')) isGreen = !isGreen;
  return (
    <Typography color={isGreen ? 'green' : 'red'} variant="body2">
      {value}
    </Typography>
  );
};

export const renderDiff = (params: GridRenderCellParams<string>) => {
  return <DiffCell params={params} />;
};

const columns: GridColDef[] = [
  {
    field: 'id',
    headerClassName: 'App-Mui-DataGrid-Header',
  },
  {
    field: 'name',
    headerName: 'Metric',
    minWidth: 200,
    headerClassName: 'App-Mui-DataGrid-Header',
  },
  {
    field: 'avgBefore',
    headerName: 'Average before',
    minWidth: 120,
    headerClassName: 'App-Mui-DataGrid-Header',
  },
  {
    field: 'avgAfter',
    headerName: 'Average after',
    minWidth: 120,
    headerClassName: 'App-Mui-DataGrid-Header',
  },
  {
    field: 'diff',
    minWidth: 180,
    renderCell: renderDiff,
    headerName: 'Average difference',
    headerClassName: 'App-Mui-DataGrid-Header',
  },
  {
    field: 'diff2',
    minWidth: 180,
    renderCell: renderDiff,
    headerName: 'Group difference',
    headerClassName: 'App-Mui-DataGrid-Header',
  },
];

const _minus = (a: any, b: any) => {
  if (a === undefined || b === undefined) return '';
  a = parseFloat(a.replace('%', ''));
  b = parseFloat(b.replace('%', ''));
  let ret: any = a - b;
  ret = ret.toFixed(2);
  return ret + '%';
};

type SummaryItemType = {
  name: string;
  avgBefore: string;
  avgAfter: string;
  diff: string;
  diff2?: string;
};

type Props = {
  summarys1: SummaryItemType[];
  summarys2: SummaryItemType[];
  interventionDate: string | undefined;
};
const DashboardTables: FC<Props> = ({
  summarys1,
  summarys2,
  interventionDate,
}) => {
  if (!interventionDate) return <></>;
  summarys1 = summarys1.map((item, i: number) => ({
    ...item,
    id: i,
    diff2: _minus(summarys1[i]?.diff, summarys2[i]?.diff),
  }));
  summarys2 = summarys2.map((item, i: number) => ({
    ...item,
    id: i,
    diff2: _minus(summarys2[i]?.diff, summarys1[i]?.diff),
  }));
  return (
    <Box sx={{ display: 'flex' }}>
      <Box sx={{ flexGrow: 1 }}>
        <Typography variant="h6" sx={{ textAlign: 'center' }}>
          Test Group
        </Typography>
        <DataGridPro
          initialState={{
            columns: {
              columnVisibilityModel: {
                id: false,
              },
            },
          }}
          autoHeight
          getRowHeight={() => 'auto'}
          columns={columns}
          rows={summarys1}
          disableDensitySelector
          disableColumnFilter
          sx={{
            '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': {
              py: '12px',
              borderBottomColor: '#eee',
            },
            '&.MuiDataGrid-root--densityStandard .MuiDataGrid-columnSeparator':
              {
                color: '#ccc',
              },
          }}
        />
      </Box>
      <Box sx={{ flexGrow: 1 }}>
        <Typography variant="h6" sx={{ textAlign: 'center' }}>
          Control Group
        </Typography>
        <DataGridPro
          initialState={{
            columns: {
              columnVisibilityModel: {
                diff2: false,
                id: false,
              },
            },
          }}
          autoHeight
          getRowHeight={() => 'auto'}
          columns={columns}
          rows={summarys2}
          disableDensitySelector
          disableColumnFilter
          sx={{
            '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': {
              py: '12px',
              borderBottomColor: '#eee',
            },
            '&.MuiDataGrid-root--densityStandard .MuiDataGrid-columnSeparator':
              {
                color: '#ccc',
              },
          }}
        />
      </Box>
    </Box>
  );
};

export default DashboardTables;
