import { Box } from '@mui/material';
import IndexNewEdit from 'components/settings/IndexNewEdit';
import LoadingSpinner from 'components/shared/ui/LoadingSpinner';
import { useUser } from 'contexts/user-context';
import { FC } from 'react';

const SettingsIndexSettings: FC = () => {
  const { curIndex } = useUser();
  return (
    <Box>
      {!curIndex && <LoadingSpinner />}
      {curIndex && (
        <IndexNewEdit indexId={curIndex.value} hideCancelBtn={true} />
      )}
    </Box>
  );
};

export default SettingsIndexSettings;
