import { Box } from '@mui/material';
import LoadingSpinner from 'components/shared/ui/LoadingSpinner';
import { useUser } from 'contexts/user-context';
import { FC } from 'react';
import { useLocation } from 'react-router-dom';
import DashboardSegNew from './DashboardSegNew';

const DashboardSegDetail: FC = () => {
  const location = useLocation();
  const { curIndex } = useUser();
  if (!curIndex) {
    return <LoadingSpinner />;
  }
  const pathNames = location.pathname.split('/');
  const subPath = pathNames[3];
  // const subPath2 = pathNames[4];
  let content = <></>;
  if (subPath === 'New-Segment') {
    content = <DashboardSegNew />;
  }
  return <Box sx={{ ml: 3, mr: 3, mt: 4 }}>{content}</Box>;
};

export default DashboardSegDetail;
