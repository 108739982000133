import { Box, LinearProgress, Typography } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import { useQuery } from '@tanstack/react-query';
import { getSiteTopicTopPages } from 'apis/site_topic';
import LoadingSpinner from 'components/shared/ui/LoadingSpinner';
import { useUser } from 'contexts/user-context';
import { FC } from 'react';

type ItemType = {
  url: string;
  total_impression: number;
  total_click: number;
};

const TopPagesCell: FC<{ params: GridRenderCellParams<string[]> }> = ({
  params,
}) => {
  const { curIndex } = useUser();
  const keywords = params.row.keywords.join(',');
  // const keywords = 'similar ai,keyword1,keyword2,keyword3';
  const report_id = params.row.report_id;
  const { data, isLoading } = useQuery(
    [curIndex, 'site-topic', report_id, 'top-pages', keywords],
    () => getSiteTopicTopPages(curIndex!.value, report_id, keywords),
    { enabled: Boolean(curIndex) }
  );

  if (isLoading) return <LoadingSpinner />;

  const items: ItemType[] = data?.items || [];
  const maxClick = Math.max(...items.map((item) => item.total_click), 1);
  if (items.length > 0) {
  }

  return (
    <Box
      sx={{
        width: '80%',
        display: 'flex',
        flexDirection: 'column',
        gap: 4,
        my: 8,
      }}
    >
      {items.map((item) => (
        <Box
          key={item.url}
          sx={{ display: 'flex', flexDirection: 'column', gap: 0.6 }}
        >
          <Box
            sx={{
              display: 'flex',
              gap: 2,
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Typography variant="body2" sx={{ fontWeight: 700 }}>
              {item.url}
            </Typography>
            <Typography
              variant="body2"
              sx={{ fontWeight: 700 }}
            >{`${item.total_click} clicks`}</Typography>
          </Box>
          <Box>
            <LinearProgress
              sx={{ height: 10, borderRadius: 5 }}
              variant="determinate"
              value={(item.total_click / maxClick) * 100}
            />
          </Box>
        </Box>
      ))}
    </Box>
  );
};

export const renderTopPages = (params: GridRenderCellParams<string[]>) => {
  return <TopPagesCell params={params} />;
};

export default TopPagesCell;
