import Layout from 'components/layout/Layout';
import OpprtunitiesMain from 'components/opportunities/OpportunitiesMain';
import { FC } from 'react';

const OpportunitesPage: FC = () => {
  return (
    <Layout>
      <OpprtunitiesMain />
    </Layout>
  );
};

export default OpportunitesPage;
