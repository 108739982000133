import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { FC } from 'react';
import { useTheme } from '@mui/material';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top' as const,
    },
  },
};

type VerticalBarsProps = {
  label: string;
  data: number[];
};

export type Props = {
  labels: string[];
  title1: string;
  data1: number[];
  title2?: string;
  data2?: number[];
  labels1?: string[];
  labels2?: string[];
  verticalBars?: VerticalBarsProps;
};

const LineChart: FC<Props> = ({
  title1,
  data1,
  labels,
  title2,
  data2,
  verticalBars,
}) => {
  const theme = useTheme();
  const color = theme.palette.primary.main;
  const dataset1 = {
    label: title1,
    data: data1,
    borderColor: color,
    backgroundColor: color,
  };
  let maxY = Math.max(...data1.filter((num) => !Number.isNaN(num)));
  if (data2)
    maxY = Math.max(maxY, ...data2.filter((num) => !Number.isNaN(num)));
  let bars: any = undefined;

  let data = {
    labels,
    datasets: [dataset1],
  };

  if (verticalBars) {
    bars = {
      type: 'bar' as const,
      label: verticalBars.label,
      backgroundColor: 'rgb(75, 192, 192)',
      data: verticalBars.data.map((x) => ({ x, y: maxY })),
      barThickness: 1,
    };
    data = {
      labels,
      datasets: [dataset1, bars],
    };
  }

  let dataset2;
  if (title2 && data2) {
    dataset2 = {
      label: title2,
      data: data2,
      borderColor: 'rgb(53, 162, 235)',
      backgroundColor: 'rgba(53, 162, 235, 0.5)',
    };
    data = {
      labels,
      datasets: [dataset1, dataset2],
    };
    if (verticalBars) {
      data = {
        labels,
        datasets: [dataset1, dataset2, bars],
      };
    }
  }

  /* @ts-ignore*/
  return <Line options={options} data={data} />;
};

export default LineChart;
