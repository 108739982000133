import { Box, Paper } from '@mui/material';
import { DataGridPro, GridColDef, GridToolbar } from '@mui/x-data-grid-pro';

import { FC, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { getIndexExportJobs } from 'apis/index_export';

import { Link } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';

const UrlCell: FC<{ params: GridRenderCellParams<string> }> = ({ params }) => {
  const value = params.value;
  if (!value) return <></>;
  const fileName = value.match(/[a-zA-Z0-9-_.:/]+\?/g);
  let link = value;
  if (fileName) {
    link = fileName[0].replace('?', '');
  }
  return (
    <Link
      sx={{ wordBreak: 'break-all' }}
      href={value}
      target="_blank"
      rel="noreferrer"
    >
      {link}
    </Link>
  );
};

export const renderUrl = (params: GridRenderCellParams<string>) => {
  return <UrlCell params={params} />;
};

const columns: GridColDef[] = [
  {
    field: 'id',
    headerClassName: 'App-Mui-DataGrid-Header',
  },
  {
    field: 'link',
    renderCell: renderUrl,
    headerClassName: 'App-Mui-DataGrid-Header',
    flex: 1,
  },
  {
    field: 'opportunity',
    headerClassName: 'App-Mui-DataGrid-Header',
    headerName: 'opportunity id',
    width: 150,
  },
  {
    field: 'count',
    headerName: 'count',
    headerClassName: 'App-Mui-DataGrid-Header',
    width: 100,
  },
  {
    field: 'username',
    headerName: 'user',
    headerClassName: 'App-Mui-DataGrid-Header',
    width: 200,
  },
  {
    field: 'updated',
    headerClassName: 'App-Mui-DataGrid-Header',
    width: 180,
    valueGetter: (params) => new Date(params.value).toLocaleString(),
  },
];

type Props = {
  indexId: string;
  opporId?: number;
};

const DownloadTable: FC<Props> = ({ indexId, opporId }) => {
  let { data, isLoading } = useQuery([indexId, 'DownloadTable'], () =>
    getIndexExportJobs(indexId)
  );
  data = data || [];
  data = data.map((item: any) => ({
    ...item,
    id: item.base,
  }));
  if (opporId) {
    data = data.filter((item: any) => item.opportunity === opporId);
  }
  const [pageSize, setPageSize] = useState(10);
  return (
    <Paper
      sx={{ display: 'flex', height: '100%', flexDirection: 'column', gap: 2 }}
    >
      <Box sx={{ flexGrow: 1 }}>
        <DataGridPro
          pagination
          loading={isLoading}
          autoHeight
          getRowHeight={() => 'auto'}
          columns={columns}
          rows={data}
          disableDensitySelector
          disableColumnFilter
          components={{ Toolbar: GridToolbar }}
          pageSize={pageSize}
          rowsPerPageOptions={[10, 25, 100]}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          componentsProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
            },
          }}
          sx={{
            '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': {
              py: '12px',
              borderBottomColor: '#eee',
            },
            '&.MuiDataGrid-root--densityStandard .MuiDataGrid-columnSeparator':
              { color: '#ccc' },
          }}
        />
      </Box>
    </Paper>
  );
};
export default DownloadTable;
