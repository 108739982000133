import { Box, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { getPageIngredientCrawlItems } from 'apis/page_ingredient';
import HorizontalBarChart from 'components/chart/HorizontalBarChart';
import { FC } from 'react';
import { useParams } from 'react-router-dom';
import PIJobResultTable from './PIJobResultTable';

const PIJobResultMain: FC = () => {
  const params = useParams();
  const indexId = params.idx!;
  const pageIngredientId = params.pageIngredientId!;
  let { data } = useQuery([indexId, 'page-ingredient-job-result', 0], () =>
    getPageIngredientCrawlItems(indexId, pageIngredientId, 0, 10)
  );
  const url_path_prefixes_aggregation = data?.url_path_prefixes_aggregation;
  const keys = url_path_prefixes_aggregation?.map((item: any) => item.key);
  const doc_counts = url_path_prefixes_aggregation?.map(
    (item: any) => item.doc_count
  );
  return (
    <Box sx={{ ml: 3, mr: 3, mt: 4 }}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Typography variant="h3">Page Ingredient Job Result</Typography>
      </Box>
      <Box sx={{ mt: 4 }}>
        <HorizontalBarChart
          labelX="doc count"
          labels={keys}
          bars={doc_counts}
        />
      </Box>
      <Box sx={{ mt: 4 }}>
        <PIJobResultTable
          indexId={indexId}
          pageIngredientId={pageIngredientId}
        />
      </Box>
    </Box>
  );
};

export default PIJobResultMain;
