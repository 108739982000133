import { IconButton, Tooltip } from '@mui/material';
import { FC } from 'react';
import ViewColumnIcon from '@mui/icons-material/ViewColumn';

type Props = {
  onClick: any;
};
const SetupColBtn: FC<Props> = ({ onClick }) => {
  return (
    <Tooltip title="Setup Columns">
      <IconButton onClick={onClick} color="primary">
        <ViewColumnIcon />
      </IconButton>
    </Tooltip>
  );
};
export default SetupColBtn;
