import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  TextField,
  DialogTitle,
  Box,
} from '@mui/material';
import { FunctionComponent } from 'react';
import { useForm, Controller } from 'react-hook-form';

export type FormData = {
  endpoint: string;
  username: string;
  password: string;
};

type Props = {
  open: boolean;
  onClose: () => void;
  onSave: (data: FormData) => void;
};

const AddXmlrpcDialog: FunctionComponent<Props> = ({
  open,
  onClose,
  onSave,
}) => {
  const { handleSubmit, control } = useForm<FormData>();

  const onSubmit = (data: FormData) => {
    const { endpoint, username, password } = data;
    onSave({ endpoint, username, password });
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <Box component="form" onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>Enter XmlRPC CLient Setting</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please enter the XmlRPC CLient Information.
          </DialogContentText>
          <Controller
            name="endpoint"
            defaultValue=""
            control={control}
            render={({ field }) => (
              <TextField
                margin="dense"
                label="Endpoint"
                fullWidth
                variant="standard"
                {...field}
              />
            )}
          />
          <Controller
            name="username"
            defaultValue=""
            control={control}
            render={({ field }) => (
              <TextField
                margin="dense"
                label="Username"
                fullWidth
                variant="standard"
                {...field}
              />
            )}
          />
          <Controller
            name="password"
            defaultValue=""
            control={control}
            render={({ field }) => (
              <TextField
                margin="dense"
                label="Password"
                fullWidth
                variant="standard"
                {...field}
              />
            )}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button type="submit">Create</Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default AddXmlrpcDialog;
