import { Box, IconButton, Menu, MenuItem } from '@mui/material';
import { FC, useState } from 'react';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { useSnackbar } from 'contexts/snackbar-context';
import { useNavigate } from 'react-router-dom';
import { deleteFeedIngredients } from 'apis/feed_ingredient';

type MoreMenuBtnProps = {
  indexId: number | string;
  documentId: number | string;
};

const MoreMenuBtn: FC<MoreMenuBtnProps> = ({ documentId, indexId }) => {
  const { setSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const handlePreview = async () => {
    navigate(`/settings/${indexId}/feedingredient/${documentId}/preview`);
  };
  const handleEdit = async () => {
    navigate(`/settings/${indexId}/feedingredient/${documentId}/edit`);
  };
  const handleDelete = async (documentId: string | number) => {
    setAnchor(null);
    try {
      await deleteFeedIngredients(indexId, documentId);
      setSnackbar({ severity: 'success', message: 'Deleted.' });
    } catch (err) {}
  };
  const [anchor, setAnchor] = useState<null | HTMLElement>(null);
  const menuOpen = Boolean(anchor);
  const handleOpenMenu = (e: React.MouseEvent<HTMLButtonElement>) => {
    setAnchor(e.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchor(null);
  };
  return (
    <Box>
      <IconButton onClick={handleOpenMenu}>
        <MoreHorizIcon />
      </IconButton>
      <Menu
        anchorEl={anchor}
        open={menuOpen}
        onClose={handleCloseMenu}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem onClick={handlePreview}>Preview</MenuItem>
        <MenuItem onClick={handleEdit}>Edit</MenuItem>
        <MenuItem onClick={handleDelete.bind(null, documentId)}>
          Delete
        </MenuItem>
      </Menu>
    </Box>
  );
};

export default MoreMenuBtn;
