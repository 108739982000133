import { TextField, Typography, Box, Autocomplete } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { getIndexes } from 'apis';
import LoadingSpinner from 'components/shared/ui/LoadingSpinner';
import { useAuth } from 'contexts/auth-context';
import { useUser } from 'contexts/user-context';
import { FunctionComponent, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { IndexType, OptionType } from 'types/types';

const IdxSelector: FunctionComponent = () => {
  const { logout } = useAuth();
  const { allIndexes, curIndex, setAllIndexes, setCurIndex } = useUser();
  const navigate = useNavigate();
  const location = useLocation();
  //location.pathname: /pages/387
  const pathname = location.pathname.split('/')[1];
  const pathIdx = location.pathname.split('/')[2];

  const handleChangeOption = (id: string) => {
    const options: IndexType[] = allIndexes.map((item: any) => ({
      value: item.id.toString(),
      label: item.display_name,
      market: item.market,
    }));
    const _curIdx = options.find((item) => item.value === id);
    setCurIndex(_curIdx);
    let path = `/${pathname}/${_curIdx?.value || options[0].value}`;
    if (pathname === 'index') {
      path = `/workbench/${_curIdx?.value || options[0].value}`;
    }
    navigate(path);
  };

  const options: OptionType[] = allIndexes.map((item: any) => ({
    value: item.id.toString(),
    label: item.display_name,
  }));

  const { data, isError, isLoading } = useQuery(
    ['all-indexes'],
    () => getIndexes(),
    { staleTime: 1000 * 60 }
  );
  if (isError) {
    logout();
  }

  //init
  useEffect(() => {
    if (data && data.length > 0) {
      if (curIndex) return;
      const options: IndexType[] = data.map((item: any) => ({
        value: item.id.toString(),
        label: item.display_name,
        market: item.market,
      }));
      setAllIndexes(data);
      const curPathOption =
        options.find((item) => item.value === pathIdx) || options[0];
      setCurIndex(curPathOption);
      if (pathIdx === ':idx') {
        navigate(`/${pathname}/${curPathOption.value}`);
      }
    }
  }, [data, setAllIndexes, setCurIndex, pathIdx, navigate, pathname, curIndex]);

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (!data || data.length === 0 || !curIndex) {
    return <Typography>No Index Found.</Typography>;
  }

  const optionAuto = options.map((o) => ({ label: o.label, id: o.value }));

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Autocomplete
        options={optionAuto}
        value={{
          label: curIndex.label || optionAuto[0].label,
          id: curIndex.value || optionAuto[0].id,
        }}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        onChange={(e, value) => handleChangeOption(value?.id || '')}
        sx={{ minWidth: 300 }}
        renderInput={(params) => (
          <TextField
            {...params}
            size="small"
            sx={{
              backgroundColor: '#F7F8FB',
              '& fieldset': { border: 'none' },
            }}
          />
        )}
      />
    </Box>
  );
};

export default IdxSelector;
