import {
  Button,
  TextField,
  Box,
  Typography,
  Container,
  Link,
} from '@mui/material';
import { LayoutBlank } from 'components/layout/LayoutBlank';
import { useState } from 'react';
import Logo from 'components/header/Logo';
import { postSendPasswordLink } from 'apis/reset_password';
import { AxiosError } from 'axios';

export default function ForgotPassword() {
  const [email, setEmail] = useState('');
  const [error, setError] = useState({
    detail: '',
  });
  const [success, setSuccess] = useState(false);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      await postSendPasswordLink(email);
      setSuccess(true);
    } catch (err) {
      if (err instanceof AxiosError) {
        const errorData = err.response?.data;
        setError({
          detail: errorData.detail || '',
        });
      } else {
        setError({
          detail: '',
        });
      }
    }
  };

  return (
    <LayoutBlank>
      <Container component="main" maxWidth="sm">
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            border: '1px solid black',
            pt: 8,
            pb: 1,
            borderRadius: '4px',
          }}
        >
          <Box sx={{ mb: 4 }}>
            <Logo width="200px" color="black" />
          </Box>
          {!success ? (
            <Typography component="h3" variant="h3">
              Reset Password
            </Typography>
          ) : null}
          {!success ? (
            <Box
              component="form"
              onSubmit={handleSubmit}
              noValidate
              sx={{ mt: 1, p: 2 }}
            >
              <TextField
                margin="normal"
                size="small"
                required
                fullWidth
                label="Email"
                name="email"
                autoFocus
                value={email}
                onChange={(e) => setEmail(e.currentTarget.value)}
                error={Boolean(error.detail)}
                helperText={error.detail}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Send Reset Link
              </Button>
            </Box>
          ) : null}
          {success ? (
            <Typography align="center" sx={{ mb: 4 }}>
              <h3>Reset link sent</h3>
              <p>Please sign in to your email to verify your account</p>
            </Typography>
          ) : null}
          <Link href="/signin">Sign In</Link>
        </Box>
      </Container>
    </LayoutBlank>
  );
}
