import { Link } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import { FC } from 'react';

const UrlCell: FC<{ params: GridRenderCellParams<string> }> = ({ params }) => {
  const value = params.value;
  if (!value) return <></>;
  return (
    <Link
      href={value}
      target="_blank"
      rel="noreferrer"
      sx={{ overflowWrap: 'break-word', width: '100%' }}
    >
      {value}
    </Link>
  );
};

export const renderUrl = (params: GridRenderCellParams<string>) => {
  return <UrlCell params={params} />;
};

export default UrlCell;
