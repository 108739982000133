import {
  Button,
  TextField,
  Box,
  Typography,
  Container,
  Link,
} from '@mui/material';
import { LayoutBlank } from 'components/layout/LayoutBlank';
import { useState } from 'react';
import Logo from 'components/header/Logo';
import { postResetPassword } from 'apis/reset_password';
import { AxiosError } from 'axios';
import { useLocation } from 'react-router-dom';

export default function VerifyResetPassword() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const user_id = Number(searchParams.get('user_id'));
  const timestamp = Number(searchParams.get('timestamp'));
  const signature = String(searchParams.get('signature'));
  const [password, setPassword] = useState('');
  const [error, setError] = useState({
    password: [],
    detail: '',
  });
  const [success, setSuccess] = useState(false);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      await postResetPassword(password, user_id, timestamp, signature);
      setSuccess(true);
    } catch (err) {
      if (err instanceof AxiosError) {
        const errorData = err.response?.data;
        setError({
          password: errorData.password || [],
          detail: errorData.detail || '',
        });
      } else {
        setError({
          password: [],
          detail: '',
        });
      }
    }
  };

  return (
    <LayoutBlank>
      <Container component="main" maxWidth="sm">
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            border: '1px solid black',
            pt: 8,
            pb: 1,
            borderRadius: '4px',
          }}
        >
          <Box sx={{ mb: 4 }}>
            <Logo width="200px" color="black" />
          </Box>
          {!success ? <Typography component="h3" variant="h3">
            Reset Password
          </Typography> : null }
          {!success ? <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1, p: 2 }}
          >
            <TextField
              size="small"
              margin="normal"
              required
              fullWidth
              name="password"
              label="New Password"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.currentTarget.value)}
              error={Boolean(error.password.length)}
              helperText={error.password}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Submit
            </Button>
          </Box> : null}
          {success ? <Typography align="center" sx={{ mb: 4 }}>
            <h3>Password Changed!</h3>
            <p>Your password has been changed successfully!</p>
            <p>Please <Link href="/signin">Sign In</Link></p>
          </Typography> : null}
        </Box>
      </Container>
    </LayoutBlank>
  );
}
