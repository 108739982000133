import { Button } from '@mui/material';
import { FunctionComponent } from 'react';
import DownloadIcon from '@mui/icons-material/Download';
import { jsonObj2Url } from 'utils/utils-query-builder';

type Props = {
  jsonObj: any;
  fileName?: string;
};

const ExportRecipeBtn: FunctionComponent<Props> = ({ jsonObj, fileName }) => {
  if (!fileName) fileName = 'recipe';
  const clickHandler = () => {
    const jsonStr = 'data:text/json;chatset=utf-8,' + jsonObj2Url(jsonObj);
    const link = document.createElement('a');
    link.href = jsonStr;
    link.download = fileName + '.json';
    link.click();
  };
  if (!jsonObj) return <></>;
  return (
    <Button onClick={clickHandler}>
      <DownloadIcon sx={{ mr: 0.2 }} />
      Export
    </Button>
  );
};
export default ExportRecipeBtn;
