import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Box,
  TextField,
  MenuItem,
  Typography,
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
  List,
  FormLabel,
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import React, { FC, useEffect, useRef, useState } from 'react';
import InfoIcon from '@mui/icons-material/Info';
import {
  KeywordIngredientSourceType,
  LabelGroupItemType,
  MarketType,
} from 'types/types';
import { MARKET_OPTIONS } from 'constants/constants';
import KWIFilter from './KWIFilter';
import {
  convertQueryBuilderTree,
  convertQueryBuilderTreeB2F,
} from 'utils/utils-query-builder';
import { useQuery } from '@tanstack/react-query';
import {
  getGSCUserSite,
  previewTopFolders,
  previewTopFoldersProgress,
} from 'apis/keyword_ingredient';
import { useUser } from 'contexts/user-context';
import { postGetPagedLabels } from 'apis/index_labels';
import LoadingSpinner from 'components/shared/ui/LoadingSpinner';
import CircularProgressWithLabel from 'components/shared/ui/CircularProgressWithLabel';
import useUploadedDoc from 'hooks/useUploadedDoc';
import { UploadedDocItem } from '../page/PICreateDialog';
import { convertFileName, isEmpty } from 'utils';
import CopyTxtBtn from 'components/shared/ui/CopyBtn';
import { getFeedIngredients } from 'apis/feed_ingredient';
import { FileType } from '../taxonomy/dialog/CreateLabelDialog';
import InvDocTableDlg from './InvDocTableDlg';
import { useAtom } from 'jotai';
import { inventDocRecipeFrontAtom } from 'store/jotai';

type LabelFileItemProps = {
  file: FileType;
  selectedFile: FileType | undefined;
  disabled: boolean;
  setSelectedFile: React.Dispatch<React.SetStateAction<FileType | undefined>>;
};

const LabelFileItem: FC<LabelFileItemProps> = ({
  file,
  selectedFile,
  disabled,
  setSelectedFile,
}) => {
  const handleCheckFile = (
    e: React.ChangeEvent<HTMLInputElement>,
    _file: FileType
  ) => {
    if (selectedFile?.id === _file?.id) {
      setSelectedFile(undefined);
    } else {
      setSelectedFile(_file);
    }
  };
  const [invDocOpen, setInvDocOpen] = useState(false);
  return (
    <FormGroup
      sx={{
        display: 'flex',
        gap: 1,
        flexDirection: 'row',
        alignItems: 'center',
      }}
      key={file.id}
    >
      {invDocOpen && (
        <InvDocTableDlg
          open={invDocOpen}
          onClose={() => setInvDocOpen(false)}
          docId={file.id}
        />
      )}
      <FormControlLabel
        control={
          <Checkbox
            disabled={disabled}
            checked={selectedFile?.id === file.id}
            onChange={(e) => handleCheckFile(e, file)}
            name={file.file}
          />
        }
        label={file.file}
      />
      <Button
        size="small"
        variant="outlined"
        onClick={() => setInvDocOpen(true)}
      >
        Result
      </Button>
    </FormGroup>
  );
};

type LabelFileListProps = {
  allFiles?: FileType[];
  disabled: boolean;
  selectedFile: FileType | undefined;
  setSelectedFile: React.Dispatch<React.SetStateAction<FileType | undefined>>;
};
const LabelFileList: FC<LabelFileListProps> = ({
  allFiles,
  selectedFile,
  disabled,
  setSelectedFile,
}) => {
  return (
    <Box sx={{ display: 'flex' }}>
      <FormControl component="fieldset" variant="standard">
        <FormLabel component="legend">Files:</FormLabel>
        {allFiles?.map((file) => (
          <LabelFileItem
            key={file.id}
            disabled={disabled}
            selectedFile={selectedFile}
            file={file}
            setSelectedFile={setSelectedFile}
          />
        ))}
      </FormControl>
    </Box>
  );
};

type LabelGroupRootType = { id: number | string; name: string };

type LabelListProps = {
  items: LabelGroupRootType[];
  selectedItems: LabelGroupRootType[];
  setSelectedItems: React.Dispatch<React.SetStateAction<LabelGroupRootType[]>>;
  disabled: boolean;
};
const LabelList: FC<LabelListProps> = ({
  items,
  selectedItems,
  setSelectedItems,
  disabled,
}) => {
  const handleCheck = (
    e: React.ChangeEvent<HTMLInputElement>,
    item: LabelGroupRootType
  ) => {
    const isChecked = e.target.checked;
    if (isChecked) {
      if (selectedItems.findIndex((o) => o.id === item.id) === -1) {
        setSelectedItems([...selectedItems, item]);
      }
    } else {
      if (selectedItems.findIndex((o) => o.id === item.id) !== -1) {
        setSelectedItems((pre) => pre.filter((o) => o.id !== item.id));
      }
    }
  };
  return (
    <Box sx={{ display: 'flex' }}>
      <FormControl component="fieldset" variant="standard">
        <FormLabel component="legend">Labels:</FormLabel>
        {items.map((item) => (
          <FormGroup key={item.id}>
            <FormControlLabel
              control={
                <Checkbox
                  disabled={disabled}
                  checked={
                    selectedItems.findIndex((o) => o.id === item.id) !== -1
                  }
                  onChange={(e) => handleCheck(e, item)}
                  name={item.name}
                />
              }
              label={item.name}
            />
          </FormGroup>
        ))}
      </FormControl>
    </Box>
  );
};

const DOMAIN_REGX =
  /^(?!www\.)\w+(?:(?:(?:[a-zA-z-]+):\/{1,3})?(?:[a-zA-Z0-9])(?:[a-zA-Z0-9\-.]){1,61}(?:\.[a-zA-Z]{2,})+|\[(?:(?:(?:[a-fA-F0-9]){1,4})(?::(?:[a-fA-F0-9]){1,4}){7}|::1|::)\]|(?:(?:[0-9]{1,3})(?:\.[0-9]{1,3}){3}))(?::[0-9]{1,5})?$/;

export type OnSaveParams = {
  domain?: string;
  siteUrl?: string;
  db?: string;
  urlFilter?: string;
  source?: KeywordIngredientSourceType;
  selectedLabelGroups?: any[];
  upload_url?: string;
  gsc_month_range?: number;
  docId?: number;
  name?: string;
  grammar?: string;
  min_listing?: number;
  max_listing?: number;
};

type Props = {
  open: boolean;
  onClose: () => void;
  initSource: KeywordIngredientSourceType;
  onSave?: (params: OnSaveParams) => void;
  ingredient?: any;
};

type FormData = {
  domain: string;
  siteUrl: string;
};

const KWICreateEditDialog: FC<Props> = ({
  open,
  onClose,
  onSave,
  initSource,
  ingredient,
}) => {
  const {
    formState: { errors },
    watch,
    setValue,
    handleSubmit,
    control,
  } = useForm<FormData>();
  const isEdit = Boolean(ingredient);
  const [domain, siteUrl] = watch(['domain', 'siteUrl']);

  useEffect(() => {
    if (isEdit) {
      setSource(ingredient.source);
      setValue('domain', ingredient.domain);
      setValue('siteUrl', ingredient.site_url);
    }
  }, [isEdit, ingredient, setValue]);

  const { curIndex } = useUser();
  const [source, setSource] = useState<KeywordIngredientSourceType>(initSource);
  const [db, setDb] = useState<MarketType>('us');
  const [urlFilter, setUrlFilter] = useState<any>('');
  const [gscDateRange, setGscDateRange] = useState('');
  const onSubmit = (data: FormData) => {
    let { domain, siteUrl } = data;
    if (source === 'semrush') {
      siteUrl = '';
    }
    if (source === 'product') {
      onSave!({
        source,
        grammar,
        min_listing: minListing,
        max_listing: maxListing,
      });
    } else {
      onSave!({
        name,
        domain,
        siteUrl,
        db,
        urlFilter,
        source,
        selectedLabelGroups,
        upload_url: uploadedDoc?.filename,
        gsc_month_range: parseInt(gscDateRange),
        docId: selectedFile?.id,
        grammar,
        min_listing: minListing,
        max_listing: maxListing,
      });
    }
  };

  let { data: gscUserSitesData } = useQuery(
    [curIndex, 'GSCUserSite'],
    () => getGSCUserSite(curIndex!.value),
    { enabled: Boolean(curIndex) }
  );

  useEffect(() => {
    if (isEdit) {
      setDb(ingredient.db);
    } else if (curIndex && curIndex.market) {
      setDb(curIndex!.market as MarketType);
    }
  }, [curIndex, isEdit, ingredient]);

  useEffect(() => {
    if (domain) {
      const length = domain.length;
      if (domain[length - 1] === '/') {
        setValue('domain', domain.slice(0, length - 1));
      }
    }
  }, [domain, setValue]);

  const handleUrlFilterChange = (jsonTree: any) => {
    const _filter = convertQueryBuilderTree(jsonTree);
    setUrlFilter(_filter);
  };

  const title = isEdit
    ? 'Edit Keyword Ingredient'
    : 'Create Keyword Ingredient';

  //source == labels
  const { data: labelGroupsData } = useQuery(
    [curIndex, 'LabelGroups'],
    () => postGetPagedLabels(curIndex!.value, {}),
    { enabled: Boolean(curIndex) }
  );
  let labelGroups: LabelGroupItemType[] = [];
  if (!isEmpty(labelGroupsData?.items)) {
    labelGroups = labelGroupsData.items;
  }

  const [selectedLabelGroups, setSelectedLabelGroups] = useState<
    LabelGroupRootType[]
  >([]);

  const [progress, setProgress] = useState<number>(0);

  const previewTopFolderIntervalIdRef = useRef<any>();
  const handleSetDomain = () => {
    if (isEmpty(domain)) {
      return;
    }
    previewTopFolders(curIndex!.value, { domain, db });
    setProgress(0);
    const intervalID = setInterval(updateProgress, 1000);
    previewTopFolderIntervalIdRef.current = intervalID;
    async function updateProgress() {
      let data = await previewTopFoldersProgress(curIndex!.value, {
        domain,
        db,
      });
      const { current, total } = data;
      setProgress(Math.ceil((current / total) * 100));
      if (current >= total) {
        clearInterval(intervalID);
      }
    }
  };

  let { data: topFolders, isFetching } = useQuery(
    [curIndex, 'KWICreateDialogPreviewTopFolders', domain, db],
    () => previewTopFolders(curIndex!.value, { domain, db }),
    {
      enabled: progress >= 100,
      staleTime: 1000 * 60 * 30,
    }
  );
  if (topFolders) {
    topFolders = topFolders.top_folders.map(
      (item: any) => item.key
    ) as string[];
  }
  topFolders = topFolders || [];

  const showProgress = progress > 0 && progress < 100;

  const { uploadedDocData, uploadedDocIsLoading, uploadedDoc, setUploadedDoc } =
    useUploadedDoc();
  let noUploadedDoc = false;
  if (isEmpty(uploadedDocData) && source === 'upload') noUploadedDoc = true;
  let disableSave = false;
  disableSave = noUploadedDoc;

  // csv files and columns
  let { data: csvFiles } = useQuery(
    [curIndex, 'FeedIngredient'],
    () => getFeedIngredients(curIndex!.value),
    { staleTime: 1000 * 60 }
  );
  if (csvFiles) {
    csvFiles = csvFiles.results;
    csvFiles = csvFiles.filter((item: any) => item.type === 'inv');
    csvFiles = csvFiles.map((doc: any) => {
      doc.file = convertFileName(doc.filename);
      return doc;
    });
  }
  const [selectedFile, setSelectedFile] = useState<FileType>();

  const [disableLabelList, setDisableLabelList] = useState(false);
  const [disableFileList, setDisableFileList] = useState(false);

  useEffect(() => {
    if (ingredient?.fileId && csvFiles) {
      const file = csvFiles.find((item: any) => item.id === ingredient.fileId);
      setSelectedFile(file);
    }
  }, [ingredient, csvFiles]);

  useEffect(() => {
    if (!isEmpty(selectedFile)) {
      setSelectedLabelGroups([]);
      setDisableLabelList(true);
    } else if (!isEmpty(selectedLabelGroups)) {
      setSelectedFile(undefined);
      setDisableFileList(true);
    } else {
      setDisableFileList(false);
      setDisableLabelList(false);
    }
  }, [selectedFile, selectedLabelGroups]);

  const [name, setName] = useState('');

  const [inventDocRecipeFront, setInventDocRecipeFront] = useAtom(
    inventDocRecipeFrontAtom
  );

  const [initRecipeFront, setInitRecipeFront] = useState<any>();
  useEffect(() => {
    if (inventDocRecipeFront) {
      setInitRecipeFront(inventDocRecipeFront);
    }
  }, [inventDocRecipeFront]);

  useEffect(() => {
    if (ingredient && ingredient.recipe && !inventDocRecipeFront) {
      const recipeFront = convertQueryBuilderTreeB2F(ingredient.recipe);
      setInitRecipeFront(recipeFront);
    }
  }, [ingredient, inventDocRecipeFront]);

  const handleClose = () => {
    clearInterval(previewTopFolderIntervalIdRef.current);
    setInventDocRecipeFront(undefined);
    onClose();
  };

  const [grammar, setGrammar] = useState('');
  const [minListing, setMinListing] = useState(0);
  const [maxListing, setMaxListing] = useState(0);

  let dataRangeHasError = false;
  let propertyHasError = false;
  if (source === 'gsc') {
    propertyHasError = !Boolean(siteUrl);
    dataRangeHasError = !Boolean(gscDateRange);
    if (!disableSave) {
      disableSave = dataRangeHasError || propertyHasError;
    }
  }

  return (
    <Dialog open={open} onClose={handleClose}>
      <Box component="form" onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <InfoIcon color="info" /> {title}
        </DialogTitle>
        <DialogContent>
          <TextField
            value={source}
            disabled={isEdit}
            onChange={(e) =>
              setSource(e.target.value as KeywordIngredientSourceType)
            }
            select
            margin="dense"
            label="Source"
            fullWidth
            variant="standard"
          >
            <MenuItem value={'gsc'}>Google Search Console</MenuItem>
            <MenuItem value={'semrush'}>Competitor</MenuItem>
            <MenuItem value={'labels'}>Labels</MenuItem>
            <MenuItem value={'upload'}>Upload</MenuItem>
            <MenuItem value={'product'}>Product</MenuItem>
            <MenuItem value={'site_topic'}>Site Topic</MenuItem>
          </TextField>
          {source === 'semrush' && (
            <Controller
              name="domain"
              defaultValue=""
              control={control}
              rules={{
                required: true,
                pattern: DOMAIN_REGX,
              }}
              render={({ field }) => (
                <TextField
                  {...field}
                  disabled={isEdit}
                  onBlur={handleSetDomain}
                  margin="dense"
                  label="Domain"
                  helperText={
                    errors.domain && 'Invalid domain. Example: google.com'
                  }
                  error={Boolean(errors.domain)}
                  fullWidth
                  variant="standard"
                />
              )}
            />
          )}
          {showProgress && <CircularProgressWithLabel value={progress} />}
          {source === 'gsc' && (
            <Controller
              name="siteUrl"
              defaultValue=""
              control={control}
              render={({ field }) => (
                <TextField
                  select
                  margin="dense"
                  label="Property"
                  fullWidth
                  error={propertyHasError}
                  variant="standard"
                  {...field}
                >
                  {gscUserSitesData?.map((item: any) => (
                    <MenuItem value={item.site_url} key={item.id}>
                      {item.site_url}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />
          )}
          {source === 'gsc' && (
            <TextField
              size="small"
              select
              margin="dense"
              sx={{ width: 200 }}
              label="Date range"
              value={gscDateRange}
              error={dataRangeHasError}
              onChange={(e) => setGscDateRange(e.target.value)}
            >
              <MenuItem value="1">Last 1 month</MenuItem>
              <MenuItem value="3">Last 3 month</MenuItem>
              <MenuItem value="6">Last 6 month</MenuItem>
              <MenuItem value="12">Last 12 month</MenuItem>
            </TextField>
          )}
          {source === 'product' && (
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
              <TextField
                variant="standard"
                size="small"
                margin="dense"
                label="Grammar"
                value={grammar}
                onChange={(e) => setGrammar(e.target.value)}
              />
              <TextField
                variant="standard"
                size="small"
                margin="dense"
                label="Min listing"
                value={minListing}
                onChange={(e) => setMinListing(+e.target.value)}
              />
              <TextField
                variant="standard"
                size="small"
                margin="dense"
                label="Max listing"
                value={maxListing}
                onChange={(e) => setMaxListing(+e.target.value)}
              />
            </Box>
          )}
          {source === 'site_topic' && (
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
              <TextField
                multiline
                variant="standard"
                size="small"
                margin="dense"
                label="Grammar"
                value={grammar}
                onChange={(e) => setGrammar(e.target.value)}
              />
            </Box>
          )}
          {source !== 'labels' &&
            source !== 'upload' &&
            source !== 'product' &&
            source !== 'site_topic' && (
              <>
                <TextField
                  select
                  margin="dense"
                  value={db}
                  label="Market"
                  disabled={isEdit}
                  fullWidth
                  onChange={(e) => setDb(e.target.value as MarketType)}
                  variant="standard"
                >
                  {MARKET_OPTIONS.map((item) => (
                    <MenuItem key={item.value} value={item.value}>
                      {item.label}
                    </MenuItem>
                  ))}
                </TextField>
                {source === 'semrush' && (
                  <Box sx={{ mt: 2 }}>
                    <Typography variant="body2" sx={{ mb: 1 }}>
                      Avalaible top folders:
                    </Typography>
                    {isFetching && <LoadingSpinner />}
                    {!isFetching && (
                      <ul style={{ listStyle: 'square' }}>
                        {topFolders.map((item: string) => (
                          <li key={item}>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                              <Typography variant="body2">{item}</Typography>
                              <CopyTxtBtn text={item} />
                            </Box>
                          </li>
                        ))}
                      </ul>
                    )}
                  </Box>
                )}
                <Box sx={{ mt: 2 }}>
                  <Typography variant="body2" mb={1}>
                    Rules
                  </Typography>
                  <KWIFilter
                    sourceType={source}
                    onQBChange={handleUrlFilterChange}
                    recipeFront={initRecipeFront}
                  />
                </Box>
              </>
            )}
          {source === 'labels' && labelGroups && (
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
              <TextField
                margin="dense"
                value={name}
                label="Name"
                fullWidth
                disabled={isEdit}
                onChange={(e) => setName(e.target.value)}
                variant="standard"
              />
              <LabelList
                items={labelGroups}
                selectedItems={selectedLabelGroups}
                setSelectedItems={setSelectedLabelGroups}
                disabled={isEdit || disableLabelList}
              />
              <Box>
                <LabelFileList
                  allFiles={csvFiles}
                  selectedFile={selectedFile}
                  setSelectedFile={setSelectedFile}
                  disabled={isEdit || disableFileList}
                />
              </Box>
              <Box>
                <Typography variant="body2" mb={1}>
                  Rules
                </Typography>
                <KWIFilter
                  sourceType={source}
                  onQBChange={handleUrlFilterChange}
                  recipeFront={initRecipeFront}
                />
              </Box>
            </Box>
          )}
          {source === 'upload' && (
            <List>
              {uploadedDocIsLoading && <LoadingSpinner />}
              {!uploadedDocIsLoading && noUploadedDoc && (
                <Typography color="error">
                  Please upload files in Upload CSV files tab first
                </Typography>
              )}
              {!uploadedDocIsLoading &&
                uploadedDocData.map((item: any) => (
                  <UploadedDocItem
                    doc={item}
                    key={item.id}
                    curUploadedDoc={uploadedDoc}
                    setCurUploadedDoc={setUploadedDoc}
                  />
                ))}
            </List>
          )}
        </DialogContent>
        <DialogActions>
          <Button color="inherit" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="contained" type="submit" disabled={disableSave}>
            Save
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default KWICreateEditDialog;
