import { Box, Tab, Tabs } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import WorkbenchKeyword from './keyword/WBKeyword';
import WBPage from './page/WBPage';
import WBTopic from './topic/WBTopic';
import { useLocation, useNavigate } from 'react-router-dom';
import { useUser } from 'contexts/user-context';
import SiteTopicMain from 'components/site-topic/SiteTopicMain';

const PATH_TAB_MAP: Record<string, number> = {
  page: 1,
  topic: 2,
  report: 3,
};
const TAB_PATH_LOOKUP: string[] = ['', 'page', 'topic', 'report'];

const WorkbenchMain: FC = () => {
  const [tab, setTab] = useState(0);
  const { curIndex } = useUser();
  const navigate = useNavigate();
  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    const tabPath = TAB_PATH_LOOKUP[newValue];
    navigate(`/workbench/${curIndex!.value}/${tabPath}`);
  };
  const { pathname } = useLocation();
  const paths = pathname.split('/');
  const path = paths[3];
  useEffect(() => {
    if (path && path in PATH_TAB_MAP) {
      setTab(PATH_TAB_MAP[path]);
    } else {
      setTab(0);
    }
  }, [path]);
  return (
    <Box sx={{ ml: 3, mr: 3, mt: 4 }}>
      <Tabs value={tab} onChange={handleChangeTab}>
        <Tab label="Keyword" />
        <Tab label="Page" />
        <Tab label="Topic" />
        <Tab label="Report" />
      </Tabs>
      <Box sx={{ mt: 4 }}>
        {tab === 0 && <WorkbenchKeyword />}
        {tab === 1 && <WBPage />}
        {tab === 2 && <WBTopic />}
        {tab === 3 && <SiteTopicMain />}
      </Box>
    </Box>
  );
};

export default WorkbenchMain;
