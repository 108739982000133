import { Box, Button, Typography } from '@mui/material';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { getBotifyAccounts, postBotifyAccounts } from 'apis/settings';
import { useSnackbar } from 'contexts/snackbar-context';
import { useUser } from 'contexts/user-context';
import { FC, useState } from 'react';
import BotifyDialog, { FormData } from '../dialog/AddBotifyDialog';

export type OnCrawlItemProps = {
  id: number;
  index_id: number;
  name: string;
  token: string;
  is_active: boolean;
  project_slug?: string;
};

const Item: FC<OnCrawlItemProps> = ({
  id,
  index_id,
  name,
  token,
  is_active,
  project_slug,
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        gap: 2,
        alignItems: 'center',
        mt: 1,
      }}
    >
      <Typography sx={{ fontWeight: 700, textDecoration: 'none' }}>
        {name}
      </Typography>
      <Typography sx={{ fontWeight: 700, textDecoration: 'none' }}>
        {token?.replace(/^(.{4}).*(.{4})$/, '$1******$2')}
      </Typography>
      {project_slug && (
        <Typography sx={{ fontWeight: 700, textDecoration: 'none' }}>
          {project_slug}
        </Typography>
      )}
    </Box>
  );
};

const BotifyConnection: FC = () => {
  const { curIndex } = useUser();
  const { setSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const { mutate } = useMutation({
    mutationFn: (body: any) => postBotifyAccounts(curIndex!.value, body),
    onSuccess: () => {
      queryClient.invalidateQueries([curIndex, 'SettingsBotify']);
      setSnackbar({ severity: 'success', message: 'Saved' });
    },
    onError: () => {
      setSnackbar({ severity: 'error', message: 'Invalid name or token' });
    },
  });

  let { data } = useQuery(
    [curIndex, 'SettingsBotify'],
    () => getBotifyAccounts(curIndex!.value),
    { enabled: Boolean(curIndex) }
  );

  data = data || {};

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleSave = async ({ name, token, project_slug }: FormData) => {
    mutate({ name, token, project_slug });
  };

  return (
    <Box>
      <BotifyDialog open={open} onClose={handleClose} onSave={handleSave} />
      <Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Typography sx={{ fontSize: 18, fontWeight: 700 }}>
            Botify Connections:
          </Typography>
          <Button onClick={handleOpen}>Connect</Button>
        </Box>
        <Box sx={{ mt: 2 }}>
          <Item
            key={data.id}
            id={data.id}
            index_id={data.index_id}
            name={data.name}
            token={data.token}
            is_active={data.is_active}
            project_slug={data.project_slug}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default BotifyConnection;
