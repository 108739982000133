import { Box, Button } from '@mui/material';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { FC, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from 'react-router-dom';
import { useUser } from 'contexts/user-context';
import LoadingSpinner from 'components/shared/ui/LoadingSpinner';
import DashboardChartMain from './chart/DashboardChartMain';
import { postExportPageSegmentUrlsJob } from 'apis/index_export';
import { useSnackbar } from 'contexts/snackbar-context';

const DashboardMain: FC = () => {
  const navigate = useNavigate();
  const { setSnackbar } = useSnackbar();
  const { curIndex } = useUser();
  const [pageSegmentId, setPageSegmentId] = useState<number>(0);
  if (!curIndex) {
    return <LoadingSpinner />;
  }
  const handleClickNew = () => {
    navigate(`/dashboard/${curIndex!.value}/New-Segment`);
  };
  const handleExport = async () => {
    if (pageSegmentId === 0) {
      setSnackbar({
        severity: 'error',
        message: 'Please select a segment.',
      });
    } else {
      const { job_id } = await postExportPageSegmentUrlsJob(curIndex!.value, {
        pageSegmentId,
      });
      setSnackbar({
        severity: 'success',
        message: `Job started. Please go to Setup > Download Tab to see the result, id: ${job_id}`,
      });
      }
  };
  return (
    <Box sx={{ ml: 3, mr: 3, mt: 4 }}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          onClick={handleClickNew}
        >
          Add a segment
        </Button>
        <Button
          sx={{ ml: 3 }}
          variant="contained"
          startIcon={<CloudDownloadIcon />}
          onClick={handleExport}
        >
          Export segment urls
        </Button>
      </Box>
      <Box sx={{ mt: 2 }}>
        <DashboardChartMain setPageSegmentId={setPageSegmentId} />
      </Box>
    </Box>
  );
};

export default DashboardMain;
