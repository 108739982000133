import { Box, Paper } from '@mui/material';
import { DataGridPro, GridColDef, GridToolbar } from '@mui/x-data-grid-pro';

import { FC, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { previewFeedIngredientItems } from 'apis/feed_ingredient';

let columns: GridColDef[] = [];

type Props = {
  indexId: string;
  documentId: string | number;
};

const DocumentItemsTable: FC<Props> = ({ indexId, documentId }) => {
  const [page, setPage] = useState(0);
  const [offset, setOffset] = useState(10);
  let { data, isLoading } = useQuery(
    [indexId, 'FeedIngredientItems', documentId, page, offset],
    () => previewFeedIngredientItems(indexId, documentId, page, offset)
  );
  data = data || {
    header: [],
    count: 0,
    next: null,
    previous: null,
    results: [],
  };

  // data.results
  let results = [];
  if (data.results.length > 0) {
    data.results.map((item: any, id: number) => item.data);
    results = data.results.map(function (item: any, id: number) {
      if (item.data.id === undefined) {
        item.data['id'] = item.id;
      }
      return item.data || {};
    });
  }

  // change header columns by api
  if (data.header.length > 0) {
    columns = data.header.map((field: any, id: number) => ({
      field: field,
      headerClassName: 'App-Mui-DataGrid-Header',
      width: 300,
      sortable: false,
    }));
  }

  return (
    <Paper
      sx={{ display: 'flex', height: '100%', flexDirection: 'column', gap: 2 }}
    >
      <Box sx={{ flexGrow: 1 }}>
        <DataGridPro
          initialState={{
            columns: {
              columnVisibilityModel: {
                id: false,
              },
            },
          }}
          pagination
          loading={isLoading}
          autoHeight
          getRowHeight={() => 'auto'}
          columns={columns}
          rows={results}
          paginationMode="server"
          rowCount={data.count}
          pageSize={offset}
          onPageSizeChange={setOffset}
          page={page}
          onPageChange={setPage}
          rowsPerPageOptions={[10, 25, 100]}
          disableDensitySelector
          disableColumnFilter
          components={{ Toolbar: GridToolbar }}
          sx={{
            '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': {
              py: '12px',
              borderBottomColor: '#eee',
            },
            '&.MuiDataGrid-root--densityStandard .MuiDataGrid-columnSeparator':
              { color: '#ccc' },
          }}
        />
      </Box>
    </Paper>
  );
};
export default DocumentItemsTable;
