import { Box, CssBaseline, ThemeProvider } from '@mui/material';
import { FunctionComponent, ReactNode } from 'react';
import { getTheme } from 'theme/theme';
import Snackbar from 'components/shared/ui/Snackbar';

interface Props {
  children: ReactNode;
}

export const LayoutBlank: FunctionComponent<Props> = ({ children }) => {
  const theme = getTheme();
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box sx={{ margin: '2rem auto', padding: { xs: 1 } }}>{children}</Box>
      <Snackbar />
    </ThemeProvider>
  );
};
