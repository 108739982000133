import { Button } from '@mui/material';
import { FunctionComponent, useState } from 'react';
import LinkIcon from '@mui/icons-material/Link';
import { useLocation } from 'react-router-dom';
import { jsonObj2Url } from 'utils/utils-query-builder';

type Props = {
  jsonObj: any;
};

const CopyLinkBtn: FunctionComponent<Props> = ({ jsonObj }) => {
  const [isCopied, setIsCopied] = useState(false);
  const location = useLocation();
  const pathName = location.pathname;
  const host = window.location.host;
  let text = 'Copy link';
  if (isCopied) text = 'Link copied';
  const clickHandler = () => {
    const jsonObjStr = jsonObj2Url(jsonObj);
    const link = `${host}${pathName}?jsonObjStr=${jsonObjStr}`;
    navigator.clipboard.writeText(link);
    setIsCopied(true);
    setTimeout(() => setIsCopied(false), 3000);
  };
  if (!jsonObj) return <></>;
  return (
    <Button onClick={clickHandler}>
      <LinkIcon sx={{ mr: 0.2 }} />
      {text}
    </Button>
  );
};
export default CopyLinkBtn;
