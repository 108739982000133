import { Box } from '@mui/material';
import { FC } from 'react';
import DownloadTable from './DownloadTable';

type Props = {
  indexId: string;
  opporId?: number;
};

const DownloadMain: FC<Props> = ({ indexId, opporId }) => {
  return (
    <Box>
      <DownloadTable indexId={indexId} opporId={opporId} />
    </Box>
  );
};

export default DownloadMain;
