import { Box, Button, Typography } from '@mui/material';
import { FC } from 'react';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from 'react-router-dom';
import IndexList from './IndexList';

const IndexMain: FC = () => {
  const navigate = useNavigate();
  const handleClickNew = () => {
    navigate(`/settings/New-Index`);
  };
  return (
    <Box sx={{ ml: 3, mr: 3, mt: 4 }}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Typography variant="h3">Index List</Typography>
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          onClick={handleClickNew}
        >
          New Index
        </Button>
      </Box>
      <Box sx={{ mt: 4 }}>
        <IndexList />
      </Box>
    </Box>
  );
};

export default IndexMain;
