import {
  FunctionComponent,
  useState,
  useCallback,
  useMemo,
  useEffect,
} from 'react';
import {
  Query,
  Builder,
  Utils as QbUtils,
  JsonTree,
} from 'react-awesome-query-builder';
import 'react-awesome-query-builder/lib/css/styles.css';
import 'react-awesome-query-builder/lib/css/compact_styles.css';
import MuiConfig from 'react-awesome-query-builder/lib/config/mui';
import styled from 'styled-components';
import { FIELD_SEPARATOR } from 'constants/constants';
import { KeywordIngredientSourceType } from 'types/types';
const InitialConfig = MuiConfig;
InitialConfig.settings.showNot = false;
InitialConfig.settings.fieldSeparator = FIELD_SEPARATOR;
InitialConfig.settings.fieldSeparatorDisplay = '.';

const OPERATORS = ['equal', 'not_equal', 'like', 'not_like'];
const OPERATORS_NUMBER = [
  'equal',
  'not_equal',
  'greater',
  'greater_or_equal',
  'less',
  'less_or_equal',
];

const CONFIG: any = {
  ...InitialConfig,
  fields: {
    url: {
      label: 'url',
      type: 'text',
      valueSources: ['value'],
      operators: OPERATORS,
    },
    keyword: {
      label: 'keyword',
      type: 'text',
      valueSources: ['value'],
      operators: OPERATORS,
    },
  },
};

export const CONFIG_SEMRUSH: any = {
  ...InitialConfig,
  fields: {
    url: {
      label: 'url',
      type: 'text',
      valueSources: ['value'],
      operators: OPERATORS,
    },
    keyword: {
      label: 'keyword',
      type: 'text',
      valueSources: ['value'],
      operators: OPERATORS,
    },
    word_count: {
      label: 'word count',
      type: 'number',
      valueSources: ['value'],
    },
    top_folders: {
      label: 'top folders',
      type: 'text',
      valueSources: ['value'],
      operators: OPERATORS,
    },
    // used by GSC keyword ingredient, rename CONFIG_SEMRUSH?
    month: {
      label: 'month',
      type: 'number',
      valueSources: ['value'],
    },
    year: {
      label: 'year',
      type: 'number',
      valueSources: ['value'],
    },
  },
};

const CONFIG_LABEL: any = {
  ...InitialConfig,
  fields: {
    brand: {
      label: 'brand',
      type: 'text',
      valueSources: ['value'],
      operators: OPERATORS,
    },
    category: {
      label: 'category',
      type: 'text',
      valueSources: ['value'],
      operators: OPERATORS,
    },
    phrase: {
      label: 'phrase',
      type: 'text',
      valueSources: ['value'],
      operators: OPERATORS,
    },
    product_count: {
      label: 'product count',
      type: 'number',
      valueSources: ['value'],
      operators: OPERATORS_NUMBER,
    },
  },
};

const QBStyles = styled('div')`
  .group {
    & > .group--header {
      .group--conjunctions {
        display: none;
      }
    }
  }
  .group--actions--tr button:last-child {
    display: none;
  }
`;

const EMPTY_RECIPE: JsonTree = {
  type: 'group',
  id: 'bb89ba8b-89ab-4cde-b012-3183699f09e4',
  children1: [
    {
      type: 'rule',
      properties: {
        field: null,
        operator: null,
        value: [],
        valueSrc: [],
      },
    },
  ],
  properties: {
    conjunction: 'AND',
    not: false,
  },
};

type Props = {
  onQBChange: Function;
  sourceType: KeywordIngredientSourceType;
  recipeFront?: any;
};

const KWIFilter: FunctionComponent<Props> = ({
  onQBChange,
  sourceType,
  recipeFront,
}) => {
  let config = useMemo<any>(() => {
    if (sourceType === 'semrush') {
      return CONFIG_SEMRUSH;
    } else if (sourceType === 'labels') {
      return CONFIG_LABEL;
    }
    return CONFIG;
  }, [sourceType]);
  const [inputBuilderTree, setInputBuilderTree] = useState(
    QbUtils.checkTree(QbUtils.loadTree(EMPTY_RECIPE), config)
  );

  useEffect(() => {
    if (recipeFront) {
      const tree = QbUtils.loadTree(recipeFront);
      setInputBuilderTree(tree);
    }
  }, [recipeFront]);

  const handleQBChange = useCallback(
    (immutableTree: any, config: any) => {
      const recipe_front = QbUtils.getTree(immutableTree);
      onQBChange(recipe_front);
      setInputBuilderTree(immutableTree);
    },
    [onQBChange]
  );

  const renderBuilder = useCallback(
    (props: any) => (
      <div className="query-builder-container" key={sourceType}>
        <div className="query-builder qb-lite" style={{ margin: 0 }}>
          <QBStyles>
            {/* 
                // @ts-ignore */}
            <Builder {...props} />
          </QBStyles>
        </div>
      </div>
    ),
    [sourceType]
  );

  return (
    <div key={sourceType}>
      {/* 
    // @ts-ignore */}
      <Query
        {...config}
        value={inputBuilderTree}
        onChange={handleQBChange}
        renderBuilder={renderBuilder}
      />
    </div>
  );
};

export default KWIFilter;
