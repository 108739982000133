import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { FunctionComponent } from 'react';
import { useTheme } from '@mui/material';

const wrapLabel = (str: string, limit: number) => {
  const words = str.split(' ');
  let aux = [];
  let concat = [];

  for (let i = 0; i < words.length; i++) {
    concat.push(words[i]);
    let join = concat.join(' ');
    if (join.length > limit) {
      aux.push(join);
      concat = [];
    }
  }

  if (concat.length) {
    aux.push(concat.join(' ').trim());
  }

  return aux;
};

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export type Props = {
  labelX: string;
  labelX2?: string;
  labelY: string;
  labels: (string | number)[];
  bars: number[];
  bars2?: number[];
  title?: string;
  height?: string;
  barThickness?: number;
};

const HorizontalBarChart2: FunctionComponent<Props> = ({
  labelX,
  labelX2,
  labelY,
  labels,
  bars,
  bars2,
  title,
  height,
  barThickness,
}) => {
  const new_labels = labels.map((item) => wrapLabel(item.toString(), 30));
  const _height = height || '200px';
  let _title = { display: false, text: title };
  if (title) _title.display = true;

  const theme = useTheme();
  const color = theme.palette.primary.main;

  const options = {
    indexAxis: 'y' as const,
    responsive: true,
    plugins: {
      legend: {
        position: 'top' as const,
      },
      title: _title,
      datalabels: {
        display: true,
        color: 'black',
        align: 'end' as 'end',
        anchor: 'end' as 'end',
        font: { size: 12 },
        formatter: (v: number) => v.toLocaleString(),
      },
    },
    scales: {
      y: {
        title: {
          display: true,
          text: labelY,
        },
      },
    },
  };

  let _data = {
    labels: new_labels,
    datasets: [
      {
        label: labelX,
        data: bars,
        backgroundColor: color,
        barThickness,
      },
    ],
  };
  if (bars2 && bars2?.length > 0) {
    _data = {
      labels: new_labels,
      datasets: [
        {
          label: labelX2!,
          data: bars2!,
          backgroundColor: color,
          barThickness,
        },
      ],
    };
  }

  return (
    <Bar
      height={_height}
      options={options}
      data={_data}
      plugins={[ChartDataLabels]}
    />
  );
};

export default HorizontalBarChart2;
