import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import { FC, useState } from 'react';
import { Box, Button, Card, Link, Typography } from '@mui/material';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import { isEmpty } from 'utils';

type SerpHeadingType = {
  keyword: string;
  url: string;
  position: number;
  h1: string[];
  h2: string[];
  h3: string[];
};

type HeadingProps = {
  heading: string[];
  type: 'H1' | 'H2' | 'H3';
};

const Heading: FC<HeadingProps> = ({ heading, type }) => {
  if (heading.length === 0) return <></>;
  let ml = 0;
  let fontSize = 16;
  if (type === 'H2') {
    ml = 2;
    fontSize = 14;
  }
  if (type === 'H3') {
    ml = 4;
    fontSize = 12;
  }
  return (
    <Box ml={ml}>
      {heading.map((item, i) => (
        <Box key={i} sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <Typography
            sx={{
              fontSize,
              fontWeight: 700,
              pr: 1,
              my: 0.6,
              borderRight: '1px solid #ccc',
            }}
            color="text.disabled"
          >
            {type}
          </Typography>
          <Typography sx={{ fontSize }}>{item}</Typography>
        </Box>
      ))}
    </Box>
  );
};

type ItemProps = {
  item: SerpHeadingType;
};

const Item: FC<ItemProps> = ({ item }) => {
  const { url, h1, h2, h3, position } = item;
  return (
    <Card sx={{ p: 2 }}>
      <Link
        href={url}
        target="_blank"
        rel="noreferrer"
        sx={{
          overflowWrap: 'break-word',
          width: '100%',
          textDecoration: 'none',
          fontWeight: 700,
          fontSize: 16,
        }}
      >
        {h1[0] || url}
      </Link>
      <Box sx={{ my: 1 }}>
        <Typography
          sx={{
            px: 1,
            py: 0.4,
            display: 'inline-block',
            backgroundColor: '#eee',
            borderRadius: '20%',
          }}
        >
          {'#' + position}
        </Typography>
      </Box>
      <Box>
        <Heading heading={h1} type="H1" />
        <Heading heading={h2} type="H2" />
        <Heading heading={h3} type="H3" />
      </Box>
    </Card>
  );
};

type Props = {
  items: SerpHeadingType[];
};

const SerpHeadingsCell: FC<Props> = ({ items }) => {
  const [isExpanded, setIsExpended] = useState(false);
  const showExpendBtn = items.length > 1 && !isExpanded;
  const handleClickShowMore = () => {
    setIsExpended(true);
  };

  const handleClickShowLess = () => {
    setIsExpended(false);
  };

  return (
    <Box>
      {!isExpanded && <Item item={items[0]} />}
      {isExpanded && (
        <Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            {items.map((item, i) => (
              <Item key={i} item={item} />
            ))}
          </Box>
          <Box>
            <Button
              onClick={handleClickShowLess}
              endIcon={<KeyboardDoubleArrowUpIcon />}
            >
              Show less
            </Button>
          </Box>
        </Box>
      )}
      {showExpendBtn && (
        <Box>
          <Button
            onClick={handleClickShowMore}
            endIcon={<KeyboardDoubleArrowDownIcon />}
          >
            Show More
          </Button>
        </Box>
      )}
    </Box>
  );
};

export const renderSerpHeadings = (params: GridRenderCellParams<any>) => {
  const items = params.value;
  if (!items || isEmpty(items)) return <></>;
  return <SerpHeadingsCell items={items} />;
};
