import { Button, Dialog, DialogActions, DialogTitle, Box } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { deleteWorkbenchPageVariable } from 'apis/workbench';
import { useUser } from 'contexts/user-context';
import { FunctionComponent } from 'react';

type Props = {
  open: boolean;
  onClose: () => void;
  varId: string | number;
  varName: string | number;
};

const DelVarDlg: FunctionComponent<Props> = ({
  open,
  onClose,
  varId,
  varName,
}) => {
  const queryClient = useQueryClient();
  const { curIndex } = useUser();
  const { mutate: m_delete } = useMutation({
    mutationFn: () => deleteWorkbenchPageVariable(curIndex!.value, varId),
    onSuccess: () => {
      queryClient.invalidateQueries([curIndex, 'WBPagesVariables']);
    },
  });
  const handleDelete = () => {
    onClose();
    m_delete();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <Box>
        <DialogTitle>
          Are you sure you want to delete variable {varName}
        </DialogTitle>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button color="error" onClick={handleDelete}>
            Delete
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default DelVarDlg;
