import Layout from 'components/layout/Layout';
import IndexDetail from 'components/settings/IndexDetail';
import { FC } from 'react';

const IndexDetailPage: FC = () => {
  return (
    <Layout>
      <IndexDetail />
    </Layout>
  );
};

export default IndexDetailPage;
