export const getKeywordIngredient = async (index: string, opporId?: number) => {
  const axiosInstance = require('utils/utils-axios-instance').default;
  let data;
  if (opporId) {
    data = await axiosInstance.get(
      `index/${index}/api/keyword-ingredient?opporId=${opporId}`
    );
  } else {
    data = await axiosInstance.get(`index/${index}/api/keyword-ingredient`);
  }
  return data.data;
};

export const postKeywordIngredient = async (index: string, body: any) => {
  const axiosInstance = require('utils/utils-axios-instance').default;
  const data = await axiosInstance.post(
    `index/${index}/api/keyword-ingredient`,
    body
  );
  return data.data;
};

export const getKeywordIngredientItem = async (
  index: string | number,
  ingredient_id: string | number,
  source: string
) => {
  const axiosInstance = require('utils/utils-axios-instance').default;
  const data = await axiosInstance.get(
    `index/${index}/api/keyword-ingredient/${ingredient_id}?source=${source}`
  );
  return data.data;
};

export const updateKeywordIngredientItem = async (
  index: string | number,
  ingredient_id: string | number,
  source: string,
  body: any
) => {
  const axiosInstance = require('utils/utils-axios-instance').default;
  const data = await axiosInstance.patch(
    `index/${index}/api/keyword-ingredient/${ingredient_id}?source=${source}`,
    body
  );
  return data.data;
};

export const deleteKeywordIngredientItem = async (
  index: string | number,
  ingredient_id: string | number,
  source: string
) => {
  const axiosInstance = require('utils/utils-axios-instance').default;
  const data = await axiosInstance.delete(
    `index/${index}/api/keyword-ingredient/${ingredient_id}?source=${source}`
  );
  return data.data;
};

export const postKeywordIngredientFetch = async (
  index: string | number,
  ingredient_id: string | number,
  source: string,
  body: any
) => {
  const axiosInstance = require('utils/utils-axios-instance').default;
  const data = await axiosInstance.post(
    `index/${index}/api/keyword-ingredient/${ingredient_id}/fetch_data?source=${source}`,
    body
  );
  return data.data;
};

export const getKeywordIngredientJobResult = async (
  index: string | number,
  ingredient_id: string | number,
  page: number,
  offset: number,
  source: string
) => {
  const axiosInstance = require('utils/utils-axios-instance').default;
  const data = await axiosInstance.get(
    `index/${index}/api/keyword-ingredient/${ingredient_id}/job_result?page=${page}&source=${source}&offset=${offset}`
  );
  return data.data;
};

export const previewTopFolders = async (index: any, body: any) => {
  const axiosInstance = require('utils/utils-axios-instance').default;
  const data = await axiosInstance.post(
    `index/${index}/api/keyword-ingredient/preview_top_folders`,
    body
  );
  return data.data;
};

export const previewTopFoldersProgress = async (index: any, body: any) => {
  const axiosInstance = require('utils/utils-axios-instance').default;
  const data = await axiosInstance.post(
    `index/${index}/api/keyword-ingredient/preview_top_folders_progress`,
    body
  );
  return data.data;
};

export const getGSCUserSite = async (index: any) => {
  const axiosInstance = require('utils/utils-axios-instance').default;
  const data = await axiosInstance.get(`index/${index}/api/gsc-user-site`);
  return data.data;
};

export const getOrganicKeywordNumber = async (
  index: any,
  db: string,
  domain: string
) => {
  const axiosInstance = require('utils/utils-axios-instance').default;
  const data = await axiosInstance.get(
    `index/${index}/api/get-organic-keyword-count?domain=${domain}&db=${db}`
  );
  return data.data;
};

export const postInvDoc = async (index: any, body: any) => {
  const axiosInstance = require('utils/utils-axios-instance').default;
  const data = await axiosInstance.post(`index/${index}/api/inv-doc`, body);
  return data.data;
};
