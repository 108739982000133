import DashboardMain from 'components/dashboard/DashboardMain';
import Layout from 'components/layout/Layout';
import { FC } from 'react';

const DashboardPage: FC = () => {
  return (
    <Layout>
      <DashboardMain />
    </Layout>
  );
};

export default DashboardPage;
