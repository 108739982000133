import { Box } from '@mui/material';
import { FC } from 'react';
import { useParams } from 'react-router-dom';
import DocumentItemsTable from './DocumentItemsTable';

const DocumentItemsMain: FC = () => {
  const params = useParams();
  const indexId = params.idx!;
  const documentId = params.documentId!;
  return (
    <Box sx={{ ml: 3, mr: 3, mt: 4 }}>
      <Box sx={{ mt: 4 }}>
        <DocumentItemsTable
          indexId={indexId}
          documentId={documentId}
        />
      </Box>
    </Box>
  );
};

export default DocumentItemsMain;
