import axios from 'axios';
import jwt_decode from 'jwt-decode';
import dayjs from 'dayjs';

const baseURL = process.env.REACT_APP_BACKEND_BASE!;

let accessToken = localStorage.getItem('accessToken');
//`Bearer ${token}`
const axiosInstance = axios.create({
  baseURL,
  headers: { Authorization: `Bearer ${accessToken}` },
});

axiosInstance.interceptors.request.use(async (req) => {
  if (!accessToken) {
    accessToken = localStorage.getItem('accessToken')!;
    req.headers!.Authorization = `Bearer ${accessToken}`;
  }
  const user: any = jwt_decode(accessToken);
  const offsetDay = dayjs.unix(user.exp).diff(dayjs()) / 60 / 60 / 24 / 1000;
  const isNearExpired = offsetDay < 7;

  if (!isNearExpired) return req;

  const refreshToken = localStorage.getItem('refreshToken');
  const response = await axios.post(`${baseURL}token/refresh/`, {
    refresh: refreshToken,
  });
  accessToken = response.data.access as string;
  localStorage.setItem('accessToken', accessToken);
  req.headers!.Authorization = `Bearer ${accessToken}`;
  return req;
});

export default axiosInstance;
