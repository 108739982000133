import { Button, Box, Typography } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import { previewChatGPT } from 'apis/operation';
import { useSnackbar } from 'contexts/snackbar-context';
import { useUser } from 'contexts/user-context';
import { useAtom } from 'jotai';
import { FC, useState } from 'react';
import ReactJson from 'react-json-view';
import { selectedFunctionIngredientIdAtom } from 'store/jotai';

const PreivewChatGPTCell: FC<{ params: GridRenderCellParams<string> }> = ({
  params,
}) => {
  let operation = params.row['__operation'];
  const [functionIngredientId] = useAtom(selectedFunctionIngredientIdAtom);
  const { curIndex } = useUser();
  if (operation?.operations) {
    operation = operation.operations[0];
  }
  const noSubject = !operation && !functionIngredientId;
  const [result, setResult] = useState('');
  const [loading, setLoading] = useState(false);
  const { setSnackbar } = useSnackbar();

  const handleClick = async () => {
    setLoading(true);
    let data: any;
    try {
      data = await previewChatGPT(curIndex!.value, {
        operation,
        doc: params.row,
        functionIngredientId,
      });
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setSnackbar({ severity: 'error', message: 'Error occurred.' });
    }
    let preview = data?.doc?.preview || '';
    setResult(preview);
  };
  let content = (
    <Typography sx={{ mt: 2, fontWeight: 700 }} variant="body2">
      {result}
    </Typography>
  );
  if (typeof result === 'object') {
    // @ts-ignore
    content = <ReactJson name={null} src={result} />;
  }
  return (
    <Box>
      <Button
        onClick={handleClick}
        variant="contained"
        size="small"
        disabled={loading || noSubject}
      >
        {loading ? 'Updating...' : 'Preview ChatGPT'}
      </Button>
      {noSubject && (
        <Typography variant="caption" color="error" sx={{ display: 'block' }}>
          *Missing function ingredient
        </Typography>
      )}
      <Box sx={{ my: 2 }}>{result && content}</Box>
    </Box>
  );
};

export const renderPreviewChatGPT = (params: GridRenderCellParams<string>) => {
  return <PreivewChatGPTCell params={params} />;
};

export default renderPreviewChatGPT;
