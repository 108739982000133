import { Box, Button, Typography } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import { FC, useState } from 'react';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';

const VariableCellShowMore: FC<{ value: string }> = ({ value }) => {
  const [curValue, setCurValue] = useState(value.slice(0, 100));
  const showMoreBtn = curValue.length < value.length;
  const handleClickShowMore = () => {
    setCurValue(value);
  };
  return (
    <Box>
      <Typography
        variant="caption"
        sx={{
          overflowWrap: 'break-word',
          width: '100%',
          my: 2,
          whiteSpace: 'pre',
        }}
      >
        {curValue}
      </Typography>
      <Box>
        {showMoreBtn && (
          <Button
            onClick={handleClickShowMore}
            endIcon={<KeyboardDoubleArrowDownIcon />}
          >
            Show More
          </Button>
        )}
      </Box>
    </Box>
  );
};

const VariableCell: FC<{ params: GridRenderCellParams<any> }> = ({
  params,
}) => {
  let value = params.value;
  if (!value) return <></>;
  if (Array.isArray(value)) value = value.join(',');
  if (typeof value !== 'string')
    return <Typography variant="caption">{value}</Typography>;

  if (value.length > 100) return <VariableCellShowMore value={value} />;

  return (
    <Typography
      variant="caption"
      sx={{
        overflowWrap: 'break-word',
        width: '100%',
      }}
    >
      {value}
    </Typography>
  );
};

export const renderVariable = (params: GridRenderCellParams<string[]>) => {
  return <VariableCell params={params} />;
};

export default VariableCell;
