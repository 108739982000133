import { Box, CssBaseline, ThemeProvider } from '@mui/material';
import Header from 'components/header/Header';
import NavBreadcrumbs from 'components/shared/breadcrumbs/NavBreadcrumbs';
import Snackbar from 'components/shared/ui/Snackbar';
import { FunctionComponent, ReactNode } from 'react';
import { getTheme } from 'theme/theme';

interface Props {
  children: ReactNode;
}

const Layout: FunctionComponent<Props> = ({ children }) => {
  const theme = getTheme();
  let content = children;

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Header />
      <Box
        sx={{
          margin: '2rem auto',
          my: 8,
        }}
      >
        <Box sx={{ mt: 12, ml: 3 }}>
          <NavBreadcrumbs />
        </Box>
        {content}
      </Box>
      <Snackbar />
    </ThemeProvider>
  );
};

export default Layout;
