import { DataGridPro, GridColDef, GridToolbar } from '@mui/x-data-grid-pro';
import { FC } from 'react';
import { renderUrl } from '../../shared/table-cell/UrlCell';
import { getNumber } from '../../shared/table-cell';

export const columns: GridColDef[] = [
  {
    field: 'keyword',
    headerName: 'keyword',
    width: 200,
  },
  {
    field: 'volume',
    headerName: 'volume',
    width: 100,
    valueGetter: getNumber,
  },
  {
    field: 'position',
    headerName: 'position',
    width: 100,
    valueGetter: getNumber,
  },
  {
    field: 'cpc',
    headerName: 'cpc',
    width: 100,
    valueGetter: getNumber,
  },
  {
    field: 'url',
    headerName: 'competitive URL',
    width: 300,
    renderCell: renderUrl,
  },
  {
    field: 'traffic',
    headerName: 'traffic',
    width: 100,
    valueGetter: getNumber,
  },
  {
    field: 'is_branded',
    headerName: 'is branded',
    width: 100,
    renderCell(params) {
      return params.value ? 'Yes' : 'No';
    },
  },
];

type Props = {
  row: any;
};
const WBTopicNestedTable: FC<Props> = ({ row }) => {
  let items = row.cluster_keywords || [];
  items = items.map((item: any, id: number) => ({ ...item, id }));
  return (
    <DataGridPro
      components={{ Toolbar: GridToolbar }}
      disableDensitySelector
      disableColumnFilter
      density="compact"
      columns={columns}
      rows={items}
      sx={{ width: 1440 }}
      hideFooter
    />
  );
};

export default WBTopicNestedTable;
