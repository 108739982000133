import { Box, Button, Link, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { getConnectedAccounts } from 'apis/settings';
import { useUser } from 'contexts/user-context';
import { FC } from 'react';

const baseURL = process.env.REACT_APP_BACKEND_BASE!;

type ItemProps = {
  site_url: string;
  gsc_user_uid: string;
};

const Item: FC<ItemProps> = ({ gsc_user_uid, site_url }) => {
  return (
    <Box
      sx={{
        mt: 2,
        display: 'flex',
        gap: 2,
        alignItems: 'center',
      }}
    >
      <Link
        href={`mailto:${gsc_user_uid}`}
        sx={{ fontWeight: 700, textDecoration: 'none' }}
      >
        {gsc_user_uid}
      </Link>
      <Link href={site_url} sx={{ fontWeight: 700, textDecoration: 'none' }}>
        {site_url}
      </Link>
    </Box>
  );
};

const GSCConnection: FC = () => {
  const { curIndex } = useUser();
  const { data } = useQuery(
    [curIndex, 'ConnectedAccount'],
    () => getConnectedAccounts(curIndex!.value),
    { enabled: Boolean(curIndex) }
  );

  const handleClick = () => {
    const url = `${baseURL}gsc/connect/?index_id=${curIndex!.value}`;
    window.open(url);
  };
  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Typography sx={{ fontSize: 18, fontWeight: 700 }}>
          GSC Connection:
        </Typography>
        <Button onClick={handleClick}>Connect</Button>
      </Box>
      <Box sx={{ mt: 1 }}>
        {data?.map((item: any) => (
          <Item
            key={item.id}
            site_url={item.site_url}
            gsc_user_uid={item.gsc_user_uid}
          />
        ))}
      </Box>
    </Box>
  );
};

export default GSCConnection;
