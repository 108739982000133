export const getSiteTopicReports = async (index: string | number) => {
  const axiosInstance = require('utils/utils-axios-instance').default;
  const data = await axiosInstance.get(
    `index/${index}/api/site-topic/get-reports`
  );
  if (data?.data?.items) {
    data.data.items = data.data.items.sort((x: any, y: any) => y.id - x.id);
  }
  return data.data;
};

export const getSiteTopicReportItem = async (
  index: string | number,
  reportId: string | number
) => {
  const axiosInstance = require('utils/utils-axios-instance').default;
  const data = await axiosInstance.get(
    `index/${index}/api/site-topic/${reportId}`
  );
  return data.data;
};

export const getSiteTopicTopLabelGroups = async (
  index: string | number,
  reportId: string | number
) => {
  const axiosInstance = require('utils/utils-axios-instance').default;
  const data = await axiosInstance.get(
    `index/${index}/api/site-topic/${reportId}/top-label-groups`
  );
  return data.data;
};

export const getSiteTopicTopLabelsByGroup = async (
  index: string | number,
  reportId: string | number,
  labelGroup: string
) => {
  const axiosInstance = require('utils/utils-axios-instance').default;
  const data = await axiosInstance.get(
    `index/${index}/api/site-topic/${reportId}/labels-by-group?label_group=${labelGroup}`
  );
  return data.data;
};

export const getSiteTopicTopLabels = async (
  index: string | number,
  reportId: string | number,
  labelGroup1: string,
  labelGroup2: string,
  label1?: string,
  label2?: string
) => {
  let params = `?label_group1=${labelGroup1}&label_group2=${labelGroup2}`;
  if (label1) params += `&label1=${label1}`;
  if (label2) params += `&label2=${label2}`;
  const axiosInstance = require('utils/utils-axios-instance').default;
  const data = await axiosInstance.get(
    `index/${index}/api/site-topic/${reportId}/top-labels${params}`
  );
  return data.data;
};

export const getSiteTopicTopPages = async (
  index: string | number,
  reportId: string | number,
  keywords: string
) => {
  let params = `?keywords=${keywords}`;
  const axiosInstance = require('utils/utils-axios-instance').default;
  const data = await axiosInstance.get(
    `index/${index}/api/site-topic/${reportId}/top-pages${params}`
  );
  return data.data;
};

export const getSiteTopicTopTopics = async (
  index: string | number,
  reportId: string | number,
  page: number,
  pageSize: number,
  orderBy: string,
  searchLabels: string = ''
) => {
  const axiosInstance = require('utils/utils-axios-instance').default;
  let params = `?page=${page}&pageSize=${pageSize}&orderBy=${orderBy}`;
  if (searchLabels) {
    params += `&searchLabels=${searchLabels}`;
  }
  const data = await axiosInstance.get(
    `index/${index}/api/site-topic/${reportId}/top-topics${params}`
  );
  const ret = data?.data;
  if (ret?.items) {
    const items = ret.items;
    for (const item of items) {
      item['report_id'] = item['report'];
    }
  }
  return ret;
};

export const getSiteTopicTopGrammar = async (
  index: string | number,
  reportId: string | number,
  page: number,
  pageSize: number,
  orderBy: string,
  grammar: string = ''
) => {
  const axiosInstance = require('utils/utils-axios-instance').default;
  let params = `?page=${page}&pageSize=${pageSize}&orderBy=${orderBy}`;
  if (grammar) {
    params += `&grammar=${grammar}`;
  }
  const data = await axiosInstance.get(
    `index/${index}/api/site-topic/${reportId}/top-grammar${params}`
  );
  const ret = data?.data;
  if (ret?.items) {
    const items = ret.items;
    const agg_total_volume = ret.agg_total_volume;
    const agg_total_impression = ret.agg_total_impression;
    const agg_total_gsc_traffic = ret.agg_total_gsc_traffic;
    const agg_competitive_traffic = ret.agg_competitive_traffic;
    for (const item of items) {
      item['report_id'] = item['report'];
      item['agg_total_volume'] = agg_total_volume;
      item['agg_total_impression'] = agg_total_impression;
      item['agg_total_gsc_traffic'] = agg_total_gsc_traffic;
      item['agg_competitive_traffic'] = agg_competitive_traffic;
    }
  }
  return ret;
};
