import { Box, Typography, Tooltip } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import { FC } from 'react';
import GridCellExpand from './GridCellExpand';

type KeywordType = {
  keyword: string;
  volume: number;
  position: number;
  cpc: number;
  url: string;
  traffic: number;
  is_branded: boolean;
};

type Props = {
  params: GridRenderCellParams<KeywordType[]>;
};

// aka topic keywords
const ClusterKeywordCell: FC<Props> = ({ params }) => {
  let value = params.value;
  if (!value) return <></>;
  value = value.sort((a, b) => b.traffic - a.traffic);
  const content = [];
  let length = value.length;
  for (let i = 0; i < length; i++) {
    const obj = value[i];
    const tip = `
		Volume: ${obj.volume} 
		| Position: ${obj.position}
		| Cpc: ${obj.cpc}
		| Url: ${obj.url}
		| Traffic: ${obj.traffic}
		| Branded: ${obj.is_branded ? 'Yes' : 'No'}
		`;
    const item = (
      <Tooltip key={i} title={tip}>
        <Box
          sx={{
            color: '#333',
            backgroundColor: '#ddd',
            padding: '4px 8px',
            borderRadius: '4px',
            display: 'flex',
            gap: 1,
            alignItems: 'center',
          }}
        >
          <Typography variant="body2" sx={{ whiteSpace: 'nowrap' }}>
            {obj.keyword}
          </Typography>
        </Box>
      </Tooltip>
    );
    content.push(item);
  }
  return <GridCellExpand value={content} width={params.colDef.computedWidth} />;
};

export const renderClusterKeyword = (
  params: GridRenderCellParams<KeywordType[]>
) => {
  return <ClusterKeywordCell params={params} />;
};

export default ClusterKeywordCell;
