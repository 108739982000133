import { Box, Button } from '@mui/material';
import { FC } from 'react';
import AddIcon from '@mui/icons-material/Add';
import OpportunitiesTable from './OpportunitiesTable';
import { useNavigate } from 'react-router-dom';

const OpprtunitiesMain: FC = () => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate(`New-Opportunities`);
  };
  return (
    <Box sx={{ ml: 3, mr: 3, mt: 4 }}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
        }}
      >
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          onClick={handleClick}
        >
          New Opportunity
        </Button>
      </Box>
      <Box sx={{ mt: 4 }}>
        <OpportunitiesTable />
      </Box>
    </Box>
  );
};

export default OpprtunitiesMain;
