import { Button } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import { FC, useState } from 'react';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import { v4 as uuid } from 'uuid';

const ArrayCellShowMore: FC<{ arr: string[] }> = ({ arr }) => {
  const [curArr, setCurArr] = useState(arr.slice(0, 10));
  const showMoreBtn = curArr.length < arr.length;
  const handleClickShowMore = () => {
    const end = curArr.length + 10;
    setCurArr(arr.slice(0, end));
  };
  return (
    <ul>
      {curArr.map((item: string) => (
        <li
          key={uuid()}
          style={{
            overflowWrap: 'break-word',
            width: '100%',
            fontSize: 14,
          }}
        >
          {item}
        </li>
      ))}
      {showMoreBtn && (
        <Button
          onClick={handleClickShowMore}
          endIcon={<KeyboardDoubleArrowDownIcon />}
        >
          Show More
        </Button>
      )}
    </ul>
  );
};

const ArrayCell: FC<{ params: GridRenderCellParams<string[]> }> = ({
  params,
}) => {
  let value = params.value;
  if (!value) return <></>;
  if (!Array.isArray(value)) value = [value];

  if (value.length > 5) return <ArrayCellShowMore arr={value} />;

  return (
    <ul>
      {value.map((item: string) => (
        <li
          key={uuid()}
          style={{
            overflowWrap: 'break-word',
            width: '100%',
            fontSize: 14,
          }}
        >
          {item}
        </li>
      ))}
    </ul>
  );
};

export const renderArray = (params: GridRenderCellParams<string[]>) => {
  return <ArrayCell params={params} />;
};

export default ArrayCell;
