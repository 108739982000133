import { Box } from '@mui/material';
import { FC } from 'react';
import { useLocation } from 'react-router-dom';
import IndexNewEdit from './IndexNewEdit';
import SetupViews from './views/SetupViews';

const IndexDetail: FC = () => {
  const location = useLocation();
  const pathNames = location.pathname.split('/');
  const subPath = pathNames[2];
  const subPath2 = pathNames[3];
  const isNew = subPath === 'New-Index';
  const isEdit = subPath2 === 'edit';

  return (
    <Box sx={{ ml: 3, mr: 3, mt: 4 }}>
      {isNew && <IndexNewEdit />}
      {isEdit && <IndexNewEdit indexId={subPath} />}
      {!isNew && !isEdit && (
        <Box>
          <SetupViews indexId={subPath} />
        </Box>
      )}
    </Box>
  );
};

export default IndexDetail;
