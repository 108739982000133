import { createTheme } from '@mui/material/styles';

export const getTheme = () => {
  const theme = createTheme({
    typography: {
      fontFamily: [
        'Public Sans',
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
    },
    palette: {
      mode: 'light',
      primary: {
        main: '#5764BA',
      },
      background: {
        paper: '#FFF',
        default: '#F5F7FA',
      },
      text: {
        primary: '#333',
        secondary: '#62646F',
      },
      divider: '#D6DAE1',
      grey: {
        A400: '#B7B9C0',
        A700: '#D6DAE1',
        600: '#81818C',
      },
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            textTransform: 'none',
            fontSize: 15,
            fontWeight: 600,
          },
        },
      },
      MuiTab: {
        styleOverrides: {
          root: {
            textTransform: 'none',
          },
        },
      },
      MuiTypography: {
        styleOverrides: {
          h3: {
            fontWeight: 600,
            fontSize: 24,
            lineHeight: 1,
          },
          h4: {
            fontWeight: 600,
            fontSize: 20,
            lineHeight: 1,
          },
          h5: {
            fontWeight: 500,
            fontSize: 16,
            lineHeight: 1,
          },
        },
      },
    },
  });
  return theme;
};
