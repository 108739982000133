import { Box, Button } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { postFunctionIngredient } from 'apis/function_ingredient';
import { useSnackbar } from 'contexts/snackbar-context';
import { FC, useState } from 'react';
import FunctionIngredientDialog from './FunctionIngredientDialog';
import FunctionIngredientList from './FunctionIngredientList';
import { useUser } from 'contexts/user-context';

type Props = {
  indexId: string;
};

const FunctionIngredientMain: FC<Props> = ({ indexId }) => {
  const queryClient = useQueryClient();
  const { curIndex } = useUser();
  const { setSnackbar } = useSnackbar();
  const { mutate } = useMutation({
    mutationFn: (body: any) => postFunctionIngredient(indexId, body),
    onSuccess: () => {
      queryClient.invalidateQueries([curIndex, 'FunctionIngredient']);
      setSnackbar({ severity: 'success', message: 'Created' });
    },
  });

  const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false);

  const handleCreateDialogClose = () => {
    setIsCreateDialogOpen(false);
  };

  const handleSave = (data: any) => {
    const { api_key, api_type, params, name, model_name } = data;
    const body = {
      index: indexId,
      model_name,
      api_key,
      api_type,
      name,
      params,
    };
    mutate(body);
  };
  const handleClickCreateBtn = () => {
    setIsCreateDialogOpen(true);
  };
  return (
    <Box>
      <FunctionIngredientDialog
        onClose={handleCreateDialogClose}
        onSave={handleSave}
        open={isCreateDialogOpen}
      />
      <Box sx={{ display: 'flex', my: 2, justifyContent: 'flex-start' }}>
        <Button variant="outlined" onClick={handleClickCreateBtn}>
          Add New
        </Button>
      </Box>
      <Box>
        <FunctionIngredientList />
      </Box>
    </Box>
  );
};

export default FunctionIngredientMain;
