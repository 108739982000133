import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  TextField,
  DialogTitle,
  Box,
} from '@mui/material';
import { FunctionComponent } from 'react';
import { useForm, Controller } from 'react-hook-form';

export type FormData = {
  name: string;
  token: string;
};

type Props = {
  open: boolean;
  onClose: () => void;
  onSave: (data: FormData) => void;
};

const AddOnCrawlDialog: FunctionComponent<Props> = ({
  open,
  onClose,
  onSave,
}) => {
  const { handleSubmit, control } = useForm<FormData>();

  const onSubmit = (data: FormData) => {
    const { name, token } = data;
    onSave({ name, token });
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <Box component="form" onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>Enter Name and Token</DialogTitle>
        <DialogContent>
          <DialogContentText>
            To create OnCrawl connection, please enter the name and token.
          </DialogContentText>
          <Controller
            name="name"
            defaultValue=""
            control={control}
            render={({ field }) => (
              <TextField
                margin="dense"
                label="Name"
                fullWidth
                variant="standard"
                {...field}
              />
            )}
          />
          <Controller
            name="token"
            defaultValue=""
            control={control}
            render={({ field }) => (
              <TextField
                margin="dense"
                label="Token"
                fullWidth
                variant="standard"
                {...field}
              />
            )}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button type="submit">Create</Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default AddOnCrawlDialog;
