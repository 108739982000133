import { useLocation } from 'react-router-dom';
import { Breadcrumbs as MuiBreadcrumbs } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import { FunctionComponent } from 'react';
import LinkRouter from '../ui/LinkRouter';

const BreadcrumbsSettings: FunctionComponent = () => {
  const location = useLocation();
  const pathNames = location.pathname.split('/');
  const indexPath = pathNames[2];
  let path2 = `/settings/${indexPath}`;
  if (indexPath === 'New-Index') path2 = '/settings/New-Index';

  return (
    <MuiBreadcrumbs>
      <HomeIcon color="disabled" />
      <LinkRouter underline="hover" color="inherit" to={`/settings`}>
        Setup
      </LinkRouter>
      <LinkRouter underline="hover" color="inherit" to={path2}>
        {indexPath}
      </LinkRouter>
    </MuiBreadcrumbs>
  );
};

export default BreadcrumbsSettings;
