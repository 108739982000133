import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
  DialogTitle,
  Box,
} from '@mui/material';
import React, { FunctionComponent, useState } from 'react';

type Props = {
  open: boolean;
  onClose: () => void;
  onSave: (param: { name: string; id?: number | string }) => void;
  variable?: any;
  saveOrApply: 'Save' | 'Apply';
};
const OperationApplyDialog: FunctionComponent<Props> = ({
  open,
  onClose,
  onSave,
  variable,
  saveOrApply,
}) => {
  const [name, setName] = useState(variable?.name || '');
  const [errName, setErrName] = useState(false);
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const _name = e.target.value;
    setName(_name);
    const isValid = /^[a-zA-Z][0-9a-zA-Z_]*$/.test(_name);
    if (!isValid) {
      setErrName(true);
    } else {
      setErrName(false);
    }
  };
  const canSave = Boolean(name.trim() && !errName);
  const submitHandler = (e: React.FormEvent) => {
    e.preventDefault();
    if (errName) return;
    let id: any = undefined;
    if (variable) id = variable.id;
    onSave({ name: name.trim(), id });
  };
  let title = 'Enter name';
  if (variable) title = 'Update variable ' + variable.name;
  return (
    <Dialog open={open} onClose={onClose}>
      <Box component="form" onSubmit={submitHandler}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            autoComplete="off"
            margin="dense"
            label="Name"
            value={name}
            error={errName}
            helperText={
              errName &&
              'Name should not be empty and start with number and only allow alphabet, number, and _'
            }
            onChange={handleChange}
            fullWidth
            variant="standard"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button type="submit" disabled={!canSave}>
            {saveOrApply}
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default OperationApplyDialog;
