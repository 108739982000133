export const getIndexSchduledAction = async (index: string | number) => {
  const axiosInstance = require('utils/utils-axios-instance').default;
  const data = await axiosInstance.get(`index/${index}/api/scheduled-action`);
  return data.data;
};

export const postIndexSchduledAction = async (
  index: string | number,
  body: any
) => {
  const axiosInstance = require('utils/utils-axios-instance').default;
  const data = await axiosInstance.post(
    `index/${index}/api/scheduled-action`,
    body
  );
  return data.data;
};

export const patchIndexSchduledAction = async (
  index: string | number,
  id: number,
  body: any
) => {
  const axiosInstance = require('utils/utils-axios-instance').default;
  const data = await axiosInstance.patch(
    `index/${index}/api/scheduled-action/${id}`,
    body
  );
  return data.data;
};
