import DashboardSegDetail from 'components/dashboard/detail/DashboardSegDetail';
import Layout from 'components/layout/Layout';
import { FC } from 'react';

const DashboardSegDetailPage: FC = () => {
  return (
    <Layout>
      <DashboardSegDetail />
    </Layout>
  );
};

export default DashboardSegDetailPage;
