export const postOperation = async (index: string | number, body: any) => {
  const axiosInstance = require('utils/utils-axios-instance').default;
  const data = await axiosInstance.post(
    `index/${index}/api/apply-operation`,
    body
  );
  return data.data;
};

export const previewChatGPT = async (index: string | number, body: any) => {
  const axiosInstance = require('utils/utils-axios-instance').default;
  const data = await axiosInstance.post(
    `index/${index}/api/preview-chatgpt`,
    body
  );
  return data.data;
};
