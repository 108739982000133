import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import {
  DataGridPro,
  GridColDef,
  GridSortModel,
  GridToolbar,
} from '@mui/x-data-grid-pro';
import { useQuery } from '@tanstack/react-query';
import { postInvDoc } from 'apis/keyword_ingredient';
import { useUser } from 'contexts/user-context';
import { FC, useCallback, useState } from 'react';
import { initTableDataFromDataRaw } from 'utils';
import { convertQueryBuilderTree } from 'utils/utils-query-builder';
import KWIFilter from './KWIFilter';
import { useAtom } from 'jotai';
import { inventDocRecipeFrontAtom } from 'store/jotai';

const columns: GridColDef[] = [
  {
    field: 'id',
    headerClassName: 'App-Mui-DataGrid-Header',
    sortable: false,
  },
  {
    field: 'brand',
    headerClassName: 'App-Mui-DataGrid-Header',
  },
  {
    field: 'category',
    headerClassName: 'App-Mui-DataGrid-Header',
  },
  {
    field: 'phrase',
    headerClassName: 'App-Mui-DataGrid-Header',
    flex: 1,
  },
  {
    field: 'product_count',
    headerName: 'product count',
    minWidth: 120,
    headerClassName: 'App-Mui-DataGrid-Header',
  },
  {
    field: 'keywords_count',
    headerName: 'keyword count',
    minWidth: 120,
    headerClassName: 'App-Mui-DataGrid-Header',
  },
  {
    field: 'updated',
    headerClassName: 'App-Mui-DataGrid-Header',
  },
];

type Props = {
  open: boolean;
  docId: number;
  onClose: () => void;
};

const InvDocTableDlg: FC<Props> = ({ open, onClose, docId }) => {
  const { curIndex } = useUser();
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [backQuery, setBackQuery] = useState<any>(null);
  const [orderBy, setOrderBy] = useState<string>('-product_count');
  const handleSorting = useCallback(
    (sortModel: GridSortModel) => {
      const _sort = sortModel[0];
      if (!_sort) return;
      let prefix = '';
      if (_sort.sort === 'desc') prefix = '-';
      setOrderBy(prefix + _sort.field);
    },
    [setOrderBy]
  );

  const [, setInventDocRecipe] = useAtom(inventDocRecipeFrontAtom);

  const handleChangeRecipe = useCallback(
    (jsonTree: any) => {
      const _backQuery = convertQueryBuilderTree(jsonTree);
      setBackQuery(_backQuery);
      setInventDocRecipe(jsonTree);
    },
    [setInventDocRecipe]
  );

  const { data, isLoading } = useQuery(
    [curIndex, 'inv-doc', docId, orderBy, page, pageSize, backQuery],
    () =>
      postInvDoc(curIndex!.value, {
        orderBy,
        page,
        pageSize,
        backQuery,
        docId,
      }),
    { staleTime: 1000 * 60, keepPreviousData: true }
  );
  const tableData = initTableDataFromDataRaw(data);

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle>Inventory Document Result</DialogTitle>
      <DialogContent>
        <Box>
          <KWIFilter sourceType={'labels'} onQBChange={handleChangeRecipe} />
        </Box>
        <DataGridPro
          initialState={{
            columns: {
              columnVisibilityModel: {
                id: false,
              },
            },
          }}
          pageSize={pageSize}
          onPageSizeChange={setPageSize}
          page={page}
          onPageChange={setPage}
          rowsPerPageOptions={[10, 25, 100]}
          onSortModelChange={handleSorting}
          pagination
          paginationMode="server"
          sortingMode="server"
          loading={isLoading}
          autoHeight
          getRowHeight={() => 'auto'}
          columns={columns}
          rows={tableData.items}
          rowCount={tableData.count}
          disableDensitySelector
          disableColumnFilter
          components={{ Toolbar: GridToolbar }}
          sx={{
            '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': {
              py: '12px',
              borderBottomColor: '#eee',
            },
            '&.MuiDataGrid-root--densityStandard .MuiDataGrid-columnSeparator':
              { color: '#ccc' },
            display: 'flex',
            flexDirection: 'column-reverse',
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default InvDocTableDlg;
