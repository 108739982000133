import { Box, Paper, Switch, Link } from '@mui/material';
import { DataGridPro, GridColDef, GridToolbar } from '@mui/x-data-grid-pro';
import { Utils as QbUtils } from 'react-awesome-query-builder';
import { CONFIG_SEMRUSH } from './KWIFilter';

import { FC, useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import {
  getKeywordIngredient,
  getOrganicKeywordNumber,
  updateKeywordIngredientItem,
} from 'apis/keyword_ingredient';
import MoreMenuBtn from './MoreMenuBtn';
import { convertQueryBuilderTreeB2F } from 'utils/utils-query-builder';
import { useUser } from 'contexts/user-context';
import { getNumber } from 'components/shared/table-cell';
import { KeywordIngredientSourceType } from 'types/types';
import { renderUrl } from '../download/DownloadTable';
import { convertFileName } from 'utils';

type NumberOfOrganicKeywordCellProp = {
  db: string;
  domain: string;
};
const NumberOfOrganicKeywordCell: FC<NumberOfOrganicKeywordCellProp> = ({
  db,
  domain,
}) => {
  const { curIndex } = useUser();
  const { data, isLoading } = useQuery(
    ['NumberOfOrganicKeywordCell', db, domain],
    () => getOrganicKeywordNumber(curIndex!.value, db, domain)
  );
  let res: any = '';
  if (data && data.data && data.data.length > 0) {
    res = data.data[0]['Organic Keywords'];
    if (res) res = parseFloat(res).toLocaleString();
  }
  return <Box key={isLoading.toString()}>{isLoading ? 'loading...' : res}</Box>;
};

type EnabledSwitchProps = {
  indexId: string | number;
  ingredientId: string | number;
  source: string;
  enabled: boolean;
};
const EnabledSwitch: FC<EnabledSwitchProps> = ({
  ingredientId,
  enabled,
  indexId,
  source,
}) => {
  const [checked, setChecked] = useState(enabled);
  useEffect(() => {
    setChecked(enabled);
  }, [enabled]);
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
    updateKeywordIngredientItem(indexId, ingredientId, source, {
      disabled: !event.target.checked,
    });
  };
  return <Switch checked={checked} onChange={handleChange} />;
};

type LinkOpporSwitchProps = {
  indexId: string | number;
  ingredientId: string | number;
  source: string;
  opporId: number;
  enabled: boolean;
};
const LinkOpporSwitch: FC<LinkOpporSwitchProps> = ({
  ingredientId,
  enabled,
  indexId,
  opporId,
  source,
}) => {
  const [checked, setChecked] = useState(enabled);
  useEffect(() => {
    setChecked(enabled);
  }, [enabled]);
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
    updateKeywordIngredientItem(indexId, ingredientId, source, {
      opporEnabled: event.target.checked,
      opporId,
    });
  };
  return <Switch checked={checked} onChange={handleChange} />;
};

const columnsGSC: GridColDef[] = [
  {
    field: 'id',
    headerClassName: 'App-Mui-DataGrid-Header',
  },
  {
    field: 'db',
    headerClassName: 'App-Mui-DataGrid-Header',
    width: 10,
  },
  {
    field: 'disabled',
    headerName: 'enabled',
    headerClassName: 'App-Mui-DataGrid-Header',
    width: 80,
    valueGetter: (params) => !params.value,
    renderCell(params) {
      const enabled = !params.row.disabled;
      return (
        <EnabledSwitch
          ingredientId={params.id}
          indexId={params.row.indexId}
          enabled={enabled}
          source={params.row.source}
        />
      );
    },
  },
  {
    field: 'isBindOppor',
    headerName: 'Link current opportunity',
    headerClassName: 'App-Mui-DataGrid-Header',
    width: 180,
    valueGetter: (params) => params.value,
    renderCell(params) {
      const enabled = !!params.row.isBindOppor;
      return (
        <LinkOpporSwitch
          ingredientId={params.id}
          indexId={params.row.indexId}
          opporId={params.row.opporId}
          enabled={enabled}
          source={params.row.source}
        />
      );
    },
  },
  {
    field: 'site_url',
    headerName: 'site url',
    headerClassName: 'App-Mui-DataGrid-Header',
    width: 200,
    flex: 1,
  },
  {
    field: 'fetch_status',
    headerName: 'fetch status',
    headerClassName: 'App-Mui-DataGrid-Header',
    width: 100,
    valueGetter: (params) => params.row.status,
  },
  {
    field: 'rows_written',
    headerName: 'row count',
    headerClassName: 'App-Mui-DataGrid-Header',
    valueGetter: getNumber,
  },
  {
    field: 'date',
    headerClassName: 'App-Mui-DataGrid-Header',
    width: 100,
  },
  {
    field: 'recipe',
    headerClassName: 'App-Mui-DataGrid-Header',
    width: 200,
    valueGetter: (params) => params.row.recipeStr,
    flex: 1,
  },
  {
    field: 'action',
    headerClassName: 'App-Mui-DataGrid-Header',
    width: 80,
    renderCell(params) {
      return (
        <MoreMenuBtn
          indexId={params.row.indexId}
          source={params.row.source}
          opporId={params.row.opporId}
          ingredient={params.row.ingredient}
        />
      );
    },
  },
];

const columnsSemrush: GridColDef[] = [
  {
    field: 'id',
    headerClassName: 'App-Mui-DataGrid-Header',
  },
  {
    field: 'db',
    headerClassName: 'App-Mui-DataGrid-Header',
    width: 10,
  },
  {
    field: 'disabled',
    headerName: 'enabled',
    headerClassName: 'App-Mui-DataGrid-Header',
    width: 80,
    valueGetter: (params) => !params.value,
    renderCell(params) {
      const enabled = !params.row.disabled;
      return (
        <EnabledSwitch
          ingredientId={params.id}
          indexId={params.row.indexId}
          enabled={enabled}
          source={params.row.source}
        />
      );
    },
  },
  {
    field: 'isBindOppor',
    headerName: 'Link current opportunity',
    headerClassName: 'App-Mui-DataGrid-Header',
    width: 180,
    valueGetter: (params) => params.value,
    renderCell(params) {
      const enabled = !!params.row.isBindOppor;
      return (
        <LinkOpporSwitch
          ingredientId={params.id}
          indexId={params.row.indexId}
          opporId={params.row.opporId}
          enabled={enabled}
          source={params.row.source}
        />
      );
    },
  },
  {
    field: 'domain',
    headerClassName: 'App-Mui-DataGrid-Header',
    width: 200,
  },
  {
    field: 'limit',
    headerClassName: 'App-Mui-DataGrid-Header',
    valueGetter: getNumber,
  },
  {
    field: 'fetch_status',
    headerName: 'fetch status',
    headerClassName: 'App-Mui-DataGrid-Header',
    width: 100,
    valueGetter: (params) => params.row.status,
  },
  {
    field: 'rows_written',
    headerName: 'row count',
    headerClassName: 'App-Mui-DataGrid-Header',
    valueGetter: getNumber,
  },
  {
    field: 'number_of_organic_keyword',
    headerName: '# organic keyword',
    headerClassName: 'App-Mui-DataGrid-Header',
    width: 180,
    renderCell: (params) => (
      <NumberOfOrganicKeywordCell
        db={params.row.db}
        domain={params.row.domain}
      />
    ),
  },
  {
    field: 'recipe',
    headerClassName: 'App-Mui-DataGrid-Header',
    width: 200,
    valueGetter: (params) => params.row.recipeStr,
    flex: 1,
  },
  {
    field: 'url_filter',
    headerName: 'display_filter',
    headerClassName: 'App-Mui-DataGrid-Header',
    width: 200,
    flex: 1,
  },
  {
    field: 'semrush_filter_url',
    headerName: 'display url',
    headerClassName: 'App-Mui-DataGrid-Header',
    width: 200,
    renderCell: renderUrl,
    flex: 1,
  },
  {
    field: 'action',
    headerClassName: 'App-Mui-DataGrid-Header',
    width: 80,
    renderCell(params) {
      return (
        <MoreMenuBtn
          indexId={params.row.indexId}
          source={params.row.source}
          opporId={params.row.opporId}
          ingredient={params.row.ingredient}
        />
      );
    },
  },
];

const columnsLabels: GridColDef[] = [
  {
    field: 'id',
    headerClassName: 'App-Mui-DataGrid-Header',
  },
  {
    field: 'name',
    headerClassName: 'App-Mui-DataGrid-Header',
  },
  {
    field: 'disabled',
    headerName: 'enabled',
    headerClassName: 'App-Mui-DataGrid-Header',
    width: 80,
    valueGetter: (params) => !params.value,
    renderCell(params) {
      const enabled = !params.row.disabled;
      return (
        <EnabledSwitch
          ingredientId={params.id}
          indexId={params.row.indexId}
          enabled={enabled}
          source={params.row.source}
        />
      );
    },
  },
  {
    field: 'isBindOppor',
    headerName: 'Link current opportunity',
    headerClassName: 'App-Mui-DataGrid-Header',
    width: 180,
    valueGetter: (params) => params.value,
    renderCell(params) {
      const enabled = !!params.row.isBindOppor;
      return (
        <LinkOpporSwitch
          ingredientId={params.id}
          indexId={params.row.indexId}
          opporId={params.row.opporId}
          enabled={enabled}
          source={params.row.source}
        />
      );
    },
  },
  {
    field: 'file',
    headerName: 'inventory file',
    headerClassName: 'App-Mui-DataGrid-Header',
    flex: 3,
    renderCell(params) {
      const file = params.row.file;
      let fileName = convertFileName(params.row.filename);
      return (
        <Link href={file} target="_blank" rel="noreferrer">
          {fileName}
        </Link>
      );
    },
  },
  {
    field: 'labels',
    headerName: 'labels',
    headerClassName: 'App-Mui-DataGrid-Header',
    width: 100,
    flex: 1,
  },
  {
    field: 'label_group_to_parse',
    headerName: 'label group to parse phrase',
    headerClassName: 'App-Mui-DataGrid-Header',
    width: 100,
    flex: 1,
  },
  {
    field: 'recipe',
    headerClassName: 'App-Mui-DataGrid-Header',
    width: 200,
    // TODO: change it to params.row.recipeStr, but requires add
    // fields to CONFIG_SEMRUSH
    valueGetter: (params) => JSON.stringify(params.row.recipe, null, 2),
    flex: 1,
  },
  {
    field: 'fetch_status',
    headerName: 'fetch status',
    headerClassName: 'App-Mui-DataGrid-Header',
    width: 100,
    valueGetter: (params) => params.row.status,
  },
  {
    field: 'count',
    headerName: 'phrase count',
    headerClassName: 'App-Mui-DataGrid-Header',
    valueGetter: getNumber,
  },
  {
    field: 'rows_written',
    headerName: 'keyword count',
    headerClassName: 'App-Mui-DataGrid-Header',
    valueGetter: getNumber,
    flex: 1,
  },
  {
    field: 'action',
    headerClassName: 'App-Mui-DataGrid-Header',
    width: 80,
    renderCell(params) {
      return (
        <MoreMenuBtn
          indexId={params.row.indexId}
          source={params.row.source}
          opporId={params.row.opporId}
          ingredient={params.row.ingredient}
        />
      );
    },
  },
];

const columnsUpload: GridColDef[] = [
  {
    field: 'id',
    headerClassName: 'App-Mui-DataGrid-Header',
  },
  {
    field: 'disabled',
    headerName: 'enabled',
    headerClassName: 'App-Mui-DataGrid-Header',
    width: 80,
    valueGetter: (params) => !params.value,
    renderCell(params) {
      const enabled = !params.row.disabled;
      return (
        <EnabledSwitch
          ingredientId={params.id}
          indexId={params.row.indexId}
          enabled={enabled}
          source={params.row.source}
        />
      );
    },
  },
  {
    field: 'isBindOppor',
    headerName: 'Link current opportunity',
    headerClassName: 'App-Mui-DataGrid-Header',
    width: 180,
    valueGetter: (params) => params.value,
    renderCell(params) {
      const enabled = !!params.row.isBindOppor;
      return (
        <LinkOpporSwitch
          ingredientId={params.id}
          indexId={params.row.indexId}
          opporId={params.row.opporId}
          enabled={enabled}
          source={params.row.source}
        />
      );
    },
  },
  {
    field: 'upload_url',
    headerName: 'uploaded file',
    headerClassName: 'App-Mui-DataGrid-Header',
    flex: 1,
  },
  {
    field: 'fetch_status',
    headerName: 'fetch status',
    headerClassName: 'App-Mui-DataGrid-Header',
    width: 100,
    valueGetter: (params) => params.row.status,
  },
  {
    field: 'rows_written',
    headerName: 'row count',
    headerClassName: 'App-Mui-DataGrid-Header',
    valueGetter: getNumber,
  },
  {
    field: 'action',
    headerClassName: 'App-Mui-DataGrid-Header',
    width: 80,
    renderCell(params) {
      return (
        <MoreMenuBtn
          indexId={params.row.indexId}
          source={params.row.source}
          opporId={params.row.opporId}
          ingredient={params.row.ingredient}
        />
      );
    },
  },
];

const columnsProduct: GridColDef[] = [
  {
    field: 'id',
    headerClassName: 'App-Mui-DataGrid-Header',
  },
  {
    field: 'disabled',
    headerName: 'enabled',
    headerClassName: 'App-Mui-DataGrid-Header',
    width: 80,
    valueGetter: (params) => !params.value,
    renderCell(params) {
      const enabled = !params.row.disabled;
      return (
        <EnabledSwitch
          ingredientId={params.id}
          indexId={params.row.indexId}
          enabled={enabled}
          source={params.row.source}
        />
      );
    },
  },
  {
    field: 'isBindOppor',
    headerName: 'Link current opportunity',
    headerClassName: 'App-Mui-DataGrid-Header',
    width: 180,
    valueGetter: (params) => params.value,
    renderCell(params) {
      const enabled = !!params.row.isBindOppor;
      return (
        <LinkOpporSwitch
          ingredientId={params.id}
          indexId={params.row.indexId}
          opporId={params.row.opporId}
          enabled={enabled}
          source={params.row.source}
        />
      );
    },
  },
  {
    field: 'grammar',
    headerName: 'grammar',
    headerClassName: 'App-Mui-DataGrid-Header',
    flex: 1,
  },
  {
    field: 'min_listing',
    headerName: 'min listing count',
    headerClassName: 'App-Mui-DataGrid-Header',
    width: 140,
  },
  {
    field: 'max_listing',
    headerName: 'max listing count',
    headerClassName: 'App-Mui-DataGrid-Header',
    width: 140,
  },
  {
    field: 'total',
    headerName: 'total',
    headerClassName: 'App-Mui-DataGrid-Header',
    width: 100,
  },
  {
    field: 'discarded_floor',
    headerName: 'discarded low count',
    headerClassName: 'App-Mui-DataGrid-Header',
    width: 160,
  },
  {
    field: 'discarded_ceil',
    headerName: 'discarded high count',
    headerClassName: 'App-Mui-DataGrid-Header',
    width: 160,
  },
  {
    field: 'rows_written',
    headerName: 'row count',
    headerClassName: 'App-Mui-DataGrid-Header',
    valueGetter: getNumber,
  },
  {
    field: 'action',
    headerClassName: 'App-Mui-DataGrid-Header',
    width: 80,
    renderCell(params) {
      return (
        <MoreMenuBtn
          indexId={params.row.indexId}
          source={params.row.source}
          opporId={params.row.opporId}
          ingredient={params.row.ingredient}
        />
      );
    },
  },
];

const columnsSiteTopic: GridColDef[] = [
  {
    field: 'id',
    headerClassName: 'App-Mui-DataGrid-Header',
  },
  {
    field: 'disabled',
    headerName: 'enabled',
    headerClassName: 'App-Mui-DataGrid-Header',
    width: 80,
    valueGetter: (params) => !params.value,
    renderCell(params) {
      const enabled = !params.row.disabled;
      return (
        <EnabledSwitch
          ingredientId={params.id}
          indexId={params.row.indexId}
          enabled={enabled}
          source={params.row.source}
        />
      );
    },
  },
  {
    field: 'isBindOppor',
    headerName: 'Link current opportunity',
    headerClassName: 'App-Mui-DataGrid-Header',
    width: 180,
    valueGetter: (params) => params.value,
    renderCell(params) {
      const enabled = !!params.row.isBindOppor;
      return (
        <LinkOpporSwitch
          ingredientId={params.id}
          indexId={params.row.indexId}
          opporId={params.row.opporId}
          enabled={enabled}
          source={params.row.source}
        />
      );
    },
  },
  {
    field: 'grammar',
    headerName: 'grammar',
    headerClassName: 'App-Mui-DataGrid-Header',
    flex: 1,
  },
  {
    field: 'total',
    headerName: 'total',
    width: 100,
    headerClassName: 'App-Mui-DataGrid-Header',
  },
  {
    field: 'rows_written',
    headerName: 'row count',
    headerClassName: 'App-Mui-DataGrid-Header',
    width: 120,
    valueGetter: getNumber,
  },
  {
    field: 'action',
    headerClassName: 'App-Mui-DataGrid-Header',
    width: 80,
    renderCell(params) {
      return (
        <MoreMenuBtn
          indexId={params.row.indexId}
          source={params.row.source}
          opporId={params.row.opporId}
          ingredient={params.row.ingredient}
        />
      );
    },
  },
];

const COLUMNS_LOOKUP: Record<KeywordIngredientSourceType, GridColDef[]> = {
  gsc: columnsGSC,
  semrush: columnsSemrush,
  labels: columnsLabels,
  upload: columnsUpload,
  product: columnsProduct,
  site_topic: columnsSiteTopic,
};

type Props = {
  indexId: string;
  ingredientSource: KeywordIngredientSourceType;
  opporId?: number;
};

const KWIngredientTable: FC<Props> = ({
  indexId,
  ingredientSource,
  opporId,
}) => {
  let { data } = useQuery([indexId, 'keyword-ingredient', opporId], () =>
    getKeywordIngredient(indexId, opporId)
  );

  if (data) {
    data = data.filter(
      (item: any) => item.source === ingredientSource.toLocaleLowerCase()
    );
    data = data.map((item: any) => {
      const recipe_back = item.recipe;
      let recipeStr: any = '';
      if (recipe_back) {
        const recipe_front = convertQueryBuilderTreeB2F(recipe_back);
        const inputBuilderTree = QbUtils.checkTree(
          QbUtils.loadTree(recipe_front),
          CONFIG_SEMRUSH
        );
        recipeStr = QbUtils.queryString(inputBuilderTree, CONFIG_SEMRUSH, true);
      }
      return {
        ...item,
        indexId,
        ingredient: item,
        recipeStr,
        opporId,
      };
    });
  }
  data = data || [];

  const [pageSize, setPageSize] = useState(10);
  let columns = COLUMNS_LOOKUP[ingredientSource];
  if (!opporId) {
    columns = columns.filter((col) => col.field !== 'isBindOppor');
  }

  return (
    <Paper
      sx={{ display: 'flex', height: '100%', flexDirection: 'column', gap: 2 }}
    >
      <Box sx={{ flexGrow: 1 }}>
        <DataGridPro
          initialState={{
            columns: {
              columnVisibilityModel: {
                id: true,
              },
            },
          }}
          pagination
          pageSize={pageSize}
          rowsPerPageOptions={[10, 25, 100]}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          autoHeight
          getRowHeight={() => 'auto'}
          columns={columns}
          rows={data}
          disableDensitySelector
          disableColumnFilter
          components={{ Toolbar: GridToolbar }}
          componentsProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
            },
          }}
          sx={{
            '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': {
              py: '12px',
              borderBottomColor: '#eee',
            },
            '&.MuiDataGrid-root--densityStandard .MuiDataGrid-columnSeparator':
              { color: '#ccc' },
          }}
        />
      </Box>
    </Paper>
  );
};
export default KWIngredientTable;
